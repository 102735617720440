import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { useLocation } from 'react-router-dom'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import { getUser } from '../auth/LoginState'
import ApprovePost from './ApprovePost'
import ApprovePostRemoveAttachments from './ApprovePostRemoveAttachments'
import DeletePost from './DeletePost'
import Post from './Post'

type P = {
    pid: string
}

const PostsNeedApproving: React.FC = () => {
    const user = getUser()
    const location = useLocation().state as P
    const [posts, setPosts] = useState<any>()
    const [modal, setModal] = useState<boolean>(false)
    const [modalAttachments, setModalAttachments] = useState<boolean>(false)
    const [, setModalDelete] = useState<boolean>(false)
    const [formPost, setFormPost] = useState<any>()
    const [formDelete, setFormDelete] = useState<any>()
    const [formLinks, setFormLinks] = useState<any>()

    const getPosts = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/approve/list`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setPosts(res.data.posts)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [user.token])

    useEffect(() => {
        getPosts()
    }, [setPosts, getPosts])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-5xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Posts requiring approval ({posts?.length})</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-5xl mt-16 min-h-screen'>
                {posts &&
                    posts.map((p: any, c: number) => {
                        return (
                            <div key={c}>
                                <Post
                                    post={p}
                                    notificationId={location ? location.pid : undefined}
                                    setFormPost={setFormPost}
                                    setFormLinks={setFormLinks}
                                    links={p.links}
                                    setModal={setModal}
                                    setModalAttachments={setModalAttachments}
                                    setModalDelete={setModalDelete}
                                    setFormDelete={setFormDelete}
                                />
                            </div>
                        )
                    })}
            </div>

            {modal ? (
                <ApprovePost
                    modal={setModal}
                    post={formPost}
                    getPosts={getPosts}
                />
            ) : null}

            {modalAttachments ? (
                <ApprovePostRemoveAttachments
                    modal={setModalAttachments}
                    post={formPost}
                    getPosts={getPosts}
                    formLinks={formLinks}
                    setFormLinks={setFormLinks}
                />
            ) : null}

            {formDelete ? (
                <DeletePost
                    modal={setFormDelete}
                    post={formDelete}
                    getPosts={getPosts}
                />
            ) : null}
        </>
    )
}

export default PostsNeedApproving
