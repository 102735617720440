import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import IHearYouIcon from '../../assets/images/icon_i hear you.svg'
import ISupportYouIcon from '../../assets/images/icon_i support you.svg'
import { userAction, logResourceAction } from '../../helpers/logs'

type PR = {
    componentType: string
    type: string
    resource?: string
    comment?: string
    hearYou: boolean
    hearYouCounter: number
    supportYou: boolean
    supportYouCounter: number
    getResources: () => void
    // logResourceAction: (action: string, reaction: string) => void
}

const ResourceReact: React.FC<PR> = ({
    componentType,
    type,
    resource,
    hearYou,
    supportYou,
    hearYouCounter,
    supportYouCounter,
    getResources,
    // logResourceAction,
}) => {
    const usr = getUser()
    const [reaction, setReaction] = useState<string>('')

    const update = async (t: string) => {
        let reactionState = ''

        if (type === 'hear') {
            if (hearYou) {
                reactionState = 'I hear you OFF'
            } else {
                reactionState = 'I hear you ON'
            }
        } else if (type === 'support') {
            if (supportYou) {
                reactionState = 'I support you OFF'
            } else {
                reactionState = 'I support you ON'
            }
        }

        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/resource/${resource}/reaction`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {
                    type: type,
                    user: usr.id,
                    resourceId: resource,
                },
            })

            let finalReaction = ''

            if (t === 'I hear you') {
                if (hearYou) {
                    finalReaction = 'I hear you OFF'
                } else {
                    finalReaction = 'I hear you ON'
                }
            } else if (t === 'I support you') {
                if (supportYou) {
                    finalReaction = 'I support you OFF'
                } else {
                    finalReaction = 'I support you ON'
                }
            }

            let reaction = {
                reaction: true,
                type: finalReaction,
                context: 'resource',
            }

            // if (resource) {
            //     userAction(usr.id, 'Resource', resource, 'reaction', reaction)
            // }

            if (res.status === 201) {
                getResources()

                logResourceAction(usr, resource, 'Reaction', reactionState)
            } else {
                console.log('error with response')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        switch (componentType) {
            case 'hear':
                setReaction('I hear you')
                break
            case 'support':
                setReaction('I support you')
                break
            default:
                setReaction('')
                break
        }
    }, [setReaction, componentType])

    return (
        <>
            <div
                className='flex flex-col text-xs items-center mr-2 md:mr-4 text-blue1 font-cgbold cursor-pointer'
                onClick={() => update(reaction)}
            >
                <div className='mb-1'>{reaction ? reaction : ''}</div>

                <div className='justify-center items-center'>
                    {type === 'hear' ? (
                        <img
                            className={hearYou ? 'w-10 rounded-full p-1 bg-blue2' : 'w-10 rounded-full p-1 bg-yellow1'}
                            src={IHearYouIcon}
                            alt='emoji reaction'
                        />
                    ) : null}

                    {type === 'support' ? (
                        <img
                            className={
                                supportYou ? 'w-10 rounded-full p-1 bg-blue2' : 'w-10 rounded-full p-1 bg-yellow1'
                            }
                            src={ISupportYouIcon}
                            alt='emoji reaction'
                        />
                    ) : null}
                </div>
                <div className='mt-1'>
                    {type === 'hear' ? (hearYouCounter !== 0 ? hearYouCounter : '0') : null}
                    {type === 'support' ? (supportYouCounter !== 0 ? supportYouCounter : '0') : null}
                </div>
            </div>
        </>
    )
}

export default ResourceReact
