import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Notification } from '../notifications'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import { ModerationComments } from './ModerationComments'

type FPE = {
    modal: (param: boolean) => void
    post: any
}

const FlaggedPostDelete: React.FC<FPE> = ({ modal, post }) => {
    const nav = useNavigate()
    const usr = getUser()
    const [submitting, setSubmitting] = useState(false)
    const [reasonError, setReasonError] = useState(false)

    const initialValues = {
        reason: '',
        content: '',
    }

    const validationSchema = Yup.object().shape({
        reason: Yup.string().min(2, 'Community guideline is required'),
        content: Yup.string(),
    })

    const onSubmit = async (values: any) => {
        setSubmitting(true)

        if (values.reason === '' || values.reason === null) {
            setReasonError(true)
            setSubmitting(false)
        } else {
            try {
                const data = values
                data.postId = post?._id
                data.moderation = {
                    reason: Object.values(ModerationComments)[values.reason.replace('reason', '')],
                    comments: values.content,
                    ts: new Date(),
                }

                const res = await axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_SERVER}/api/admin/post/moderation/delete`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${usr.token}`,
                    },
                    data: data,
                })
                if (res.status === 201) {
                    setSubmitting(false)
                    modal(false)
                    nav('/admin/posts/flagged')

                    return Notification({
                        message: 'Post successfully deleted.',
                        type: 'success',
                    })
                } else {
                    return Notification({
                        message:
                            'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                        type: 'warning',
                    })
                }
            } catch (error) {
                return Notification({
                    message: error,
                    type: 'danger',
                })
            }
        }
    }

    return (
        <>
            <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
                <div className='flex items-center justify-end h-11'>
                    <button
                        className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                        onClick={() => modal(false)}
                    >
                        X
                    </button>
                </div>

                <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                    <div className='p-2 md:p-0'>
                        <div className='bg-white max-w-4xl mx-auto rounded-xl p-4'>
                            {post ? (
                                <>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={onSubmit}
                                    >
                                        {() => (
                                            <Form>
                                                <div>
                                                    <h2 className='text-5xl text-center font-cgbold mt-2'>
                                                        Delete Flagged Post
                                                    </h2>
                                                    <div className='mt-4'>
                                                        <label className='block font-cgbold leading-6'>
                                                            Moderation Comments
                                                        </label>
                                                        <Field
                                                            component='select'
                                                            name='reason'
                                                            placeholder='Select moderation option (optional)'
                                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                        >
                                                            <option value=''>Please select an option</option>
                                                            {Object.keys(ModerationComments).map((i, c) => {
                                                                return (
                                                                    <option
                                                                        key={c}
                                                                        value={i}
                                                                    >
                                                                        {Object.values(ModerationComments)[c]}
                                                                    </option>
                                                                )
                                                            })}
                                                        </Field>
                                                        {reasonError ? (
                                                            <div className='block text-error font-cgbold mt-2'>
                                                                Please select a moderation option
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div className='mt-4'>
                                                        <label
                                                            className='block font-cgbold leading-6'
                                                            htmlFor='content'
                                                        >
                                                            Additional Comments <small>(Optional)</small>
                                                        </label>
                                                        <Field
                                                            component='textarea'
                                                            rows='5'
                                                            name='content'
                                                            placeholder='Additional comments'
                                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                        />
                                                    </div>
                                                </div>

                                                <div className='mt-4'>
                                                    <div className='flex justify-between'>
                                                        <button
                                                            type='submit'
                                                            className='bg-red1 font-cgbold rounded-full py-2 px-6'
                                                        >
                                                            {submitting && <div className='loading'></div>}
                                                            Delete Post
                                                        </button>
                                                        <button
                                                            type='button'
                                                            className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                            onClick={() => modal(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FlaggedPostDelete
