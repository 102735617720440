// @ts-nocheck
import React, { useCallback, useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Post from '../community/Post'
import Masthead from '../layout/MastHead'
import Resource from '../resources/Resource'
import { getUser } from '../auth/LoginState'
import ScrollToTop from '../layout/ScrollToTop'

const Garden = () => {
    const usr = getUser()
    const [user, setUser] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [selected, setSelected] = useState('posts')
    const [posts, setPosts] = useState<Array<any>>([])
    const [postUsers, setPostUsers] = useState<Array<any>>([])
    const [resourceUsers, setResourceUsers] = useState<Array<any>>([])
    const [resources, setResources] = useState<Array<any>>([])

    const getPostBookmarks = useCallback(async () => {
        try {
            setIsLoading(true)

            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/user/bookmarks/posts`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                if (res.data.user) setUser(res.data.user)
                setPostUsers(res.data.users)
                setPosts(res.data.posts)
                setIsLoading(false)

            }
        } catch (error) {
            console.log('ERR', error)
        }
    }, [usr.token])

    const getResourceBookmarks = useCallback(async () => {
        try {
            setIsLoading(true)

            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/user/bookmarks/resources`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                if (res.data.user) setUser(res.data.user)
                setResourceUsers(res.data.users)
                setResources(res.data.resources)
                setIsLoading(false)
            }
        } catch (error) {
            console.log('ERR', error)
        }
    }, [usr.token])


    const getLoggedInUser = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/account/${usr.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {},
            })

            if (res.status == 200){
                setUser(res.data)
            } else {
                console.log(res.error)
            }
        } catch (error) {
            console.log(error)
        }
    })
    // const logResourceAction = async (action: string, reaction: string) => {
    //     const currentUrl = window.location.href
    //     let source = ''

    //     if (currentUrl.includes('resource')) {
    //         source = 'Resource page'
    //     } else if (currentUrl.includes('mygarden')) {
    //         source = 'My Garden'
    //     } else {
    //         source = 'NA'
    //     }

    //     try {
    //         const res = await axios({
    //             method: 'post',
    //             url: `${process.env.REACT_APP_SERVER}/api/stats/resourcelog`,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             withCredentials: true,
    //             data: {
    //                 user: usr.id,
    //                 source: source,
    //                 resourceId: item._id,
    //                 action: action,
    //                 reaction: reaction,
    //             },
    //         })

    //         if (res.status !== 200) {
    //             return Notification({
    //                 message:
    //                     'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
    //                 type: 'warning',
    //             })
    //         }
    //     } catch (error) {
    //         return Notification({
    //             message: error.message ? error.message : error,
    //             type: 'danger',
    //         })
    //     }
    // }

    useEffect(() => {
        getPostBookmarks()
        getResourceBookmarks()
    }, [getPostBookmarks, getResourceBookmarks])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center px-2'>
                <h2 className='text-5xl font-cgbold mt-56 md:mt-20'>{usr && usr.username}'s Garden</h2>
                <h4 className='text-md my-4'>
                    This is your own private page that we like to call your Garden. You can save any community post or
                    resource to your Garden. Whether you save posts that you think are useful, or a resource you might
                    want to refer to in the future, your Garden is safe, secure, and easy to access.
                </h4>
                {posts.length > 0 || resources.length > 0 ? (
                    <div className='flex mx-auto my-9 max-w-sm justify-between'>
                        <Link
                            to='#'
                            className={
                                selected === 'posts'
                                    ? 'font-cgbold text-center cursor-pointer rounded-full py-4 px-8 bg-blue1 text-white'
                                    : 'font-cgbold text-center cursor-pointer rounded-full py-4 px-8 bg-blue2 text-white'
                            }
                            onClick={() => {
                                setSelected('posts')
                            }}
                        >
                            Community
                        </Link>
                        <Link
                            to='#'
                            className={
                                selected === 'resources'
                                    ? 'font-cgbold text-center cursor-pointer rounded-full py-4 px-8 bg-blue1 text-white'
                                    : 'font-cgbold text-center cursor-pointer rounded-full py-4 px-8 bg-blue2 text-white'
                            }
                            onClick={() => {
                                setSelected('resources')
                            }}
                        >
                            Resources
                        </Link>
                    </div>
                ) : (
                    <div className='container mx-auto w-full max-w-4xl mt-1'>
                        <p className='text-center font-cgbold text-xl mt-20'>
                            You've not saved anything from the community to your garden yet.
                        </p>
                    </div>
                )}
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 px-2'>
                <div className='appWidth'>
                    <div className='contentCol'>
                        {isLoading && <p>Loading...</p>}

                        {selected === 'posts' &&
                            posts.length > 0 &&
                            posts.map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <Post
                                            post={item.post}
                                            user={postUsers[item.post.user]}
                                            users={postUsers}
                                            loggedInUser={user}
                                            getLoggedInUser={getLoggedInUser}
                                            links={item.links}
                                            getPosts={getPostBookmarks}
                                            // logResourceAction={logResourceAction}
                                        />
                                    </div>
                                )
                            })}

                        {selected === 'resources' &&
                            resources.length > 0 &&
                            resources.map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <Resource
                                            user={resourceUsers[item.resource.user]}
                                            users={resourceUsers}
                                            loggedInUser={user}
                                            item={item.resource}
                                            links={item.links}
                                            getResources={getResourceBookmarks}
                                            // logResourceAction={logResourceAction}
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </div>

                <ScrollToTop />
            </div>
        </>
    )
}

export default Garden
