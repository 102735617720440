export const ModerationComments = {
    reason0:
        'Your post contained information that might reveal your identity or the identity of another community member. Remember CommonGround is an anonymous platform and personal information should not be shared',
    reason1:
        'All posts and peer- to - peer interactions should be supportive and respectful – our moderators will take appropriate action if this guideline is not followed',
    reason2: 'Your post is inappropriately talking about another community member or moderator',
    reason3: 'Your post talks about illegal activity',
    reason4: 'Your post promotes or encourages illegal drug or substance misuse',
    reason5: 'Your post promotes or encourages alcohol misuse',
    reason6:
        'Your post contains inappropriate descriptions about the quantities of, using, or acquiring illegal drugs or alcohol',
    reason7: 'Your post contains content about harming yourself or others',
    reason8:
        'Your post encourages or promotes self - harm or suicide which may cause distress or harm to other community members',
    reason9:
        'Your post includes descriptions and / or comparisons of self - harm or suicide methods.We encourage people to share their lived experiences on CommonGround, however this type of content can be distressing or triggering for others',
    reason10:
        'Your post includes description of self - harm or suicide methods.We are sorry that you are struggling at the moment – please feel free to repost how you are feeling to seek support, ensuring to follow our community principles. We deleted your post because it would not make sense if it was edited. We know it can be difficult to think about what we are saying when we are struggling or talking about sensitive topics, but please try to follow our community principles',
    reason11:
        'Your post contains graphic descriptions about physical and / or sexual abuse and assault.We encourage people to share their lived experiences in a safe way',
    reason12: 'Your post is selling or advertising a product or service',
    reason13: 'Your post contains sexual innuendo and / or graphic sexual content',
    reason14: 'Your post contains false or misleading information',
    reason15: 'Your post contains excessive swearing or swearing aimed at a community member(s)',
    reason16: 'for other admin requirements'
}
