import React, { useCallback, useEffect, useState } from 'react'
import Masthead from '../layout/MastHead'
import { useParams } from 'react-router-dom'
import Post from './Post'
import axios from 'axios'
import { useUser } from '../../helpers/useUser'
import { User } from 'grommet-icons'
import { getUser } from '../auth/LoginState'

const SearchResultsTags = () => {
    const USER = getUser()
    const params = useParams<{ tag: string }>()
    const [tab, setTab] = useState('posts')
    const [user, setUser] = useState<any>(null)
    const [users, setUsers] = useState<any>(null)
    const [posts, setPosts] = useState([])
    const [resources, setResources] = useState([])

    const getSearchResults = useCallback( async () => {
        if(!USER){
            return;
        }
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/post/search/tag/${params.tag}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {},
            })
            
            if (res.status === 200) {
                setPosts(res.data.posts)
                setResources(res.data.resources)
                setUser(res.data.user)
                setUsers(res.data.users)

                window.sessionStorage.setItem('searchId', res.data.searchId)
            } else {
                console.log('error with response')
            }
        } catch (error) {
            console.log(error)
        }
    }, [USER])

    useEffect(() => {
        getSearchResults()
    }, [])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-52 md:mt-16 text-center px-2'>
                <h2 className='text-5xl font-cgbold mt-20'>Search Results</h2>
                <h4 className='text-md my-4'>
                    You can choose to browse search results in the Community Feed or our Resources Page.
                </h4>
                <p className='text-md my-4'>
                    Please note that you cannot filter by relevance when searching via content tags. Search results are shown with the most recent at the top.
                </p>

                <div className='flex justify-between mx-auto max-w-md items-center my-6'>
                    <div
                        className={
                            tab === 'posts'
                                ? 'cursor-pointer rounded-full px-4 py-2 text-center font-cgbold bg-green1'
                                : 'cursor-pointer rounded-full px-4 py-2 text-center font-cgbold bg-green3'
                        }
                        onClick={() => setTab('posts')}
                    >
                        <h4>Community feed ({posts.length})</h4>
                    </div>
                    <div
                        className={
                            tab === 'resources'
                                ? 'cursor-pointer rounded-full px-6 py-2 text-center font-cgbold bg-red1'
                                : 'cursor-pointer rounded-full px-6 py-2 text-center font-cgbold bg-red2'
                        }
                        onClick={() => setTab('resources')}
                    >
                        <h4>Resources page ({resources.length})</h4>
                    </div>
                </div>

                <div className='my-2 flex justify-between'>
                    <div>
                        You searched for: <strong>{params.tag}</strong>, and there are{' '}
                        <strong>{posts.length + resources.length}</strong> results!
                    </div>
                </div>

                <div className='container mx-auto w-full max-w-4xl mt-16'>
                    {tab === 'posts' && posts
                        ? posts.length > 0
                            ? posts.map((p: any, c: number) => {
                                  return (
                                      <Post
                                          key={c}
                                          user={users? users[p.user]: null}
                                          loggedInUser={user}
                                          item={p}
                                          section='community'
                                          getPosts={getSearchResults}
                                      />
                                  )
                              })
                            : 'No posts matching your search in the Community Feed.'
                        : null}

                    {tab === 'resources' && resources
                        ? resources.length > 0
                            ? resources.map((r: any, c: number) => {
                                  return (
                                      <Post
                                          key={c}
                                          user={users? users[r.user]: null}
                                          loggedInUser={user}
                                          item={r}
                                          section='resources'
                                          getPosts={getSearchResults}
                                      />
                                  )
                              })
                            : 'No resources matching your search'
                        : null}
                </div>
            </div>
        </>
    )
}

export default SearchResultsTags
