import React, { useEffect } from 'react'
import Comment from './Comment'
import PostComment from './PostComment'
import { CommentType } from '../interfaces/Comment'

interface C {
    users: any
    loggedInUser: any
    postId: string
    comments: Array<CommentType>
    getPosts: () => void
}

const Comments: React.FC<C> = ({ users, loggedInUser, postId, comments, getPosts }) => {

    return (
        <div>
            {comments &&
                comments.map((item: any, index: any) => {
                    if (item.active === true && item.status !== 'deleted') {
                        return (
                            <Comment
                                user={users[item.user]}
                                loggedInUser={loggedInUser}
                                key={index}
                                postId={postId}
                                comment={item}
                                getPosts={getPosts}
                            />
                        )
                    }
                })}
            <PostComment
                user={loggedInUser}
                postId={postId}
                getPosts={getPosts}
            />
        </div>
    )
}

export default Comments
