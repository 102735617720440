export const formatDate = (date: string | Date, shortDate = true, ts = true) => {
    let d = new Date(date),
        day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate(),
        month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1,
        hrs = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours(),
        min = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()

    if (ts) {
        if (shortDate) {
            return `${day}/${month} ${hrs}:${min}`
        } else {
            return `${day}/${month}/${d.getFullYear().toString().substring(2, 4)} ${hrs}:${min}`
        }
    } else {
        if (shortDate) {
            return `${day}/${month}`
        } else {
            return `${day}/${month}/${d.getFullYear().toString().substring(2, 4)}`
        }
    }
}