import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notification } from '../notifications'
import axios from 'axios'
import { getUser } from '../auth/LoginState'

type FPE = {
    modal: (param: boolean) => void
    post: any
}

const FlaggedPostEscalate: React.FC<FPE> = ({ modal, post }) => {
    const nav = useNavigate()
    const usr = getUser()
    const [submitting, setSubmitting] = useState(false)

    const escalate = async () => {
        setSubmitting(true)

        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/post/moderation/escalate`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {
                    postId: post._id,
                },
            })
            if (res.status === 201) {
                setSubmitting(false)
                modal(false)
                nav('/admin/posts/flagged')

                return Notification({
                    message: 'Post successfully escalated.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <>
            <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
                <div className='flex items-center justify-end h-11'>
                    <button
                        className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                        onClick={() => modal(false)}
                    >
                        X
                    </button>
                </div>

                <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                    <div className='p-2 md:p-0'>
                        <div className='bg-white max-w-4xl mx-auto rounded-xl p-6'>
                            {post ? (
                                <>
                                    <h2 className='text-5xl text-center font-cgbold mt-2'>Escalate Flagged Post</h2>
                                    <p className='text-center text-lg font-cgbold mt-6'>
                                        Are you sure that you want to escalate this post?
                                    </p>
                                    <div className='mt-6'>
                                        <div className='flex justify-between'>
                                            <button
                                                onClick={() => escalate()}
                                                className='bg-red1 font-cgbold rounded-full py-2 px-6'
                                            >
                                                {submitting && <div className='loading'></div>}
                                                Escalate Post
                                            </button>
                                            <button
                                                type='button'
                                                className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                onClick={() => modal(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FlaggedPostEscalate
