import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notification } from '../notifications'
import axios from 'axios'
import { AuthContext } from '../../context/auth-context'

const AutoLogout = () => {
    const nav = useNavigate()
    const { logout } = useContext(AuthContext)
    const [timeOut, setTimeOut] = useState<number | undefined>()
    const [modal, setModal] = useState(false)

    const autoLogOut = () => {
        document.addEventListener('mousemove', () => {
            setTimeOut(600)
        })
        document.addEventListener('keydown', () => {
            setTimeOut(600)
        })
    }

    const logOut = async () => {
        const USER = JSON.parse(window.sessionStorage.getItem('CGUSER') || '')

        if (USER !== '') {
            try {
                const res = await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_SERVER}/api/account/logout`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${USER.token}`,
                    },
                    data: {
                        userId: USER.id,
                        sessionId: USER.sessionId,
                        logoutMethod: false,
                    },
                })
                if (res.status === 200) {
                    window.sessionStorage.removeItem('CGUSER')
                    logout()
                    window.location.href = '/account/login'
                }
            } catch (error) {
                return Notification({
                    message: error,
                    type: 'danger',
                })
            }
        }
    }

    useEffect(() => {
        // check if session storage is set, otherwise logout
        const session = window.sessionStorage.getItem('CGUSER')

        if (session) {
            let myInterval = setInterval(() => {
                if (timeOut) {
                    if (timeOut >= 0) {
                        setTimeOut(timeOut - 1)
                    }
                    if (timeOut === 1) {
                        setModal(true)
                        document.body.classList.add('overflow-hidden')
                        clearInterval(myInterval)
                    }
                }
            }, 1000)
            return () => clearInterval(myInterval)
        }
    }, [timeOut])

    useEffect(() => {
        if (timeOut === undefined) {
            setTimeOut(600)
        }

        autoLogOut()
    }, [timeOut])

    return (
        <>
            {modal ? (
                <div className='fixed top-0 left-0 w-screen h-screen bg-white/60 backdrop-blur-md z-50 flex justify-center items-center px-6'>
                    <div className='flex flex-col items-center justify-center font-cgbold'>
                        Your session has expired, and you will now be logged out. The community on CommonGround hopes to
                        see you again soon. <br />
                        <button
                            className='rounded-full py-2 px-6 bg-green1 text-white mt-4'
                            onClick={logOut}
                        >
                            OK, log me out!
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default AutoLogout
