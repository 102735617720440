import React from 'react'
import Masthead from '../layout/MastHead'
import Menu from './Menu'

const AdminIndex: React.FC = () => {
    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Admin dashboard</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                <div className='contentCol'>Please select from the sub-menu</div>
            </div>
        </>
    )
}

export default AdminIndex
