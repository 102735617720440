import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import Happy from '../../assets/images/reactions/Happy.svg'
import Sad from '../../assets/images/reactions/Sad.svg'
import Proud from '../../assets/images/reactions/Proud.svg'
import Laughter from '../../assets/images/reactions/Laughter.svg'
import { userAction } from '../../helpers/logs'

type ER = {
    post: string
    happy: boolean
    happyCounter: number
    sad: boolean
    sadCounter: number
    proud: boolean
    proudCounter: number
    laughter: boolean
    laughterCounter: number
    getPosts: () => void
}

const EmojiReact: React.FC<ER> = ({
    post,
    happy,
    happyCounter,
    sad,
    sadCounter,
    proud,
    proudCounter,
    laughter,
    laughterCounter,
    getPosts,
}) => {
    const usr = getUser()
    const [showEmojis, setShowEmojis] = useState<boolean>(false)

    const update = async (t: string) => {
        try {
            let statsReaction = '',
                notifyReaction = '',
                notifyToggle = ''

            if (t === 'happy') {
                if (happy) {
                    statsReaction = 'Happy OFF'
                    notifyToggle = 'removed'
                } else {
                    statsReaction = 'Happy ON'
                    notifyToggle = 'reacted'
                }
                notifyReaction = 'Happy'
            } else if (t === 'sad') {
                if (sad) {
                    statsReaction = 'Sad OFF'
                    notifyToggle = 'removed'
                } else {
                    statsReaction = 'Sad ON'
                    notifyToggle = 'reacted'
                }
                notifyReaction = 'Sad'
            } else if (t === 'proud') {
                if (proud) {
                    statsReaction = 'Proud OFF'
                    notifyToggle = 'removed'
                } else {
                    statsReaction = 'Proud ON'
                    notifyToggle = 'reacted'
                }
                notifyReaction = 'Proud'
            } else if (t === 'laughter') {
                if (laughter) {
                    statsReaction = 'Laughter OFF'
                    notifyToggle = 'removed'
                } else {
                    statsReaction = 'Laughter ON'
                    notifyToggle = 'reacted'
                }
                notifyReaction = 'Laughter'
            }

            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/${post}/reaction`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {
                    type: t,
                    toggle: notifyToggle,
                    reaction: notifyReaction,
                    user: usr.id,
                    postId: post,
                },
            })

            let reaction = {
                reaction: true,
                type: statsReaction,
                context: 'post',
            }

            if (post) {
                userAction(usr.id, 'Post', post, 'reaction', reaction)
            }

            if (res.status === 201) {
                getPosts()
                setShowEmojis(false)
            } else {
                console.log('error with response')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleOutsideClick = (e: any) => {
        window.addEventListener('click', (e) => {
            // @ts-ignore
            if (e.target.id === 'modal-container') {
                setShowEmojis(false)
            }
        })
    }

    return (
        <>
            <div
                className='flex flex-col text-xs items-center mr-2 md:mr-4 text-blue1 font-cgbold cursor-pointer'
                onClick={(event) => {
                    setShowEmojis(!showEmojis)
                    handleOutsideClick(event)
                }}
            >
                <div className='mb-1'>Emoji reactions</div>
                <div className='justify-center items-center'>
                    <img
                        src={Happy}
                        alt='emoji reaction'
                        className={showEmojis ? 'w-10 rounded-full p-1 bg-blue2' : 'w-10 rounded-full p-1 bg-yellow1'}
                    />
                </div>
                <div className='mt-1'>{happyCounter + sadCounter + proudCounter + laughterCounter}</div>
            </div>

            {showEmojis ? (
                <div
                    className='fixed flex top-0 left-0 w-screen h-screen z-50 items-center justify-center'
                    id='modal-container'
                >
                    <div className='absolute w-48 rounded-lg shadow-xl p-4 ml-10 bg-white border-2 border-black/20 text-center'>
                        <span className='font-cgbold'>Select the emoji reaction you want</span>
                        <div>
                            <div
                                className={
                                    happy
                                        ? 'cursor-pointer flex item-center place-items-center w-full rounded-full bg-yellow1 my-1'
                                        : 'cursor-pointer flex item-center place-items-center w-full bg-transparent my-1'
                                }
                                onClick={() => update('happy')}
                            >
                                <img
                                    src={Happy}
                                    alt='happy emoji'
                                    className='w-10 rounded-full p-1 bg-blue2'
                                />
                                <span className='absolute w-6 h-6 text-2xs font-cgbold text-white rounded-full bg-blue1 ml-7 -mt-7 pt-1'>
                                    {happyCounter}
                                </span>
                                <span className='ml-3'>Happy</span>
                            </div>
                            <div
                                className={
                                    sad
                                        ? 'cursor-pointer flex item-center place-items-center w-full rounded-full bg-yellow1 my-1'
                                        : 'cursor-pointer flex item-center place-items-center w-full bg-transparent my-1'
                                }
                                onClick={() => update('sad')}
                            >
                                <img
                                    src={Sad}
                                    alt='sad emoji'
                                    className='w-10 rounded-full p-1 bg-blue2'
                                />
                                <span className='absolute w-6 h-6 text-2xs font-cgbold text-white rounded-full bg-blue1 ml-7 -mt-7 pt-1'>
                                    {sadCounter}
                                </span>
                                <span className='ml-3'>Sad</span>
                            </div>
                            <div
                                className={
                                    proud
                                        ? 'cursor-pointer flex item-center place-items-center w-full rounded-full bg-yellow1 my-1'
                                        : 'cursor-pointer flex item-center place-items-center w-full bg-transparent my-1'
                                }
                                onClick={() => update('proud')}
                            >
                                <img
                                    src={Proud}
                                    alt='proud emoji'
                                    className='w-10 rounded-full p-1 bg-blue2'
                                />
                                <span className='absolute w-6 h-6 text-2xs font-cgbold text-white rounded-full bg-blue1 ml-7 -mt-7 pt-1'>
                                    {proudCounter}
                                </span>
                                <span className='ml-3'>Proud</span>
                            </div>
                            <div
                                className={
                                    laughter
                                        ? 'cursor-pointer flex item-center place-items-center w-full rounded-full bg-yellow1 my-1'
                                        : 'cursor-pointer flex item-center place-items-center w-full bg-transparent my-1'
                                }
                                onClick={() => update('laughter')}
                            >
                                <img
                                    src={Laughter}
                                    alt='laughter emoji'
                                    className='w-10 rounded-full p-1 bg-blue2'
                                />
                                <span className='absolute w-6 h-6 text-2xs font-cgbold text-white rounded-full bg-blue1 ml-7 -mt-7 pt-1'>
                                    {laughterCounter}
                                </span>
                                <span className='ml-3'>Laughter</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default EmojiReact
