// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/auth-context'
import { useNavigate, useLocation } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Notification } from '../notifications'
import * as Yup from 'yup'
import axios from 'axios'
import { accountService } from '../../services'
import Masthead from '../layout/MastHead'

const ValidationCode: React.FC = () => {
    const nav = useNavigate()
    const loc = useLocation()
    const [codeSent, setCodeSent] = useState<boolean>(false)
    const { login, logout } = useContext(AuthContext)

    const initialValues = {
        code: '',
    }

    const validationSchema = Yup.object().shape({
        code: Yup.string().required('Code is required'),
    })

    const onSubmit = async ({ code }: { code: string }, { setSubmitting }: { setSubmitting: any }) => {

        const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/api/account/verify-validation-code`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${loc.state.token}`,
            },
            data: {
                userid: loc.state.uid,
                code: code,
                token: loc.state.token,
            },
            withCredentials: true,
            validateStatus: () => true,
        })

        if (res.status === 200) {
            const user = {
                id: res.data.user.uid,
                email: res.data.user.email,
                username: res.data.user.username,
                avatar: res.data.user.avatar,
                role: res.data.user.role,
                roleAdmin: res.data.user.roleAdmin,
                ts: new Date(),
            }

            window.localStorage.setItem('CGUSER', JSON.stringify(user))

            login()

            nav('/community')

        } else if (res.status === 403) {

            return Notification({
                message: res.data.error,
                type: 'danger',
            })
        } else if (res.status === 401) {

            document.getElementById('incorrect')?.classList.remove('hidden')

            return Notification({
                message: res.data.error,
                type: 'danger',
            })
        } else {
            return Notification({
                message:
                    'There was a problem verifying your account. Please try again. If the problem continues, please contact the team at commonground@kcl.ac.uk',
                type: 'danger',
            })
        }
        
    }

    useEffect(() => {
        if (loc.state !== undefined) {
            accountService
                .sendCode(loc.state.uid)
                .then(() => {
                    setCodeSent(true)
                })
                .catch((error) => console.log('SEND CODE ERROR', error))
        }
    }, [setCodeSent, loc.state])

    return (
        <>
            <Masthead />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div className='container h-screen mx-auto w-full max-w-xl mt-16 text-center'>
                            <h2 className='text-5xl font-cgbold mt-20'>Please verify your account</h2>
                            <div className='bg-white/60 backdrop-blur-sm p-4 my-4'>
                                We have sent a four-digit code to your phone, please enter the code here to log in. This
                                process helps us to keep your account safe. <br />
                                You can read more about two-factor authentication{' '}
                                <a
                                    href='https://www.ncsc.gov.uk/guidance/setting-2-step-verification-2sv'
                                    className='font-cgbold text-blue1'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    here
                                </a>
                                .
                            </div>

                            <div className='bg-white/60 backdrop-blur-sm border border-black/6 shadow-2xl rounded-lg px-8 pt-6 pb-8 my-16 text-left'>
                                <div className='mb-4'>
                                    <label
                                        className='block font-cgbold leading-6'
                                        htmlFor='username'
                                    >
                                        Code
                                    </label>
                                    <Field
                                        type='text'
                                        name='code'
                                        placeholder='Enter your validation code here'
                                        className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='code'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                    <div id='incorrect' className='text-error hidden'>The code you entered is incorrect, please try again</div>
                                </div>

                                <div className='mb-4'>
                                    <button
                                        type='submit'
                                        className='rounded-2xl bg-blue1 py-2 px-6 text-white font-cgbold'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting && <div className='loading'></div>}
                                        Log in
                                    </button>

                                    <p className='text-sm mt-4 mb-4'>
                                        Are you experiencing issues? Please contact the research team at
                                        commonground@kcl.ac.uk
                                    </p>

                                    <button
                                        type='button'
                                        className='rounded-2xl bg-green1 py-2 px-6 text-white font-cgbold'
                                        onClick={() => {
                                            setCodeSent(false)
                                            accountService
                                                .sendCode(loc.state.uid)
                                                .then(() => {
                                                    setCodeSent(true)
                                                })
                                                .catch((error) => console.log('SEND CODE ERROR', error))
                                        }}
                                    >
                                        RESEND CODE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ValidationCode
