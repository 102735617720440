import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../helpers/useUser'
import { getUser } from '../auth/LoginState'
import { hashtags } from '../../helpers/data'
import { urlParser } from '../../helpers/logs'

type S = {
    searchModal: boolean
    setSearchModal: (searchModal: boolean) => void
}

export const SearchField: React.FC<S> = ({ searchModal, setSearchModal }) => {
    const nav = useNavigate()
    const USER = getUser()
    const initialValues = {
        searchTerm: '',
    }

    const validationSchema = Yup.object().shape({
        searchTerm: Yup.string().required('Search term is required'),
    })

    const onSubmit = async ({ searchTerm }: { searchTerm: string }, { setSubmitting }: { setSubmitting: any }) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/post/search`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {
                    searchTerm: searchTerm,
                    context: urlParser(window.location.href)
                },
            })

            if (res.status === 200) {
                setSearchModal(false)

                window.sessionStorage.setItem('searchId', res.data.searchId)

                nav('/search/results', {
                    state: {
                        posts: res.data.posts,
                        resources: res.data.resources,
                        searchTerm: searchTerm,
                    },
                })
            } else {
                console.log('error with response')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const modal = document.getElementById('modal-container')

        window.addEventListener('click', (e) => {
            if (e.target === modal) {
                setSearchModal(false)
            }
        })
    }, [])

    return (
        <div
            className={searchModal ? 'fixed w-screen h-screen z-40' : 'hidden'}
            id='modal-container'
        >
            <div className='fixed bg-white p-4 overflow-y-auto rounded-lg shadow-2xl w-full top-28 translate-y-1 md:w-2/3 md:right-0 block border border-blue1'>
                <div className='flex justify-between py-2 border-b-2 border-black/30 mb-4'>
                    <h4 className='text-3xl font-cgbold'>Search CommonGround</h4>
                </div>
                <p className='my-6'>
                    What would you like to search? You can filter your results by posts from the community feed and or
                    our resources.
                </p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <>
                                <div>
                                    <Field
                                        type='text'
                                        name='searchTerm'
                                        placeholder='Please enter your search term'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='searchTerm'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='my-4'>
                                    Unsure what to search? Click on any of the tags below to browse all the posts with
                                    that tag.
                                    <div className='m-4 flex flex-wrap'>
                                        {hashtags.map((h, c) => {
                                            return (
                                                <div
                                                    className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4 cursor-pointer'
                                                    key={c}
                                                    onClick={() => {
                                                        nav(`/search/tag/${h.value}`)
                                                        setSearchModal(false)
                                                    }}
                                                >
                                                    {h.value}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className='margin-reset'>
                                    <button
                                        type='submit'
                                        className='bg-green1 rounded-full py-2 px-4 text-white font-cgbold mt-4'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting && <div className='loading'></div>}
                                        Search
                                    </button>
                                </div>
                            </>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
