import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Notification } from '../notifications'
import { getUser } from './LoginState'
import { accountService } from '../../services'
import Masthead from '../layout/MastHead'
import a1 from '../../assets/images/avatar1.png'
import a2 from '../../assets/images/avatar2.png'
import a3 from '../../assets/images/avatar3.png'
import a4 from '../../assets/images/avatar4.png'
import a5 from '../../assets/images/avatar5.png'
import a6 from '../../assets/images/avatar6.png'
import a7 from '../../assets/images/avatar7.png'
import a8 from '../../assets/images/avatar8.png'
import a9 from '../../assets/images/avatar9.png'

type Params = {
    user: {
        email: string
        _id: string
    }
}

const ProfileSetup: React.FC = () => {
    const nav = useNavigate()
    const location = useLocation().state as Params
    const user = getUser()

    const formComplete = (values: any) => {
        if (typeof values.avatar === 'undefined' || values.avatar === '') {
            return false
        }

        return true
    }

    const onSubmit = (fields: any, { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }) => {
        setStatus()

        fields.email = location.user.email
        fields.id = location.user._id

        accountService
            .completeProfile(fields)
            .then(() => {
                nav('/account/register/confirmation')
            })
            .catch((error) => {
                setSubmitting(false)

                return Notification({
                    message: error,
                    type: 'danger',
                })
            })

        setSubmitting(false)
    }

    return (
        <>
            <Masthead />

            <Formik
                enableReinitialize
                initialValues={{
                    biography: user ? user.biography : '',
                    pronouns: user ? user.pronouns : '',
                }}
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                        <div className='container mx-auto w-full max-w-5xl mt-16 text-center'>
                            <h2 className='text-5xl font-cgbold mt-20'>Your profile</h2>
                            <div className='bg-white/60 backdrop-blur-sm p-4 my-4'>
                                Here you can create your profile. Please select an avatar profile picture. If you would
                                like, you can write a little about yourself and add your pronouns. This will be visible
                                to all platform users. Please remember to remain anonymous.
                            </div>

                            <div className='pb-12'>
                                <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-2 text-left'>
                                    <div className='sm:col-span-3'>
                                        <label
                                            className='block font-cgbold leading-6'
                                            htmlFor='biography'
                                        >
                                            Tell us a little about yourself (optional)
                                            <br />
                                            <span className='text-xs text-gray-700'>
                                                Write a little about yourself, if you want to. <br />
                                                It is up to you what you choose to share. You can say why you are using
                                                CommonGround or about your health journey so far. You can say what your
                                                hobbies or interests are. You can edit this and hide this on your
                                                profile at any time. Please remember to remain anonymous.
                                            </span>
                                        </label>
                                        <Field
                                            component='textarea'
                                            rows='6'
                                            name='biography'
                                            placeholder='What do you want to write?'
                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                        />
                                        <ErrorMessage
                                            name='biography'
                                            component='div'
                                            className='block text-error font-cgbold mt-2'
                                        />
                                    </div>

                                    <div className='sm:col-span-3'>
                                        <label className='block font-cgbold leading-6'>
                                            Select your avatar (required)
                                            <br />
                                            <span className='text-xs text-gray-700'>
                                                Please choose one of the avatars below to be your display image!
                                            </span>
                                        </label>
                                        <div className='flex flex-wrap justify-center align-middle md:mt-10'>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a1}
                                                    alt='avatar 1'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar1'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a2}
                                                    alt='avatar 2'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar2'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a3}
                                                    alt='avatar 3'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar3'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a4}
                                                    alt='avatar 4'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar4'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a5}
                                                    alt='avatar 5'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar5'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a6}
                                                    alt='avatar 6'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar6'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a7}
                                                    alt='avatar 7'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar7'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a8}
                                                    alt='avatar 8'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar8'
                                                />
                                            </label>
                                            <label className='flex-row text-center'>
                                                <img
                                                    src={a9}
                                                    alt='avatar 9'
                                                    width={98}
                                                />
                                                <Field
                                                    type='radio'
                                                    name='avatar'
                                                    value='avatar9'
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='sm:col-span-3'>
                                        <label
                                            className='block font-cgbold leading-6'
                                            htmlFor='pronouns'
                                        >
                                            Your Pronouns (optional) <br />
                                            <span className='text-xs text-gray-700'>
                                                If you would like to, write your pronouns here (e.g., she/her,
                                                they/them, he/him, etc.). You can edit this and hide/display on your
                                                profile at any time.
                                            </span>
                                        </label>
                                        <Field
                                            type='text'
                                            name='pronouns'
                                            placeholder="What's pronouns?"
                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                        />
                                        <ErrorMessage
                                            name='pronouns'
                                            component='div'
                                            className='block text-error font-cgbold mt-2'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p className='mb-8 text-sm'>
                                    When you have finished creating your profile, please click the button below. The
                                    research team will then approve your account soon - you will be notified via email
                                    when your account has been approved. Please note that you will not be able to access
                                    CommonGround until our team have approved your account. If you encounter any issues
                                    or questions, please contact us: commonground@kcl.ac.uk
                                </p>
                                <button
                                    type='submit'
                                    className={
                                        !formComplete(values)
                                            ? 'mb-32 block btn bg-green5 text-gray-600 mt-4 mx-auto py-2 px-6 rounded-xl font-cgbold'
                                            : 'mb-32 block btn bg-green2 mt-4 mx-auto py-2 px-6 rounded-xl font-cgbold'
                                    }
                                    disabled={isSubmitting || !formComplete(values)}
                                >
                                    {isSubmitting && <div className='loading'></div>}
                                    Create my profile
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ProfileSetup
