import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../helpers/useUser'
import { Notification } from '../notifications'
import ProfilePicture from '../../assets/images/profile-picture.svg'
import admin from '../../assets/images/admin_avatar.png'
import admin2 from '../../assets/images/admin2_avatar.png'
import avatar1 from '../../assets/images/avatar1.png'
import avatar2 from '../../assets/images/avatar2.png'
import avatar3 from '../../assets/images/avatar3.png'
import avatar4 from '../../assets/images/avatar4.png'
import avatar5 from '../../assets/images/avatar5.png'
import avatar6 from '../../assets/images/avatar6.png'
import avatar7 from '../../assets/images/avatar7.png'
import avatar8 from '../../assets/images/avatar8.png'
import avatar9 from '../../assets/images/avatar9.png'
import axios from 'axios'
import { urlParser, userInteractionLog } from '../../helpers/logs'

interface U {
    user: any
    loggedInUser: any
    adminStats?: boolean
    getPosts: () => void
}

const User: React.FC<U> = ({ user, loggedInUser, adminStats, getPosts }) => {
    const nav = useNavigate()
    const USER = user
    const [following, setFollowing] = useState(false)


    const fetchAvatar = () => {
        if (USER.role === 'admin') {
            if (USER.roleAdmin === 'moderator') {
                return admin
            } else {
                return admin2
            }
        } else if (USER.role === 'user' && USER.privacy.avatar === true) {
            switch (USER.avatar) {
                case 'avatar1':
                    return avatar1
                case 'avatar2':
                    return avatar2
                case 'avatar3':
                    return avatar3
                case 'avatar4':
                    return avatar4
                case 'avatar5':
                    return avatar5
                case 'avatar6':
                    return avatar6
                case 'avatar7':
                    return avatar7
                case 'avatar8':
                    return avatar8
                case 'avatar9':
                    return avatar9
                default:
                    break
            }
        } else {
            return ProfilePicture
        }
    }

    const followUser = async (uid: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/user/follow`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${loggedInUser.token}`,
                },
                data: {
                    userId: uid,
                },
            })

            if (res.status === 201) {
                let msg = 'You now follow this community member.'
                if (res.data.state === 'unfollow') {
                    msg = 'You have unfollowed this community member.'
                }

                getPosts();

                return Notification({
                    message: msg,
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error) {
            return Notification({
                message: error,
                type: 'error',
            })
        }
    }

    // const checkIfFollowing = () => {
    //     if (loggedInUser && loggedInUser._id !== user) {
    //         const isFollowing =
    //             USER.followers.filter((uf: any) => {
    //                 return uf.user === loggedInUser._id
    //             }).length > 0

    //         if (isFollowing) {
    //             setFollowing(true);
    //         } else {
    //             setFollowing(false);
    //         }
    //     }

    //     return ''
    // }

    const getFollowing = async () => {
        if(!(USER && loggedInUser)){
            return
        }

        setFollowing(false)

        loggedInUser.following.users.map((following: any) => {
            if (following.user.toString() == user._id.toString()) {
                setFollowing(true)
            }
        })
    }

    const logNavigation = async (path: string) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/stats/navlog`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    studyId: USER.role === 'admin' ? 'admin' : USER.studyId,
                    userId: USER._id,
                    path: path,
                },
            })
            if (res.status !== 200) {
                return Notification({
                    message: 'Error with response from server',
                    type: 'danger',
                })
            }
        } catch (error) {
            return Notification({
                message: error,
                type: 'danger',
            })
        }
    }

    const navToUser = () => {
        userInteractionLog(urlParser(window.location.href), user, 'profileView', true)
        nav(`/user/profile/${USER._id}`)
    }

    const navToProfile = () => {
        logNavigation('Own Profile')
        nav('/account/profile')
    }


    useEffect(() => {
        getFollowing()
    }, [followUser])

    return (
        <>
            {USER && loggedInUser ? (
                <div className='flex flex-row items-center md:flex-col'>
                    <div className='flex flex-col flextext-center items-center justify-center'>
                        <img
                            src={fetchAvatar()}
                            alt='Display users profile'
                            onClick={() => USER._id == loggedInUser._id ? navToProfile() : navToUser()}
                            width={50}
                        />
                        <p className='font-cgbold text-sm break-all'>{USER.privacy.username ? USER.username : ''}</p>

                        
                    </div>
                    {user._id == loggedInUser._id? null : following? 
                        <button
                            className='bg-green1 text-white font-cgbold py-2 px-6 rounded-full md:mt-4 ml-2 md:ml-0'
                            onClick={() => followUser(USER._id)}
                        >
                            Following
                        </button>
                        :
                        <button
                            className='font-cgbold border-2 border-green1 text-green1 py-2 px-6 rounded-full md:mt-4 ml-2 md:ml-0'
                            onClick={() => followUser(USER._id)}
                        >
                            Follow
                        </button>
                    }
                </div>
            ) : null}
        </>
    )
}

export default User
