import { BehaviorSubject } from 'rxjs'
import { fetchWrapper } from '../helpers'

const accountSubject = new BehaviorSubject(null)
const baseUrl = `${process.env.REACT_APP_SERVER}/api/account`

const register = (params) => {
    return fetchWrapper.post(`${baseUrl}/register`, params).then((u) => {
        return u
    })
}

const sendCode = (userid) => {
    return fetchWrapper.post(`${baseUrl}/send-code`, { userid }).then((u) => {
        return u
    })
}

//////////////////////////////////////////////////////////////////

const login = (email, password, device) => {
    return fetchWrapper.post(`${baseUrl}/login`, { email, password, device }).then((u) => {
        return u
    })
}

const logout = () => {
    fetchWrapper.post(`${baseUrl}/revoke-token`, {})
    stopRefreshTokenTimer()
    accountSubject.next(null)

    window.location.href('/account/login')
}

const getToken = () => {
    //const getCookieValue = (name) => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    let token = ''
    //token = getCookieValue('token')

    //if (token === '') {
    //    const isAuth = !!localStorage.getItem('token')
    //    if (isAuth) {
    //        token = localStorage.getItem('token') || ''
    //    }
    //}
    return token
}

const refreshToken = () => {
    let token = getToken()

    return fetchWrapper
        .post(`${baseUrl}/refresh-token`, { token })
        .then((u) => {
            // Publish user to subscribers and start timer to refresh token
            saveToken(u.token)
            accountSubject.next(u)
            startRefreshTokenTimer()
            return u
        })
        .catch((error) => {
            console.log('refresh-token???', error)
        })
}

const saveToken = (token) => {
    // TODO
    // https://blog.logrocket.com/jwt-authentication-best-practices/#:~:text=Don't%20store%20it%20in,storage%20(or%20session%20storage).&text=The%20JWT%20needs%20to%20be,JavaScript%20running%20in%20the%20browser.
    // https://owasp.org/www-community/HttpOnly
    // https://stackoverflow.com/questions/55129348/express-how-to-read-httponly-cookie-in-request-to-api
    //localStorage.setItem('token', token);
}

const resendValidationCode = (email) => {
    return fetchWrapper.post(`${baseUrl}/resend-validation-code`, { email })
}

const verifyEmail = (token) => {
    return fetchWrapper.post(`${baseUrl}/verify-email`, { token })
}

const forgotPassword = (email) => {
    return fetchWrapper.post(`${baseUrl}/forgot-password`, { email })
}

const validateResetToken = (token) => {
    return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token })
}

const resetPassword = ({ token, password, confirmPassword }) => {
    return fetchWrapper.post(`${baseUrl}/reset-password`, {
        token,
        password,
        confirmPassword,
    })
}

const completeProfile = (params) => {
    return fetchWrapper.post(`${baseUrl}/complete-profile`, { params }).then((u) => {
        return u
    })
}

const getAvatar = async (user) => {
    return fetchWrapper.get(`${baseUrl}/get-avatar`, { user }).then((u) => {
        return u
    })
}

const getPostAvatar = async (user) => {
    return fetchWrapper.get(`${baseUrl}/get-post-avatar/${user}`, { user }).then((u) => {
        return u
    })
}

const getAll = () => {
    return fetchWrapper.get(baseUrl)
}

const getById = (id) => {
    return fetchWrapper.get(`${baseUrl}/${id}`)
}

const create = (params) => {
    return fetchWrapper.post(baseUrl, params)
}

const update = (id, params) => {
    return fetchWrapper.put(`${baseUrl}/${id}`, params).then((u) => {
        // Update stored user if the logged in user updated their own record
        if (u.id === accountSubject.value.id) {
            // Publish updated user to subscribers
            u = { ...accountSubject.value, ...u }
            accountSubject.next(u)
        }
        return u
    })
}

// Bookmarks
const getBookmarks = (type) => {
    if (type && type !== '') type = '/' + type
    return fetchWrapper.get(`${baseUrl}/bookmarks${type}`)
}

// Prefixed with underscore because 'delete' is a reserved word in javascript
const _delete = (id) => {
    return fetchWrapper.delete(`${baseUrl}/${id}`).then((u) => {
        // Auto logout if the logged in user deleted their own record
        if (id === accountSubject.value.id) {
            logout()
        }
        return u
    })
}

// Helper functions
let refreshTokenTimeout

const startRefreshTokenTimer = () => {
    // Parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(accountSubject.value.token.split('.')[1])) // jwtToken

    // Set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000)
    const timeout = expires.getTime() - Date.now() - 60 * 1000

    refreshTokenTimeout = setTimeout(refreshToken, timeout)
}

const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout)
}

export const accountService = {
    login,
    logout,
    sendCode,
    getAvatar,
    getPostAvatar,
    getToken,
    refreshToken,
    register,
    resendValidationCode,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    completeProfile,
    getAll,
    getById,
    create,
    update,
    getBookmarks,
    delete: _delete,
    user: accountSubject.asObservable(),
    get userValue() {
        return accountSubject.value
    },
}
