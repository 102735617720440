// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import User from './User'
import { Notification } from '../notifications'
import axios from 'axios'
import EmojiReact from './EmojiReact'
import ResourceReact from './ResourceReact'
import CommentsHandler from './CommentsHandler'
import Bookmark from './Bookmark'
import TextContent from './TextContent'
import Comments from './Comments'
import Moderation from './Moderation'
import { getUser } from '../auth/LoginState'
import { logResourceAction } from '../../helpers/logs'

interface R {
    item: any
    links: any
    user: any
    users: any
    loggedInUser: any
    getResources: () => void
}

const Resource: React.FC<R> = ({ item, links, user, users, loggedInUser, getResources }) => {
    const nav = useNavigate()
    const usr = loggedInUser
    const localUser = getUser()
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
    const [showComments, setShowComments] = useState<boolean>(false)
    const [hearYou, setHearYou] = useState<boolean>(false)
    const [hearYouCounter, setHearYouCounter] = useState<number>(0)
    const [supportYou, setSupportYou] = useState<boolean>(false)
    const [supportYouCounter, setSupportYouCounter] = useState<number>(0)
    const [happy, setHappy] = useState<boolean>(false)
    const [happyCounter, setHappyCounter] = useState<number>(0)
    const [sad, setSad] = useState<boolean>(false)
    const [sadCounter, setSadCounter] = useState<number>(0)
    const [proud, setProud] = useState<boolean>(false)
    const [proudCounter, setProudCounter] = useState<number>(0)
    const [laughter, setLaughter] = useState<boolean>(false)
    const [laughterCounter, setLaughterCounter] = useState<number>(0)

    const checkUserBookmark = useCallback(() => {
        setIsBookmarked(false)

        if (item.stats.bookmarks.length > 0) {
            item.stats.bookmarks.filter((post) => {
                if (post.user === usr._id) {
                    setIsBookmarked(true)
                }

                return ''
            })
        }
    }, [item.stats.bookmarks, usr])

    const checkUserReactions = useCallback(() => {
        setHearYou(false)
        setSupportYou(false)
        setHappy(false)
        setSad(false)
        setProud(false)
        setLaughter(false)
        setHearYouCounter(item.stats.counters.hear)
        setSupportYouCounter(item.stats.counters.support)
        setHappyCounter(item.stats.counters.happy)
        setSadCounter(item.stats.counters.sad)
        setProudCounter(item.stats.counters.proud)
        setLaughterCounter(item.stats.counters.laughter)
        if (item.stats.reactions.length > 0) {
            item.stats.reactions.filter((item) => {
                switch (item.reaction) {
                    case 'hear':
                        if (item.user === usr._id) {
                            setHearYou(true)
                        }
                        break
                    case 'support':
                        if (item.user === usr._id) {
                            setSupportYou(true)
                        }
                        break
                    case 'happy':
                        if (item.user === usr._id) {
                            setHappy(true)
                        }
                        break
                    case 'sad':
                        if (item.user === usr._id) {
                            setSad(true)
                        }
                        break
                    case 'proud':
                        if (item.user === usr._id) {
                            setProud(true)
                        }
                        break
                    case 'laughter':
                        if (item.user === usr._id) {
                            setLaughter(true)
                        }
                        break

                    default:
                        break
                }

                return ''
            })
        }
    }, [item.stats.reactions, item.stats.counters, usr._id])

    // const logResourceAction = async (action: string, reaction: string, commentId?: string, commentLength?: int) => {
    //     const currentUrl = window.location.href
    //     let source = ''

    //     if (currentUrl.includes('article')) {
    //         source = 'Article page'
    //     } else if (currentUrl.includes('resource')) {
    //         source = 'Resource page'
    //     } else if (currentUrl.includes('mygarden')) {
    //         source = 'My Garden'
    //     } else {
    //         source = 'NA'
    //     }

    //     try {
    //         const res = await axios({
    //             method: 'post',
    //             url: `${process.env.REACT_APP_SERVER}/api/stats/resourcelog`,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             withCredentials: true,
    //             data: {
    //                 user: usr.id,
    //                 source: source,
    //                 resourceId: item._id,
    //                 action: action,
    //                 reaction: reaction,
    //                 commentId: commentId,
    //                 commentLength: commentLength,
    //             },
    //         })

    //         if (res.status !== 200) {
    //             return Notification({
    //                 message:
    //                     'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
    //                 type: 'warning',
    //             })
    //         }
    //     } catch (error) {
    //         return Notification({
    //             message: error.message ? error.message : error,
    //             type: 'danger',
    //         })
    //     }
    // }

    useEffect(() => {
        checkUserBookmark()
        checkUserReactions()
    }, [checkUserBookmark, checkUserReactions, getResources])

    return (
        <>
            {item && (
                <div className='w-full shadow-lg rounded-lg my-5 border-2 border-black/10 flex flex-col md:flex-row p-4'>
                    <div className='flex flex-row shrink-0 justify-between items-center md:flex-col md:justify-start mr-3 border-b-2 md:border-b-transparent md:border-r-2 border-black/20 w-full md:w-1/6'>
                        <User 
                            user={user}
                        />
                        <div className='text-sm md:text-2xs md:mt-4 w-32'>
                            <span>Posted: </span>
                            <span>{DateTime.fromISO(item.dateCreated).toRelative()}</span>
                        </div>
                        <div className='text-sm md:text-2xs md:mt-4 text-center w-max'>
                            <span>Last reply: </span>
                            <span>
                                {item &&
                                item.comments &&
                                item.comments.length &&
                                item.comments[item.comments.length - 1] &&
                                item.comments[item.comments.length - 1].dateCreated
                                    ? DateTime.fromISO(item.comments[item.comments.length - 1].dateCreated).toRelative()
                                    : '---'}
                            </span>
                        </div>
                    </div>

                    <div className='w-full mt-3 md:mt-0 overflow-hidden'>
                        <div className='flex justify-between items-center'>
                            <div className='text-center'>
                                <div className='rounded-full font-cgbold text-sm py-2 px-6 bg-green2'>
                                    {item.topic.name}
                                </div>
                            </div>
                            <Moderation
                                item={item}
                                type='post'
                                getResources={getResources}
                            />
                        </div>

                        <h4
                            className='text-2xl font-semibold cursor-pointer mt-6 mb-4 text-blue1'
                            onClick={() => {
                                nav(`/resource/article/${item._id}`, {
                                    state: {
                                        links: links ? links : null,
                                    },
                                })

                                // logResourceAction(usr, item._id, 'open', '')
                            }}
                        >
                            {item.title}
                        </h4>

                        <TextContent
                            item={item}
                            links={links}
                        />

                        {links && links.length > 0 ? (
                            <div className='mt-6 border-t border-b border-black/20 py-6'>
                                {links.map((i, c) => {
                                    let readableName = i.name.split('.')

                                    readableName = readableName[0].slice(0, readableName[0].length - 12)

                                    return (
                                        <div
                                            key={c}
                                            className='flex items-center'
                                        >

                                            {item.type && item.type != 'Image' && (
                                                <div className='bg-blue1 text-white rounded-lg mr-2 font-cgbold py-1 px-2'>
                                                    {item.type}
                                                </div>
                                            )}
                                            {item.type == 'Image'? 
                                            <div key={c}>
                                                <a
                                                    href={i.link}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    className='text-blue1 font-cgbold break-all'
                                                    onClick={() => logResourceAction(usr, item, 'Open')}
                                                >
                                                    <img
                                                        src={i.link}
                                                        alt={readableName}
                                                        width={100}
                                                    />
                                                </a>
                                            </div>
                                            :
                                            <a
                                                href={i.link}
                                                target='_blank'
                                                rel='noreferrer'
                                                className='text-blue1 font-cgbold break-all'
                                                onClick={() => logResourceAction(usr, item, 'Open')}
                                            >
                                                {readableName}
                                            </a>
                                            }
                                            
                                        </div>
                                    )
                                })}
                            </div>
                        ) : null}

                        {item.url != '' ? (
                            <div
                                className={
                                    links.length > 0
                                        ? 'flex items-center mt-6 border-b border-black/20 py-4'
                                        : 'flex items-center mt-6 border-t border-b border-black/20 py-4'
                                }
                            >
                                <div className='bg-blue1 text-white rounded-lg mr-2 font-cgbold py-1 px-2'>URL</div>
                                <a
                                    href={item.url}
                                    target='_blank'
                                    title='External link'
                                    className='text-blue1 font-cgbold break-all'
                                    onClick={() => logResourceAction(usr, item, 'Open')}
                                >
                                    {item.url}
                                </a>
                            </div>
                        ) : null}

                        <div className='mt-2 pt-6 flex flex-wrap'>
                            {item.hashtags
                                ? item.hashtags.map((h, c) => {
                                    return (
                                        <div
                                            className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4 cursor-pointer'
                                            key={c}
                                            onClick={() => nav(`/search/tag/${h}`)}
                                        >
                                            {h}
                                        </div>
                                    )
                                })
                                : null}
                        </div>

                        <div className='flex flex-row justify-between md:justify-start my-6 items-center mr-4'>
                            <ResourceReact
                                componentType='hear'
                                type='hear'
                                resource={item._id}
                                hearYou={hearYou}
                                supportYou={supportYou}
                                hearYouCounter={hearYouCounter}
                                getResources={getResources}
                                item={item}
                                // logResourceAction={logResourceAction}
                            />
                            <ResourceReact
                                componentType='support'
                                type='support'
                                resource={item._id}
                                hearYou={hearYou}
                                supportYou={supportYou}
                                supportYouCounter={supportYouCounter}
                                getResources={getResources}
                                // logResourceAction={logResourceAction}
                            />
                            <EmojiReact
                                resource={item._id}
                                happy={happy}
                                happyCounter={happyCounter}
                                sad={sad}
                                sadCounter={sadCounter}
                                proud={proud}
                                proudCounter={proudCounter}
                                laughter={laughter}
                                laughterCounter={laughterCounter}
                                getResources={getResources}
                                // logResourceAction={logResourceAction}
                            />
                        </div>

                        <div className='flex flex-row justify-center md:justify-end mt-8'>
                            <Bookmark
                                id={item._id}
                                userToken={localUser.token}
                                userId={localUser.id}
                                isBookmarked={isBookmarked}
                                getResources={getResources}
                                // logResourceAction={logResourceAction}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Resource
