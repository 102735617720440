import React from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { logResourceAction } from '../../helpers/logs'
import { useUser } from '../../helpers/useUser'
import { getUser } from '../auth/LoginState'

interface B {
    id: string
    userToken: string
    userId: string
    isBookmarked: boolean
    getResources: () => void
    // logResourceAction: (action: string) => void
}

const Bookmark: React.FC<B> = ({ 
    id, 
    userToken, 
    userId, 
    isBookmarked, 
    getResources, 
    // logResourceAction, 
}) => {
    const user = getUser()
    const updateBookmarked = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/resource/${id}/bookmark`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${userToken}`,
                },
                data: {
                    resourceId: id,
                    type: 'resource',
                },
            })

            const saved = {
                user: userId, 
                postId: id,
                action: isBookmarked ? 'Unsave' : 'Save',
            }

            if (res.status === 201) {
                getResources()

                logResourceAction(user, id, isBookmarked ? 'Unsave' : 'Save')

                return Notification({
                    message: isBookmarked
                        ? 'Resource removed from your Garden'
                        : 'Resource successfully saved to your Garden',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <>
            {id && (
                <div
                    className={
                        isBookmarked
                            ? 'cursor-pointer py-2 px-6 rounded-full border-2 border-blue1 bg-blue1 text-white font-cgbold'
                            : 'cursor-pointer py-2 px-6 rounded-full border-2 border-blue1 text-blue1 font-cgbold'
                    }
                    onClick={() => {
                        updateBookmarked()
                    }}
                >
                    {isBookmarked ? <div>Remove from my Garden</div> : <div>Save to my Garden</div>}
                </div>
            )}
        </>
    )
}

export default Bookmark
