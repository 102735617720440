import React, { useState, useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Notification } from '../notifications'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import { ModerationComments } from '../admin/ModerationComments'

type FPE = {
    modal: (param: boolean) => void
    post: any
    getPosts: () => void
}

const PostEdit: React.FC<FPE> = ({ modal, post, getPosts }) => {
    const usr = getUser()
    const [submitting, setSubmitting] = useState(false)
    const [moderationReason, setModerationReason] = useState<boolean>(false)

    const initialValues = {
        title: post ? post.title : '',
        content: post ? post.content : '',
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        content: Yup.string().required('Content is required'),
    })

    const onSubmit = async (values: any) => {
        setSubmitting(true)

        try {
            const data = values

            data.postId = post?._id

            if (values.moderation && values.moderation !== '') {
                data.moderation = {
                    reason: values.moderation,
                    text: Object.values(ModerationComments)[values.moderation.replace('reason', '')],
                    ts: new Date(),
                }

                const res = await axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_SERVER}/api/admin/post/moderation/edit/community`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${usr.token}`,
                    },
                    data: data,
                })
                setSubmitting(false)

                if (res.status === 201) {
                    getPosts()
                    modal(false)

                    return Notification({
                        message: 'Post successfully moderated.',
                        type: 'success',
                    })
                } else {
                    return Notification({
                        message:
                            'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                        type: 'warning',
                    })
                }
            } else {
                setModerationReason(true)
                setSubmitting(false)
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (e.target.id == 'modal-container') {
                modal(false)
            }
        })
    }, [])

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'
        id='modal-container'>
            <div className='flex items-center justify-end h-11'
            id='modal-container'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => modal(false)}
                >
                    X
                </button>
            </div>
            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'
            id='modal-container'>
                <div className='p-2 md:p-0 flex justify-center'
                id='modal-container'>
                    <div className='bg-white max-w-4xl mx-0 rounded-xl p-2'>
                        {post ? (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div>
                                            <h2 className='text-5xl text-center font-cgbold mt-2'>Edit Post</h2>
                                            <div className='mt-4'>
                                                <label
                                                    className='block font-cgbold leading-6'
                                                    htmlFor='title'
                                                >
                                                    Title
                                                </label>
                                                <Field
                                                    component='textarea'
                                                    rows='3'
                                                    name='title'
                                                    placeholder='Post Title'
                                                    className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                />
                                                <ErrorMessage
                                                    name='title'
                                                    component='div'
                                                    className='block text-error font-cgbold mt-2'
                                                />
                                            </div>

                                            <div className='mt-4'>
                                                <label
                                                    className='block font-cgbold leading-6'
                                                    htmlFor='title'
                                                >
                                                    Content
                                                </label>
                                                <Field
                                                    component='textarea'
                                                    rows='9'
                                                    name='content'
                                                    placeholder='Post Content'
                                                    className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                />
                                                <ErrorMessage
                                                    name='content'
                                                    component='div'
                                                    className='block text-error font-cgbold mt-2'
                                                />
                                            </div>

                                            <div className='mt-4'>
                                                <label className='block font-cgbold leading-6'>Hashtags</label>
                                                <div className='mt-2 flex flex-wrap'>
                                                    {post.hashtags && post.hashtags.length > 0 ? (
                                                        <>
                                                            <div className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4'>
                                                                {post.hashtags.map((i: string, c: number) => {
                                                                    return <div key={c}>{i}</div>
                                                                })}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        'No hasthags have been added to this post'
                                                    )}
                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <label className='block font-cgbold leading-6'>
                                                    Moderation comments
                                                </label>
                                                <small>
                                                    If you've made any content changes, please state your reasons here.
                                                    This will be sent to the Original Poster.
                                                </small>
                                                <Field
                                                    component='select'
                                                    name='moderation'
                                                    placeholder='Select moderation option (optional)'
                                                    className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                >
                                                    <option value=''>Please select an option</option>
                                                    {Object.keys(ModerationComments).map((i, c) => {
                                                        return (
                                                            <option
                                                                key={c}
                                                                value={i}
                                                            >
                                                                {Object.values(ModerationComments)[c]}
                                                            </option>
                                                        )
                                                    })}
                                                </Field>
                                                {moderationReason ? (
                                                    <div className='block text-error font-cgbold mt-2'>
                                                        Please select a moderation option
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className='my-4'>
                                            <div className='flex justify-between'>
                                                <button
                                                    type='submit'
                                                    className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                                    disabled={isSubmitting}
                                                >
                                                    {submitting && <div className='loading'></div>}
                                                    Edit Post
                                                </button>
                                                <button
                                                    type='button'
                                                    className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                    onClick={() => {
                                                        modal(false)
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostEdit
