import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const TextContent = ({ item }: { item: any }) => {
    const nav = useNavigate()
    const [article, setArticle] = useState<string>()
    const [longArticle, setLongArticle] = useState<boolean>(false)

    useEffect(() => {
        if (item.content.length > 500) {
            setArticle(item.content.substring(0, item.content.lastIndexOf(' ', 500)))

            setLongArticle(true)
        } else {
            setArticle(item.content)
        }
    }, [item])

    return (
        <>
            {article && (
                <div className='text-left'>
                    {article}
                    {longArticle ? (
                        <>
                            {' '}
                            ...{' '}
                            <span
                                className='font-cgbold cursor-pointer text-blue1'
                                title={`Read entire ${item.title} article.`}
                                onClick={() => nav(`/community/article/${item._id}`)}
                            >
                                Read more
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            )}
        </>
    )
}

export default TextContent
