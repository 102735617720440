import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { useLocation, useNavigate } from 'react-router-dom'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import { getUser } from '../auth/LoginState'
import ExpandableContent from './ExpandableContent'
type P = {
    pid: string
}

const PostDeleteRequests: React.FC = () => {
    const user = getUser()
    const location = useLocation().state as P
    const nav = useNavigate()
    const [posts, setPosts] = useState<any>([])

    const getDeleteRequests = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/remove/user-request`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setPosts(res.data.posts)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [user.token, setPosts])

    const deletePost = async (id: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/remove/user-request/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 201) {
                getDeleteRequests()

                return Notification({
                    message: 'Post has been successfully removed.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        getDeleteRequests()
    }, [setPosts, getDeleteRequests])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Post delete requests</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                {posts && posts.length > 0 ? (
                    <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full w-[70vw]'>
                        <thead>
                            <tr className='bg-blue2'>
                                <th className='py-2 px-4'>#</th>
                                <th className='py-2 px-4'>Title</th>
                                <th className='py-2 px-4'>Content</th>
                                <th className='py-2 px-4'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='w-full'>
                            {posts.map((p: any, c: any) => {
                                return (
                                    <tr
                                        key={c}
                                        className={
                                            location && p._id === location.pid
                                                ? 'border-b border-black/20 bg-green3 hover:bg-blue-100 w-full'
                                                : 'border-b border-black/20 bg-white hover:bg-blue-100 w-full'
                                        }
                                    >
                                        <td className='py-2 px-4 text-center'>{c + 1}</td>
                                        <td
                                            className='py-2 px-4 w-[20%]'
                                        >
                                            <div
                                                className='cursor-pointer text-blue1 font-cgbold'
                                                onClick={() =>
                                                    nav(`/community/article/${p._id}`, {
                                                        state: { adminOrigin: true },
                                                    })
                                                }
                                            >
                                                {p.title}
                                            </div>
                                        </td>
                                        <td
                                            className='py-2 px-4 w-[70%] max-w-[70%]'
                                        >
                                            {/* <div
                                                style={{
                                                    maxWidth: '600px',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    cursor: 'default',
                                                }}
                                                title={p.content}
                                            >
                                                {p.content}
                                            </div> */}
                                            <ExpandableContent
                                                content={p.content}
                                            />
                                        </td>
                                        <td className='py-2 px-4 text-center'>
                                            <button
                                                className='rounded-full py-2 px-4 bg-red1 font-cgbold'
                                                onClick={() => deletePost(p._id)}
                                            >
                                                Delete post
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>There are currently no posts for removal!</div>
                )}
            </div>
        </>
    )
}

export default PostDeleteRequests
