import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Notification } from '../notifications'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import User from '../community/User'
import { getUser } from '../auth/LoginState'
import { formatDate } from '../../helpers/dates'

const EscalatedPosts: React.FC = () => {
    const user = getUser()
    const nav = useNavigate()
    const [posts, setPosts] = useState<any>([])

    const options = ['not yet started', 'ACTIVE', 'ARCHIVED']

    const getEscalatedPosts = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/escalated/list`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setPosts(res.data.posts)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [setPosts, user.token])

    const updateEscalationStatus = async (status: string, postId: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/escalated/update`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {
                    status: status,
                    postId: postId,
                },
            })

            if (res.status === 201) {
                getEscalatedPosts()

                return Notification({
                    message: 'Escalation status updated.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const highlightRow = (status: any) => {
        if (status === 'not yet started') {
            return '#f2d04a'
        } else if (status === 'ACTIVE') {
            return '#ffc3d2'
        } else if (status === 'ARCHIVED') {
            return ''
        }
    }

    useEffect(() => {
        getEscalatedPosts()
    }, [getEscalatedPosts])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-5xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Escalated Posts</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-5xl mt-16 min-h-screen'>
                {posts && posts.length > 0 ? (
                    <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full'>
                        <thead>
                            <tr className='bg-blue2'>
                                <th className='py-2 px-4'>#</th>
                                <th
                                    className='py-2 px-4'
                                    style={{ width: '50%' }}
                                >
                                    Title
                                </th>
                                <th
                                    className='py-2 px-4'
                                    style={{ width: '15%' }}
                                >
                                    User
                                </th>
                                <th className='py-2 px-4'>Date posted</th>
                                <th className='py-2 px-4'>Date escalated</th>
                                <th
                                    className='py-2 px-4'
                                    style={{ width: '20%' }}
                                >
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {posts.map((p: any, c: any) => {
                                return (
                                    <tr
                                        key={c}
                                        style={{ backgroundColor: highlightRow(p.escalation.status) }}
                                        className='border-b border-black/20'
                                    >
                                        <td className='py-2 px-4 text-center'>{c + 1}</td>
                                        <td className='py-2 px-4'>
                                            <div
                                                className='text-blue1 cursor-pointer font-cgbold'
                                                onClick={() =>
                                                    nav(`/community/article/${p._id}`, {
                                                        state: {
                                                            adminOrigin: true,
                                                        },
                                                    })
                                                }
                                            >
                                                {p.title}
                                            </div>
                                        </td>
                                        <td
                                            className='py-2 px-4'
                                            style={{ textAlign: 'center' }}
                                        >
                                            <User
                                                user={p.u}
                                                adminStats={true}
                                                allowFollow={false}
                                                getLoggedInUser={getEscalatedPosts}
                                            />
                                        </td>
                                        <td
                                            className='py-2 px-4'
                                            style={{ textAlign: 'center' }}
                                        >
                                            {formatDate(p.dateCreated)}
                                        </td>
                                        <td
                                            className='py-2 px-4'
                                            style={{ textAlign: 'center' }}
                                        >
                                            {formatDate(p.escalation.escalationDate)}
                                        </td>
                                        <td
                                            className='py-2 px-4'
                                            style={{ textAlign: 'center' }}
                                        >
                                            <select
                                                className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                onChange={(e) => updateEscalationStatus(e.target.value, p._id)}
                                            >
                                                {options.map((o: string, i: number) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            value={o}
                                                            selected={o === p.escalation.status}
                                                        >
                                                            {o}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>There are currently no escalated posts!</div>
                )}
            </div>
        </>
    )
}

export default EscalatedPosts
