import React from 'react'
import CommentsIcon from '../../assets/images/icon_comments.svg'

type CH = {
    showComments: boolean
    setShowComments: (param: boolean) => void
    count: number
}

const CommentsAdd: React.FC<CH> = ({ showComments, setShowComments, count }) => {
    return (
        <>
            <div
                className={
                    showComments
                        ? 'cursor-pointer py-2 px-6 mx-2 rounded-full border-2 border-blue1 bg-blue1 text-white font-cgbold text-center flex align-center'
                        : 'cursor-pointer py-2 px-6 mx-2 rounded-full border-2 border-blue1 text-blue1 font-cgbold text-center flex align-center'
                }

                onClick={() => setShowComments(!showComments)}
            >
                {showComments? <div className='flex my-auto'>Hide comments</div> :<div className='flex my-auto'>Add a comment</div>}
            </div>
        </>
    )
}

export default CommentsAdd
