import React from 'react'
import { useNavigate } from 'react-router-dom'
import Masthead from '../layout/MastHead'
import AdminAvatar from '../../assets/images/admin_avatar.png'
import AdminAvatar2 from '../../assets/images/admin2_avatar.png'
import PostFlag from '../../assets/images/postflag.png'
import TalkSafely from '../../assets/images/talksafely.png'
import Offensive from '../../assets/images/offensive.png'
import Priority from '../../assets/images/priority.png'
import PolicyBottom from '../../assets/images/policybottom.png'
import Clock from '../../assets/images/icons/clock.png'
import EditDelete from '../../assets/images/icons/editdelete.png'
import Flag from '../../assets/images/icons/flag.png'
import InfoIcon from '../../assets/images/icons/infoicon.png'
import InfoLoop from '../../assets/images/icons/infoloop.png'
import Messages from '../../assets/images/icons/messages.png'
import MsgBubble from '../../assets/images/icons/msgbubble.png'
import PaperClip from '../../assets/images/icons/paperclip.png'
import PplLove from '../../assets/images/icons/ppllove.png'
import ThankYou from '../../assets/images/icons/thankyou.jpg'
import People from '../../assets/images/icons/people.png'
import Info from '../../assets/images/icons/info.png'
import Phone from '../../assets/images/icons/phone.png'
import Doctor from '../../assets/images/icons/doctor.png'
import HeartHands from '../../assets/images/icons/hearthands.png'
import ScrollToTop from '../layout/ScrollToTop'

const ModerationPolicy = () => {
    const nav = useNavigate()

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-52 md:mt-16 text-center px-4'>
                <h1 className=' text-4xl md:text-5xl font-cgbold mt-20'>CommonGround Moderation and Safeguarding Policies</h1>
                <p className='mt-4'>
                    On this page you can read our moderation Policy and our Safeguarding Policy. These help us to keep
                    the CommonGround community a safe, non-judgement and supportive environment for all of our members.
                    If you have any questions or concerns, please reach out to the CommonGround team via email:
                    commonground@kcl.ac.uk.
                </p>
            </div>

            <div className='container mx-auto w-full max-w-3xl mt-8 min-h-screen mb-32'>
                <div className='flex flex-wrap max-w-2xl mx-auto items-center justify-center'>
                    <a
                        href='#moderation'
                        className='flex items-center justify-center cursor-pointer bg-green1 mx-1 my-2 rounded-xl py-6 px-8 font-cgbold text-center text-white text-3xl'
                    >
                        Moderation
                        <br />
                        Policy
                    </a>
                    <a
                        href='#safeguarding'
                        className='flex items-center justify-center cursor-pointer bg-green3 mx-1 my-2 rounded-xl py-6 px-8 font-cgbold text-center text-green1 text-3xl'
                    >
                        Safeguarding
                        <br />
                        Policy
                    </a>
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='moderation'
                        className='font-cgbold text-2xl mt-8'
                    >
                        Moderation Policy
                    </h2>
                    <p className='mt-4'>
                        We want to ensure that CommonGround is a safe, respectful space for you to interact with others
                        and share your experiences. We hope that the majority of interactions between community members
                        will be positive and empowering. However, we will have safeguards in place to help protect
                        everyone using the platform and deal with any situations quickly and appropriately. Our
                        moderation policy helps us keep CommonGround a safe, supportive space where everyone feels
                        welcomed and discussion can flourish.
                    </p>
                </div>

                <div className='flex flex-wrap max-w-2xl mx-auto items-center justify-center mt-4'>
                    <a
                        href='#whomoderators'
                        className='flex items-center justify-center cursor-pointer bg-green3 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Who are our moderators?
                    </a>
                    <a
                        href='#moderatorsdo'
                        className='flex items-center justify-center cursor-pointer bg-red1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        What will our moderators do?
                    </a>
                    <a
                        href='#looking'
                        className='flex items-center justify-center cursor-pointer bg-green1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Looking after you
                    </a>
                    <a
                        href='#moderatorsnotdo'
                        className='flex items-center justify-center cursor-pointer bg-purple1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        What will our moderators not do?
                    </a>
                    <a
                        href='#anon'
                        className='flex items-center justify-center cursor-pointer bg-yellow1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Keeping you anonymous
                    </a>
                    <a
                        href='#risks'
                        className='flex items-center justify-center cursor-pointer bg-blue2 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Are there any risks?
                    </a>
                    <a
                        href='#talkabout'
                        className='flex items-center justify-center cursor-pointer bg-blue1 text-white w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        What can I talk about?
                    </a>
                    <a
                        href='#disagreements'
                        className='flex items-center justify-center cursor-pointer bg-blue3 text-black w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Tips for handling disagreements
                    </a>
                </div>

                <div className='mt-4 px-4'>
                    <h2
                        id='whomoderators'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-green3 text-2xl py-2 mt-8'
                    >
                        Who are our moderators?
                    </h2>
                    <p className='mt-4'>
                        CommonGround will be moderated to help keep a safe, respectful, and supportive space.  
                        <strong>Please be aware that CommonGround is <u>not</u> moderated 24/7.</strong> If you are in need for extra 
                        support or are experiencing a crisis, please check out our 
                        <a 
                            href='/support'
                            title='Support'
                            className='font-cgbold text-blue1 cursor-pointer'
                        >
                            Further Support and Crisis page.
                        </a> 
                        You will see our moderators in the community feed and resources page – it is easy to spot 
                        who is a CommonGround moderator because their username is ‘ModeratorBlue’, ‘ModeratorGreen’, 
                        ‘ModeratorYellow’, ‘ModeratorPurple’ ‘ModeratorOrange’ and ‘ModeratorPink’, and they have a 
                        unique avatar.
                    </p>

                    <p className='mt-4 max-w-sm mx-auto flex flex-row justify-between items-center'>
                        <div className='flex flex-col items-center font-cgbold text-center mr-4'>
                            <img
                                src={AdminAvatar}
                                alt='Admin Avatar'
                                className='w-16 h-16'
                            />
                            <div>
                                The <span className='text-green1'>Moderation</span>
                                <br />
                                Team
                            </div>
                        </div>
                        <div className='flex flex-col items-center font-cgbold text-center'>
                            <img
                                src={AdminAvatar2}
                                alt='Admin Avatar'
                                className='w-16 h-16'
                            />
                            <div>
                                The{' '}
                                <span className='text-red1'>
                                    Engagement
                                    <br />
                                    and Support
                                </span>{' '}
                                Team
                            </div>
                        </div>
                    </p>

                    <p className='mt-4'>
                        If you see an account that you think might be impersonating a CommonGround team member or
                        moderator, please do not hesitate to contact us or flag one of their posts to our moderating
                        team.
                    </p>

                    <p className='mt-4'>
                        You might also see another CommonGround Team account posting, reacting, and commenting in the
                        community. This is our engagement team who are here to nurture CommonGround but do not carry out
                        moderation responsibilities.
                    </p>
                </div>

                <div className='mt-4 px-4'>
                    <h2
                        id='moderatorsdo'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-red1 text-2xl py-2 mt-8'
                    >
                        What will our moderators do?
                    </h2>
                    <p className='mt-4'>
                        Our moderators are here to keep everyone safe and make sure that people follow our Community
                        Principles when using CommonGround. You can read our Community Principles 
                        <span className="font-cgbold text-blue1 cursor-pointer" onClick={() => {
                            nav('/principles', {
                                state: {
                                    noCheckbox: false,
                                },
                            });
                        }}> here. </span>
                        Our moderators will be objective, impartial, and consistent. Our moderators will be…
                    </p>

                    <div className='grid grid-cols-1 gap-2 md:flex md:flex-row justify-items-center justify-items items-center mt-4'>
                        <div className='md:h-80 w-full md:w-1/5 bg-purple1 rounded-lg p-3 flex flex-col items-center mx-1'>
                            <img
                                src={Flag}
                                alt=''
                                className='w-16 h-16'
                            />
                            <div className='mt-4 text-center'>
                                <strong>Reviewing posts</strong> that our community members have{' '}
                                <strong>flagged</strong>
                            </div>
                        </div>
                        <div className='md:h-80 w-full md:w-1/5 bg-green3 rounded-lg p-3 flex flex-col items-center mx-1'>
                            <img
                                src={EditDelete}
                                alt=''
                                className='h-16'
                            />
                            <div className='mt-4 text-center'>
                                <strong>Editing and deleting posts</strong> to ensure that the community principles are
                                followed
                            </div>
                        </div>
                        <div className='md:h-80 w-full md:w-1/5 bg-green4 rounded-lg p-3 flex flex-col items-center mx-1'>
                            <img
                                src={Messages}
                                alt=''
                                className='h-16'
                            />
                            <div className='mt-4 text-center'>
                                <strong>Guiding discussions</strong> and handling any potential disagreements{' '}
                            </div>
                        </div>
                        <div className='md:h-80 w-full md:w-1/5 bg-green1 rounded-lg p-3 flex flex-col items-center mx-1'>
                            <img
                                src={InfoIcon}
                                alt=''
                                className='w-16 h-16'
                            />
                            <div className='mt-4 text-white text-center'>
                                <strong>Sharing resources</strong> and <strong>information</strong> via community posts,
                                comments, or email
                            </div>
                        </div>
                        <div className='md:h-80 w-full md:w-1/5 bg-blue3 rounded-lg p-3 flex flex-col items-center mx-1'>
                            <img
                                src={PaperClip}
                                alt=''
                                className='h-16'
                            />
                            <div className='mt-4 text-center'>
                                <strong>Reviewing posts</strong> with <strong>attachments</strong> before they are
                                published
                            </div>
                        </div>
                    </div>

                    <p className='mt-4'>
                        As we want to promote our community members posting as freely as possible, <strong>our moderators will
                        only edit or comment on posts if necessary to ensure that our
                        <span
                            className='font-cgbold text-blue1 cursor-pointer'
                            onClick={() =>
                                nav('/principles', {
                                    state: {
                                        noCheckbox: true,
                                    },
                                })
                            }
                        >
                            {' '}
                            Community Principles{' '}
                        </span>
                        are followed.</strong> Any post or comment that has been edited will be clearly labelled. If a moderator
                        needs to edit or delete any of your posts, you will receive a notification to let you know that
                        this has happened, and the reason why will also be provided. Any post or comment containing
                        content that violates our Community Principles may be deleted without warning to protect our
                        community. Our moderators will also reach out to community members about their behaviour on
                        CommonGround via email if necessary.
                    </p>

                    <p className='mt-4'>
                        If you are worried about anything or anyone on CommonGround or see any inappropriate content or
                        behaviour, you can notify our moderators by 'flagging' the post.
                    </p>

                    <ul className='mt-4 list-disc list-inside'>
                        <strong>How to flag a post?</strong>
                        <li>
                            To flag a post, simply click on the three dots in the top right-hand corner of the post,
                            select 'flag this post to our moderators' and explain why you are flagging the post - this
                            helps our moderators to understand why the post is being flagged. This will not
                            automatically result in someone being 'blocked' or their post removed from the community
                            feed.
                        </li>
                        <img src={PostFlag}></img>
                        <li>
                            Once you have flagged something to us, our moderators will prioritise reviewing the post as
                            soon as possible and you will be notified when the review is complete. Our moderators will
                            not tell the community member that you flagged their post.
                        </li>
                        <li>
                            If one of your posts is flagged, and our moderators decide if it is necessary to edit or
                            delete your post, you will receive a notification to tell you.
                        </li>
                    </ul>

                    <p className='mt-4'>
                        <span className='font-cgbold'>Please note that the CommonGround team has the right, but no obligation, to remove any content
                        for any reason, at any time.</span> Our moderators will review and take action as appropriate against
                        any member who uses the 'flag post' button inappropriately.
                    </p>

                    <p className='mt-4'>
                        <strong>On CommonGround, healthy discussion and the sharing of ideas are encouraged.</strong> We
                        hope that our community members will be able to resolve any conflicts themselves - check out
                        our <a href='#disagreements text-cgbold text-blue1'>“tips for handling disagreements and conflicts yourself”</a> at the bottom of the Moderation Policy. However, you might see our moderators commenting on posts
                        in the community feed if they feel the discussion has become potentially harmful or
                        disrespectful. If the discussion escalates, or there is a violation of the Community Principles,
                        the moderators can delete the post and contact the user(s) privately.
                    </p>

                    <p className='mt-4'>
                        <strong>
                            We know that people may breach principles unintentionally at times. Therefore, we operate a
                            'three strike' policy. If someone repeatedly violates our Community Principles
                        </strong>
                        , our moderators will privately contact them to explain which principle(s) has been violated,
                        why the principles are important, and any actions that will be taken.{' '}
                        <strong>
                            However, we understand that behind every post is a human voice and review each case
                            individually.
                        </strong>{' '}
                        As a registered member on CommonGround you have consented to our moderators privately contacting
                        you under these circumstances. Please be aware that CommonGround reserves the right to remove a
                        user temporarily or permanently from the site at any time.
                    </p>

                    <p className='mt-4'>
                        <strong>
                            If you feel distressed by anything you may have seen, please access our support resources
                            <a
                                href='/support'
                                title='Support'
                                className='font-cgbold text-blue1 cursor-pointer'
                            >
                                {' '}
                                here
                            </a>
                        </strong>
                        . If you no longer wish a member's posts to appear in your community feed, you can 'mute' the
                        user. To do this, click the 'mute' button on their profile (to view their profile, click on
                        their avatar in the community feed). You may also "flag a post" at any point directly to the
                        CommonGround team (see 'how to flag a post' above).
                    </p>

                    <p className='mt-4'>
                        Our moderators will also follow our Community Principles and will be respectful to all. If you
                        wish to discuss an instance where you believe a moderator has breached our Community Principles,
                        please contact the CommonGround team via email: commonground@kcl.ac.uk.
                    </p>
                </div>

                <div className='mt-4 px-4'>
                    <h2
                        id='looking'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-green1 text-2xl py-2 mt-8'
                    >
                        Looking after you.
                    </h2>
                    <p className='mt-4'>
                        CommonGround is here to provide a safe, judgement-free space for people to share their
                        experiences and seek support, using a combination of community discussion and our educational
                        resources.{' '}
                        <strong>
                            CommonGround is not a crisis service. However, we understand that sometimes you might find
                            yourself in a difficult situation and be unsure about how to get additional support.
                        </strong>{' '}
                        The CommonGround team may share resources that might be useful to someone having a difficult
                        time via email to all community members. Our moderators might also share relevant resources in
                        the comment sections of posts that they feel might be useful.{' '}
                        <strong>
                            However, please be aware that our moderators will not (and are unable to) act upon anything
                            you post here that might suggest you are struggling - this means that we will not contact
                            the emergency services, your GP, or any other member of your healthcare team.
                        </strong>
                    </p>

                    <p className='mt-4'>
                        We also have a dedicated
                        <a
                            href='/support'
                            title='Support'
                            className='font-cgbold text-blue1 cursor-pointer'
                        >
                            {' '}
                            Further Support and Crisis{' '}
                        </a>
                        page, where you can find resources to help if you are in crisis or concerned that someone else
                        is in crisis. Always dial 999 in an emergency or if you feel unsafe.
                    </p>
                </div>

                <div className='mt-4 px-4'>
                    <h2
                        id='moderatorsnotdo'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-purple1 text-2xl py-2 mt-8'
                    >
                        What will our moderators NOT do?
                    </h2>
                    <p className='mt-4'>
                        <strong>
                            Our CommonGround platform is not a crisis service and cannot provide one-to-one support.
                        </strong>{' '}
                        We also cannot offer any personal medical advice. Our moderators will signpost community members
                        to resources that may help support you, but they{' '}
                        <span className='font-cgbold underline'>will not</span> contact the emergency services, talk to
                        your GP, or any other member of your healthcare team.
                    </p>
                </div>

                <div className='mt-4 px-4'>
                    <h2
                        id='anon'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-yellow1 text-2xl py-2 mt-8'
                    >
                        Keeping you anonymous.
                    </h2>
                    <p className='mt-4'>
                        On CommonGround, <strong>you are anonymous to all other users</strong>. The platform is
                        anonymous to encourage open sharing of experiences and advice, as people might feel more
                        comfortable talking about their life when 'no one knows who I am'.
                    </p>

                    <p className='mt-4'>
                        To{' '}
                        <strong>
                            keep everyone safe, our moderators can access the personal details linked to your
                            CommonGround profile
                        </strong>
                        , including your real name, email address, and telephone number. Therefore, although you are
                        anonymous to other community members, our moderators can identify you if needed. As a registered
                        user of CommonGround, you have consented to our moderators to use these details to contact you
                        privately when they are concerned about your participation in the community. Therefore, although
                        you are anonymous to other community members, our moderators and research team can identify you
                        if necessary.
                    </p>

                    <p className='mt-4'>
                        You may also receive emails from the CommonGround team that are sent to the whole community to
                        share information, updates or resources that might be useful to you.
                    </p>

                    <p className='mt-4'>
                        To ensure you remain anonymous, you must create a display name that does not include a real
                        name. You can also choose one of the CommonGround avatars for your profile. Our Community
                        Principles state that community members should not disclose any information that could identify
                        themselves or other users, and our moderators will edit or delete posts to maintain anonymity.
                    </p>

                    <p className='mt-4'>
                        If a community member continues to disclose personal information, the moderators may directly
                        contact the user to remind them of the
                        <span
                            className='font-cgbold text-blue1 cursor-pointer'
                            onClick={() =>
                                nav('/principles', {
                                    state: {
                                        noCheckbox: true,
                                    },
                                })
                            }
                        >
                            {' '}
                            Community Principles
                        </span>
                        .
                    </p>
                </div>

                <div className='flex flex-col mt-4 px-4'>
                    <h2
                        id='talkabout'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-blue1 text-white text-2xl py-2 mt-8'
                    >
                        What can I talk about on CommonGround?
                    </h2>
                    <p className='mt-4'>
                        Here on CommonGround we want people to talk freely about their lived experiences, whatever these
                        might be - this is your safe space to share. For that reason, there are no topics which are
                        'banned' on CommonGround, but there are some topics which we need to make sure are spoken about
                        safely. Sharing experiences or talking about sensitive topics can be helpful for many people,
                        but to keep everyone safe, there are certain types of content that our moderators will review
                        and edit or delete if necessary. The topics our moderators will review include self-harm,
                        suicide, and risky behaviours.
                    </p>
                    
                    <img className="flex self-center" src={TalkSafely} alt="" width={250}/>
                    <p className='mt-4 font-cgbold'>What do these mean?</p>
                    <p className='mt-2'>
                        <a
                            href='https://www.samaritans.org/about-samaritans/research-policy/internet-suicide/guidelines-tech-industry/understanding-self-harm-and-suicide-content'
                            target='_blank'
                            className='font-bold text-blue1'
                            rel='noreferrer'
                        >
                            Self-harm{' '}
                        </a>
                        includes any deliberate act of self-injury or self-poisoning or self-injury without suicidal
                        intent. This does not include accidents, substance misuse (e.g., of drugs or alcohol), and
                        eating disorders. This also does not include episodes of self-harm where the person was trying
                        to take their own life.
                    </p>

                    <p className='mt-2'>
                        <a
                            href='https://www.samaritans.org/about-samaritans/research-policy/internet-suicide/guidelines-tech-industry/understanding-self-harm-and-suicide-content'
                            target='_blank'
                            className='font-bold text-blue1'
                            rel='noreferrer'
                        >
                            Suicide{' '}
                        </a>
                        is the act of intentionally ending one's life. Many factors can contribute toward someone's
                        decision to end their life, including depression and mental illness, stress, financial problems,
                        relationship breakdown, bereavement, and abuse.
                    </p>

                    <p className='mt-2'>
                        <a
                            href='https://link.springer.com/referenceworkentry/10.1007/978-1-4419-1005-9_1551'
                            target='_blank'
                            className='font-bold text-blue1'
                            rel='noreferrer'
                        >
                            Risky behaviour{' '}
                        </a>
                        means any behaviour that potentially exposes someone to harm, or significant risk of harm. Risky
                        behaviours might have physical, economic, or psychosocial costs for oneself or other people.
                        This can include excessive alcohol consumption or using alcohol as a coping mechanism, illegal
                        drug use, sexual behaviour, and the sharing of misinformation or prejudice/discriminatory views.
                    </p>

                    <p className='mt-2'>
                        <strong>What is okay to post about self-harm, suicide, and risky behaviour?</strong>
                    </p>

                    <p className='mt-2'>
                        The sections below show what is okay and what is not okay to talk about when discussing these
                        topics. The Samaritans also have some pointers on talking about self-harm and suicide
                        experiences which you can find{' '}
                        <a
                            href='https://www.samaritans.org/about-samaritans/research-policy/internet-suicide/online-safety-resources/how-talk-about-your-experiences-self-harm-and-suicidal-feelings-online-safely/'
                            target='_blank'
                            className='font-bold text-blue1'
                            rel='noreferrer'
                        >
                            here
                        </a>
                        .
                    </p>

                    <div className='mt-4 border-2 border-blue2 rounded-lg shadow-lg'>
                        <div className='flex flex-col'>
                            <div className='bg-blue2 p-4 text-center font-bold text-xl'>It is okay to share…</div>
                        </div>
                        <div className='p-4'>
                            <ul className='list-disc list-inside'>
                                <li>
                                    <strong>Your experiences of self-harm or suicide</strong>, methods of coping during
                                    these times, and positive stories of recovery.
                                </li>
                                <li className='mt-2'>
                                    <strong>Your lived experience of alcohol and/or drug use</strong>, including misuse,
                                    struggles that you experienced, self-care and coping during these times, and
                                    positive stories of recovery.{' '}
                                </li>
                                <li className='mt-2'>
                                    <strong>Your experiences of using{' '} 
                                        <a  className='text-blue1' 
                                            href='https://www.nhs.uk/conditions/medical-cannabis/'
                                            rel='norefferer'
                                            target='_blank'
                                        >
                                            prescribed medicinal cannabis
                                        </a>
                                    </strong> and information your
                                    healthcare team have shared with you about it. You may wish to read the latest NHS webpage on 
                                    <a href="https://www.nhs.uk/conditions/medical-cannabis/" target="_blank" className='text-blue1 font-cgbold'> Medical Cannabis.</a>{' '}
                                </li>
                                <li className='mt-2'>
                                    <strong>Your lived experience(s) of violence or abuse</strong>, and positive stories
                                    of survival.
                                </li>
                                <li>
                                    <strong>Your experiences of different medications or treatment options</strong>,
                                    being mindful that decisions about someone's treatment is between themselves and
                                    their GP/healthcare team.{' '}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='mt-4 border-2 border-blue3 rounded-lg shadow-lg'>
                        <div className='flex flex-col'>
                            <div className='bg-blue3 p-4 text-center font-bold text-xl'>
                                It is NOT okay to talk about…
                            </div>
                        </div>
                        <div className='p-4'>
                            <ul className='list-disc list-inside'>
                                <li>
                                    <strong>The promotion or encouragement of self-harm and suicide. </strong>Any content that
                                    intentionally encourages self-harm or suicide, or portrays these behaviours as
                                    positive or desirable, will be removed. Please be aware that it is illegal to share
                                    content that intentionally encourages the suicide or attempted suicide of another
                                    person (
                                    <a
                                        className='font-bold text-blue1'
                                        target='_blank'
                                        href='https://www.legislation.gov.uk/ukpga/Eliz2/9-10/60'
                                        rel='noreferrer'
                                    >
                                        under the 1961 Suicide Act
                                    </a>
                                    ).
                                </li>
                                <li className='mt-2'>
                                    <strong>Graphic descriptions or depictions of acts of self-harm or suicide</strong>.
                                    Any image or media that represents self-harm or suicide will not be approved by our
                                    moderators. Any post or comment that contains descriptions of methods or
                                    instructions, consideration of their 'effectiveness', or comparisons of different
                                    methods will be edited or removed by our moderators.
                                </li>
                                <li className='mt-2'>
                                    <strong>Suicide pacts and challenges</strong>, where people might be encouraged to
                                    harm themselves or partake in harmful behaviour together.
                                </li>
                                <li className='mt-2'>
                                    <strong>Promotion and encouragement of drug use</strong>, descriptions of
                                    quantities, or how to use or acquire drugs (prescription, legal, illegal drugs).
                                    Promotion and encouragement of underage drinking.
                                </li>
                                <li>
                                    <strong>Discrimination, mockery, hate speech, or bullying of any kind</strong>. This
                                    includes toward people who have self-harmed, attempted, or died, by suicide. This
                                    also includes the protected characteristics - age, ethnicity, sex, sexual
                                    orientation, gender identity and gender reassignment, disability, religion or
                                    beliefs, disability, marital status, or pregnancy and maternity status.
                                </li>
                                <li>
                                    <strong>
                                        Promotion of, or persuasion to try, different treatments and/or medications
                                    </strong>{' '}
                                    or specific doses of medications. Whilst you can share your own experiences,
                                    someone's decision about their healthcare is between themselves and their health
                                    care professionals.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <p className='mt-4'>
                        If you have been affected in any way by any of the topics discussed above, please do not
                        hesitate to get some further support. Please see below or go to our{' '}
                        <a
                            href='/support'
                            title='Support'
                            className='font-cgbold text-blue1 cursor-pointer'
                        >
                            {' '}
                            Support and Crisis page
                        </a>
                        .
                    </p>

                    <div className='mt-4 grid grid-cols-2 justify-items-center gap-x-2 gap-y-12 md:gap-0 md:flex md:flex-row items-center justify-between'>
                        <div className='flex flex-col bg-green2 w-full md:w-1/4 h-64 rounded-xl p-4 text-center items-center justify-center mx-1'>
                            <div className='font-cgbold'>TALK TO SOMEONE AROUND YOU</div>
                            <div>Talk to someone you trust - family, friend, or colleague</div>
                            <div className='rounded-full bg-green2 absolute mt-64 p-2'>
                                <img
                                    src={People}
                                    alt='exclamation mark'
                                    width='50'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col bg-purple1 w-full md:w-1/4 h-64 rounded-xl p-4 text-center items-center justify-center mx-1'>
                            <div className='font-cgbold'>
                                SUPPORT VIA PHONE
                                <br />
                                Call 116 123
                            </div>
                            <div>Talk anonymously with a Samaritan volunteer (free, 24/7)</div>
                            <div className='rounded-full bg-purple1 absolute mt-64 p-2'>
                                <img
                                    src={Info}
                                    alt='exclamation mark'
                                    width='50'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col bg-red1 w-full md:w-1/4 h-64 rounded-xl p-4 text-center items-center justify-center mx-1'>
                            <div className='font-cgbold'>
                                SUPPORT VIA TEXT
                                <br />
                                Text SHOUT to 85258
                            </div>
                            <div>Text anonymously with SHOUT volunteer (free, 24/7)</div>
                            <div className='rounded-full bg-red1 absolute mt-64 p-2'>
                                <img
                                    src={Phone}
                                    alt='exclamation mark'
                                    width='50'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col bg-yellow1 w-full md:w-1/4 h-64 rounded-xl p-4 text-center items-center justify-center mx-1'>
                            <div className='font-cgbold'>TALK TO YOUR GP</div>
                            <div>You can request an emergency appointment</div>
                            <div className='rounded-full bg-yellow1 absolute mt-64 p-2'>
                                <img
                                    src={Doctor}
                                    alt='exclamation mark'
                                    width='50'
                                />
                            </div>
                        </div>
                    </div>

                    <p className='mt-12'>
                        You might notice that some posts are labelled with a 'may contain offensive language or
                        sensitive content' tag to advise you that the post may contain some of these topics. If you do 
                        see something that you think is inappropriate, please do not hesitate to ‘flag’ this to one of 
                        our moderators for review. You can do this by clicking on the three dots in the top right-hand 
                        corner of the post and selecting ‘flag this post to the moderators’.
                    </p>
                    <img className='mt-4' src={Offensive}></img>
                    <p className='mt-2'>
                        <strong>What language can I use?</strong> We understand that everyone has different ways of
                        expressing their thoughts and feelings. Please be aware that our moderators will remove
                        excessive swearing or swearing aimed at other community member(s) or moderators. If a post
                        contains a profanity, it will automatically be labelled with a 'may contain offensive language
                        or sensitive content' tag to make you aware of this.
                    </p>

                    <p className='mt-2'>
                        <strong>What can I share?</strong> You can upload things that you have found useful for managing
                        your well-being and living with your long-term condition(s) - these can be PDFs, word documents,
                        videos, images, or audio clips. Please be aware that all uploads will be reviewed by our moderators
                        before they are shared with the community. You can also share links in your posts - please make
                        sure that you only share links to safe, reputable websites. Please be aware that CommonGround
                        and KCL do not endorse any resources or links that are shared by our community members.
                    </p>
                </div>

                <div className='mt-4 px-4'>
                    <h2
                        id='risks'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-blue2 text-2xl py-2 mt-8'
                    >
                        Are there any risks of using CommonGround?
                    </h2>
                    <p className='mt-4'>
                        CommonGround has a set of{' '}
                        <span
                            className='font-cgbold text-blue1 cursor-pointer'
                            onClick={() =>
                                nav('/principles', {
                                    state: {
                                        noCheckbox: true,
                                    },
                                })
                            }
                        >
                            {' '}
                            Community Principles
                        </span>{' '}
                        and a Moderation Policy to help keep our community members safe. However, <strong>each community member
                        has responsibility for the content that they share, and we are unable to review every post or
                        comment before it is shared.</strong> This means that you might encounter sensitive content that might be
                        difficult to view or cause some distress. If this happens you can seek additional support 
                        <a
                            href='/support'
                            title='Support'
                            className='font-cgbold text-blue1 cursor-pointer'
                        >
                            {' '}
                            here
                        </a>,
                        flag the post for review by our moderators, and/or 'mute' a user.
                    </p>

                    <p className='mt-4'>
                        Sometimes when we are going through a difficult time ourselves, it can be difficult to hear the
                        stories of others or attempt to offer help as we feel overwhelmed or may be unsure about how
                        best to help. Peer support platforms, like CommonGround, might not be helpful for every moment
                        of your journey of living with a long-term condition(s). If you are feeling like this, we
                        encourage you to take a break from CommonGround and come back when you feel ready to engage with
                        the community again.
                    </p>
                    <img className='mt-4' src={Priority} />
                    <p className='mt-4'>
                        Please remember that our community members are people like you who are living with long-term
                        condition(s). As they are not medical professionals, it is important that you discuss any
                        changes to your treatment or medications with your GP or healthcare professionals.{' '}
                    </p>
                    <p className='mt-4'>
                        If you are struggling with your emotional well-being or mental health, please remember that
                        CommonGround is a platform you can use to gain support from others and share what you are
                        experiencing, but it is not a substitute for mental health care. You can find information about
                        extra support that is available{' '}
                        <a
                            href='/support'
                            title='Support'
                            className='font-cgbold text-blue1 cursor-pointer'
                        >
                            {' '}
                            here
                        </a>
                        .{' '}
                    </p>

                    <p className='mt-4'>
                        As CommonGround is an online platform, please be aware of the risks of the internet, and take
                        steps to make sure are staying safe online. The following links might be useful:
                        <ul className='list-disc list-inside'>
                            <li>
                                <a
                                    href='https://www.getsafeonline.org/personal/articles/safe-internet-use/'
                                    title='Internet Safety'
                                    className='font-cgbold text-blue1 cursor-pointer'
                                    rel='noreferrer'
                                    target='_blank'
                                >
                                    How to use the internet safely
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.getsafeonline.org/personal/articles/social-networking-sites/'
                                    title='Internet Safety'
                                    className='font-cgbold text-blue1 cursor-pointer'
                                    rel='noreferrer'
                                    target='_blank'
                                >
                                    Tips for safe social networking
                                </a>
                            </li>
                        </ul>
                    </p>
                </div>

                <div className='mt-4 px-4'>
                    <h2
                        id='disagreements'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-blue3 text-2xl py-2 mt-8'
                    >
                        Tips for handling disagreements and conflicts yourself
                    </h2>
                    <p className='mt-4'>
                        When we interact with other people online, a small difference in opinion or slight
                        misunderstandings can quickly escalate. Sometimes we can misinterpret the tone or meaning of
                        what someone is saying. There are a few things that can help to communicate with another
                        community member when trying to resolve a potential conflict:
                    </p>

                    <div className='flex-row mt-4'>
                        <h4 className='text-xl font-cgbold ml-16'>Take some time.</h4>
                        <div className='flex flex-row items-center'>
                            <div className='w-14 h-14 rounded-full p-2 bg-blue2'>
                                <img
                                    src={Clock}
                                    alt='listen icon'
                                    width={45}
                                />
                            </div>
                            <p className='rounded-lg bg-blue2 w-full -ml-2 p-2 pl-4'>
                                <strong>Avoid responding immediately.</strong> Sometimes our initial response can be
                                filled with emotions, so taking a step back can help us to{' '}
                                <strong>reflect on the situation and respond differently.</strong> After taking some
                                time, you might also realise you do not want to respond to someone - this is ok too.
                            </p>
                        </div>

                        <h4 className='text-xl font-cgbold ml-16 mt-4'>Be empathetic and understanding.</h4>
                        <div className='flex flex-row items-center'>
                            <div className='w-14 h-14 rounded-full p-2 bg-purple1'>
                                <img
                                    src={PplLove}
                                    alt='listen icon'
                                    width={45}
                                />
                            </div>
                            <p className='rounded-lg bg-purple1 w-full -ml-2 p-2 pl-4'>
                                Everyone can have a bad day, and react with intensitivity or misinterpret what you say -{' '}
                                <strong>try to put yourself in someone else's shoes.</strong> It is likely that most
                                people are not trying to cause harm.
                            </p>
                        </div>

                        <h4 className='text-xl font-cgbold ml-16 mt-4'>
                            Make sure that everyone is on the same page.
                        </h4>
                        <div className='flex flex-row items-center'>
                            <div className='w-14 rounded-full p-2 bg-green1'>
                                <img
                                    src={MsgBubble}
                                    alt='listen icon'
                                    width={45}
                                />
                            </div>
                            <p className='rounded-lg bg-green1 text-white w-full -ml-2 p-2 pl-4'>
                                Ask <strong>open-ended questions</strong> to help clarify what some is trying to say and
                                correct any misunderstandings. You could ask "what did you mean by ...?".
                            </p>
                        </div>

                        <h4 className='text-xl font-cgbold ml-16 mt-4'>Use "I" statements.</h4>
                        <div className='flex flex-row items-center'>
                            <div className='w-14 h-14 rounded-full p-2 bg-blue2'>
                                <img
                                    src={InfoIcon}
                                    alt='listen icon'
                                    width={45}
                                />
                            </div>
                            <p className='rounded-lg bg-blue2 w-full -ml-2 p-2 pl-4'>
                                It is best to express how you are felling by saying "I feel..." rather than "you made me
                                feel...". Remember to <strong>pay attention to any feelings</strong> the other person
                                might me expressing. Also, when disagreeing on a topic, it's better to say, "I disagree
                                with..." rather than "you are wrong about...".
                            </p>
                        </div>

                        <h4 className='text-xl font-cgbold ml-16 mt-4'>Always be respectful.</h4>
                        <div className='flex flex-row items-center'>
                            <div className='w-14 rounded-full p-2 bg-green2'>
                                <img
                                    src={HeartHands}
                                    alt='listen icon'
                                    width={45}
                                />
                            </div>
                            <p className='rounded-lg bg-green2 w-full -ml-2 p-2 pl-4'>
                                We are all <strong>different</strong> and that's great! Interacting in a{' '}
                                <strong>respectful</strong> way that is{' '}
                                <strong>
                                    sensitive to someone's life experiences and circumstances is essential to resolve
                                    disagreements.
                                </strong>
                            </p>
                        </div>
                    </div>

                    <p className='mt-4' id='tips'>
                        The above tips for handling disagreements have been adapted from
                        <a
                            href='https://mifwa.saneforums.org/t5/Forum-News-and-Updates/Forum-Guides/m-p/105963#M1039'
                            title='SANE Australia'
                            className='font-cgbold text-blue1 cursor-pointer'
                            rel='noreferrer'
                            target='_blank'
                        >
                            {' '}
                            SANE Australia advice{' '}
                        </a>
                        and
                        <a
                            href='https://www.helpguide.org/articles/relationships-communication/conflict-resolution-skills.htm'
                            title='SANE Australia'
                            className='font-cgbold text-blue1 cursor-pointer'
                            rel='noreferrer'
                            target='_blank'
                        >
                            {' '}
                            helpguide.org
                        </a>
                        .
                    </p>
                </div>
                <div className='px-4'>
                    <div className='mt-4 bg-blue1 text-white font-xl rounded-2xl p-4'>Thank you for taking the time to read our Moderation Policy. If you have any questions, please contact us via email: commonground@kcl.ac.uk.</div>
                </div>
                <div className='mt-4'>
                    <img
                        src={PolicyBottom}
                        alt=''
                        className='mx-auto'
                    />
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='safeguarding'
                        className='font-cgbold rounded-2xl scroll-mt-40 text-center bg-green3 text-green1 text-2xl py-2 mt-16'
                    >
                        Safeguarding Policy
                    </h2>
                    <p className='mt-4'>
                        We want to ensure that CommonGround is a safe, respectful space for you to interact with others
                        and share your experiences. We hope that the majority of what people share will be suitable for
                        the platform. However, we have our safeguarding policy to help protect everyone and have
                        standard procedures that will be used under certain circumstances. In some cases, the
                        CommonGround team may be legally or morally obliged to take further action on something a
                        community member has shared on CommonGround, which might involve liaising and cooperating with
                        relevant authorities. This safeguarding policy outlines what actions might be taken for
                        different circumstances.
                    </p>

                    <ul className='list-disc list-inside mt-4'>
                        The research team will notify the relevant authorities if something shared on CommonGround
                        indicates:
                        <li>The intention of, or previous, child protection offences.</li>
                        <li>Intention of, or previous, terrorism activity.</li>
                        <li>Concern for the welfare of a child or vulnerable adult.</li>
                    </ul>

                    <p className='mt-4'>
                        The content will be immediately removed from the platform by our moderation team. The research
                        team may contact you via email to discuss what you shared with you and inform you of any
                        action(s) being taken. Each case will be handled on a case-by-case basis.
                    </p>

                    <p className='mt-4 italic'>[1] Intention, ongoing or previous child protection offences.</p>
                    <p className='mt-2'>
                        If the research team become aware of information which causes them to know or have reasonable
                        belief might indicate that a child has been or is currently the victim of physical or sexual
                        abuse, the research team will (i) notify the police in case of emergency (ii) notify the police,
                        NSPCC, and/or the local council as appropriate. The research team and KCL will cooperate and
                        follow the guidance of the relevant organisations.
                    </p>

                    <p className='mt-4 italic'>[2] Intention of, or previous, terrorism activity.</p>
                    <p className='mt-2'>
                        If the research team become aware of information that is, or they believe might be of, material
                        assistance in preventing an act of terrorism or in securing the arrest, prosecution, or
                        conviction of someone involved in the commission, preparation, or instigation of an act of
                        terrorism the research team will inform the police as soon as possible. The research team and
                        KCL will cooperate and follow the guidance of the relevant organisations.
                    </p>

                    <p className='mt-4 italic'>[3] Concern for the welfare of a child or vulnerable adult.</p>
                    <p className='mt-2'>
                        If the research team are concerned about the welfare of a child or vulnerable adult, they will consult 
                        the appropriate organisations and the Lead Safeguarding Officer at KCL.
                    </p>

                    <p className='mt-4 italic'>[4] Disclosure of criminal activity.</p>
                    <p className='mt-2'>
                        If the research team become aware of information relating to criminal activity, they will consult the 
                        principal investigator and the appropriate organisations.
                    </p>

                    <p className='mt-2'>
                        A <strong>child</strong> is any person under the age of 18 years. A <strong>vulnerable adult</strong> is 
                        any adult aged 18 years or older who is, or may be, in need
                        of community care services by reason of mental or other disability, age or illness; and who is
                        or may be unable to take care of themselves, or unable to protect themselves against significant
                        harm or exploitation.
                    </p>
                    
                    <p className='mt-2'>
                        Investigating any concerns that you may have. At KCL we are committed to taking safeguarding seriously 
                        and adopting our responsibilities in protecting people from harm. If you have any concern, we will listen 
                        and fully investigate any reported concerns in liaison with the appropriate organisations.
                    </p>

                    <p className='mt-4'>
                        If you wish to discuss anything about the CommonGround Safeguarding Policy with the team, please
                        reach out via email: commonground@kcl.ac.uk.
                    </p>
                </div>
            </div>
            <ScrollToTop />
        </>
    )
}

export default ModerationPolicy
