// @ts-nocheck

import React, { useState, useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { postService as service, alertService } from '../../services'
import Dropzone from 'react-dropzone'
import Delete from '../../assets/images/delete.svg'
import Confused from '../../assets/images/feeling/Confused.svg'
import Frustrated from '../../assets/images/feeling/Frustrated.svg'
import Happy from '../../assets/images/feeling/Happy.svg'
import Proud from '../../assets/images/feeling/Proud.svg'
import Relieved from '../../assets/images/feeling/Relieved.svg'
import Sad from '../../assets/images/feeling/Sad.svg'
import SmilingAnyway from '../../assets/images/feeling/SmilingAnyway.svg'
import Stressed from '../../assets/images/feeling/Stressed.svg'
import Tired from '../../assets/images/feeling/Tired.svg'
import Unsure from '../../assets/images/feeling/Unsure.svg'
import Worried from '../../assets/images/feeling/Worried.svg'
import NoEntry from '../../assets/images/feeling/NoEntry.svg'
import { hashtags } from '../../helpers/data'

interface PU {
    setModalIsOpen: (param: boolean) => void
    topics: any
    getPosts: () => void
}

const PostUploadForm: React.FC<PU> = ({ setModalIsOpen, topics, getPosts }) => {
    const [file, setFile] = useState(null)
    const [fileError, setFileError] = useState(false)
    const [audioFile, setAudioFile] = useState(null)
    const [audioError, setAudioError] = useState(false)
    const [pictureFile, setPictureFile] = useState(null)
    const [pictureFileError, setPictureError] = useState(false)
    const [selectedTags, setSelectedTags] = useState<Array<string>>([])
    const [feeling, setFeeling] = useState<string>('')

    const initialValues = {
        title: '',
        type: topics[0].text || '',
        topic: topics[0].text || '',
        content: '',
        url: '',
        dateCreated: new Date(),
        lastUpdated: new Date(),
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        content: Yup.string().required('Content is required'),
    })

    const handleFileDrop = (dropped) => {
        setFile(dropped[0])
    }

    const handleFileUpload = (e) => {
        setFile(e.target.files[0])
    }

    const handleFileDelete = () => {
        setFile(null)
    }

    const handleAudioFileDrop = (dropped) => {
        setAudioFile(dropped[0])
    }

    const handleAudioFileUpload = (e) => {
        setAudioFile(e.target.files[0])
    }

    const handleAudioFileDelete = () => {
        setAudioFile(null)
    }

    const handlePictureFileDrop = (dropped) => {
        setPictureFile(dropped[0])
    }

    const handlePictureFileUpload = (e) => {
        setPictureFile(e.target.files[0])
    }

    const handlePictureFileDelete = () => {
        setPictureFile(null)
    }

    const handleTags = (val: string) => {
        if (selectedTags) {
            const temp = selectedTags

            if (temp.length !== 0) {
                if (!temp.includes(val)) {
                    temp.push(val)
                } else {
                    const idx = temp.indexOf(val)

                    temp.splice(idx, 1)
                }

                setSelectedTags(temp)
            } else {
                setSelectedTags([val])
            }
        }
    }

    const onSubmit = (fields, { setStatus, setSubmitting }) => {
        setSubmitting(true)

        const files = {
            file,
            audio: audioFile,
            picture: pictureFile,
        }

        // check file extensions
        if (file) {
            if (file.name.split('.').pop() !== 'pdf') {
                setFile(null)
                setFileError(true)
                setSubmitting(false)
            }
        }
        if (audioFile) {
            if (audioFile.name.split('.').pop() !== 'mp3') {
                setAudioFile(null)
                setAudioError(true)
                setSubmitting(false)
            }
        }
        if (pictureFile) {
            if (
                pictureFile.name.split('.').pop() !== 'jpg' &&
                pictureFile.name.split('.').pop() !== 'png' &&
                pictureFile.name.split('.').pop() !== 'jpeg'
            ) {
                setPictureFile(null)
                setPictureError(true)
                setSubmitting(false)
            }
        }

        // check if feeling is set
        if (feeling !== '') {
            fields.feeling = feeling
        }

        fields.tags = selectedTags

        if (!fileError && !audioError && !pictureFileError) {
            service
                .create(fields, files)
                .then((response) => {
                    window.location.reload(true)
                })
                .catch((error) => {
                    alertService.error(error)
                    setSubmitting(false)
                })
        }
    }

    const getContent = (what) => {
        if (what === 'sectionIntroH2') {
            if (topics[0].text === 'thought') return 'Post a thought.'
            if (topics[0].text === 'knowledge') return 'Share some knowledge.'
            if (topics[0].text === 'question') return 'Ask a question.'
            if (topics[0].text === 'feeling') return 'How are you feeling?'
        }

        if (what === 'sectionIntroP') {
            if (topics[0].text === 'thought')
                return 'Sharing a thought can be rewarding for you and for other community members. You could share an experience, anecdote, or anything that is on your mind!'
            if (topics[0].text === 'knowledge')
                return 'Our community members are experts in living with long-term physical health conditions. You can share a skill, resource, event, or anything else that you think might help the community. '
            if (topics[0].text === 'question')
                return 'Ask a question to the CommonGround community. The community is a safe and supportive space of people that understand you.'
            if (topics[0].text === 'feeling')
                return 'Telling the community how you feel is a great way to start conversations and share support. Whether you are having a good or a bad day, why not share with the community?'
        }

        if (what === 'title') {
            if (topics[0].text === 'thought') return 'Title'
            if (topics[0].text === 'knowledge') return 'Title'
            if (topics[0].text === 'question') return 'Title'
            if (topics[0].text === 'feeling') return 'How are you feeling?'
        }

        if (what === 'placeholderTitle') {
            if (topics[0].text === 'thought') return 'Type your title here'
            if (topics[0].text === 'knowledge')
                return "Type your title here. It can be helpful to say what you're sharing and the potential benefits"
            if (topics[0].text === 'question') return 'Give your question a title… What is it about?'
            if (topics[0].text === 'feeling') return 'I am feeling...'
        }

        if (what === 'content') {
            if (topics[0].text === 'thought') return 'Create your thought'
            if (topics[0].text === 'knowledge') return 'Share your knowledge'
            if (topics[0].text === 'question') return 'What is your question?'
            if (topics[0].text === 'feeling') return 'Share how you are feeling with the community...'
        }

        if (what === 'placeholderContent') {
            if (topics[0].text === 'thought') return 'What is your thought? Type it here'
            if (topics[0].text === 'knowledge')
                return 'What are you sharing? Type it here. You could add a description, how you found it, and anything else you think it might be helpful for the community to know'
            if (topics[0].text === 'question') return 'What do you want to ask the community?'
            if (topics[0].text === 'feeling') return 'What do you want to share with the community?'
        }

        if (what === 'button') {
            if (topics[0].text === 'thought') return 'Post my thought!'
            if (topics[0].text === 'knowledge') return 'Share my knowledge!'
            if (topics[0].text === 'question') return 'Ask my question'
            if (topics[0].text === 'feeling') return 'Share how I am feeling'
        }

        return ''
    }

    useEffect(() => {
        window.addEventListener('click', (e) => {
            if (e.target.id == 'modal-container') {
                setModalIsOpen(false)
            }
        })
    }, [])

    return (
        <div
            className='fixed top-0 left-0 z-50 w-screen h-screen bg-black/80 backdrop-blur-md'
            id='modal-container'
        >
            <div
                className='flex items-center justify-end h-11 w-full'
                id='modal-container'
            >
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => setModalIsOpen(false)}
                >
                    X
                </button>
            </div>
            <div
                className='w-full max-h-min md:mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overflow-x-hidden py-2 pb-24'
                id='modal-container'
            >
                <div
                    className='p-4 md:p-0 flex align-center justify-items-center items-center justify-center'
                    id='modal-container'
                >
                    <div className='bg-white w-full max-w-4xl self-center mx-0 rounded-xl p-2 md:p-6'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div>
                                        <h2 className='text-5xl text-center font-cgbold mt-2'>
                                            {getContent('sectionIntroH2')}
                                        </h2>
                                        <p className='text-center mt-2'>{getContent('sectionIntroP')}</p>

                                        <div className='mt-4'>
                                            <label
                                                className='block font-cgbold leading-6'
                                                htmlFor='title'
                                            >
                                                {getContent('title')}
                                            </label>
                                            <Field
                                                component='textarea'
                                                rows='3'
                                                name='title'
                                                placeholder={getContent('placeholderTitle')}
                                                className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            />
                                            <ErrorMessage
                                                name='title'
                                                component='div'
                                                className='block text-error font-cgbold mt-2'
                                            />
                                        </div>

                                        <div className='mt-4'>
                                            <label
                                                className='block font-cgbold leading-6'
                                                htmlFor='title'
                                            >
                                                {getContent('content')}
                                            </label>
                                            <Field
                                                component='textarea'
                                                rows='9'
                                                name='content'
                                                placeholder={getContent('placeholderContent')}
                                                className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            />
                                            <ErrorMessage
                                                name='content'
                                                component='div'
                                                className='block text-error font-cgbold mt-2'
                                            />
                                        </div>

                                        <div className='mt-4'>
                                            <label className='block font-cgbold leading-6'>
                                                How are you feeling right now?
                                            </label>
                                            <small>
                                                {' '}
                                                Showing how you are feeling can help community members know how best to
                                                respond to your post or support you (optional)
                                            </small>

                                            <div className='flex flex-wrap md:flex-nowrap border border-black/20 rounded-lg text-xs md:text-sm justify-center text-center cursor-pointer'>
                                                <div
                                                    onClick={() => setFeeling('Happy')}
                                                    className={
                                                        feeling === 'Happy'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Happy}
                                                        width={50}
                                                    />
                                                    <p>Feeling happy</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('SmilingAnyway')}
                                                    className={
                                                        feeling === 'SmilingAnyway'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={SmilingAnyway}
                                                        width={50}
                                                    />
                                                    <p>Is smiling anyway</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Confused')}
                                                    className={
                                                        feeling === 'Confused'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Confused}
                                                        width={50}
                                                    />
                                                    <p>Feeling confused</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Worried')}
                                                    className={
                                                        feeling === 'Worried'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Worried}
                                                        width={50}
                                                    />
                                                    <p>Feeling worried</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Proud')}
                                                    className={
                                                        feeling === 'Proud'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Proud}
                                                        width={50}
                                                    />
                                                    <p>Feeling proud</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Relieved')}
                                                    className={
                                                        feeling === 'Relieved'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Relieved}
                                                        width={50}
                                                    />
                                                    <p>Feeling relieved</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Stressed')}
                                                    className={
                                                        feeling === 'Stressed'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Stressed}
                                                        width={50}
                                                    />
                                                    <p>Feeling stressed</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Frustrated')}
                                                    className={
                                                        feeling === 'Frustrated'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Frustrated}
                                                        width={50}
                                                    />
                                                    <p>Feeling frustrated</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Sad')}
                                                    className={
                                                        feeling === 'Sad'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Sad}
                                                        width={50}
                                                    />
                                                    <p>Feeling sad</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Tired')}
                                                    className={
                                                        feeling === 'Tired'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Tired}
                                                        width={50}
                                                    />
                                                    <p>Feeling tired</p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('Unsure')}
                                                    className={
                                                        feeling === 'Unsure'
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={Unsure}
                                                        width={50}
                                                    />
                                                    <p>
                                                        Feeling unsure
                                                        <br />
                                                        what to say
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => setFeeling('')}
                                                    className={
                                                        feeling === ''
                                                            ? 'flex flex-col items-center bg-green2'
                                                            : 'flex flex-col items-center hover:bg-green1'
                                                    }
                                                >
                                                    <img
                                                        alt=''
                                                        src={NoEntry}
                                                        width={50}
                                                    />
                                                    <p>Remove feeling</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-4 p-2 border border-black/30 rounded-lg'>
                                            <label className='block font-cgbold leading-6'>Add a file (optional)</label>
                                            <p className='text-sm'>
                                                You can add a file to your post if you want to. Our moderation team will
                                                review all attachments before they are posted in the community feed.
                                                This means that your post will not be published immediately.
                                            </p>
                                            <small className='block mt-2'>
                                                <em>Only Word documents and PDF files are accepted</em>
                                            </small>
                                            {!file && (
                                                <>
                                                    <Dropzone
                                                        onDrop={handleFileDrop}
                                                        accept={
                                                            ('application/pdf',
                                                            'application/msword',
                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                                                        }
                                                        noClick
                                                        noKeyboard
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                {/* <div className='w-full p-4 border border-black/20'>
                                                                    Drop a file here
                                                                    <br />
                                                                    <em>Only PDF files are accepted</em>
                                                                    <img
                                                                        alt=''
                                                                        src={Upload}
                                                                        style={{
                                                                            width: '20px',
                                                                            height: '20px',
                                                                            marginLeft: '20px',
                                                                            marginTop: '-40px',
                                                                        }}
                                                                    />
                                                                </div> */}

                                                                <input {...getInputProps()} />
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    {fileError ? (
                                                        <div className='block text-error font-cgbold mt-2'>
                                                            Only PDF or Word files are accepted
                                                        </div>
                                                    ) : null}
                                                    <div className='uploadButton'>
                                                        <input
                                                            accept='application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                            type='file'
                                                            name='newImage'
                                                            className='fileUpload'
                                                            onChange={handleFileUpload}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {file && file.name && (
                                                <div className='flex my-2'>
                                                    {/* {['image/jpeg', 'image/jpg', 'image/gif', 'image/png'].indexOf(
                                                        file.type
                                                    ) === -1 && <div className='uploadPreviewFile' />}
                                                    {['image/jpeg', 'image/jpg', 'image/gif', 'image/png'].indexOf(
                                                        file.type
                                                    ) > -1 && (
                                                        <div
                                                            className='uploadPreview'
                                                            style={{
                                                                backgroundImage: `url(${URL.createObjectURL(file)})`,
                                                            }}
                                                        />
                                                    )} */}
                                                    {file.name}
                                                    <img
                                                        alt=''
                                                        src={Delete}
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginLeft: '20px',
                                                        }}
                                                        onClick={handleFileDelete}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className='mt-4 p-2 border border-black/30 rounded-lg'>
                                            <label className='block font-cgbold leading-6'>
                                                Add an audio or video file (optional)
                                            </label>
                                            <p className='text-sm'>
                                                You can add a sound or video clip to your post if you want to. All audio
                                                and video files will be reviewed by our moderation team before they are
                                                posted in the community feed. Please make sure that your attachment does
                                                not include images or videos of yourself or your voice to protect your
                                                anonymity.
                                            </p>
                                            <small className='block mt-2'>
                                                <em>Only MP3 and MP4 files are accepted</em>
                                            </small>
                                            {!audioFile && (
                                                <>
                                                    <Dropzone
                                                        onDrop={handleAudioFileDrop}
                                                        noClick
                                                        noKeyboard
                                                        accept={('audio/mp3', 'video/mp4')}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                {/* <div className='dropZone mobileHide'>
                                                                    Drop a file here
                                                                    <br />
                                                                    <em>Only MP3 files are accepted</em>
                                                                    <img
                                                                        alt=''
                                                                        src={Upload}
                                                                        style={{
                                                                            width: '20px',
                                                                            height: '20px',
                                                                            marginLeft: '20px',
                                                                        }}
                                                                    />
                                                                </div> */}
                                                                <input {...getInputProps()} />
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    {audioError ? (
                                                        <div className='block text-error font-cgbold mt-2'>
                                                            Only MP3 and MP4 files are accepted
                                                        </div>
                                                    ) : null}
                                                    <div className='uploadButton'>
                                                        <input
                                                            type='file'
                                                            name='newAudioImage'
                                                            accept='audio/mp3, video/mp4'
                                                            className='fileUpload'
                                                            onChange={handleAudioFileUpload}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {audioFile && audioFile.name && (
                                                <div>
                                                    <div className='flex my-2'>
                                                        {['audio/mp3'].indexOf(audioFile.type) === -1 && (
                                                            <div className='uploadPreviewFile' />
                                                        )}
                                                        {audioFile.name}
                                                        <img
                                                            alt=''
                                                            src={Delete}
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginLeft: '20px',
                                                            }}
                                                            onClick={handleAudioFileDelete}
                                                        />
                                                    </div>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <audio
                                                            controls
                                                            preload={'auto'}
                                                        >
                                                            <source
                                                                src={`${URL.createObjectURL(audioFile)}`}
                                                                type='audio/mpeg'
                                                            />
                                                            Your browser does not support the
                                                            <code>audio</code> element.
                                                        </audio>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className='mt-4 p-2 border border-black/30 rounded-lg'>
                                            <label className='block font-cgbold leading-6'>Add a picture file (optional)</label>
                                            <p className='text-sm'>
                                                This image will appear below the post text content.
                                            </p>
                                            <small className='block mt-2'>
                                                <em>Only JPG and PNG files are accepted.</em>
                                            </small>
                                            {!pictureFile && (
                                                <>
                                                    <Dropzone
                                                        onDrop={handlePictureFileDrop}
                                                        accept={('image/jpg', 'image/png', 'image/jpeg')}
                                                        noClick
                                                        noKeyboard
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    {pictureFileError ? (
                                                        <div className='block text-error font-cgbold mt-2'>
                                                            Only JPG and PNG files are accepted
                                                        </div>
                                                    ) : null}
                                                    <div className='uploadButton'>
                                                        <input
                                                            accept='image/jpg, image/jpeg, image/png'
                                                            type='file'
                                                            name='newPictureImage'
                                                            className='fileUpload'
                                                            onChange={handlePictureFileUpload}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {pictureFile && pictureFile.name && (
                                                <>
                                                    <div className='flex my-2'>
                                                        {pictureFile.name}
                                                        <img
                                                            alt=''
                                                            src={Delete}
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginLeft: '20px',
                                                            }}
                                                            onClick={handlePictureFileDelete}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='mt-4'>
                                            <label className='block font-cgbold leading-6'>
                                                Content tags <span className='text-sm font-cg'>(optional)</span>
                                            </label>
                                            <small>
                                                {' '}
                                                If you want to add content tags to your post, select them from the list
                                                below. Content tags can help community members know what you are talking
                                                about or find your post when they search for the content tag.
                                            </small>

                                            <div className='mt-4 p-2 border border-black/20 rounded-lg max-h-60 overflow-y-scroll'>
                                                {hashtags.map((i: any, c: number) => (
                                                    <div key={c}>
                                                        <input
                                                            type='checkbox'
                                                            onChange={(val) => handleTags(val.target.value)}
                                                            value={i.value}
                                                        />{' '}
                                                        {i.text}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className='my-4'>
                                            <p className='my-4'>
                                                If you are happy with your post, post it to the community feed. If you
                                                click cancel, your post will not be shared or saved.
                                            </p>
                                            <div className='flex justify-between'>
                                                <button
                                                    type='submit'
                                                    className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting && <div className='loading'></div>}
                                                    {getContent('button')}
                                                </button>
                                                <button
                                                    type='button'
                                                    className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                    onClick={() => {
                                                        setModalIsOpen(false)
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostUploadForm
