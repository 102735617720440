// @ts-nocheck

import React from 'react'
import { useNavigate } from 'react-router-dom'
import User from './User'
import TextContent from './TextContent'
import { DateTime } from 'luxon'
import PostType from '../community/PostType'
import PostFeeling from '../community/PostFeeling'
import { Notification } from '../notifications'
import axios from 'axios'

interface P {
    item: any
    user: any
    loggedInUser: any
    section: string
    getPosts: (string?) => void
}

const Post: React.FC<P> = ({ item, user, loggedInUser, section, getPosts }) => {
    const nav = useNavigate()

    const updateSearchPath = async (type: string, pathId: string) => {
        try {
            let searchId = window.sessionStorage.getItem('searchId');

            if (searchId === undefined){
                return;
            }

            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/stats/searchPath`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    pathId: pathId,
                    type: type,
                    searchId: window.sessionStorage.getItem('searchId'),
                },
            })

            if (res.status === 200) {
                window.sessionStorage.removeItem('searchId')
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error) {
            return Notification({
                message:
                    'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                type: 'danger',
            })
        }
    }

    return (
        <>
            {item && (
                <div className='w-full shadow-lg rounded-lg my-5 border-2 border-black/10 flex flex-col md:flex-row p-4'>
                    <div className='flex flex-row justify-between items-center md:flex-col md:justify-start mr-3 border-b-2 md:border-b-transparent md:border-r-2 border-black/20 w-full md:w-1/6 md:pr-3'>
                        <User 
                            user={user}
                            loggedInUser={loggedInUser}
                            getPosts={getPosts}
                        />
                        <div className='text-sm md:text-2xs md:mt-4 w-max'>
                            <span>Posted: </span>
                            <span>{DateTime.fromISO(item.dateCreated).toRelative()}</span>
                        </div>
                        <div className='text-sm md:text-2xs md:mt-4 text-center w-max'>
                            <span>Last reply: </span>
                            <span>
                                {item &&
                                item.comments &&
                                item.comments.length &&
                                item.comments[item.comments.length - 1] &&
                                item.comments[item.comments.length - 1].dateCreated
                                    ? DateTime.fromISO(item.comments[item.comments.length - 1].dateCreated).toRelative()
                                    : '---'}
                            </span>
                        </div>
                    </div>

                    <div className='w-full mt-3 md:mt-0'>
                        <div className={section === 'community' ? 'flex justify-start' : 'flex justify-between'}>
                            {section === 'community' ? (
                                <>
                                    <PostType type={item.topic.name} />
                                    {item.feeling? <PostFeeling feeling={item.feeling} /> : null}
                                </>
                            ) : null}
                            {section === 'resources' ? (
                                <div className='rounded-full font-cgbold text-sm py-2 px-6 bg-green2'>
                                    {item.topic.name}
                                </div>
                            ) : null}
                        </div>

                        <h4
                            className='text-2xl font-semibold cursor-pointer mt-6 mb-4 text-blue1 text-left'
                            onClick={() => {
                                let link = ''

                                if (section === 'community') {
                                    link = `/community/article/${item._id}`
                                    updateSearchPath('community', item._id)
                                } else if (section === 'resources') {
                                    link = `/resource/article/${item._id}`
                                    updateSearchPath('resources', item._id)
                                } else {
                                    link = ''
                                }

                                nav(`${link}`, {
                                    state: {
                                        searchOrigin: true,
                                    },
                                })
                            }}
                        >
                            {item.title}
                        </h4>

                        <TextContent item={item} />

                        <div className='mt-2 pt-6 flex flex-wrap'>
                            {item.hashtags
                                ? item.hashtags.map((h, c) => {
                                      return (
                                          <div
                                              className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4'
                                              key={c}
                                          >
                                              {h}
                                          </div>
                                      )
                                  })
                                : null}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Post
