import React, { useState, useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Notification } from '../notifications'
import * as Yup from 'yup'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import { userAction } from '../../helpers/logs'
import { useNavigate } from 'react-router-dom'

type MPF = {
    item: any
    setModal: (arg: boolean) => void
}

const ModeratePostForm: React.FC<MPF> = ({ item, setModal }) => {
    const usr = getUser()
    const nav = useNavigate()
    const [submitting, setSubmitting] = useState(false)

    const initialValues = {
        description: '',
    }

    const validationSchema = Yup.object().shape({
        description: Yup.string().required('Description is required'),
    })

    const onSubmit = async (values: any) => {
        setSubmitting(true)
        values.id = item._id

        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/${item._id}/report`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: values,
            })

            setSubmitting(false)

            const flaggedPost = {
                user: usr.id,
                postId: item._id,
                flaggedReason: values.description,
            }
            userAction(usr.id, 'Post', item._id, 'flagged', {}, {}, flaggedPost)

            if (res.status === 201) {
                setModal(false)

                return Notification({
                    message: 'Thank you for flagging this post. Our Moderation Team will review the post as soon as possible.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (e.target.id == 'modal-container') {
                setModal(false)
            }
        })
    }, [])

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'
        id='modal-container'>
            <div className='flex items-center justify-end h-11'
            id='modal-container'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => setModal(false)}
                >
                    X
                </button>
            </div>

            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'
            id='modal-container'>
                <div className='p-2 md:p-0 flex justify-center'
                id='modal-container'>
                    <div className='bg-white max-w-4xl mx-auto rounded-xl p-4'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {() => (
                                <Form>
                                    <div>
                                        <h2 className='text-3xl text-center font-cgbold mt-2'>
                                            Tell us why you are flagging this post
                                        </h2>
                                        <p className='mt-4'>
                                            Please tell us why you are flagging this post. Our team of moderators will
                                            review the post as soon as possible. They will consider your feedback and
                                            our community principles to take appropriate action. Remember you can always
                                            read our{' '}
                                            <a
                                                title='Community Principles'
                                                className='font-cgbold text-blue1'
                                                rel='noreferrer'
                                                target='_blank'
                                                onClick={() => {
                                                    nav('/principles', {
                                                        state: {
                                                            noCheckbox: true,
                                                        },
                                                    });
                                                }}
                                            >
                                                Community Principles
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                href='/moderation-policy'
                                                title='Community Principles'
                                                className='font-cgbold text-blue1'
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                Moderation Policy
                                            </a>
                                            .
                                        </p>
                                    </div>

                                    <div className='mt-4'>
                                        <label
                                            className='block font-cgbold leading-6'
                                            htmlFor='description'
                                        >
                                            Flag reason
                                        </label>
                                        <Field
                                            type='text'
                                            component='textarea'
                                            name='description'
                                            placeholder='Please tell us why you are flagging this post (required). This helps our moderators.'
                                            className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                        />
                                        <ErrorMessage
                                            name='description'
                                            component='div'
                                            className='block text-error font-cgbold mt-2'
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <p className='text-center font-cgbold'>
                                            Thank you for taking the time to flag this post to and for helping us to
                                            keep CommonGround safe.
                                        </p>
                                        <p className='mt-2'>
                                            If you would like some additional support, please refer to our{' '}
                                            <a
                                                href='/moderation-policy'
                                                title='Community Principles'
                                                className='font-cgbold text-blue1'
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                Crisis and Support page
                                            </a>
                                            .
                                        </p>
                                    </div>

                                    <div className='my-4'>
                                        <div className='flex justify-between'>
                                            <button
                                                type='submit'
                                                className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                                disabled={submitting}
                                            >
                                                Flag Post
                                            </button>
                                            <button
                                                type='button'
                                                className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                onClick={() => {
                                                    setModal(false)
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModeratePostForm
