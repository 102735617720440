import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const TextContent = ({ item, links }: { item: any; links: any }) => {
    const nav = useNavigate()
    const [article, setArticle] = useState<string>()
    const [longArticle, setLongArticle] = useState<boolean>(false)

    const includeNewLines = (article: String) => {
        let split = article.split('\r\n')
        let lines: any[] = []
        split.forEach((line) => {
            console.log(line == '')
            lines.push(line? line : <br></br>)
        })
        return lines
    }

    useEffect(() => {
        if (item.content.length > 200) {
            setArticle(item.content.substring(0, item.content.lastIndexOf(' ', 200)))

            setLongArticle(true)
        } else {
            setArticle(item.content)
        }
    }, [item])

    return (
        <>
            {article && (
                <div className='break-words grow-0'>
                    {article.includes('\n')? includeNewLines(article).map((line, index) => {
                        return <p key={index}>
                                {line}
                            </p>
                    } ) : article}
                    {longArticle ? (
                        <>
                            {' '}
                            ...{' '}
                            <span
                                className='font-cgbold cursor-pointer text-blue1'
                                title={`Read entire ${item.title} article.`}
                                onClick={() =>
                                    nav(`/community/article/${item._id}`, {
                                        state: {
                                            links: links,
                                        },
                                    })
                                }
                            >
                                Read more
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            )}
        </>
    )
}

export default TextContent
