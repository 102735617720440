import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Notification } from '../notifications'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import { ModerationComments } from './ModerationComments'

type AP = {
    modal: (param: boolean) => void
    post: any
    formLinks?: any
    setFormLinks: any
    getPosts: any
}

type LINK = {
    name: string
}

const ApprovePostRemoveAttachments: React.FC<AP> = ({ modal, post, formLinks, setFormLinks, getPosts }) => {
    const usr = getUser()
    const [submitting, setSubmitting] = useState(false)
    const [links, setLinks] = useState<any | undefined>()

    const initialValues = {
        title: post ? post.title : '',
        content: post ? post.content : '',
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        content: Yup.string().required('Content is required'),
    })

    const removeFile = async (folder: string, name: string) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/attachment/remove`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {
                    folder: folder,
                    file: name,
                    postId: post._id,
                },
            })

            if (res.status === 200) {
                setSubmitting(false)

                const fileIndex = formLinks.findIndex((item: LINK) => {
                    return item.name === name
                })

                if (fileIndex > -1) {
                    formLinks.splice(fileIndex, 1)
                }
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }

        getPosts()
    }

    const onSubmit = async (values: any) => {
        setSubmitting(true)

        try {
            const data = values

            data.postId = post?._id

            if (values.moderation && values.moderation !== '') {
                data.moderation = {
                    reason: values.moderation,
                    text: Object.values(ModerationComments)[values.moderation.replace('reason', '')],
                    ts: new Date(),
                }
            }

            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/approve`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: data,
            })
            setSubmitting(false)

            if (res.status === 201) {
                getPosts()
                modal(false)

                return Notification({
                    message: 'Post successfully approved.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        if (formLinks && formLinks.length > 0) {
            setLinks(formLinks)
        }
    }, [formLinks])

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
            <div className='flex items-center justify-end h-11'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => modal(false)}
                >
                    X
                </button>
            </div>

            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                <div className='p-2 md:p-0'>
                    <div className='bg-white max-w-4xl mx-auto rounded-xl p-2 md:p-6'>
                        {post ? (
                            <>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div>
                                                <h2 className='text-5xl text-center font-cgbold mt-2'>
                                                    Approve Post - Remove attachments
                                                </h2>

                                                <div className='mt-4'>
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='title'
                                                    >
                                                        Title
                                                    </label>
                                                    <Field
                                                        component='textarea'
                                                        rows='3'
                                                        name='title'
                                                        placeholder='Post Title'
                                                        className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                    />
                                                    <ErrorMessage
                                                        name='title'
                                                        component='div'
                                                        className='block text-error font-cgbold mt-2'
                                                    />
                                                </div>

                                                <div className='mt-4'>
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='content'
                                                    >
                                                        Content
                                                    </label>
                                                    <Field
                                                        component='textarea'
                                                        rows='9'
                                                        name='content'
                                                        placeholder='Post Content'
                                                        className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                    />
                                                    <ErrorMessage
                                                        name='content'
                                                        component='div'
                                                        className='block text-error font-cgbold mt-2'
                                                    />
                                                </div>

                                                <div className='mt-4'>
                                                    <label className='block font-cgbold leading-6'>Hashtags</label>
                                                    <div className='mt-2 flex flex-wrap'>
                                                        {post.hashtags && post.hashtags.length > 0 ? (
                                                            <>
                                                                <div className='flex'>
                                                                    {post.hashtags.map((i: string, c: number) => {
                                                                        return (
                                                                            <div
                                                                                className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4'
                                                                                key={c}
                                                                            >
                                                                                {i}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            'No hasthags have been added to this post'
                                                        )}
                                                    </div>
                                                </div>

                                                {links && links.length > 0 ? (
                                                    <div className='mt-4'>
                                                        <label className='block font-cgbold leading-6'>
                                                            Attachments
                                                        </label>
                                                        <table className='table-auto'>
                                                            <tbody>
                                                                {formLinks.map((file: any, index: number) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <a
                                                                                    href={file.link}
                                                                                    target='_blank'
                                                                                    rel='noreferrer'
                                                                                    className='text-blue1 font-cgbold'
                                                                                >
                                                                                    {file.name}
                                                                                </a>
                                                                            </td>

                                                                            <td>
                                                                                <button
                                                                                    className='rounded-full py-2 px-4 bg-red1 font-cgbold ml-3'
                                                                                    onClick={() =>
                                                                                        removeFile(
                                                                                            'post-files',
                                                                                            file.name
                                                                                        )
                                                                                    }
                                                                                    type='button'
                                                                                >
                                                                                    REMOVE
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : null}

                                                <div className='mt-4'>
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='title'
                                                    >
                                                        Moderation comments <small>(Optional)</small>
                                                    </label>
                                                    <div className='text-sm'>
                                                        If you've made any content changes, please state your reasons
                                                        here. This will be sent to the Original Poster.
                                                    </div>
                                                    <Field
                                                        component='select'
                                                        name='moderation'
                                                        placeholder='Select moderation option (optional)'
                                                        className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                    >
                                                        <option value=''>Please select an option</option>
                                                        {Object.keys(ModerationComments).map((i, c) => {
                                                            return (
                                                                <option
                                                                    key={c}
                                                                    value={i}
                                                                >
                                                                    {Object.values(ModerationComments)[c]}
                                                                </option>
                                                            )
                                                        })}
                                                    </Field>
                                                    <ErrorMessage
                                                        name='ethnicity'
                                                        component='div'
                                                        className='block text-error font-cgbold mt-2'
                                                    />
                                                </div>

                                                <div className='mt-4'>
                                                    <div className='flex justify-between'>
                                                        <button
                                                            type='submit'
                                                            className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                                        >
                                                            {submitting && <div className='loading'></div>}
                                                            Approve Post
                                                        </button>
                                                        <button
                                                            type='button'
                                                            className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                            onClick={() => modal(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprovePostRemoveAttachments
