// @ts-nocheck

import React, { useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import axios from 'axios'
import { Notification } from '../notifications'
import * as Yup from 'yup'
import { useUser } from '../../helpers/useUser'
import { userAction } from '../../helpers/logs'
import { getUser } from '../auth/LoginState'

type PC = {
    user: any
    postId: string
    getPosts: () => void
}

const PostComment: React.FC<PC> = ({user, postId, getPosts }) => {
    const localUser = getUser()
    const initialValues = {
        comment: '',
    }

    const validationSchema = Yup.object().shape({
        comment: Yup.string().required('Do not forget to post your comment'),
    })

    const onSubmit = async (
        { comment }: { comment: string },
        { setSubmitting, resetForm }: { setSubmitting: boolean; resetForm: any }
    ) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/post/${postId}/comment`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localUser.token}`,
                },
                data: {
                    comment: comment,
                    user: user._id,
                    postId: postId,
                },
            })

            if (res.status === 200) {
                const comment = {
                    user: res.data.user,
                    context: 'Community Page',
                    commentCharCount: res.data.comment.content.length,
                    commentId: res.data.comment._id,
                }

                userAction(res.data.user, 'Post', postId, 'comment', {}, comment)
                getPosts()
                resetForm()

                return Notification({
                    message: 'Your comment has been successfully posted.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        let input = document.getElementById('input')
        window.addEventListener('click', (e) => {
            if (e.target.getAttribute('type') == 'submit'){
                return;
            }
            if (input.value !== '') {
                document.getElementById('reminder')?.classList.remove('hidden')
            } else {
                document.getElementById('reminder')?.classList.add('hidden')
            }
        })
    }, [])

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className='flex flex-col p-1 md:p-6'>
                            <h2 className='font-cgbold text-xl text-blue1'>Add a comment</h2>
                            <Field
                                component='textarea'
                                rows='4'
                                name='comment'
                                placeholder='Write your comment here…'
                                className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                id="input"
                            />
                            
                            <div className='block text-error font-cgbold mt-2 hidden' id='reminder'>Do not forget to post your comment</div>

                            <button
                                type='submit'
                                className='rounded-2xl bg-green1 py-2 px-6 text-white font-cgbold mt-4 w-48 mx-auto'
                                disabled={isSubmitting}
                            >
                                {isSubmitting && <div className='loading'></div>}
                                Post comment
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PostComment
