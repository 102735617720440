import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import SimplifiedUser from '../users/SimplifiedUser'
import { Notification } from '../notifications'

type U = {
    loggedInUser: any
}

const MyCommunity: React.FC<U> = ({ loggedInUser }) => {
    const [following, setFollowing] = useState<any>([])
    const [myFollowers, setMyFollowers] = useState<any>([])
    const [muted, setMuted] = useState<any>([])

    const getFollowers = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/user/followers`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${loggedInUser.token}`,
                },
                data: {},
            })

            if (res.status === 200 && res.data) {
                setFollowing(res.data.followers)
            } else {
                return Notification({
                    message: `There was a problem with your request. Code ${res.status}. Please contact the team at commonground@kcl.ac.uk`,
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [loggedInUser.token])

    const getMyFollowers = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/user/myfollowers`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${loggedInUser.token}`,
                },
                data: {},
            })

            if (res.status === 200 && res.data) {
                setMyFollowers(res.data.followers)
            } else {
                console.log('ERROR', res)
            }
        } catch (error) {
            console.log(error)
        }
    }, [loggedInUser.token])

    const getMuted = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/user/muted`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${loggedInUser.token}`,
                },
                data: {},
            })

            if (res.status === 200 && res.data) {
                setMuted(res.data.muted)
            } else {
                console.log('ERROR', res)
            }
        } catch (error) {
            console.log(error)
        }
    }, [loggedInUser.token])

    useEffect(() => {
        getFollowers()
        getMyFollowers()
        getMuted()
    }, [setFollowing, getFollowers, getMuted])

    return (
        <div className='w-full'>
            <h2 className='text-2xl font-cgbold'>My community</h2>
            <p className='mt-2 mb-4 text-sm'>
                Here you can also view your follow list, who follows you, and community members you have muted. Muting a
                community member will stop their posts appearing in your community feed. Community members will know if
                you follow them, but will not know if you have muted them.
            </p>

            <div className='mt-4 flex flex-col md:flex-row'>
                <div className='flex flex-col w-full my-2 md:my-0 md:w-1/3 md:mx-1 border border-blue2 rounded-lg p-2 md:p-4'>
                    <h2 className='text-xl font-cgbold'>
                        Members that I follow: {loggedInUser.following.users.length | 0}
                    </h2>

                    <div className='flex flex-col items-start'>
                        {following.map((f: any, key: number) => {
                            return (
                                <SimplifiedUser
                                    key={key}
                                    user={f}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='flex flex-col w-full my-2 md:my-0 md:w-1/3 md:mx-1 border border-blue2 rounded-lg p-2 md:p-4'>
                    <h2 className='text-xl font-cgbold'>Members that follow me: {loggedInUser.followers.length | 0}</h2>
                    <div className='flex flex-col items-start'>
                        {myFollowers.map((f: any, key: number) => {
                            return (
                                <SimplifiedUser
                                    key={key}
                                    user={f}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='flex flex-col w-full my-2 md:my-0 md:w-1/3 md:mx-1 border border-blue2 rounded-lg p-2 md:p-4'>
                    <h2 className='text-xl font-cgbold'>
                        Members that I have muted: {loggedInUser.following.muted.length | 0}
                    </h2>
                    <div className='flex flex-col items-start'>
                        {muted.map((m: any, key: number) => {
                            return (
                                <SimplifiedUser
                                    key={key}
                                    user={m}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyCommunity
