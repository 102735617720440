import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'

const alertSubject = new Subject()
const defaultId = 'default-alert'

// enable subscribing to alerts observable
const onAlert = (id = defaultId) => {
	return alertSubject.asObservable().pipe(filter((x) => x && x.id === id))
}

const isArr = (a) => {
	return Object.prototype.toString.call(a) === '[object Array]'
}

const success = (message, options) => {
	console.log('MSG', message)
	console.log('OPTIONS', options)
	if (isArr(message)) {
		for (const msg of message) {
			alert({ ...options, type: AlertType.Success, message: msg })
		}
	} else {
		alert({ ...options, type: AlertType.Success, message })
	}
}

const error = (message, options) => {
	if (isArr(message)) {
		for (const msg of message) {
			alert({ ...options, type: AlertType.Error, message: msg })
		}
	} else {
		alert({ ...options, type: AlertType.Error, message })
	}
}

const info = (message, options) => {
	if (isArr(message)) {
		for (const msg of message) {
			alert({ ...options, type: AlertType.Info, message: msg })
		}
	} else {
		alert({ ...options, type: AlertType.Info, message })
	}
}

const warn = (message, options) => {
	if (isArr(message)) {
		for (const msg of message) {
			alert({ ...options, type: AlertType.Warning, message: msg })
		}
	} else {
		alert({ ...options, type: AlertType.Warning, message })
	}
}

const alert = (alert) => {
	alert.id = alert.id || defaultId
	alert.autoClose = alert.autoClose === undefined ? true : alert.autoClose
	alertSubject.next(alert)
}

const clear = (id = defaultId) => {
	alertSubject.next({ id })
}

export const alertService = {
	onAlert,
	success,
	error,
	info,
	warn,
	alert,
	clear,
}

export const AlertType = {
	Success: 'Success',
	Error: 'Error',
	Info: 'Info',
	Warning: 'Warning',
}
