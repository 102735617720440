import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useUser } from '../../helpers/useUser'
import { formatDate } from '../../helpers/dates'
import { Notification } from '../notifications'
import ProfilePicture from '../../assets/images/profile-picture.svg'
import admin from '../../assets/images/admin_avatar.png'
import admin2 from '../../assets/images/admin2_avatar.png'
import avatar1 from '../../assets/images/avatar1.png'
import avatar2 from '../../assets/images/avatar2.png'
import avatar3 from '../../assets/images/avatar3.png'
import avatar4 from '../../assets/images/avatar4.png'
import avatar5 from '../../assets/images/avatar5.png'
import avatar6 from '../../assets/images/avatar6.png'
import avatar7 from '../../assets/images/avatar7.png'
import avatar8 from '../../assets/images/avatar8.png'
import avatar9 from '../../assets/images/avatar9.png'
import Happy from '../../assets/images/reactions/Happy.svg'
import CommentsIcon from '../../assets/images/icon_comments.svg'
import { userInteractionLog } from '../../helpers/logs'
import { urlParser } from '../../helpers/logs'
import { getUser } from '../auth/LoginState'

const UserProfile: React.FC = () => {
    const nav = useNavigate()
    const urlUser = useLocation().pathname.replace('/user/profile/', '')
    const usr = getUser()
    const [user, setUser] = useState<any>()
    const [loggedInUser, setLoggedInUser] = useState<any>()
    const [isFollowed, setIsFollowed] = useState<boolean>(false)
    const [isMuted, setIsMuted] = useState<boolean>(false)
    const [restricted, setRestricted] = useState<boolean>(false)
    const [posts, setPosts] = useState<any>([])

    const getUserProfile = useCallback(async () => {
        try {
            if (usr) {
                const res = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_SERVER}/api/user/profile/${urlUser}`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${usr.token}`,
                    },
                    data: {},
                })

                if (res.status === 200) {
                    setPosts(res.data.posts)
                    setUser(res.data.user)
                    setLoggedInUser(res.data.loggedInUser)
                }
            }
        }
        catch (error: any) {
            console.log(error);
        }
    }, [usr, posts])

    const fetchAvatar = () => {
        if (user.role === 'admin') {
            if (user.roleAdmin === 'moderator') {
                return admin
            } else {
                return admin2
            }
        } else if (user.role === 'user' && user.privacy.avatar === true) {
            switch (user.avatar) {
                case 'avatar1':
                    return avatar1
                case 'avatar2':
                    return avatar2
                case 'avatar3':
                    return avatar3
                case 'avatar4':
                    return avatar4
                case 'avatar5':
                    return avatar5
                case 'avatar6':
                    return avatar6
                case 'avatar7':
                    return avatar7
                case 'avatar8':
                    return avatar8
                case 'avatar9':
                    return avatar9
                default:
                    break
            }
        } else {
            return ProfilePicture
        }
    }

    const checkIfUserIsFollowed = useCallback(() => {
        if (!user || !loggedInUser){
            return
        }

        loggedInUser.following.users.map((follower: any) => {
            if (follower.user === user._id) {
                setIsFollowed(true)
            }
 
            return null
        })
    }, [user, loggedInUser])

    const checkIfUserIsMuted = useCallback(() => {
        if (!user || !loggedInUser){
            return
        }

        loggedInUser.following.muted.map((muted: any) => {
            if (muted.user === user._id) {
                setIsMuted(true)
            }

            return null
        })
    }, [loggedInUser, user])

    const checkIfUserIsRestricted = useCallback(() => {
        if (!user || !loggedInUser){
            return
        }

        user.following.muted.map((muted: any) => {
            if (muted.user === loggedInUser._id){
                setRestricted(true)
            }
        })

    }, [loggedInUser, user])

    const followUser = async (uid: string) => {
        try {
            userInteractionLog(urlParser(window.location.href), uid, 'followState', true)

            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/user/follow`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    userId: uid,
                },
            })

            if (res.status === 201) {
                let msg = 'You now follow this community member.'

                if (res.data.state === 'unfollow') {
                    msg = 'You have unfollowed this community member.'
                }

                setIsFollowed(!isFollowed)

                return Notification({
                    message: msg,
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const muteUser = async (uid: string) => {
        try {
            userInteractionLog(urlParser(window.location.href), uid, 'muteState', true)

            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/user/mute`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    userId: uid,
                },
            })

            if (res.status === 201) {
                let msg = 'You have muted this community member. You will no longer see their posts in the Community Feed and they will not be able to view your posts or your profile.'

                if (res.data.state === 'unmute') {
                    msg = 'You have unmuted this community member. You will now see their posts in the Community Feed.'
                }

                setIsMuted(!isMuted)

                return Notification({
                    message: msg,
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        getUserProfile()
    }, [])

    useEffect(() => {
        checkIfUserIsFollowed()
        checkIfUserIsMuted()
        checkIfUserIsRestricted()
    }, [user, loggedInUser])

    return (
        <>
            {user && loggedInUser && (
                <>
                    <div className='container mt-16 max-w-5xl mx-auto p-3 md:p-0'>
                        <div className='flex flex-row w-full items-center justify-between border-b-2 border-b-black/20 mb-4 pb-4'>
                            <div className='flex flex-col md:flex-row w-full justify-between items-center'>
                                <div className='flex flex-col md:flex-row justify-center items-center'>
                                    <img
                                        src={fetchAvatar()}
                                        alt='Display users avatar'
                                        width={70}
                                    />
                                    <h2 className='text-3xl font-cgbold ml-3 break-all'>{user.username} Profile</h2>
                                </div>
                                <div className='text-center mt-2'>
                                    {user._id !== loggedInUser._id ? (
                                        <>
                                            <button
                                                className={
                                                    isFollowed
                                                        ? 'bg-green1 text-white font-cgbold py-2 px-6 rounded-full md:mt-4'
                                                        : 'font-cgbold border-2 border-green1 text-green1 py-2 px-6 rounded-full md:mt-4'
                                                }
                                                onClick={() => followUser(user._id)}
                                            >
                                                {isFollowed ? 'FOLLOWING' : 'FOLLOW'}
                                            </button>
                                            {user.role !== 'admin'?
                                                <button
                                                    className={
                                                        isMuted
                                                            ? 'ml-2 bg-red1 text-white font-cgbold py-2 px-6 rounded-full md:mt-4'
                                                            : 'ml-2 font-cgbold border-2 border-red1 text-red1 py-2 px-6 rounded-full md:mt-4'
                                                    }
                                                    onClick={() => muteUser(user._id)}
                                                >
                                                    {isMuted ? 'UNMUTE' : 'MUTE'}
                                                </button>
                                            : null }
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className='border-b-2 border-b-black/20 pb-4'>
                            <div className='flex flex-col md:flex-row justify-between'>
                                <div>
                                    <strong>Posts: </strong>
                                    {user.stats.numPosts}
                                    <br />
                                    <strong>Comments: </strong>
                                    {user.stats.numComments}
                                    <br />
                                    <strong>Reactions: </strong>
                                    {user.reactions.resources.length + user.reactions.posts.length}
                                </div>

                                <div>
                                    <div>
                                        <strong>Pronouns: </strong>
                                        {user.privacy.pronouns ? user.pronouns : 'Pronouns are not public'}
                                    </div>
                                    <div>
                                        <strong>Gender: </strong>
                                        {user.privacy.gender ? user.gender : 'Gender is not public'}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <strong>A little about me: </strong>
                                <br />
                                {user.privacy.bio ? user.biography : 'A little about me is not public'}
                            </div>
                            <div className='mt-3'>
                                <strong>My story: </strong>
                                <br />
                                {user.privacy.story ? user.story : 'My story is not public'}
                            </div>
                        </div>

                        <div className='mt-4'>
                            <h2 className='text-2xl font-cgbold'>Recent posts</h2>
                            {isMuted?
                                <p>You have muted this user. Unmute the user to see their recent activity.</p>
                            : restricted?
                                <p>Recent activity of community member cannot be shown</p>
                            :<>
                                <p>Here you can see their recent posts. Click on the titles to view the post in full.</p>
                                {posts &&
                                    posts.map((post: any, count: number) => {
                                        if (post.status === 'approved') {
                                            const reactionsSum =
                                                post.stats.counters.happy +
                                                post.stats.counters.hear +
                                                post.stats.counters.laughter +
                                                post.stats.counters.proud +
                                                post.stats.counters.sad +
                                                post.stats.counters.support
                                            return (
                                                <div
                                                    key={count}
                                                    className='w-full p-4 md:p-6 rounded-lg border border-black/20 my-2 md:my-4 shadow-lg'
                                                >
                                                    <div className='flex flex-col justify-between items-center'>
                                                        <div
                                                            className='w-full text-blue1 cursor-pointer border-b border-b-black/20 pb-2'
                                                            onClick={() =>
                                                                nav(`/community/article/${post._id}`, {
                                                                    state: {
                                                                        userProfileOrigin: true,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            {post.title}
                                                        </div>

                                                        <div className='flex flex-row items-center w-72 mt-4 justify-between'>
                                                            <div className='flex flex-col justify-center items-center'>
                                                                <div className='font-cgbold'>Posted: </div>
                                                                <div>{formatDate(post.dateCreated)}</div>
                                                            </div>
                                                            <div className='flex flex-col justify-center items-center'>
                                                                <div>
                                                                    <img
                                                                        src={Happy}
                                                                        alt='Happy'
                                                                        className='w-10 rounded-full p-1 bg-yellow1'
                                                                    />
                                                                </div>
                                                                <div>{reactionsSum}</div>
                                                            </div>
                                                            <div className='flex flex-col justify-center items-center'>
                                                                <div>
                                                                    <img
                                                                        src={CommentsIcon}
                                                                        alt='Comments'
                                                                        className='w-10 rounded-full p-1 bg-yellow1'
                                                                    />
                                                                </div>
                                                                <div>{post.numComments}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return ''
                                    })
                                }
                            </>}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default UserProfile
