import React, { useEffect } from 'react'
import { Notification } from '../notifications'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import { urlParser } from '../../helpers/logs'

type FPE = {
    modal: (param: boolean) => void
    post: any
    getPosts: () => void
}

const PostRequestDelete: React.FC<FPE> = ({ modal, post, getPosts }) => {
    const USER = getUser()

    const requestDelete = async (id: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/request-delete`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {
                    postId: id,
                    context: urlParser(window.location.href)
                },
            })

            if (res.status === 201) {
                window.location.reload()

                // return Notification({
                //     message:
                //         'You have successfully requested for your post to be removed. If it was not hidden already, your post is now hidden from the community feed.',
                //     type: 'success',
                // })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (e.target.id == 'modal-container') {
                modal(false)
            }
        })
    }, [])

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'
        id='modal-container'>
            <div className='flex items-center justify-end h-11'
            id='modal-container'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => modal(false)}
                >
                    X
                </button>
            </div>
            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'
            id='modal-container'>
                <div className='p-2 md:p-0 flex justify-center'
                id='modal-container'>
                    <div className='bg-white max-w-4xl mx-auto rounded-xl p-2'>
                        {post ? (
                            <div className='p-5'>
                                If you would like one of our moderators to delete this post, please confirm below and
                                your request will be processed as soon as possible. In the meantime, your post will be
                                hidden from the feed, so other community members will not be able to see it. Please
                                note, this cannot be reversed. If you want to temporarily hide your post, and might want
                                to share it again in the future, simply click the "hide my post" option."
                                <div className='flex justify-end mt-5'>
                                    <button
                                        className='bg-red1 font-cgbold rounded-full py-2 px-6 mr-auto'
                                        onClick={() => {
                                            requestDelete(post._id)
                                            modal(false)
                                        }}
                                    >
                                        Yes, delete my post
                                    </button>
                                    <button
                                        className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                        onClick={() => modal(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostRequestDelete
