// @ts-nocheck

import React, { useCallback, useState, useEffect } from 'react'
import axios from 'axios'
import Masthead from '../layout/MastHead'
import Carousel from './Carousel'
import Resource from './Resource'
import ResourcesUploadForm from './ResourceUploadForm'
import { getUser } from '../auth/LoginState'
import ScrollToTop from '../layout/ScrollToTop'

const ResourcesIndex = () => {
    const USER = getUser()
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [category, setCategory] = useState<string>('')
    const [data, setData] = useState<any | undefined>()
    const [users, setUsers] = useState<any>()
    const [user, setUser] = useState<any>()
    const [allTopics, setTopics] = useState([])

    const getTopics = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/resource/topics`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setTopics(res.data.topics)
            }
        } catch (error) {
            console.log('ERR', error)
        }
    }, [USER.token])

    const getResources = useCallback(async (topic?: string) => {
        topic = topic? topic : '' //If no topic is sent default to ''

        try {
            let url = `${process.env.REACT_APP_SERVER}/api/resource`

            if (topic !== '') {
                url = `${url}/topic/${topic}`
            }

            const res = await axios({
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setUsers(res.data.users)
                setUser(res.data.user)
                setData(res.data.resources)
                setCategory(topic)
            }
        } catch (error) {
            console.log('ERR', error)
        }
    }, [category, USER.token])

    useEffect(() => {
        getTopics()
        getResources()
    }, [])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center px-2'>
                <h2 className='text-5xl font-cgbold mt-56 md:mt-20'>Resources</h2>
                <div className='bg-white border-8 border-green1 rounded-xl my-4 px-8'>
                    <h4 className='text-md my-4'>
                        On this page you will find a library of resources that might be useful to someone living with a
                        long-term physical health condition. All of our resources have been selected by our research team at KCL, 
                        but please note that we are not responsible for the content provided via third-party links.
                    </h4>
                    <p className='mt-4'>
                        Click on the topics below or have a browse. Remember that you can save these resources to your Garden 
                        so that you can easily refer to them when you need them.
                    </p>
                    <p className='mt-4'>
                        Do you have a resource that you think could help the community? Why not share your resource or
                        knowledge in the community feed?
                    </p>
                    <p className='mt-4'>
                        To access the resources, click the link (opens in new window) or file (downloads to your device).
                    </p>
                    <p className='mt-4'>
                        <strong>Important:</strong> It is crucial to consult a GP or a qualified healthcare professional 
                        before making any changes to your lifestyle or taking on board any recommendations from the 
                        advice/tips shared below. Always speak to a GP if you have any queries or concerns.
                    </p>
                    <p className='my-4'>If at any time you feel in need of some further support, please visit our <a href='/support' className='text-blue1 font-cgbold'>Further Support and Crisis page</a></p>
                </div>
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 mb-32 px-2'>
                <Carousel
                    options={allTopics}
                    category={category}
                    setCategory={setCategory}
                    getResources={getResources}
                />

                <div className='container mx-auto w-full max-w-4xl mt-1'>
                    {USER.role === 'admin' && (
                        <button
                            type='button'
                            className='rounded-full bg-green1 text-white px-6 py-2'
                            disabled={false}
                            onClick={() => {
                                setModalIsOpen(true)
                            }}
                        >
                            Post
                        </button>
                    )}

                    {category == '' ? null :
                        <button
                            type='button'
                            className='ml-2 rounded-full bg-green1 text-white px-6 py-2'
                            disabled={false}
                            onClick={() => {
                                getResources('');
                            }}
                        >
                            Show all resources
                        </button>
                    }

                    {data && data.length > 0 ? (
                        data.map((item: any, index: number) => {
                            return item.resource.topic.name == category 
                            || category === ''?
                            (
                                <Resource
                                    key={index}
                                    user={users? users[item.resource.user] : null}
                                    users = {users}
                                    loggedInUser={user? user : null}
                                    item={item.resource}
                                    links={item.links}
                                    getResources={getResources}
                                />
                            ) : null
                        })
                    ) : (
                        <div className='container mx-auto w-full mt-1'>
                            <p className='text-center font-cgbold text-xl'>There are no resources yet!</p>
                        </div>
                    )}
                </div>

                {modalIsOpen ? (
                    <ResourcesUploadForm
                        setModalIsOpen={setModalIsOpen}
                        topics={allTopics}
                        setCategory={setCategory}
                        getResources={getResources}
                    />
                ) : null}
            </div>
            <ScrollToTop />
        </>
    )
}

export default ResourcesIndex
