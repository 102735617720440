import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getUser } from '../auth/LoginState'
import IHearYouIcon from '../../assets/images/icon_i hear you.svg'
import ISupportYouIcon from '../../assets/images/icon_i support you.svg'
import { userAction } from '../../helpers/logs'

type PR = {
    componentType: string
    type: string
    post?: string
    comment?: string
    hearYou: boolean
    hearYouCounter: number
    supportYou: boolean
    supportYouCounter: number
    getPosts: () => void
}

const PostReact: React.FC<PR> = ({
    componentType,
    type,
    post,
    hearYou,
    supportYou,
    hearYouCounter,
    supportYouCounter,
    getPosts,
}) => {
    const usr = getUser()
    const [reaction, setReaction] = useState<string>('')

    const update = async (t: string) => {
        try {
            let statsReaction = '',
                notifyReaction = '',
                notifyToggle = ''

            if (t === 'I hear you') {
                if (hearYou) {
                    statsReaction = 'I hear you OFF'
                    notifyToggle = 'removed'
                } else {
                    statsReaction = 'I hear you ON'
                    notifyToggle = 'reacted'
                }
                notifyReaction = 'I hear you'
            } else if (t === 'I support you') {
                if (supportYou) {
                    statsReaction = 'I support you OFF'
                    notifyToggle = 'removed'
                } else {
                    statsReaction = 'I support you ON'
                    notifyToggle = 'reacted'
                }
                notifyReaction = 'I support you'
            }

            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/${post}/reaction`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    type: type,
                    toggle: notifyToggle,
                    reaction: notifyReaction,
                    user: usr.id,
                    postId: post,
                },
            })
            
            let reaction = {
                reaction: true,
                type: statsReaction,
                context: 'post',
            }

            if (post) {
                userAction(usr.id, 'Post', post, 'reaction', reaction)
            }

            if (res.status === 201) {
                getPosts()
            } else {
                console.log('error with response')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        switch (componentType) {
            case 'hear':
                setReaction('I hear you')
                break
            case 'support':
                setReaction('I support you')
                break
            default:
                setReaction('')
                break
        }
    }, [setReaction, componentType])

    return (
        <>
            <div
                className='flex flex-col text-xs items-center mr-2 md:mr-4 text-blue1 font-cgbold cursor-pointer'
                onClick={() => update(reaction)}
            >
                <div className='mb-1'>{reaction ? reaction : ''}</div>

                <div className='justify-center items-center'>
                    {type === 'hear' ? (
                        <img
                            className={hearYou ? 'w-10 rounded-full p-1 bg-blue2' : 'w-10 rounded-full p-1 bg-yellow1'}
                            src={IHearYouIcon}
                            alt='emoji reaction'
                        />
                    ) : null}

                    {type === 'support' ? (
                        <img
                            className={
                                supportYou ? 'w-10 rounded-full p-1 bg-blue2' : 'w-10 rounded-full p-1 bg-yellow1'
                            }
                            src={ISupportYouIcon}
                            alt='emoji reaction'
                        />
                    ) : null}
                </div>

                <div className='mt-1'>
                    {type === 'hear' ? (hearYouCounter !== 0 ? hearYouCounter : '0') : null}
                    {type === 'support' ? (supportYouCounter !== 0 ? supportYouCounter : '0') : null}
                </div>
            </div>
        </>
    )
}

export default PostReact
