import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { AuthProvider } from './context/auth-context'

import RequireAuth from './components/auth/RequireAuth'
import RequireAdminAuth from './components/auth/RequireAdminAuth'

import AdminIndex from './components/admin'
import AllUsers from './components/admin/AllUsers'
import NewRegistrations from './components/admin/NewRegistrations'
import PostsNeedApproving from './components/admin/PostsNeedApproving'
import FlaggedPosts from './components/admin/FlaggedPosts'
import FlaggedPostsModerated from './components/admin/FlaggedPostsModerated'
import FlaggedPostReview from './components/admin/FlaggedPostReview'
import EscalatedPosts from './components/admin/EscalatedPosts'
import ResourceTopics from './components/admin/ResourceTopics'
import PostDeleteRequests from './components/admin/PostDeleteRequests'
import UsersStats from './components/stats/Users'
import UsersSessions from './components/stats/Sessions'
import UsersWeeklySessions from './components/stats/WeeklySessions'
import SearchStats from './components/stats/Search'
import PagesStats from './components/stats/Pages'
import ResourcesStats from './components/stats/Resources'
import UserInteractions from './components/stats/UserInteractions'
import UserActions from './components/stats/UserActions'

import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Homepage from './components/homepage'
import AboutUs from './components/homepage/About'
import Privacy from './components/homepage/Privacy'
import Support from './components/homepage/Support'
import ModerationPolicy from './components/homepage/ModerationPolicy'
import Login from './components/auth/Login'
import ForgottenPassword from './components/auth/ForgottenPassword'
import NewPassword from './components/auth/NewPassword'
import Principles from './components/auth/Principles'
import Register from './components/auth/Register'
import RegisterConfirm from './components/auth/RegisterConfirm'
import PasswordResetSetup from './components/auth/PasswordResetSetup'
import NotVerified from './components/auth/NotVerified'
import ValidateCode from './components/auth/ValidateCode'
import Profile from './components/auth/Profile'
import ProfileSetup from './components/auth/ProfileSetup'
import UserProfile from './components/users/UserProfile'
import CommunityIndex from './components/community/CommunityIndex'
import Article from './components/community/Article'
import ResourcesIndex from './components/resources/ResourcesIndex'
import ResourceArticle from './components/resources/Article'
import Garden from './components/mygarden/Garden'
import SearchResults from './components/search/Results'
import SearchResultsTags from './components/search/ResultsTags'
import AutoLogout from './components/layout/AutoLogout'

const App: React.FC = () => {
    return (
        <>
            <AuthProvider>
                <AutoLogout />
                <div className='overflow-x-hidden font-cg'>
                    <Header />
                    <main className='py-16'>
                        <Routes>
                            <Route
                                path='/admin'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <AdminIndex />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/new-registrations'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <NewRegistrations />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/users'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <AllUsers />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/posts/approve/list'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <PostsNeedApproving />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/posts/flagged'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <FlaggedPosts />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/posts/flagged/moderated'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <FlaggedPostsModerated />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/posts/flagged/:id'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <FlaggedPostReview />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/posts/escalated'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <EscalatedPosts />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/posts/remove/user-requests'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <PostDeleteRequests />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/users'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <UsersStats />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/sessions'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <UsersSessions />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/weekly-sessions'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <UsersWeeklySessions />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/search'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <SearchStats />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/pages'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <PagesStats />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/resources'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <ResourcesStats />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/user-interactions'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <UserInteractions />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/admin/stats/user-actions'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <UserActions />
                                    </RequireAdminAuth>
                                }
                            />

                            <Route
                                path='/admin/resources/topics'
                                element={
                                    <RequireAdminAuth redirectTo='/unauthorised'>
                                        <ResourceTopics />
                                    </RequireAdminAuth>
                                }
                            />
                            <Route
                                path='/'
                                element={<Homepage />}
                            />
                            <Route
                                path='/aboutus'
                                element={<AboutUs />}
                            />
                            <Route
                                path='/privacy'
                                element={<Privacy />}
                            />
                            <Route
                                path='/support'
                                element={<Support />}
                            />
                            <Route
                                path='/account/login'
                                element={<Login />}
                            />
                            <Route
                                path='/account/forgotten-password'
                                element={<ForgottenPassword />}
                            />
                            <Route
                                path='/account/new-password'
                                element={<NewPassword />}
                            />
                            <Route
                                path='/principles'
                                element={<Principles />}
                            />
                            <Route
                                path='/moderation-policy'
                                element={<ModerationPolicy />}
                            />
                            <Route
                                path='/account/register'
                                element={<Register />}
                            />
                            <Route
                                path='/account/register/confirmation'
                                element={<RegisterConfirm />}
                            />
                            <Route
                                path='/account/not-verified'
                                element={<NotVerified />}
                            />
                            <Route
                                path='/account/validation-code'
                                element={<ValidateCode />}
                            />
                            <Route
                                path='/account/profile-setup'
                                element={<ProfileSetup />}
                            />
                            <Route
                                path='/password-reset/:id'
                                element={<PasswordResetSetup />}
                            />
                            <Route
                                path='/account/profile'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <Profile />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/user/profile/:id'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <UserProfile />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/community'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <CommunityIndex />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/community/article/:id'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <Article />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/resources'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <ResourcesIndex />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/resource/article/:id'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <ResourceArticle />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/mygarden'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <Garden />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/search/results'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <SearchResults />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/search/tag/:tag'
                                element={
                                    <RequireAuth redirectTo='/account/login'>
                                        <SearchResultsTags />
                                    </RequireAuth>
                                }
                            />
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </AuthProvider>
        </>
    )
}

export default App
