import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import User from '../community/User'
import { Alert } from 'grommet-icons'
import TextContent from '../community/TextContent'
import PostType from '../community/PostType'
import PostFeeling from '../community/PostFeeling'

interface P {
    post: any
    notificationId?: string
    setFormPost: any
    setFormLinks: any
    links: any
    setModal: (param: boolean) => void
    setModalAttachments: (param: boolean) => void
    setModalDelete: (param: boolean) => void
    setFormDelete: any
}

const Post: React.FC<P> = ({
    post,
    notificationId,
    setFormPost,
    setFormLinks,
    setModal,
    setModalAttachments,
    setModalDelete,
    setFormDelete,
}) => {
    const nav = useNavigate()

    return (
        <>
            {post && (
                <>
                    <div className='w-full shadow-lg rounded-lg my-5 border-2 border-black/10 flex flex-col md:flex-row p-4'>
                        <div className='flex flex-row justify-between items-center md:flex-col md:justify-start mr-3 border-b-2 md:border-b-transparent md:border-r-2 border-black/20 w-full md:w-1/6'>
                            <User
                                user={post ? post.post.u : null}
                                getLoggedInUser={setFormPost}
                                allowFollow={false}
                            />
                            <div className='text-sm md:text-2xs md:mt-4'>
                                <span>Posted: </span>
                                <span>{DateTime.fromISO(post.post.dateCreated).toRelative()}</span>
                            </div>
                            <div className='text-sm md:text-2xs md:mt-4 text-center w-max'>
                                <span>Last reply: </span>
                                <span>
                                    {post &&
                                    post.comments &&
                                    post.comments.length &&
                                    post.comments[post.comments.length - 1] &&
                                    post.comments[post.comments.length - 1].dateCreated
                                        ? DateTime.fromISO(
                                              post.comments[post.comments.length - 1].dateCreated
                                          ).toRelative()
                                        : '---'}
                                </span>
                            </div>
                        </div>

                        <div className='w-full mt-3 md:mt-0'>
                            <div className='flex justify-start items-center'>
                                <div className='flex flex-wrap w-11/12'>
                                    <PostType type={post.post.topic.name} />

                                    {post.post.feeling ? <PostFeeling feeling={post.post.feeling} /> : null}

                                    {post.post.profanity ? (
                                        <div className='flex items-center justify-center py-2 px-6 bg-blue1 rounded-full text-xs font-cgbold my-1'>
                                            <Alert
                                                size='20'
                                                color='white'
                                            />
                                            <span className='ml-2 text-white'>
                                                This post might contain offensive language or sensitive content
                                            </span>
                                        </div>
                                    ) : null}
                                    {post.post.moderation.moderatedFlag ? (
                                        <div className='flex items-center justify-center py-2 px-6 bg-black/20 rounded-full text-xs font-cgbold my-1'>
                                            Post edited by moderator
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <h4
                                className='text-2xl font-semibold cursor-pointer mt-6 mb-4 text-blue1'
                                onClick={() =>
                                    nav(`/community/article/${post.post._id}`, {
                                        state: {
                                            adminOrigin: true,
                                        },
                                    })
                                }
                            >
                                {post.post.title}
                            </h4>

                            <TextContent
                                item={post.post}
                                links={post.links}
                            />

                            {post.links && post.links.length > 0 ? (
                                <div className='mt-6 border-t-2 border-b-2 border-t-black/20 py-6'>
                                    {post.links.map((i: any, c: number) => {
                                        let readableName = i.name.split('.')
                                        readableName = readableName[0].slice(0, readableName[0].length - 12)

                                        return (
                                            <div key={c}>
                                                <a
                                                    href={i.link}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    className='text-blue1 font-cgbold'
                                                >
                                                    {readableName}
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : null}

                            <div className='my-2 flex flex-wrap'>
                                {post.post.hashtags
                                    ? post.post.hashtags.map((h: string, c: number) => {
                                          return (
                                              <div
                                                  className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4'
                                                  key={c}
                                              >
                                                  {h}
                                              </div>
                                          )
                                      })
                                    : null}
                            </div>
                            <div className='flex flex-row items-center justify-between border-t border-t-black/20 mx-auto max-w-xl mt-4 pt-6'>
                                <button
                                    onClick={() => {
                                        setModal(true)
                                        setFormPost(post.post)
                                    }}
                                    className='rounded-full py-2 px-4 font-cgbold bg-green1 text-white'
                                >
                                    Approve Post
                                </button>
                                <button
                                    onClick={() => {
                                        setModalAttachments(true)
                                        setFormPost(post.post)
                                        setFormLinks(post.links)
                                    }}
                                    className='rounded-full py-2 px-4 font-cgbold bg-yellow1'
                                >
                                    Remove attachments
                                </button>
                                <button
                                    className='rounded-full py-2 px-4 font-cgbold bg-red1'
                                    onClick={() => {
                                        setModalDelete(true)
                                        setFormDelete(post.post)
                                    }}
                                >
                                    Delete Post
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Post
