import React from 'react'
import Masthead from '../layout/MastHead'

const RegisterConfirm: React.FC = () => {
    return (
        <>
            <Masthead />

            <div className='container h-screen mx-auto w-full max-w-xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>
                    Thank you for registering on CommonGround and setting up your profile.
                </h2>

                <div className='bg-white/60 backdrop-blur-sm p-4 my-4'>
                    The CommonGround team have been notified that you have created your account and will activate your
                    account soon. You will be notified via email once your account has been approved and you can log in
                    to CommonGround! If you have any questions or need further assistance, please contact our team on
                    commonground@kcl.ac.uk.
                </div>
            </div>
        </>
    )
}

export default RegisterConfirm
