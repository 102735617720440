import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import { Notification as Not } from '../notifications'
import { getUser } from '../auth/LoginState'
import { useUser } from '../../helpers/useUser'

const Footer = () => {
    const nav = useNavigate()
    const USER = getUser()

    const logNavigation = async (path: string) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/stats/navlog`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    studyId: USER.role === 'admin' ? 'admin' : USER.studyId,
                    userId: USER._id,
                    path: path,
                },
            })
            if (res.status !== 200) {
                return Not({
                    message: 'Error with response from server',
                    type: 'danger',
                })
            }
        } catch (error) {
            return Not({
                message: error,
                type: 'danger',
            })
        }
    }

    return (
        <div className='fixed bottom-0 left-0 w-full font-cgbold bg-blue3 flex flex-row items-center justify-center px-2 py-1 pb-4 lg:pb-0'>
            <Link
                className='md:mx-8 text-center text-xs md:text-base px-1'
                to='/'
                onClick={() => USER? logNavigation('Home') : null}
            >
                Home
            </Link>
            <div
                className='cursor-pointer md:mx-8 text-center text-xs md:text-base px-1'
                onClick={() => {
                    if (USER){
                        logNavigation('Community Principles')
                    }
                    

                    nav('/principles', {
                        state: {
                            noCheckbox: true,
                        },
                    })
                }}
            >
                Community Principles
            </div>
            <a
                className='md:mx-8 text-center text-xs md:text-base px-1'
                href='/aboutus#contact'
                title='About Us'
                onClick={() => logNavigation('Contact us')}
            >
                Contact Us
            </a>
            <a
                className='md:mx-8 text-center text-xs md:text-base px-1'
                href='/moderation-policy'
                title='Moderation policy'
                onClick={() => logNavigation('Moderation policy')}
            >
                Moderation Policy
            </a>
            <a
                className='md:mx-8 text-center text-xs md:text-base px-1'
                href='/privacy'
                title='Privacy'
                onClick={() => logNavigation('Privacy')}
            >
                Privacy
            </a>
        </div>
    )
}

export default Footer
