import React from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { useUser } from '../../helpers/useUser'
import { urlParser } from '../../helpers/logs'

type Props = {
    setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
    postId: string
}

const RequestPostDeleteModal: React.FC<Props> = ({ setDeleteModal, postId }) => {
    const user = useUser()

    const requestDelete = async (id: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/request-delete`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {
                    postId: id,
                    context: urlParser(window.location.href)
                },
            })

            if (res.status === 201) {
                setDeleteModal(false)

                // return Notification({
                //     message:
                //         'You have successfully requested for your post to be removed. If it was not a hidden already, your post is now hidden from the community feed.',
                //     type: 'success',
                // })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
            <div className='flex items-center justify-end h-11'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => setDeleteModal(false)}
                >
                    X
                </button>
            </div>
            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                <div className='p-2 md:p-0'>
                    <div className='bg-white max-w-4xl mx-auto rounded-xl p-2'>
                        <h2 className='text-2xl font-cgbold text-center mt-2 mb-4'>
                            Request moderators to delete my post
                        </h2>

                        <p className='p-4'>
                            If you would like one of our moderators to delete this post, please press confirm below and
                            your request will be processed as soon as possible. In the meantime, your post will be
                            hidden from the feed, so other community members will not be able to see it.{' '}
                            <strong>Please note, this cannot be reversed.</strong>
                            If you want to temporarily hide your post, and might want to share it again in the future,
                            simply "hide my post" instead.
                        </p>

                        <div className='my-4'>
                            <div className='flex justify-between'>
                                <button
                                    type='submit'
                                    className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                    onClick={() => requestDelete(postId)}
                                >
                                    Request deletion
                                </button>
                                <button
                                    type='button'
                                    className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                    onClick={() => {
                                        setDeleteModal(false)
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestPostDeleteModal
