// @ts-nocheck

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import User from './User'
import { Alert } from 'grommet-icons'
import PostType from './PostType'
import PostFeeling from './PostFeeling'
import EmojiReact from './EmojiReact'
import PostReact from './PostReact'
import CommentsHandler from './CommentsHandler'
import CommentsAdd from './CommentsAdd'
import Bookmark from './Bookmark'
import TextContent from './TextContent'
import Comments from './Comments'
import Moderation from './Moderation'
import { getUser } from '../auth/LoginState'
import PostPin from './PostPin'
import axios from 'axios'

interface P {
    post: any
    user: any
    users: any
    loggedInUser: any
    links: any
    getLoggedInUser: () => void
    updateFilter: (type: String) => void
}

const Post: React.FC<P> = ({ post, user, users, loggedInUser, links, getLoggedInUser, updateFilter }) => {
    const nav = useNavigate()
    const usr = getUser()
    const [item, setItem] = useState<any>(post)
    const [userList, setUsers] = useState<any>(users)
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
    const [showComments, setShowComments] = useState<boolean>(false)
    const [hearYou, setHearYou] = useState<boolean>(false)
    const [hearYouCounter, setHearYouCounter] = useState<number>(0)
    const [supportYou, setSupportYou] = useState<boolean>(false)
    const [supportYouCounter, setSupportYouCounter] = useState<number>(0)
    const [happy, setHappy] = useState<boolean>(false)
    const [happyCounter, setHappyCounter] = useState<number>(0)
    const [sad, setSad] = useState<boolean>(false)
    const [sadCounter, setSadCounter] = useState<number>(0)
    const [proud, setProud] = useState<boolean>(false)
    const [proudCounter, setProudCounter] = useState<number>(0)
    const [laughter, setLaughter] = useState<boolean>(false)
    const [laughterCounter, setLaughterCounter] = useState<number>(0)

    const checkUserBookmark = useCallback(() => {
        setIsBookmarked(false)

        if (item.stats.bookmarks.length > 0) {
            item.stats.bookmarks.filter((post) => {
                if (post.user === usr.id) {
                    setIsBookmarked(true)
                }

                return ''
            })
        }
    }, [item.stats.bookmarks, usr.id])

    const checkUserReactions = useCallback(() => {
        setHearYou(false)
        setSupportYou(false)
        setHappy(false)
        setSad(false)
        setProud(false)
        setLaughter(false)
        setHearYouCounter(item.stats.counters.hear)
        setSupportYouCounter(item.stats.counters.support)
        setHappyCounter(item.stats.counters.happy)
        setSadCounter(item.stats.counters.sad)
        setProudCounter(item.stats.counters.proud)
        setLaughterCounter(item.stats.counters.laughter)

        if (item.stats.reactions.length > 0) {
            item.stats.reactions.filter((item) => {
                switch (item.reaction) {
                    case 'hear':
                        if (item.user === usr.id) {
                            setHearYou(true)
                        }
                        break
                    case 'support':
                        if (item.user === usr.id) {
                            setSupportYou(true)
                        }
                        break
                    case 'happy':
                        if (item.user === usr.id) {
                            setHappy(true)
                        }
                        break
                    case 'sad':
                        if (item.user === usr.id) {
                            setSad(true)
                        }
                        break
                    case 'proud':
                        if (item.user === usr.id) {
                            setProud(true)
                        }
                        break
                    case 'laughter':
                        if (item.user === usr.id) {
                            setLaughter(true)
                        }
                        break

                    default:
                        break
                }

                return ''
            })
        }
    }, [item.stats.reactions, item.stats.counters, usr.id])

    const getPost: () => void = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/post/${item._id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setItem(res.data.data)
                setUsers(res.data.users)
                checkUserReactions()
                
            } else {
                console.log(res.error)
            }
        } catch (error) {
            setItem(false)
            console.log(error)
        }
    }

    useEffect(() => {
        checkUserBookmark()
        checkUserReactions()
    }, [checkUserBookmark, checkUserReactions, getLoggedInUser, item, userList])

    return (
        <>
            {item && (
                <div className='w-full shadow-lg rounded-lg my-5 border-2 border-black/10 flex flex-col md:flex-row p-4'>
                    <div className='flex flex-row shrink-0 justify-between items-center md:flex-col md:justify-start mr-3 border-b-2 md:border-b-transparent md:border-r-2 border-black/20 w-full md:w-1/6 md:pr-3'>
                        <User
                            user={item ? user : null}
                            loggedInUser={loggedInUser}
                            getLoggedInUser={getLoggedInUser}
                        />

                        <div className='text-sm md:text-2xs md:mt-4 text-center w-max'>
                            <span>Posted: </span>
                            <span>{DateTime.fromISO(item.dateCreated).toRelative()}</span>
                        </div>
                        <div className='text-sm md:text-2xs md:mt-4 text-center w-max'>
                            <span>Last reply: </span>
                            <span>
                                {item &&
                                item.comments &&
                                item.comments.length &&
                                item.comments[item.comments.length - 1] &&
                                item.comments[item.comments.length - 1].dateCreated
                                    ? DateTime.fromISO(item.comments[item.comments.length - 1].dateCreated).toRelative()
                                    : '---'}
                            </span>
                        </div>
                    </div>

                    <div className='w-full mt-3 md:mt-0 overflow-hidden'>
                        <div className='flex justify-start items-center'>
                            <div className='flex flex-wrap w-11/12'>
                                {item.pinned ? <PostPin /> : null}

                                <PostType type={item.topic.name} updateFilter={updateFilter}/>

                                {item.feeling ? <PostFeeling feeling={item.feeling} /> : null}

                                {item.profanity ? (
                                    <div className='flex items-center justify-center py-2 px-6 bg-blue1 rounded-full text-xs font-cgbold my-1'>
                                        <Alert
                                            size='20'
                                            color='white'
                                        />
                                        <span className='ml-2 text-white'>
                                            This post might contain offensive language or sensitive content
                                        </span>
                                    </div>
                                ) : null}
                                {item.moderation.moderatedFlag ? (
                                    <div className='flex items-center justify-center py-2 px-6 bg-black/20 rounded-full text-xs font-cgbold my-1'>
                                        Post edited by moderator
                                    </div>
                                ) : null}
                            </div>

                            <div className='flex w-1/12'>
                                <Moderation
                                    loggedInUser={loggedInUser}
                                    item={item}
                                    getPosts={getPost}
                                />
                            </div>
                        </div>

                        <h4
                            className='text-2xl font-semibold cursor-pointer mt-6 mb-4 text-blue1'
                            onClick={() =>
                                nav(`/community/article/${item._id}`, {
                                    state: {
                                        links: links,
                                    },
                                })
                            }
                        >
                            {item.title}
                        </h4>

                        <TextContent
                            item={item}
                            links={links}
                        />

                        {links && links.length > 0 ? (
                            <div className='mt-6 border-t-2 border-b-2 border-t-black/20 py-6'>
                                {links && links.length > 0
                                    ? links.map((i, c) => {
                                          let readableName = i.name.split('.'),
                                              ext = readableName[1]

                                          readableName = readableName[0].slice(0, readableName[0].length - 12)

                                          if (ext !== 'png' && ext !== 'jpg' && ext !== 'jpeg') {
                                              return (
                                                  <div key={c}>
                                                      <a
                                                          href={i.link}
                                                          target='_blank'
                                                          rel='noreferrer'
                                                          className='text-blue1 font-cgbold break-all'
                                                      >
                                                          {readableName}
                                                      </a>
                                                  </div>
                                              )
                                          } else {
                                              return (
                                                  <div key={c}>
                                                      <a
                                                          href={i.link}
                                                          target='_blank'
                                                          rel='noreferrer'
                                                          className='text-blue1 font-cgbold break-all'
                                                      >
                                                          <img
                                                              src={i.link}
                                                              alt={readableName}
                                                              width={100}
                                                          />
                                                      </a>
                                                  </div>
                                              )
                                          }
                                      })
                                    : null}
                            </div>
                        ) : null}

                        <div className='mt-2 pt-6 flex flex-wrap'>
                            {item.hashtags
                                ? item.hashtags.map((h, c) => {
                                      return (
                                          <div
                                              className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4 cursor-pointer'
                                              key={c}
                                              onClick={() => nav(`/search/tag/${h}`)}
                                          >
                                              {h}
                                          </div>
                                      )
                                  })
                                : null}
                        </div>

                        <div className='flex flex-row justify-between md:justify-start my-6 items-center mr-4'>
                            <PostReact
                                componentType='hear'
                                type='hear'
                                post={item._id}
                                hearYou={hearYou}
                                supportYou={supportYou}
                                hearYouCounter={hearYouCounter}
                                getPosts={getPost}
                            />
                            <PostReact
                                componentType='support'
                                type='support'
                                post={item._id}
                                hearYou={hearYou}
                                supportYou={supportYou}
                                supportYouCounter={supportYouCounter}
                                getPosts={getPost}
                            />
                            <CommentsHandler
                                showComments={showComments}
                                setShowComments={setShowComments}
                                count={item.numComments}
                            />
                            <EmojiReact
                                post={item._id}
                                happy={happy}
                                happyCounter={happyCounter}
                                sad={sad}
                                sadCounter={sadCounter}
                                proud={proud}
                                proudCounter={proudCounter}
                                laughter={laughter}
                                laughterCounter={laughterCounter}
                                getPosts={getPost}
                            />
                        </div>

                        <div className='flex flex-row justify-center md:justify-end mt-8'>
                            <CommentsAdd
                                showComments={showComments}
                                setShowComments={setShowComments}
                                count={item.numComments}
                            />

                            <Bookmark
                                id={item._id}
                                userToken={usr.token}
                                userId={usr.id}
                                isBookmarked={isBookmarked}
                                getPosts={getPost}
                            />
                        </div>

                        {showComments && (
                            <div className='rounded-lg border-2 border-blue2 p-2 mt-2 bg-blue-50'>
                                <Comments
                                    users={userList}
                                    loggedInUser={loggedInUser}
                                    postId={item._id}
                                    comments={item.comments}
                                    getPosts={getPost}
                                    showComments={showComments}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default Post
