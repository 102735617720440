import React, { useEffect, useState } from 'react'
import Masthead from '../layout/MastHead'
import { useLocation } from 'react-router-dom'
import Post from './Post'
import axios from 'axios'

type LocationState = {
    posts: any
    resources: any
    searchTerm: string
}

const SearchResults = () => {
    const loc = useLocation().state as LocationState
    const [tab, setTab] = useState('posts')
    const [posts, setPosts] = useState(loc.posts)
    const [user, setUser] = useState<any>(null)
    const [users, setUsers] = useState<any>(null)
    const [resources, setResources] = useState(loc.resources)

    const sort = async (type?: string) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/post/search`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    searchTerm: loc.searchTerm,
                    searchType: type,
                    searchId: window.sessionStorage.getItem('searchId'),
                },
            })

            if (res.status === 200) {
                setPosts(res.data.posts)
                setResources(res.data.resources)
                setUser(res.data.user)
                setUsers(res.data.users)
            } else {
                console.log('error with response')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        sort()
    }, [])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-52 md:mt-16 text-center px-2'>
                <h2 className='text-5xl font-cgbold mt-20'>Search Results</h2>
                <h4 className='text-md my-4'>
                    You can choose to browse search results in the Community Feed or our Resources Page. You can also
                    filter results by relevancy or by date using the drop down below.
                </h4>

                <div className='flex justify-between mx-auto max-w-md items-center my-6'>
                    <div
                        className={
                            tab === 'posts'
                                ? 'cursor-pointer rounded-full px-6 py-2 text-center font-cgbold bg-green1'
                                : 'cursor-pointer rounded-full px-6 py-2 text-center font-cgbold bg-green3'
                        }
                        onClick={() => setTab('posts')}
                    >
                        <h4>Community feed ({posts.length})</h4>
                    </div>
                    <div
                        className={
                            tab === 'resources'
                                ? 'cursor-pointer rounded-full px-6 py-2 text-center font-cgbold bg-red1'
                                : 'cursor-pointer rounded-full px-6 py-2 text-center font-cgbold bg-red2'
                        }
                        onClick={() => setTab('resources')}
                    >
                        <h4>Resources page ({resources.length})</h4>
                    </div>
                </div>

                <div className='my-2 flex justify-between'>
                    <div>
                        You searched for: <strong>{loc.searchTerm}</strong>, and there are{' '}
                        <strong>{loc.posts.length + loc.resources.length}</strong> results!
                    </div>
                    <select
                        name='sort'
                        id='sort'
                        className='block shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                        onChange={(e) => sort(e.target.value)}
                    >
                        <option value='relevance'>Relevance</option>
                        <option value='date'>Date</option>
                    </select>
                </div>

                <div className='container mx-auto w-full max-w-4xl mt-16'>
                    {tab === 'posts'
                        ? posts.length > 0
                            ? posts.map((p: any, c: number) => {
                                  return (
                                      <Post
                                          key={c}
                                          user={users? users[p.user] : null}
                                          loggedInUser={user}
                                          item={p}
                                          section='community'
                                          getPosts={sort}
                                      />
                                  )
                              })
                            : 'No posts matching your search in the Community Feed.'
                        : null}

                    {tab === 'resources'
                        ? resources.length > 0
                            ? resources.map((r: any, c: number) => {
                                  return (
                                      <Post
                                          key={c}
                                          user={users? users[r.user] : null}
                                          loggedInUser={user}
                                          item={r}
                                          section='resources'
                                          getPosts={sort}
                                      />
                                  )
                              })
                            : 'No resources matching your search'
                        : null}
                </div>
            </div>
        </>
    )
}

export default SearchResults
