import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/auth-context'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Notification as Not } from '../notifications'
import { Alert, Search, Notification } from 'grommet-icons'
import Logo from '../../assets/images/logo.svg'
import { Notifications } from './Notifications'
import { SearchField } from './SearchField'
import { getUser } from '../auth/LoginState'

const Header = () => {
    const nav = useNavigate()
    const USER = getUser()
    const { isAuthenticated, logout } = useContext(AuthContext)
    const [avatar, setAvatar] = useState<string>('')
    const [active, setActive] = useState<boolean>(false)
    const [role, setRole] = useState<string>('')
    const [notificationsWindow, setNotificationsWindow] = useState<boolean>(false)
    const [searchModal, setSearchModal] = useState<boolean>(false)
    const [notificationsCount, setNotificationsCount] = useState<number>(0)

    const logOut = async () => {
        const USER = JSON.parse(window.sessionStorage.getItem('CGUSER') || '')

        if (USER !== '') {
            try {
                const res = await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_SERVER}/api/account/logout`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${USER.token}`,
                    },
                    data: {
                        userId: USER.id,
                        sessionId: USER.sessionId,
                        logoutMethod: true,
                    },
                })
                if (res.status === 200) {
                    window.sessionStorage.removeItem('CGUSER')
                    logout()
                    window.location.href = '/account/login'
                }
            } catch (error) {
                return Not({
                    message: error,
                    type: 'danger',
                })
            }
        }
    }

    const fetchAvatar = async () => {
        const local = window.localStorage.getItem('CGUSER')

        if (local) {
            const localData = JSON.parse(local)

            try {
                if (localData.role !== 'admin') {
                    const response = await import(`../../assets/images/${localData.avatar}.png`)

                    setAvatar(response.default)
                } else {
                    let image = ''
                    if (localData.roleAdmin === 'moderator') {
                        image = 'admin'
                    } else {
                        image = 'admin2'
                    }

                    const response = await import(`../../assets/images/${image}_avatar.png`)

                    setRole('admin')
                    setAvatar(response.default)
                }
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                const response = await import(`../../assets/images/profile-picture.svg`)
                setAvatar(response.default)

                setRole('user')
            } catch (err) {
                console.log(err)
            }
        }
    }

    const logNavigation = async (path: string) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/stats/navlog`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    studyId: USER.role === 'admin' ? 'admin' : USER.studyId,
                    userId: USER._id,
                    path: path,
                },
            })
            if (res.status !== 200) {
                return Not({
                    message: 'Error with response from server',
                    type: 'danger',
                })
            }
        } catch (error) {
            return Not({
                message: error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        fetchAvatar()
    }, [avatar, role, isAuthenticated])

    return (
        <>
            {isAuthenticated ? (
                <>
                    <div className='fixed flex flex-col w-full z-40'>
                        <div
                            className='bg-blue1 w-full flex items-center justify-center px-4 py-2 cursor-pointer text-white'
                            onClick={() => {
                                nav('/support')
                            }}
                        >
                            <Alert
                                size='20'
                                color='white'
                            />
                            <div className='mx-4 text-center'>Need urgent help? Click here to find further support</div>
                            <Alert
                                size='20'
                                color='white'
                            />
                        </div>
                        <div className='shadow-xl z-40 w-full bg-white'>
                            <nav className='container mx-auto relative flex justify-between items-center'>
                                <a
                                    className='leading-none ml-2 lg:ml-0'
                                    href='/'
                                >
                                    <img
                                        src={Logo}
                                        className='lg:hidden xl:block min-w-max'
                                        alt='logo'
                                    />
                                </a>
                                <div className='lg:hidden flex space-x-4 items-center'>
                                    <Search
                                        size='35'
                                        className='cursor-pointer'
                                        onClick={() => setSearchModal(!searchModal)}
                                    />
                                    <div className='notification-icon'>
                                        <Notification
                                            size='32'
                                            color={notificationsCount > 0 ? '#518d64' : ''}
                                            onClick={() => setNotificationsWindow(!notificationsWindow)}
                                            className='cursor-pointer select-none'
                                        />
                                        {notificationsCount > 0 ? (
                                            <div className='absolute w-4 h-4 rounded-full bg-blue2 -mt-9 ml-4 border-2 border-blue2'>
                                                &nbsp;
                                            </div>
                                        ) : null}
                                    </div>
                                    <button
                                        className='p-3 border border-black/20 rounded mr-5'
                                        onClick={() => setActive(!active)}
                                    >
                                        <svg
                                            className='block h-4 w-4 fill-current cursor-pointer'
                                            viewBox='0 0 20 20'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <title>Mobile menu</title>
                                            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z'></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className='hidden lg:flex space-x-4 items-stretch z-50'>
                                    <a
                                        className='hidden items-center md:flex py-6 px-6 ml-4 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                        href='/community'
                                        onClick={() => logNavigation('Community')}
                                    >
                                        Community
                                    </a>
                                    <a
                                        className='hidden items-center md:flex py-6 px-6 mr-2 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                        href='/resources'
                                        onClick={() => logNavigation('Resources')}
                                    >
                                        Resources
                                    </a>
                                    <a
                                        className='hidden items-center md:flex py-6 px-6 mr-2 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                        href='/mygarden'
                                        onClick={() => logNavigation('My Garden')}
                                    >
                                        My Garden
                                    </a>
                                    <a
                                        className='hidden items-center md:flex py-6 px-6 mr-2 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                        href='/aboutus'
                                        onClick={() => logNavigation('About Us')}
                                    >
                                        About Us
                                    </a>
                                    <a
                                        className='hidden items-center md:flex py-6 px-6 mr-2 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                        href='/support'
                                        onClick={() => logNavigation('Crisis Page')}
                                    >
                                        Support & Crisis
                                    </a>
                                    {role && role === 'admin' ? (
                                        <Link
                                            className='hidden items-center md:flex py-6 px-6 mr-2 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                            to='/admin'
                                        >
                                            <span>Admin</span>
                                        </Link>
                                    ) : null}
                                    <Search
                                        size='35'
                                        className='hidden my-auto cursor-pointer'
                                        onClick={() => setSearchModal(!searchModal)}
                                    />
                                    <div className='hidden items-center md:flex cursor-pointer'>
                                        <Notification
                                            size='32'
                                            color={notificationsCount > 0 ? '#518d64' : ''}
                                            onClick={() => setNotificationsWindow(!notificationsWindow)}
                                        />
                                        {notificationsCount > 0 ? (
                                            <div className='absolute w-4 h-4 rounded-full bg-blue1 -mt-9 ml-4 border-2 border-blue2'></div>
                                        ) : null}
                                    </div>
                                    <Link
                                        to='/account/profile'
                                        className='hidden items-center md:flex cursor-pointer flex-shrink-0'
                                        onClick={() => logNavigation('Own Profile')}
                                    >
                                        {avatar ? (
                                            <img
                                                src={avatar}
                                                alt='Display users profile'
                                                width='40'
                                                className='rounded-full inline-block'
                                            />
                                        ) : null}
                                    </Link>
                                    <Link
                                        to='#'
                                        className='hidden items-center md:flex h-fit my-auto bg-green1 rounded-full px-4 py-2 text-center whitespace-nowrap text-white font-cgbold ml-4'
                                        onClick={logOut}
                                    >
                                        Log out
                                    </Link>
                                </div>
                            </nav>

                            <div className={active ? 'z-50' : 'z-50 hidden'}>
                                <div className='fixed inset-0 bg-black opacity-75'></div>
                                <nav className='fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 border-r overflow-y-auto bg-white shadow-md'>
                                    <div className='flex items-center mb-8'>
                                        <Link
                                            className='mr-auto text-3xl font-cgbold leading-none'
                                            to='/community'
                                        >
                                            <img
                                                src={Logo}
                                                alt='logo'
                                            />
                                        </Link>
                                        <button
                                            className='navbar-close'
                                            onClick={() => setActive(!active)}
                                        >
                                            <svg
                                                className='h-6 w-6 text-custom-purple cursor-pointer hover:text-custom-yellow'
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='2'
                                                    d='M6 18L18 6M6 6l12 12'
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div>
                                        <div className='pt-6'>
                                            <Link
                                                className='flex items-center px-4 py-3 mb-3 font-semibold leading-none'
                                                to='/community'
                                                onClick={() => {
                                                    setActive(false)
                                                    logNavigation('Community Page')
                                                }}
                                            >
                                                Community
                                            </Link>
                                            <Link
                                                className='block px-4 py-3 mb-3 font-semibold leading-none'
                                                to='/resources'
                                                onClick={() => {
                                                    setActive(false)
                                                    logNavigation('Resources')
                                                }}
                                            >
                                                Resources
                                            </Link>
                                            <Link
                                                className='block px-4 py-3 mb-3 font-semibold leading-none'
                                                to='/mygarden'
                                                onClick={() => {
                                                    setActive(false)
                                                    logNavigation('My garden')
                                                }}
                                            >
                                                My Garden
                                            </Link>
                                            <Link
                                                className='block px-4 py-3 mb-3 font-semibold leading-none'
                                                to='/aboutus'
                                                onClick={() => {
                                                    setActive(false)
                                                    logNavigation('About us')
                                                }}
                                            >
                                                About Us
                                            </Link>
                                            <Link
                                                className='block px-4 py-3 mb-3 font-semibold leading-none'
                                                to='/support'
                                                onClick={() => {
                                                    setActive(false)
                                                    logNavigation('Support page')
                                                }}
                                            >
                                                Support & Crisis
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='mt-auto my-4'>
                                        <div
                                            className='flex items-center mb-6 min-w-max shrink-0'
                                            onClick={() => {
                                                nav('/account/profile')
                                                setActive(false)
                                                logNavigation('Own profile')
                                            }}
                                        >
                                            {avatar ? (
                                                <>
                                                    <img
                                                        src={avatar}
                                                        alt='Display users profile'
                                                        width='60'
                                                        className='rounded-full inline-block'
                                                    />
                                                    <div className='ml-4 font-cgbold text-lg'>My Profile</div>
                                                </>
                                            ) : null}
                                        </div>
                                        {role && role === 'admin' ? (
                                            <Link
                                                className='block px-4 py-3 mb-10 leading-loose text-center text-white font-semibold bg-green1 rounded-xl'
                                                to='/admin'
                                                onClick={() => setActive(false)}
                                            >
                                                <span>Admin</span>
                                            </Link>
                                        ) : null}
                                        <Link
                                            className='block px-4 py-3 mb-2 leading-loose text-center text-white font-semibold bg-blue1 rounded-xl'
                                            to='#'
                                            onClick={logOut}
                                        >
                                            Log out
                                        </Link>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <SearchField
                        searchModal={searchModal}
                        setSearchModal={setSearchModal}
                    />

                    <Notifications
                        notificationsWindow={notificationsWindow}
                        setNotificationsWindow={setNotificationsWindow}
                        setNotificationsCount={setNotificationsCount}
                    />
                </>
            ) : (
                <div className='fixed flex flex-col w-full z-40'>
                    <div
                        className='bg-blue1 text-white w-full flex items-center justify-center px-4 py-2 cursor-pointer'
                        onClick={() => {
                            nav('/support')
                        }}
                    >
                        <Alert
                            size='20'
                            color='white'
                        />
                        <div className='mx-4 text-center'>Need urgent help? Click here to find further support</div>
                        <Alert
                            size='20'
                            color='white'
                        />
                    </div>
                    <div className='shadow-xl z-40 w-full bg-white'>
                        <nav className='container mx-auto relative flex justify-between items-center'>
                            <a
                                className='leading-none ml-2'
                                href='/'
                            >
                                <img
                                    src={Logo}
                                    alt='logo'
                                />
                            </a>
                            <div className='lg:hidden flex space-x-4 items-center'>
                                <div>
                                    <a
                                        href='/account/login'
                                        title='Login'
                                        className='bg-blue1 text-white text-center font-cgbold rounded-full py-2 px-4 ml-2'
                                    >
                                        Log in
                                    </a>
                                </div>
                                <button
                                    className='p-3 border border-black/20 rounded mr-5'
                                    onClick={() => setActive(!active)}
                                >
                                    <svg
                                        className='block h-4 w-4 fill-current cursor-pointer'
                                        viewBox='0 0 20 20'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <title>Mobile menu</title>
                                        <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z'></path>
                                    </svg>
                                </button>
                            </div>
                            <div className='hidden lg:flex space-x-4 items-center z-50'>
                                <a
                                    className='hidden md:block lg:inline-block py-6 px-6 mr-2 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                    href='/aboutus'
                                >
                                    About Us
                                </a>
                                <a
                                    className='hidden md:block lg:inline-block py-6 px-6 mr-2 font-cgbold text-center hover:pb-4 hover:bg-blue3 hover:border-b-8 hover:border-blue1'
                                    href='/support'
                                >
                                    Support & Crisis
                                </a>
                                <Link
                                    to='/account/login'
                                    className='hidden md:inline-block bg-blue1 rounded-full px-4 py-2 text-white text-center font-cgbold ml-4'
                                >
                                    Log in
                                </Link>
                                <span
                                    className='cursor-pointer hidden md:inline-block bg-blue1 rounded-full px-4 py-2 text-white font-cgbold ml-4'
                                    onClick={() => {
                                        nav('/principles', {
                                            state: {
                                                noCheckbox: false,
                                            },
                                        })
                                    }}
                                >
                                    Create account
                                </span>
                            </div>
                        </nav>

                        <div className={active ? 'z-50' : 'z-50 hidden'}>
                            <div className='fixed inset-0 bg-black opacity-75'></div>
                            <nav className='fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 border-r overflow-y-auto bg-white shadow-md'>
                                <div className='flex items-center mb-8'>
                                    <Link
                                        className='mr-auto text-3xl font-cgbold leading-none'
                                        to='/community'
                                    >
                                        <img
                                            src={Logo}
                                            alt='logo'
                                        />
                                    </Link>
                                    <button
                                        className='navbar-close'
                                        onClick={() => setActive(!active)}
                                    >
                                        <svg
                                            className='h-6 w-6 text-custom-purple cursor-pointer hover:text-custom-yellow'
                                            xmlns='http://www.w3.org/2000/svg'
                                            fill='none'
                                            viewBox='0 0 24 24'
                                            stroke='currentColor'
                                        >
                                            <path
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='2'
                                                d='M6 18L18 6M6 6l12 12'
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                                <div>
                                    <div className='pt-6'>
                                        <a
                                            className='block py-2 px-6 mr-2 font-cgbold'
                                            href='/aboutus'
                                        >
                                            About Us
                                        </a>
                                        <a
                                            className='block py-6 px-6 mr-2 font-cgbold'
                                            href='/support'
                                        >
                                            Support & Crisis
                                        </a>
                                    </div>
                                </div>
                                <div className='mt-auto my-4'>
                                    <Link
                                        className='block px-4 py-3 mb-2 leading-loose text-center text-white font-semibold bg-blue1 rounded-xl'
                                        to='/account/login'
                                        onClick={() => setActive(!active)}
                                    >
                                        Log in
                                    </Link>
                                    <span
                                        className='cursor-pointer block px-4 py-3 mt-6 mb-2 leading-loose text-center text-white font-semibold bg-blue1 rounded-xl'
                                        onClick={() => {
                                            setActive(!active);
                                            nav('/principles', {
                                                state: {
                                                    noCheckbox: false,
                                                },
                                            });
                                        }}
                                    >
                                        Create account
                                    </span>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Header
