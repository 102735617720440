import React from 'react'
import Masthead from '../layout/MastHead'
import Exlamation from '../../assets/images/icons/exclamation.png'
import Info from '../../assets/images/icons/info.png'
import Phone from '../../assets/images/icons/phone.png'
import Mobile from '../../assets/images/icons/mobile.png'
import People from '../../assets/images/icons/people.png'
import Doctor from '../../assets/images/icons/doctor.png'
import Ear from '../../assets/images/icons/ear.png'
import Question from '../../assets/images/icons/question.png'
import Chat from '../../assets/images/icons/chat.png'
import HumanBubble from '../../assets/images/icons/human_bubble.png'
import Bottom from '../../assets/images/policybottom.png'
import ScrollToTop from '../layout/ScrollToTop'

const Support = () => {
    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-52 md:mt-16 text-center px-2'>
                <h1 className='text-5xl font-cgbold mt-20'>Further Support and Crisis Page</h1>
                <p className='mt-4'>
                    Welcome to the further support and crisis page. On CommonGround, we are unable to provide one-to-one
                    or emergency support for community members who are in crisis or need urgent help. This page contains
                    information that will help and contact information of organisations who are available to provide
                    this support.
                </p>
            </div>

            <div className='container mx-auto w-full max-w-3xl mt-16 min-h-screen px-2'>
                <div className='grid grid-cols-2 gap-x-2 gap-y-10 justify-items-center md:gap-0 md:flex md:flex-row items-center justify-between'>
                    <div className='flex flex-col bg-blue2 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1'>
                        <div className='font-cgbold'>CALL 999</div>
                        <div>for emergencies, if you are in immediate danger, or cannot keep yourself safe</div>
                        <div className='rounded-full bg-blue2 absolute mt-60 p-2'>
                            <img
                                src={Exlamation}
                                alt='exclamation mark'
                                width='50'
                            />
                        </div>
                    </div>
                    <div className='flex flex-col bg-green1 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1 text-white'>
                        <div className='font-cgbold'>CALL NHS 111</div>
                        <div>for medical advice and information (not emergencies)</div>
                        <div className='rounded-full bg-green1 absolute mt-60 p-2'>
                            <img
                                src={Info}
                                alt='exclamation mark'
                                width='50'
                            />
                        </div>
                    </div>
                    <div className='flex flex-col bg-purple1 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1'>
                        <div className='font-cgbold'>
                            SUPPORT VIA PHONE
                            <br />
                            Call 116 123
                        </div>
                        <div>Talk anonymously with a Samaritan volunteer (free, 24/7)</div>
                        <div className='rounded-full bg-purple1 absolute mt-60 p-2'>
                            <img
                                src={Phone}
                                alt='exclamation mark'
                                width='50'
                            />
                        </div>
                    </div>
                    <div className='flex flex-col bg-red2 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1'>
                        <div className='font-cgbold'>
                            SUPPORT VIA TEXT
                            <br />
                            Text SHOUT to 85258
                        </div>
                        <div>Text anonymously with SHOUT volunteer (free, 24/7)</div>
                        <div className='rounded-full bg-red2 absolute mt-60 p-2'>
                            <img
                                src={Mobile}
                                alt='exclamation mark'
                                width='50'
                            />
                        </div>
                    </div>
                </div>
                <div className='mt-20'>
                    <h2 className='self-center bg-blue1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2 b'>
                        ARE YOU IN CRISIS OR KNOW SOMEONE WHO IS IN CRISIS?
                    </h2>
                    <p className='mt-4'>
                        Our CommonGround community allows everyone to share their experiences and how they are feeling,
                        helping one another to navigate the challenges of living with long-term conditions. Whilst we
                        might feel low, stressed, or worried, it is rare for us to feel as though we are extremely
                        distressed and unable to cope.{' '}
                        <span className='font-cgbold'>
                            However, sometimes the unexpected can happen and we find ourselves in a crisis. When this
                            happens, we might need additional support.
                        </span>
                    </p>

                    <p className='mt-4'>
                        Our moderators might share some resources that might help someone during a crisis. However, our
                        moderators are not available 24/7 and cannot offer one-to-one support. If you, or someone you
                        know, needs emergency or one-to-one support, you can find out what other help is available on
                        this page.
                    </p>

                    <div className='mt-4 flex flex-row items-center justify-center text-xs sm:text-base'>
                        <a
                            className='bg-blue1 text-white rounded-lg p-2 mx-1 flex items-center justify-center border-2 text-center font-cgbold h-32 cursor-pointer'
                            href='#helprightnow'
                        >
                            I need help right now
                        </a>
                        <a
                            className='bg-blue1 text-white rounded-lg p-2 mx-1 flex items-center justify-center border-2 text-center font-cgbold h-32 cursor-pointer'
                            href='#someonetotalk'
                        >
                            I need someone to talk to
                        </a>
                        <a
                            className='bg-blue1 text-white rounded-lg p-2 mx-1 flex items-center justify-center border-2 text-center font-cgbold h-32 cursor-pointer'
                            href='#someoneelse'
                        >
                            I am worried about someone else
                        </a>
                        <a
                            className='bg-blue1 text-white rounded-lg p-2 mx-1 flex items-center justify-center border-2 text-center font-cgbold h-32 cursor-pointer'
                            href='#selfharm'
                        >
                            I am thinking about self-harm or suicide
                        </a>
                        <a
                            className='bg-blue1 text-white rounded-lg p-2 mx-1 flex items-center justify-center border-2 text-center font-cgbold h-32 cursor-pointer'
                            href='#extrasupport'
                        >
                            Extra support
                        </a>
                    </div>

                    <div className='mt-20'>
                        <h3
                            id='helprightnow'
                            className='mt-8 scroll-mt-40 self-center bg-blue1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2 b'
                        >
                            I NEED HELP RIGHT NOW
                        </h3>
                        <p className='mt-4 font-cgbold'>
                            <strong>If you are unsafe or are worried you cannot keep yourself safe right now, the quickest and
                            easiest way to get urgent help is to call 999</strong> or visit your nearest A&E immediately.
                        </p>
                        <p className='mt-2'>
                            Mental health emergencies are still emergencies, and you will not be wasting anyone's time.
                            You can always ask someone to help you make the call.
                        </p>
                        <p className='mt-2'>
                            If you have a medical emergency, you should visit your nearest Accident and Emergency
                            department - you can find out where your closest service is{' '}
                            <a
                                href='https://www.nhs.uk/Service-Search/other-services/Accident%20and%20emergency%20services/LocationSearch/428'
                                target='_blank'
                                rel='noreferrer'
                                className='text-blue1 font-cgbold'
                            >
                                here
                            </a>
                            . For urgent (but not life-threatening) medical advice, you can call 111 (or visit the{' '}
                            <a
                                href='https://111.nhs.uk/'
                                target='_blank'
                                rel='noreferrer'
                                className='text-blue1 font-cgbold'
                            >
                                NHS 111 website
                            </a>
                            ).
                        </p>
                    </div>

                    <div className='mt-20'>
                        <h3
                            id='someonetotalk'
                            className='mt-8 scroll-mt-40 self-center bg-blue1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2 b'
                        >
                            I NEED SOMEONE TO TALK TO
                        </h3>
                        <p className='mt-4'>
                            If you need someone to talk to, you can talk to people around you or anonymously to
                            organisations who are there to support you.
                        </p>

                        <div className='mt-4 grid grid-cols-2 gap-x-2 gap-y-12 md:gap-0 justify-items-center md:flex md:flex-row items-center justify-between'>
                            <div className='flex flex-col bg-green2 w-full h-56 rounded-xl p-4 text-center items-center justify-center mx-1'>
                                <div className='font-cgbold'>TALK TO SOMEONE AROUND YOU</div>
                                <div>Talk to someone you trust - family, friend, or colleague</div>
                                <div className='rounded-full bg-green2 absolute mt-56 p-2'>
                                    <img
                                        src={People}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col bg-purple1 w-full h-56 rounded-xl p-4 text-center items-center justify-center mx-1'>
                                <div className='font-cgbold'>
                                    SUPPORT VIA PHONE
                                    <br />
                                    Call 116 123
                                </div>
                                <div>Talk anonymously with a Samaritan volunteer (free, 24/7)</div>
                                <div className='rounded-full bg-purple1 absolute mt-56 p-2'>
                                    <img
                                        src={Info}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col bg-red1 w-full h-56 rounded-xl p-4 text-center items-center justify-center mx-1'>
                                <div className='font-cgbold'>
                                    SUPPORT VIA TEXT
                                    <br />
                                    Text SHOUT to 85258
                                </div>
                                <div>Text anonymously with SHOUT volunteer (free, 24/7)</div>
                                <div className='rounded-full bg-red1 absolute mt-56 p-2'>
                                    <img
                                        src={Phone}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col bg-yellow1 w-full h-56 rounded-xl p-4 text-center items-center justify-center mx-1'>
                                <div className='font-cgbold'>TALK TO YOUR GP</div>
                                <div>You can request an emergency appointment</div>
                                <div className='rounded-full bg-yellow1 absolute mt-56 p-2'>
                                    <img
                                        src={Doctor}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className='mt-10'>
                        If you are struggling, we encourage you to reach out to your friends, family, or anyone you
                        trust. Here are some tips for{' '}
                        <a
                            href='https://www.mind.org.uk/information-support/guides-to-support-and-services/seeking-help-for-a-mental-health-problem/talking-to-friends-family/'
                            target='_blank'
                            rel='noreferrer'
                            className='font-cgbold text-blue1'
                        >
                            talking about your mental health with family and friends
                        </a>
                        .
                    </p>

                    <p className='mt-4'>
                        Sometimes it can be easier to talk to someone we do not know - we can talk more freely without
                        anyone knowing who we are. The{' '}
                        <a
                            href='https://www.samaritans.org/'
                            target='_blank'
                            rel='noreferrer'
                            className='font-cgbold text-blue1'
                        >
                            Samaritans
                        </a>{' '}
                        offer free, one-to-one support via the phone 24/7 and 365 days a year. Call them on 116 123 and
                        someone will offer a listening ear and assist you in getting further help.
                    </p>

                    <p className='mt-4'>
                        If talking on the phone might be difficult, text 'Shout' to 85258 to receive free, one-to-one
                        support via text messaging from a trained volunteer at{' '}
                        <a
                            href='https://giveusashout.org/'
                            target='_blank'
                            rel='noreferrer'
                            className='font-cgbold text-blue1'
                        >
                            SHOUT
                        </a>
                        . All messaging is confidential, anonymous, and free on all major UK networks. SHOUT is
                        available 24/7 for 365 days a year.
                    </p>

                    <p className='mt-4'>
                        There are plenty of organisations that can provide you with assistance, some of which cater to
                        specific groups of people.
                    </p>

                    <ul className='list-disc'>
                        <li className='ml-6'>
                            <a
                                href='https://switchboard.lgbt/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                Switchboard LGBT+ helpline
                            </a>{' '}
                            offers advice and understanding for those who identify as LGBT+ from volunteers who
                            self-define as LGBT+. You can call them for a confidential chat on 0300 330 0630 between
                            10am and 10pm daily.
                        </li>
                        <li className='ml-6'>
                            <a
                                href='https://www.thesilverline.org.uk/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                The Silver Line
                            </a>{' '}
                            offers free, confidential information, friendship, and advice to older people. You can call
                            them on 0800 470 8090. Whether you need someone to have a nice conversation with or need
                            advice on specialist services if you are experiencing abuse or neglect, The Silver Line
                            services are available 24/7 for 365 days per year.
                        </li>
                        <li className='ml-6'>
                            <a
                                href='https://www.thecalmzone.net/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                CALM (Campaign Against Living Miserably)
                            </a>{' '}
                            helpline offers confidential and anonymous support via online webchat or phone (0800 58 58
                            58) between 5pm and midnight daily. CALM also has{' '}
                            <a
                                href='https://www.thecalmzone.net/guide-list'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                a variety of guides
                            </a>{' '}
                            you can read on common mental health challenges.
                        </li>
                    </ul>

                    <div className='mt-20'>
                        <h3
                            id='selfharm'
                            className='mt-8 scroll-mt-40 self-center bg-blue1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2 b'
                        >
                            I AM THINKING ABOUT SELF-HARM OR SUICIDE
                        </h3>
                        <p className='mt-4'>
                            Having feelings of wanting to harm yourself or end your life can be difficult to deal with -
                            but please remember that you are not alone, and help is available. Whilst CommonGround is
                            not a crisis service and we are unable to offer one-on-one ongoing support, we have gathered
                            some resources that might help you at these times.
                        </p>
                        <p className='mt-4'>
                            If you are thinking about ending your life, and need urgent care, the quickest way to get help is to dial 999 or visit your nearest A&E immediately. 
                            If you find it difficult to
                            do this yourself, you can ask someone to help you. If you need someone to talk to, call the
                            Samaritans on 116 123 or text 'SHOUT' to 85258 to receive one-to-one, anonymous, and
                            confidential support (both organisations are free and available 24/7, 365 days per year).
                        </p>
                        <div className='grid grid-cols-2 gap-x-2 gap-y-10 justify-items-center md:gap-0 md:flex md:flex-row items-center justify-between mt-4'>
                            <div className='flex flex-col bg-blue2 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1'>
                                <div className='font-cgbold'>CALL 999</div>
                                <div>for emergencies, if you are in immediate danger, or cannot keep yourself safe</div>
                                <div className='rounded-full bg-blue2 absolute mt-60 p-2'>
                                    <img
                                        src={Exlamation}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col bg-green1 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1 text-white'>
                                <div className='font-cgbold'>CALL NHS 111</div>
                                <div>for medical advice and information (not emergencies)</div>
                                <div className='rounded-full bg-green1 absolute mt-60 p-2'>
                                    <img
                                        src={Info}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col bg-purple1 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1'>
                                <div className='font-cgbold'>
                                    SUPPORT VIA PHONE
                                    <br />
                                    Call 116 123
                                </div>
                                <div>Talk anonymously with a Samaritan volunteer (free, 24/7)</div>
                                <div className='rounded-full bg-purple1 absolute mt-60 p-2'>
                                    <img
                                        src={Phone}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col bg-red2 w-full h-60 rounded-xl p-4 text-center items-center justify-center mx-1'>
                                <div className='font-cgbold'>
                                    SUPPORT VIA TEXT
                                    <br />
                                    Text SHOUT to 85258
                                </div>
                                <div>Text anonymously with SHOUT volunteer (free, 24/7)</div>
                                <div className='rounded-full bg-red2 absolute mt-60 p-2'>
                                    <img
                                        src={Mobile}
                                        alt='exclamation mark'
                                        width='50'
                                    />
                                </div>
                            </div>
                        </div>
                        <p className='mt-16'>
                            We also encourage you to reach out to anyone you trust, such as friends and family who will
                            be able to support you. <span className='font-cgbold'>Sometimes talking to someone about how you feel can be really scary
                            - we do not think they will understand, but they often do</span>. Please do
                            keep seeking support from our community members if this helps you, just please be mindful of
                            how you talk about self-harm because descriptions of methods or frequency can be distressing
                            for other community members to read or cause them to worry about you.
                        </p>
                        <p className='mt-4'>
                            <a
                                href='https://www.stayalive.app/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                Stay Alive
                            </a>{' '}
                            is app to help prevent suicide. You access support, useful information, and tools for
                            keeping you safe in a crisis. You can create a safety plan to help manage frequent suicidal
                            thoughts and a wellness plan to improve your mental health. They also have information on{' '}
                            <a
                                href='https://www.stayalive.app/articles/staying-safe-for-now/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                staying safe for now
                            </a>{' '}
                            if you are struggling to stay safe from suicide right now.
                        </p>

                        <ul className='list-disc mt-4'>
                            If you are thinking about harming yourself, here are some resources that might help you:
                            <li className='ml-6'>
                                Here are some{' '}
                                <a
                                    href='https://www.mind.org.uk/need-urgent-help/how-can-i-distract-myself/'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='font-cgbold text-blue1'
                                >
                                    distraction techniques
                                </a>
                                . These might help to shift your attention to something else and help keep the thoughts
                                under control.{' '}
                            </li>
                            <li className='ml-6'>
                                If you have self-harmed before,{' '}
                                <a
                                    href='https://www.mind.org.uk/information-support/types-of-mental-health-problems/self-harm/helping-yourself-now/#.WhVLs1Vl_IU'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='font-cgbold text-blue1'
                                >
                                    this might help you
                                </a>{' '}
                                to understand self-harm and offers more information on distracting yourself based on why
                                you might want to harm yourself. There are also some tips on how to help yourself in the{' '}
                                <a
                                    href='https://www.mind.org.uk/information-support/types-of-mental-health-problems/self-harm/helping-yourself-long-term/'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='font-cgbold text-blue1'
                                >
                                    longer term
                                </a>
                                , including reaching out for further help.
                            </li>
                        </ul>
                    </div>

                    <div className='mt-20'>
                        <h3
                            id='someoneelse'
                            className='mt-8 scroll-mt-40 self-center bg-blue1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2 b'
                        >
                            I AM WORRIED ABOUT SOMEONE ELSE
                        </h3>
                        <p className='mt-4'>
                            It can be difficult to know what to do when we are worried about someone else. Simply asking
                            someone 'how are you' can begin vulnerable conversations that can help someone process their
                            feelings/experiences and give them an opportunity to ask for further support. If they do not
                            want to talk to you, remind them that you are there for them. If they do want to talk,
                            actively listening to them can make a difference. Here are some tips for active listening:
                        </p>

                        <div className='flex-row mt-4'>
                            <h4 className='text-xl font-cgbold ml-16'>Actively listen to them.</h4>
                            <div className='flex flex-row items-center'>
                                <div className='w-14 h-14 rounded-full p-2 bg-blue1'>
                                    <img
                                        src={Ear}
                                        alt='listen icon'
                                        width={45}
                                    />
                                </div>
                                <p className='rounded-lg bg-blue1 w-full -ml-2 p-2 pl-4 text-white'>
                                    Show you care by listening to them with no distractions and paying full attention.
                                    Remind them that you are available to listen even if they do not want any advice.
                                </p>
                            </div>

                            <h4 className='text-xl font-cgbold ml-16 mt-4'>Use open questions.</h4>
                            <div className='flex flex-row items-center'>
                                <div className='w-14 h-14 rounded-full p-2 bg-purple1'>
                                    <img
                                        src={Question}
                                        alt='listen icon'
                                        width={45}
                                    />
                                </div>
                                <p className='rounded-lg bg-purple1 w-full -ml-2 p-2 pl-4'>
                                    Use open questions and ask "tell me more" to explore how the person is feeling.
                                    These types of questions can help people to pause and reflect.
                                </p>
                            </div>

                            <h4 className='text-xl font-cgbold ml-16 mt-4'>Repeat what they have said.</h4>
                            <div className='flex flex-row items-center'>
                                <div className='w-14 h-14 rounded-full p-2 bg-green1'>
                                    <img
                                        src={Chat}
                                        alt='listen icon'
                                        width={45}
                                    />
                                </div>
                                <p className='rounded-lg bg-green1 w-full -ml-2 p-2 pl-4 text-white'>
                                    Repeat what they have said to show you have listened to them carefully and check you
                                    have understood. Try to do this when the conversation naturally slows to avoid
                                    interrupting them.
                                </p>
                            </div>

                            <p className='mt-4'>
                                Sometimes it might take several attempts to talk before someone is ready, so being
                                patient and non-judgement can help build trust for these conversations.
                            </p>

                            <ul className='list-disc'>
                                There are also resources available that might be helpful if you are concerned about
                                someone:
                                <li className='ml-6'>
                                    <a
                                        href='https://www.stayalive.app/'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='font-cgbold text-blue1'
                                    >
                                        Stay Alive
                                    </a>{' '}
                                    have information and resources for people who are concerned someone else is
                                    suicidal, including{' '}
                                    <a
                                        href='https://www.stayalive.app/articles/practical-steps-to-help/'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='font-cgbold text-blue1'
                                    >
                                        practice steps to help someone at risk
                                    </a>
                                    .
                                </li>
                                <li className='ml-6'>
                                    You can call the{' '}
                                    <a
                                        href='https://www.samaritans.org/'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='font-cgbold text-blue1'
                                    >
                                        Samaritans
                                    </a>{' '}
                                    to talk to someone on 116 123 (free, anonymous, 24/7). They also have advice on
                                    their website on{' '}
                                    <a
                                        href='https://www.samaritans.org/how-we-can-help/if-youre-worried-about-someone-else/how-support-someone-youre-worried-about/'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='font-cgbold text-blue1'
                                    >
                                        how to support someone you’re worried about
                                    </a>
                                    .
                                </li>
                                <li className='ml-6'>
                                    <strong>If you think it is an emergency</strong>, please dial 999.
                                </li>
                            </ul>
                            <p className='mt-2'>
                            Supporting someone who is struggling can be difficult, so make sure to take care of yourself whilst you are providing this support. Reach out to people you trust or the Samaritans (call 116 123; email jo@samaritans.org) if you need to.
                            </p>
                        </div>
                    </div>

                    <div className='mt-20'>
                        <h3
                            id='extrasupport'
                            className='mt-8 scroll-mt-40 self-center bg-blue1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2 b'
                        >
                            EXTRA SUPPORT
                        </h3>
                        <img
                            src={HumanBubble}
                            alt=''
                            className='mx-auto'
                            width={'40%'}
                        />
                        <p className='mt-4'>
                            Sometimes we realise that we need a little extra help to look after our mental health.
                            Talking to your GP about what options are available can always be a good start. Your GP will
                            be able to listen to what you are struggling with and talk through the different options
                            available. If you are unsure how to start the conversation, this{' '}
                            <a
                                href='https://www.mind.org.uk/about-us/our-policy-work/you-and-your-gp/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                information{' '}
                            </a>
                            and{' '}
                            <a
                                href='https://www.mind.org.uk/about-us/our-policy-work/you-and-your-gp/for-gp-patients/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                Find the Words
                            </a>{' '}
                            guide might be useful.
                        </p>
                        <p className='mt-4'>
                            You are also able to refer yourself for free, non-urgent
                            <a
                                href='https://www.nhs.uk/mental-health/talking-therapies-medicine-treatments/talking-therapies-and-counselling/nhs-talking-therapies/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                {' '}
                                psychological therapies service from the NHS
                            </a>
                            . This service provides evidence-based treatments for depression and anxiety. These talking
                            therapies can help you if you’re feeling anxious, low, or struggling to cope with day-to-day
                            life. You must be registered with a GP to refer yourself, but you can refer yourself without
                            telling your GP.
                        </p>
                        <p className='mt-4'>
                            The NHS also offers a
                            <a
                                href='https://www.nhs.uk/every-mind-matters/mental-wellbeing-tips/your-mind-plan-quiz/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                {' '}
                                Mind Plan
                            </a>
                            . After answering a few short questions, it creates a plan with different tips and
                            activities that might help you cope with life’s challenges. There are small things that we
                            can all do to help look after our minds and make a big difference in how we are feeling.
                        </p>

                        <p className='mt-4'>
                            <a
                                href='https://hubofhope.co.uk/'
                                target='_blank'
                                rel='noreferrer'
                                className='font-cgbold text-blue1'
                            >
                                {' '}
                                Hub of Hope{' '}
                            </a>
                            allows you to find services near you. After entering your postcode and selecting what you
                            need help with (ranging from depression to money difficulties) you can browse the services
                            available in your local area.
                        </p>
                    </div>
                    <img
                        className='mt-4'
                        src={Bottom}
                    >
                    </img>
                </div>
                
            </div>
            

            <ScrollToTop />
        </>
    )
}

export default Support
