type LocalData = {
	id: string
	email: string
	firstName: string
	surname: string
	displayName: string
	biography: string
	pronouns: string
	avatar: string
	token: string
	ts: string
	role: string
}

export const isLoggedIn = (): boolean => {
	const session = window.sessionStorage.getItem('CGUSER')
	const local = window.localStorage.getItem('CGUSER')

	if (local) {
		const localData: LocalData = JSON.parse(local)
		const currentDate = new Date().getDate()

		if (localData) {
			const date = new Date(localData.ts).getDate()

			if (currentDate !== date) {
				window.localStorage.removeItem('CGUSER')
			}
		}
	}

	if (session !== null && local !== null) {
		return true
	}
	return false
}

export const isAdmin = (): boolean => {
	const session = window.sessionStorage.getItem('CGUSER')
	const local = window.localStorage.getItem('CGUSER')

	if (local !== null && session !== null) {
		const localData: LocalData = JSON.parse(local)

		if (localData) {
			if (localData.role === 'admin') {
				return true
			}
		}
	}

	return false
}

export const getUser = () => {
	const session = window.sessionStorage.getItem('CGUSER')

	if (session !== null) {
		return JSON.parse(session)
	}

	return false
}
