import React from 'react'
import { Link } from 'react-router-dom'

const Menu: React.FC = () => {
    return (
        <>
            <div className='mx-auto flex w-full items-center justify-center'>
                <div className='group relative cursor-pointer py-2'>
                    <div className='flex items-center justify-between space-x-5 bg-blue-200 px-4'>
                        <span className='menu-hover my-2 py-2 text-base font-medium text-black lg:mx-4'>Users</span>
                        <span>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='currentColor'
                                className='h-6 w-6'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                            </svg>
                        </span>
                    </div>
                    <div className='invisible absolute z-50 flex flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible w-80'>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/new-registrations'
                        >
                            New registrations
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/users'
                        >
                            All users
                        </Link>
                    </div>
                </div>

                <div className='group relative cursor-pointer py-2'>
                    <div className='flex items-center justify-between space-x-5 bg-blue-200 px-4'>
                        <span className='menu-hover my-2 py-2 text-base font-medium text-black lg:mx-4'>Posts</span>
                        <span>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='currentColor'
                                className='h-6 w-6'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                            </svg>
                        </span>
                    </div>
                    <div className='invisible absolute z-50 flex flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible w-80'>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/posts/flagged'
                        >
                            Flagged posts
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/posts/flagged/moderated'
                        >
                            Previously Flagged posts
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/posts/remove/user-requests'
                        >
                            Posts delete requests
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/posts/approve/list'
                        >
                            Posts requiring approval
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/posts/escalated'
                        >
                            Escalated posts
                        </Link>
                    </div>
                </div>

                <div className='group relative cursor-pointer py-2'>
                    <div className='flex items-center justify-between space-x-5 bg-blue-200 px-4'>
                        <span className='menu-hover my-2 py-2 text-base font-medium text-black lg:mx-4'>Resources</span>
                        <span>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='currentColor'
                                className='h-6 w-6'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                            </svg>
                        </span>
                    </div>
                    <div className='invisible absolute z-50 flex flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible w-80'>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/resources/topics'
                        >
                            Manage Topics
                        </Link>
                    </div>
                </div>

                <div className='group relative cursor-pointer py-2'>
                    <div className='flex items-center justify-between space-x-5 bg-blue-200 px-4'>
                        <span className='menu-hover my-2 py-2 text-base font-medium text-black lg:mx-4'>Stats</span>
                        <span>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='currentColor'
                                className='h-6 w-6'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                            </svg>
                        </span>
                    </div>
                    <div className='invisible absolute z-50 flex flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible w-80'>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/users'
                        >
                            Users
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/sessions'
                        >
                            Sessions
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/weekly-sessions'
                        >
                            Weekly sessions
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/user-actions'
                        >
                            User actions
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/user-interactions'
                        >
                            User interactions
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/search'
                        >
                            Search
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/pages'
                        >
                            Pages
                        </Link>
                        <Link
                            className='my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2'
                            to='/admin/stats/resources'
                        >
                            Resources
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Menu
