// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import User from './User'
import Tag from './Tag'
import CommentEmojiReact from './CommentEmojiReact'
import CommentReact from './CommentReact'
import { Notification } from '../notifications'
import { getUser } from '../auth/LoginState'
import { useUser } from '../../helpers/useUser'

interface C {
    user: any
    loggedInUser: any
    postId: string
    comment: any
    getPosts: () => void
}

const Comment: React.FC<C> = ({ user, loggedInUser, postId, comment, getPosts }) => {
    const usr = getUser()
    const USER = loggedInUser
    const itemUser = user
    const [hearYou, setHearYou] = useState<boolean>(false)
    const [hearYouCounter, setHearYouCounter] = useState<number>(0)
    const [supportYou, setSupportYou] = useState<boolean>(false)
    const [supportYouCounter, setSupportYouCounter] = useState<number>(0)
    const [happy, setHappy] = useState<boolean>(false)
    const [happyCounter, setHappyCounter] = useState<number>(0)
    const [sad, setSad] = useState<boolean>(false)
    const [sadCounter, setSadCounter] = useState<number>(0)
    const [proud, setProud] = useState<boolean>(false)
    const [proudCounter, setProudCounter] = useState<number>(0)
    const [laughter, setLaughter] = useState<boolean>(false)
    const [laughterCounter, setLaughterCounter] = useState<number>(0)

    const checkUserReactions = useCallback(() => {
        setHearYou(false)
        setSupportYou(false)
        setHappy(false)
        setSad(false)
        setProud(false)
        setLaughter(false)
        setHearYouCounter(comment.stats.counters.hear)
        setSupportYouCounter(comment.stats.counters.support)
        setHappyCounter(comment.stats.counters.happy)
        setSadCounter(comment.stats.counters.sad)
        setProudCounter(comment.stats.counters.proud)
        setLaughterCounter(comment.stats.counters.laughter)

        if (comment.reactions.length > 0) {
            comment.reactions.filter((comment) => {
                switch (comment.reaction) {
                    case 'hear':
                        if (comment.user === usr.id) {
                            setHearYou(true)
                        }
                        break
                    case 'support':
                        if (comment.user === usr.id) {
                            setSupportYou(true)
                        }
                        break
                    case 'happy':
                        if (comment.user === usr.id) {
                            setHappy(true)
                        }
                        break
                    case 'sad':
                        if (comment.user === usr.id) {
                            setSad(true)
                        }
                        break
                    case 'proud':
                        if (comment.user === usr.id) {
                            setProud(true)
                        }
                        break
                    case 'laughter':
                        if (comment.user === usr.id) {
                            setLaughter(true)
                        }
                        break

                    default:
                        break
                }

                return ''
            })
        }
    }, [comment.reactions, comment.stats.counters, usr.id])

    const deleteComment = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/deleteComment`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    postId: postId,
                    commentId: comment._id,
                },
            })

            if (res.status === 201) {
                getPosts()

                return Notification({
                    message: 'Comment hidden from Community Feed',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const showUser = function () {
        if (USER && itemUser && itemUser.following.muted && itemUser.following.muted.map((muted) => {return muted.user}).includes(USER._id)){
            return false
        }
        
        if (USER && itemUser && USER.following.muted && USER.following.muted.map((muted) => {return muted.user}).includes(itemUser._id)){
            return false
        } 

        return  true
    }

    useEffect(() => {
        checkUserReactions()
        
    }, [checkUserReactions])

    const getContent = function (content:string) {
        let ret = content.split(' ').map((word, i) => {
            if (word.startsWith('@')){
                var tag = word.slice(1)
                return <Tag user={tag} key={i}/>

            }

            return `${word} `
        })

        return <span>{ret.map(item => {return item})}</span>
    }

    return (
        <>
            {comment && USER && loggedInUser && (
                <div className='flex flex-col md:flex-row border-b-2 border-blue2 mb-6'>
                    <div className='md:w-1/5 py-2'>
                        <div className='flex flex-row md:hidden'>
                            <div className='w-5/12 border-r-2 border-blue-300'>
                                <User
                                    user={itemUser}
                                    loggedInUser={loggedInUser}
                                    allowFollow={false}
                                />
                            </div>
                            <div className='w-8/12 flex flex-row px-2 text-center'>
                                <CommentReact
                                    componentType='hear'
                                    type='hear'
                                    postId={postId}
                                    commentId={comment._id}
                                    hearYou={hearYou}
                                    hearYouCounter={hearYouCounter}
                                    supportYou={supportYou}
                                    supportYouCounter={supportYouCounter}
                                    getPosts={getPosts}
                                />
                                <CommentReact
                                    componentType='support'
                                    type='support'
                                    postId={postId}
                                    commentId={comment._id}
                                    hearYou={hearYou}
                                    hearYouCounter={hearYouCounter}
                                    supportYou={supportYou}
                                    supportYouCounter={supportYouCounter}
                                    getPosts={getPosts}
                                />
                                <CommentEmojiReact
                                    postId={postId}
                                    commentId={comment._id}
                                    happy={happy}
                                    happyCounter={happyCounter}
                                    sad={sad}
                                    sadCounter={sadCounter}
                                    proud={proud}
                                    proudCounter={proudCounter}
                                    laughter={laughter}
                                    laughterCounter={laughterCounter}
                                    getPosts={getPosts}
                                />
                            </div>
                        </div>
                        <div className='hidden md:flex md:flex-col justify-center items-center'>
                            <User
                                className={showUser()? 'hidden' : ''}
                                user={itemUser}
                                loggedInUser={loggedInUser}
                                allowFollow={false}
                            />
                        </div>
                    </div>

                    <div className='md:w-4/5 md:p-4'>
                        <div className='commentText'>{getContent(comment.content)}</div>
                        <div className='hidden md:flex md:flex-row justify-between md:justify-start my-6 items-center mr-4'>
                            <CommentReact
                                componentType='hear'
                                type='hear'
                                postId={postId}
                                commentId={comment._id}
                                hearYou={hearYou}
                                hearYouCounter={hearYouCounter}
                                supportYou={supportYou}
                                supportYouCounter={supportYouCounter}
                                getPosts={getPosts}
                            />
                            <CommentReact
                                componentType='support'
                                type='support'
                                postId={postId}
                                commentId={comment._id}
                                hearYou={hearYou}
                                hearYouCounter={hearYouCounter}
                                supportYou={supportYou}
                                supportYouCounter={supportYouCounter}
                                getPosts={getPosts}
                            />
                            <CommentEmojiReact
                                postId={postId}
                                commentId={comment._id}
                                happy={happy}
                                happyCounter={happyCounter}
                                sad={sad}
                                sadCounter={sadCounter}
                                proud={proud}
                                proudCounter={proudCounter}
                                laughter={laughter}
                                laughterCounter={laughterCounter}
                                getPosts={getPosts}
                            />
                        </div>

                        {usr.role === 'admin' && (
                            <div className='flex flex-row justify-end mb-4'>
                                <button
                                    type='button'
                                    className='flex items-center justify-center rounded-full bg-blue1 text-white px-4 py-2 text-xs font-bold'
                                    onClick={() => deleteComment()}
                                >
                                    Delete comment
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default Comment
