import React, { useState } from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import Masthead from '../layout/MastHead'
import Menu from '../admin/Menu'

const UsersStats: React.FC = () => {
    const [submitting, setSubmitting] = useState<boolean>(false)

    const getData = async () => {
        setSubmitting(true)

        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/stats/users`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {},
            })

            if (res.status === 200) {
                setSubmitting(false)
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')

                link.href = url
                link.setAttribute('download', 'users_data.csv')
                document.body.appendChild(link)
                link.click()
                if (link.parentNode) {
                    link.parentNode.removeChild(link)
                }
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>All Users</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                <p className='mt-4'>This page allows you to download a CSV export of all registered users.</p>

                <button
                    type='button'
                    className='mt-4 bg-green1 hover:bg-green2 text-white font-bold py-2 px-4 rounded'
                    disabled={submitting ? true : false}
                    onClick={() => getData()}
                >
                    Generate CSV
                </button>
            </div>
        </>
    )
}

export default UsersStats
