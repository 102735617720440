import React, { useState } from 'react'
import { Notification } from '../notifications'
import axios from 'axios'
import { getUser } from '../auth/LoginState'

type AP = {
    modal: (param: boolean) => void
    post: any
    getPosts: any
}

const DeletePost: React.FC<AP> = ({ modal, post, getPosts }) => {
    const usr = getUser()
    const [submitting, setSubmitting] = useState(false)

    const removePost = async (id: string) => {
        setSubmitting(true)

        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/remove`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {
                    pid: id,
                },
            })
            setSubmitting(false)

            if (res.status === 200) {
                getPosts()
                modal(false)

                return Notification({
                    message: 'Post successfully removed.',
                    type: 'warning',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
            <div className='flex items-center justify-end h-11'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => modal(false)}
                >
                    X
                </button>
            </div>

            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                <div className='p-2 md:p-0'>
                    <div className='bg-white max-w-4xl mx-auto rounded-xl p-2 md:p-6'>
                        {post ? (
                            <>
                                <div>
                                    <h2 className='text-5xl text-center font-cgbold mt-2'>Delete Post</h2>

                                    <div className='mt-4'>
                                        <h5 className='text-xl text-center font-cgbold mt-2'>
                                            Are you sure that you want to remove this post?
                                        </h5>
                                        <br />
                                        <strong>Title: </strong>
                                        {post.title}
                                        <br />

                                        {post.files ? (
                                            <>
                                                <br />
                                                <strong>Files: </strong>
                                                {post.files.map((i: any, c: number) => {
                                                    return <div key={c}>{i.name}</div>
                                                })}
                                            </>
                                        ) : null}
                                    </div>

                                    <div className='mt-4'>
                                        <div className='flex justify-between'>
                                            <button
                                                type='button'
                                                className='bg-red1 font-cgbold rounded-full py-2 px-6'
                                                onClick={() => removePost(post._id)}
                                            >
                                                {submitting && <div className='loading'></div>}
                                                Delete Post
                                            </button>
                                            <button
                                                type='button'
                                                className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                onClick={() => modal(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeletePost
