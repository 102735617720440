// @ts-nocheck
import React, { useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Notification } from '../notifications'
import { Alert } from 'grommet-icons'
import { DateTime } from 'luxon'
import User from '../community/User'
import EmojiReact from '../community/EmojiReact'
import PostReact from '../community/PostReact'
import CommentsHandler from '../community/CommentsHandler'
import Bookmark from '../community/Bookmark'
import Comments from '../community/Comments'
import { getUser } from '../auth/LoginState'
import FlaggedPostEdit from './FlaggedPostEdit'
import FlaggedPostDelete from './FlaggedPostDelete'
import FlaggedPostEscalate from './FlaggedPostEscalate'
import PostType from '../community/PostType'
import PostFeeling from '../community/PostFeeling'
import TextContent from '../community/TextContent'

const FlaggedPostReview = () => {
    const usr = getUser()
    const loc = useLocation()
    const nav = useNavigate()
    const articleId = loc.pathname.replace('/admin/posts/flagged/', '')
    const [data, setData] = useState<any>()
    const [user, setUser] = useState<any>()
    const [loggedInUser, setLoggedInUser] = useState<any>()
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
    const [showComments, setShowComments] = useState<boolean>(false)
    const [hearYou, setHearYou] = useState<boolean>(false)
    const [hearYouCounter, setHearYouCounter] = useState<number>(0)
    const [supportYou, setSupportYou] = useState<boolean>(false)
    const [supportYouCounter, setSupportYouCounter] = useState<number>(0)
    const [happy, setHappy] = useState<boolean>(false)
    const [happyCounter, setHappyCounter] = useState<number>(0)
    const [sad, setSad] = useState<boolean>(false)
    const [sadCounter, setSadCounter] = useState<number>(0)
    const [proud, setProud] = useState<boolean>(false)
    const [proudCounter, setProudCounter] = useState<number>(0)
    const [laughter, setLaughter] = useState<boolean>(false)
    const [laughterCounter, setLaughterCounter] = useState<number>(0)
    const [, setImg] = useState()
    const [flags, setFlags] = useState<array<any>>([])
    const [currentFlag, setCurrentFlag] = useState<any>([])
    const [flagSelected, setFlagSelected] = useState<string>('')
    const [editModal, setEditModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [escalateModal, setEscalateModal] = useState<boolean>(false)

    const checkUserBookmark = useCallback(
        (post: any) => {
            if (!post){
                return
            }  

            const bookmarks = post.stats.bookmarks

            if (bookmarks && bookmarks.length > 0) {
                bookmarks.filter((post) => {
                    if (post.user === usr.id) {
                        setIsBookmarked(true)
                    }

                    return null
                })
            }
        },
        [usr.id]
    )

    const checkUserReactions = useCallback(
        (post: any) => {
            setHearYou(false)
            setSupportYou(false)
            setHappy(false)
            setSad(false)
            setProud(false)
            setLaughter(false)
            setHearYouCounter(post.stats.counters.hear)
            setSupportYouCounter(post.stats.counters.support)
            setHappyCounter(post.stats.counters.happy)
            setSadCounter(post.stats.counters.sad)
            setProudCounter(post.stats.counters.proud)
            setLaughterCounter(post.stats.counters.laughter)

            if (post.stats.reactions.length > 0) {
                post.stats.reactions.filter((reaction) => {
                    switch (reaction.reaction) {
                        case 'hear':
                            if (reaction.user === usr.id) {
                                setHearYou(true)
                            }
                            break
                        case 'support':
                            if (reaction.user === usr.id) {
                                setSupportYou(true)
                            }
                            break
                        case 'happy':
                            if (reaction.user === usr.id) {
                                setHappy(true)
                            }
                            break
                        case 'sad':
                            if (reaction.user === usr.id) {
                                setSad(true)
                            }
                            break
                        case 'proud':
                            if (reaction.user === usr.id) {
                                setProud(true)
                            }
                            break
                        case 'laughter':
                            if (reaction.user === usr.id) {
                                setLaughter(true)
                            }
                            break

                        default:
                            break
                    }

                    return null
                })
            }
        },
        [usr.id]
    )

    const getPost = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/post/${articleId}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                let post = res.data.data
                setData(post)
                setUser(res.data.postUser)
                setLoggedInUser(res.data.user)
                fetchFeelingImage(post)
                checkUserBookmark(post)
                checkUserReactions(post)
                
                const flagSelected = post.moderation.reports.filter((flag: any) => {
                    return flag.resolved === false
                })

                setFlags(flagSelected)
                setCurrentFlag(flagSelected[0])
                setFlagSelected(flagSelected[0]._id)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [articleId, usr.token, checkUserBookmark, checkUserReactions])

    const fetchFeelingImage = async (post: any) => {
        try {
            if (post && post.feeling) {
                const img = await import(`../../assets/images/feeling/${post.feeling}.svg`)

                setImg(img.default)
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const approvePost = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/post/moderation/approve`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {
                    postId: data._id,
                    action: 'approve',
                },
            })

            if (res.status === 201) {
                nav('/admin/posts/flagged')

                return Notification({
                    message: 'Post successfully moderated.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        getPost()
    }, [setData, getPost])

    return (
        <>
            {data && (
                <div className='w-full flex flex-row'>
                    <div className='w-1/6 p-4 mt-20'>
                        <div
                            className='rounded-full py-2 px-6 bg-purple1 font-cgbold text-center cursor-pointer'
                            onClick={() => nav(-1)}
                        >
                            <span>Go back to Flagged posts</span>
                        </div>

                        <div className='moderations'>
                            <details>
                                <summary className='cursor-pointer mt-4'>Flag history</summary>
                                {flags &&
                                    flagSelected &&
                                    data.moderation.reports.map((flag: any, c: number) => {
                                        return (
                                            <div
                                                className={
                                                    flagSelected === flag._id
                                                        ? 'flex flex-col bg-slate-400 border border-blue3 rounded-lg my-2 p-2'
                                                        : 'flex flex-col border border-blue3 rounded-lg my-2 p-2'
                                                }
                                                key={c}
                                                onClick={() => {
                                                    setFlagSelected(flag._id)
                                                    setCurrentFlag(flag)
                                                }}
                                            >
                                                <div className='flex items-center justify-center border-b border-b-blue3 mb-4 w-full'>
                                                    <span className='mr-2'>Flagged by: </span>
                                                    <User
                                                        user={user}
                                                        allowFollow={false}
                                                        getLoggedInUser={getPost}
                                                    />
                                                </div>
                                                <div className='flex flex-row'>
                                                    Resolved:{' '}
                                                    <strong className='ml-2'>
                                                        {flag.resolved === true ? 'Resolved' : 'Not resolved'}
                                                    </strong>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </details>
                        </div>
                    </div>
                    <div className='w-5/6 mt-20 px-4'>
                        <div className='flex flex-col items-center border border-blue1 p-4'>
                            <div className='flex flex-row justify-between items-center w-full max-w-2xl mb-6'>
                                <button
                                    onClick={() => approvePost()}
                                    className='rounded-full bg-green1 px-4 py-2 text-white font-cgbold'
                                >
                                    Approve
                                </button>
                                <button
                                    onClick={() => setEditModal(true)}
                                    className='rounded-full bg-yellow1 px-4 py-2 font-cgbold'
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => setDeleteModal(true)}
                                    className='rounded-full bg-red1 px-4 py-2 font-cgbold'
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => setEscalateModal(true)}
                                    className='rounded-full bg-purple1 px-4 py-2 font-cgbold'
                                >
                                    ESCALATE
                                </button>
                            </div>
                            <div className='w-full text-left'>
                                <h1 className='font-cgbold text-xl'>Flag request</h1>
                                <span className='mt-4'>{currentFlag && currentFlag.description}</span>
                            </div>
                        </div>

                        <div className='flex flex-row border border-black/20 shadow-lg my-4 rounded-lg'>
                            <div className='flex flex-row justify-between items-center md:flex-col md:align-center md:items-center md:justify-start mr-3 border-b-2 md:border-b-transparent md:border-r-2 border-black/20 w-full md:w-1/6 p-2'>
                                <User 
                                    user={user} 
                                    loggedInUser={loggedInUser} 
                                    getLoggedInUser={getPost}
                                />
                                <div className='text-sm md:text-2xs md:mt-4 text-center'>
                                    <span>Posted: </span>
                                    <span>{DateTime.fromISO(data.dateCreated).toRelative()}</span>
                                </div>
                                <div className='text-sm md:text-2xs md:mt-4 text-center w-max'>
                                    <span>Last reply: </span>
                                    <span>
                                        {data &&
                                        data.comments &&
                                        data.comments.length &&
                                        data.comments[data.comments.length - 1] &&
                                        data.comments[data.comments.length - 1].dateCreated
                                            ? DateTime.fromISO(
                                                  data.comments[data.comments.length - 1].dateCreated
                                              ).toRelative()
                                            : '---'}
                                    </span>
                                </div>
                            </div>

                            <div className='w-full mt-3 md:mt-0 p-4'>
                                <div className='flex justify-start items-center'>
                                    <div className='flex flex-wrap w-11/12'>
                                        <PostType type={data? data.topic.name: null} />

                                        {data.feeling ? <PostFeeling feeling={data.feeling} /> : null}

                                        {data.profanity ? (
                                            <div className='flex items-center justify-center py-2 px-6 bg-blue1 rounded-full text-xs font-cgbold my-1'>
                                                <Alert
                                                    size='20'
                                                    color='white'
                                                />
                                                <span className='ml-2 text-white'>
                                                    This post might contain offensive language or sensitive content
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <h4
                                    className='text-2xl font-semibold cursor-pointer mt-6 mb-4 text-blue1'
                                    onClick={() => nav(`/community/article/${data._id}`)}
                                >
                                    {data.title}
                                </h4>

                                <TextContent item={data} />

                                {data.files && data.files.length > 0 ? (
                                    <div className='mt-6 border-t-2 border-b-2 border-t-black/20 py-6'>
                                        {data.files.map((i, c) => {
                                            return (
                                                <div key={c}>
                                                    <a
                                                        href={i.link}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        className='text-blue1 font-cgbold'
                                                    >
                                                        {i.name}
                                                    </a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}

                                <div className='mt-2 pt-6 flex flex-wrap'>
                                    {data.hashtags
                                        ? data.hashtags.map((h, c) => {
                                              return (
                                                  <div
                                                      className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4'
                                                      key={c}
                                                  >
                                                      {h}
                                                  </div>
                                              )
                                          })
                                        : null}
                                </div>

                                <div className='flex flex-row justify-between md:justify-start my-6 items-center mr-4'>
                                    <PostReact
                                        componentType='hear'
                                        type='hear'
                                        post={data._id}
                                        hearYou={hearYou}
                                        supportYou={supportYou}
                                        hearYouCounter={hearYouCounter}
                                        getPosts={getPost}
                                    />
                                    <PostReact
                                        componentType='support'
                                        type='support'
                                        post={data._id}
                                        hearYou={hearYou}
                                        supportYou={supportYou}
                                        supportYouCounter={supportYouCounter}
                                        getPosts={getPost}
                                    />
                                    <CommentsHandler
                                        showComments={showComments}
                                        setShowComments={setShowComments}
                                        count={data.numComments}
                                    />
                                    <EmojiReact
                                        post={data._id}
                                        happy={happy}
                                        happyCounter={happyCounter}
                                        sad={sad}
                                        sadCounter={sadCounter}
                                        proud={proud}
                                        proudCounter={proudCounter}
                                        laughter={laughter}
                                        laughterCounter={laughterCounter}
                                        getPosts={getPost}
                                    />
                                </div>

                                <div className='flex flex-row justify-center md:justify-end mt-8'>
                                    <Bookmark
                                        id={data._id}
                                        user={usr.token}
                                        isBookmarked={isBookmarked}
                                        getPosts={getPost}
                                    />
                                </div>

                                {showComments && (
                                    <div className='rounded-lg border-2 border-blue2 p-2 mt-2 bg-blue-50'>
                                        <Comments
                                            postId={data._id}
                                            comments={data.comments}
                                            getPosts={getPost}
                                            showComments={showComments}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {editModal && (
                <FlaggedPostEdit
                    post={data}
                    modal={setEditModal}
                />
            )}

            {deleteModal && (
                <FlaggedPostDelete
                    post={data}
                    modal={setDeleteModal}
                />
            )}

            {escalateModal && (
                <FlaggedPostEscalate
                    post={data}
                    modal={setEscalateModal}
                />
            )}
        </>
    )
}

export default FlaggedPostReview
