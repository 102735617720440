import React, { useState } from 'react'
import { Topic } from '../../types/Posts'

type C = {
    options: any
    category: string,
    setCategory: (param: string) => void
    getResources: (topic: string) => void
}

const Carousel: React.FC<C> = ({ options, category, setCategory, getResources }) => {
    const colours = ['#c1e4d1', '#e6abab', '#518d64', '#bfa1d6', '#fae89a']

    return (
        <div className='flex flex-wrap max-w-2xl mx-auto items-center justify-center'>
            {options.map((option: Topic, index: number) => {
                let colourIndex = 0

                colourIndex = index % colours.length

                return (
                    <div
                        key={index}
                        className='flex flex-wrap items-center justify-center m-2 p-4 rounded-full cursor-pointer'
                        style={{
                            backgroundColor: category === option.name ? '#2f6786' : colours[colourIndex],
                            color: category === option.name ? 'white' : 'black',
                        }}
                        onClick={() => {
                            getResources(option.name)
                        }}
                    >
                        <div
                            className='text-3xl font-cgbold'
                            style={{ color: category === option.name ? 'white' : 'black' }}
                        >
                            {option.resourceCount}
                        </div>
                        <div className='ml-2 font-cgbold'>{option.name}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default Carousel
