import axios from 'axios'
import { Notification } from '../components/notifications'

/**
 *
 * @param source page source from where the log is being called
 * @param interactionUser the user id that is being followed/blocked etc
 * @param type the type of interaction (follow, mute, profile-view, etc)
 * @param profileView boolean to check if the interaction is a profile view
 */
export const userInteractionLog = async (
    source: string,
    interactionUser: string,
    type: string,
    profileView: boolean
) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/api/stats/user-interaction`,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data: {
                source: source,
                interactionUser: interactionUser,
                type: type,
                profileView: profileView,
            },
        })

        if (res.status !== 200) {
            return Notification({
                message:
                    'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                type: 'warning',
            })
        }
    } catch (error: any) {
        return Notification({
            message: error.message ? error.message : error,
            type: 'danger',
        })
    }
}

/**
 *
 * @param source page source from where the log is being called
 * @return subpage string
 */
export const urlParser = (url: string) => {
    if (url.includes('user/profile')) {
        return 'User Profile'
    } else if (url.includes('account/profile')) {
        return 'Own Profile'
    } else if (url.includes('search')) {
        return 'Search Page'
    } else if (url.includes('article')) {
        return 'Article Page'
    } else if (url.includes('community')) {
        return 'Community Page'
    } else if (url.includes('resources')) {
        return 'Resources Page'
    } else if (url.includes('mygarden')) {
        return 'My Garden'
    } else {
        return 'Other'
    }
}

/**
 *
 * @param user user that is performing an action
 * @param context action source page (community, resources, other user profile, own profile, my garden)
 * @param postId post id that is being interacted with
 * @param type type of action (comment, reaction community post, delete post, hide post, save/unsave post, clicked notification)
 * @param reactions stats data related to reactions
 * @param comments comments data related to comments
 * @param flagged flagged data related to flagged posts
 * @param hidden hidden data related to hidden posts
 * @param notifications notifications data related to notifications
 * @param saved saved data related to saved posts
 */
export const userAction = async (
    user: string,
    content: string,
    postId: string,
    type: string,
    reactions: object = {},
    comments: object = {},
    flagged: object = {},
    hidden: object = {},
    notifications: object = {},
    saved: object = {},
) => {
    const currentUrl = window.location.href
    let source = ''

    if (type == 'notification') {
        source = 'Notification'
    } else if (currentUrl.includes('article')) {
        source = 'Article Page'
    } else if (currentUrl.includes('resource')) {
        source = 'Resources Page'
    } else if (currentUrl.includes('community')) {
        source = 'Community Page'
    } else if (currentUrl.includes('mygarden')) {
        source = 'My Garden'
    } else {
        source = 'NA'
    }

    try {
        const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/api/stats/user-action`,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data: {
                user: user,
                content: content,
                context: source,
                postId: postId,
                type: type,
                reactions: reactions ? reactions : {},
                comments: comments ? comments : {},
                flagged: flagged ? flagged : {},
                hidden: hidden ? hidden : {},
                notifications: notifications ? notifications : {},
                saved: saved ? saved : {},
            },
        })

        if (res.status !== 200) {
            return Notification({
                message:
                    'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                type: 'warning',
            })
        }
    } catch (error: any) {
        return Notification({
            message: error.message ? error.message : error,
            type: 'danger',
        })
    }
}


export const logResourceAction = async (
    user: any,
    itemId: any,
    action: string, 
    reaction?: string,
    commentId?: string, 
    commentLength?: Int16Array
) => {
    const currentUrl = window.location.href
    let source = ''

    if (currentUrl.includes('article')) {
        source = 'Article Page'
    } else if (currentUrl.includes('resource')) {
        source = 'Resources Page'
    } else if (currentUrl.includes('mygarden')) {
        source = 'My Garden'
    } else {
        source = 'NA'
    }

    try {
        const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/api/stats/resourcelog`,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data: {
                user: user.id,
                source: source,
                resourceId: itemId,
                action: action,
                reaction: reaction,
                commentId: commentId,
                commentLength: commentLength,
            },
        })

        if (res.status !== 200) {
            return Notification({
                message:
                    'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                type: 'warning',
            })
        }
    } catch (error: any) {
        return Notification({
            message: error.message ? error.message : error,
            type: 'danger',
        })
    }
}
