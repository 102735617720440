import React from 'react'
import bg_desktop_left from '../../assets/images/bg_desktop_left.svg'
import bg_desktop_right from '../../assets/images/bg_desktop_right.svg'

const Masthead: React.FC = () => {
    return (
        <section className='min-h-min relative'>
            <img
                src={bg_desktop_left}
                className='absolute top-0 left-0 w-1/2 md:w-1/6 -z-10'
                alt=''
            />
            <img
                src={bg_desktop_right}
                className='absolute top-0 right-0 w-1/3 md:w-1/6 -z-10'
                alt=''
            />
        </section>
    )
}

export default Masthead
