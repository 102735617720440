import React from 'react'

type PT = {
    type: string
    updateFilter?: (type: String) => void
}

const PostType: React.FC<PT> = ({ type, updateFilter = () => {} }) => {

    const filter = () => {
        
        updateFilter(type)
        
    }

    return (
        <div className='my-1 cursor-pointer' onClick={filter}>
            {type === 'thought' && (
                <div className='rounded-full font-cgbold text-sm py-2 px-6 bg-green2 mr-2'>Thought</div>
            )}
            {type === 'knowledge' && (
                <div className='rounded-full font-cgbold text-sm py-2 px-6 bg-red2 mr-2'>Knowledge</div>
            )}
            {type === 'question' && (
                <div className='rounded-full font-cgbold text-sm py-2 px-6 bg-purple2 mr-2'>Question</div>
            )}
            {type === 'feeling' && <div className='rounded-full font-cgbold text-sm py-2 px-6 bg-blue2 mr-2'>Feeling</div>}
        </div>
    )
}

export default PostType
