// @ts-nocheck

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Notification } from '../notifications'
import { StatusCritical, StatusGood, FormView, Hide } from 'grommet-icons'
import { accountService } from '../../services'
import Masthead from '../layout/MastHead'

const Register: React.FC = () => {
    const nav = useNavigate()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
    const [genderOther, setGenderOther] = useState<string>('')
    const [ethnicityOther, setEthnicityOther] = useState<string>('')
    const [passCorrect, setPassCorrect] = useState<boolean>(false)
    const [passLength, setPassLength] = useState<boolean>(false)
    const [passUppercase, setPassUppercase] = useState<boolean>(false)
    const [passLowercase, setPassLowercase] = useState<boolean>(false)
    const [passNumbers, setPassNumbers] = useState<boolean>(false)
    const [passSpecial, setPassSpecial] = useState<boolean>(false)
    const [passSpecialChars, setPassSpecialChars] = useState([])
    const maxYear = parseInt(moment().subtract(18, 'years').format('YYYY'))
    const mobileRegex = /^((\+44|0)7\d{3}\s?\d{6})$/
    const usernameRegEx = /^[A-Za-z0-9]*$/

    const initialValues = {
        check1: false,
        check2: false,
        check3: false,
        check4: false,
        check5: false,
        check6: false,
        check7: false,
        confirmPassword: '',
        email: '',
        ethnicity: '',
        firstName: '',
        gender: '',
        healthConditions: '',
        mobileNumber: '',
        password: '',
        surname: '',
        username: '',
        yBirth: '',
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        surname: Yup.string().required('Surname is required'),
        username: Yup.string()
            .required('Username is required')
            .matches(usernameRegEx, 'Username must not contain special characters or spaces'),
        email: Yup.string().email('Email is invalid').required('Email is required'),
        gender: Yup.string().required('Gender is required'),
        ethnicity: Yup.string().required('Ethnicity is required'),
        yBirth: Yup.string()
            .required('Year of your birth is required')
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(4, 'Must be 4 digits')
            .max(4, 'Must be 4 digits')
            .test('date-range', 'Your year of birth is invalid', (val) => {
                return parseInt(val) > 1900 && parseInt(val) <= maxYear
            }),
        password: Yup.string()
            .required('Password is required')
            .test('pass-correct', 'Please make a password that meets the criteria listed below', () => {
                if (passSpecialChars && passSpecialChars.length >= 2) {
                    return true
                } else {
                    return false
                }
            }),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'Your passwords do not match'),
        mobileNumber: Yup.string()
            .required('Mobile number is required')
            .matches(mobileRegex, 'Mobile number not valid'),
        check1: Yup.bool().oneOf([true], 'You must agree to the above statement to create your CommonGround account.'),
        check2: Yup.bool().oneOf([true], 'You must agree to the above statement to create your CommonGround account.'),
        check3: Yup.bool().oneOf([true], 'You must agree to the above statement to create your CommonGround account.'),
        check4: Yup.bool().oneOf([true], 'You must agree to the above statement to create your CommonGround account.'),
        check5: Yup.bool().oneOf([true], 'You must agree to the above statement to create your CommonGround account.'),
        check6: Yup.bool().oneOf([true], 'You must agree to the above statement to create your CommonGround account.'),
        check7: Yup.bool().oneOf([true], 'You must agree to the above statement to create your CommonGround account.'),
    })

    const updateEthinicityOther = (val: string) => {
        setEthnicityOther(val)
    }

    const updateGenderOther = (val: string) => {
        setGenderOther(val)
    }

    const onSubmit = (fields: any, { setStatus, setSubmitting }: { setStatus: void; setSubmitting: void }) => {
        setStatus()
        const reg = /^[a-zA-Z\s]*$/

        if (genderOther !== '' && genderOther.match(reg)) {
            fields.genderOther = genderOther
        }

        if (ethnicityOther !== '' && ethnicityOther.match(reg)) {
            fields.ethnicityOther = ethnicityOther
        }

        accountService
            .register(fields)
            .then((response) => {
                nav('/account/profile-setup', {
                    state: {
                        user: response.user,
                    },
                })
            })
            .catch((error) => {
                setSubmitting(false)

                return Notification({ message: error, type: 'danger' })
            })

        setSubmitting(false)
    }

    useEffect(() => {
        // force window to scroll to the top
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Masthead />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className='container mx-auto w-full max-w-5xl mt-16 text-center'>
                            <h2 className='text-5xl font-cgbold mt-20'>Create account</h2>

                            <div className='bg-white/60 backdrop-blur-sm p-4 my-4'>
                                <p>
                                    To create an account, please provide the following details. Other community members
                                    on CommonGround will not be able to see your personal information. Only your
                                    username will be visible to other community members. You can choose to display your
                                    gender or pronouns once your account is approved. Please note, our moderators can
                                    access your contact details that you provide to privately contact you if required.
                                    You can read our full moderation policy{' '}
                                    <a
                                        href='moderation-policy'
                                        className='font-cgbold text-blue1'
                                        title='Moderation Policy'
                                    >
                                        here
                                    </a>
                                    .
                                </p>
                                <p className='mt-4'>
                                    CommonGround is a platform co-produced by people with long-term health conditions,
                                    academic and clinical researchers from King's College London, and software
                                    developers from bitJam (and previously TOAD).
                                </p>
                            </div>

                            <div className='w-full mt-10 pb-12 p-2 text-left container md:max-w-xl md:mx-auto'>
                                <div>
                                    <label className='block font-cgbold leading-6'>First name</label>
                                    <Field
                                        type='text'
                                        name='firstName'
                                        placeholder='Enter your first name'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='firstName'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>Surname</label>
                                    <Field
                                        type='text'
                                        name='surname'
                                        placeholder='Enter your last name'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='surname'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>
                                        Username
                                        <br />
                                        <span className=' text-gray-700'>
                                            Please create a username for CommonGround. Do{' '}
                                            <span className='font-cgbold underline'>not</span> use your real name - you
                                            must be <span className='font-cgbold underline'>anonymous</span>! All
                                            usernames will be checked by our team before approval.
                                        </span>
                                    </label>
                                    <Field
                                        type='text'
                                        name='username'
                                        placeholder='Enter your Username'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='username'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>
                                        Email
                                        <br />
                                        <span className='text-s text-gray-700'>
                                            Please use the email address you have provided the research team with before
                                            - this allows the research team to easily approve your account.
                                        </span>
                                    </label>
                                    <Field
                                        type='email'
                                        name='email'
                                        placeholder='Enter your Email'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='email'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>Gender</label>
                                    <Field
                                        component='select'
                                        name='gender'
                                        placeholder='Select your Gender'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                        onChange={(e) => {
                                            setFieldValue('gender', e.target.value)

                                            if (e.target.value === 'Prefer to self-describe') {
                                                document.getElementById('genderOther').classList.remove('hidden')
                                            } else {
                                                document.getElementById('genderOther').classList.add('hidden')
                                            }
                                        }}
                                    >
                                        <option value=''>Please select an option</option>
                                        <option value='Prefer not to say'>Prefer not to say</option>
                                        <option value='Female, including transgender females'>
                                            Female, including transgender females
                                        </option>
                                        <option value='Male, including transgender males'>
                                            Male, including transgender males
                                        </option>
                                        <option value='Non-binary'>Non-binary</option>
                                        <option value='Prefer to self-describe'>Prefer to self-describe</option>
                                    </Field>
                                    <ErrorMessage
                                        name='gender'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div
                                    id='genderOther'
                                    className='hidden mt-6'
                                >
                                    <label className='block font-cgbold leading-6'>Self describe Gender</label>
                                    <Field
                                        name='genderOther'
                                        placeholder='Describe your Gender'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                        onChange={(e) => {
                                            updateGenderOther(e.target.value)
                                        }}
                                    ></Field>
                                    <ErrorMessage
                                        name='genderOther'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>Ethnicity</label>
                                    <Field
                                        component='select'
                                        name='ethnicity'
                                        placeholder='Select your Ethnicity'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                        onChange={(e) => {
                                            setFieldValue('ethnicity', e.target.value)

                                            if (e.target.value === 'Any other ethnic group') {
                                                document.getElementById('ethnicityOther').classList.remove('hidden')
                                            } else {
                                                document.getElementById('ethnicityOther').classList.add('hidden')
                                            }
                                        }}
                                    >
                                        <option value=''>Please select an option</option>
                                        <option value='Prefer not to say'>Prefer not to say</option>
                                        <optgroup label='Asian or Asian British'>
                                            <option value='Indian'>Indian</option>
                                            <option value='Pakistani'>Pakistani</option>
                                            <option value='Bangladeshi'>Bangladeshi</option>
                                            <option value='Chinese'>Chinese</option>
                                            <option value='Any other Asian background'>
                                                Any other Asian background
                                            </option>
                                        </optgroup>
                                        <optgroup label='Black, Black British, Caribbean or African'>
                                            <option value='Caribbean'>Caribbean</option>
                                            <option value='African'>African</option>
                                            <option value='Any other Black, Black British, or Caribbean background'>
                                                Any other Black, Black British, or Caribbean background
                                            </option>
                                        </optgroup>
                                        <optgroup label='Mixed or multiple ethnic groups'>
                                            <option value='White and Black Caribbean'>White and Black Caribbean</option>
                                            <option value='White and Black African'>White and Black African</option>
                                            <option value='White and Asian'>White and Asian</option>
                                            <option value='Any other Mixed or multiple ethnic background'>
                                                Any other Mixed or multiple ethnic background
                                            </option>
                                        </optgroup>
                                        <optgroup label='White'>
                                            <option value='English, Welsh, Scottish, Northern Irish or British'>
                                                English, Welsh, Scottish, Northern Irish or British
                                            </option>
                                            <option value='Irish'>Irish</option>
                                            <option value='Gypsy or Irish Traveller'>Gypsy or Irish Traveller</option>
                                            <option value='Roma'>Roma</option>
                                            <option value='Any other White background'>
                                                Any other White background
                                            </option>
                                        </optgroup>
                                        <optgroup label='Other ethnic group'>
                                            <option value='Arab'>Arab</option>
                                            <option value='Any other ethnic group'>Any other ethnic group</option>
                                        </optgroup>
                                    </Field>
                                    <ErrorMessage
                                        name='ethnicity'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div
                                    id='ethnicityOther'
                                    className='hidden mt-6'
                                >
                                    <label className='block font-cgbold leading-6'>Self describe Ethnicity</label>
                                    <Field
                                        name='ethnicityOther'
                                        placeholder='Describe your Ethnicity'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                        onChange={(e) => {
                                            updateEthinicityOther(e.target.value)
                                        }}
                                    ></Field>
                                    <ErrorMessage
                                        name='ethnicityOther'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>
                                        Year of birth
                                        <br />
                                        <span className=' text-gray-700'>
                                            You must be at least 18 years old to join our community
                                        </span>
                                    </label>
                                    <Field
                                        type='text'
                                        name='yBirth'
                                        placeholder='Enter your birth year'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='yBirth'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>
                                        Mobile number
                                        <br />
                                        <span className='text-s text-gray-700'>
                                            Your mobile number is required for two-factor authentication to keep your
                                            account secure. Your contact details will not be shared with anyone outside
                                            of the research and software developer teams.
                                        </span>
                                    </label>
                                    <Field
                                        type='text'
                                        name='mobileNumber'
                                        placeholder='Enter your mobile number'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='mobileNumber'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>
                                        Diagnosed long-term health condition(s) (optional)
                                    </label>
                                    <Field
                                        component='textarea'
                                        name='healthConditions'
                                        rows='6'
                                        placeholder='Here you can talk about the long-term health condition(s) that you are living with. This information is not displayed on your profile.'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='healthConditions'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>
                                        Password
                                        <br />
                                        <span className=' text-gray-700'>
                                            Please create your password. It must meet the criteria listed below. Please
                                            keep your password safe and never share it with anyone.
                                        </span>
                                    </label>
                                    <div className='flex items-center'>
                                        <Field
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            placeholder='Type password here'
                                            className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                            onChange={(e) => {
                                                let v = e.target.value,
                                                    upperCount = v.replace(/[^A-Z]/g, '').length,
                                                    lowerCount = v.replace(/[^a-z]/g, '').length,
                                                    digitCount = v.replace(/\D/g, '').length,
                                                    special = /[!@#$£%^&*(),.?":{}|<>]/g

                                                setFieldValue('password', v)

                                                if (v.length >= 9) {
                                                    setPassLength(true)
                                                } else {
                                                    setPassLength(false)
                                                }

                                                if (upperCount >= 2) {
                                                    setPassUppercase(true)
                                                } else {
                                                    setPassUppercase(false)
                                                }

                                                if (lowerCount >= 2) {
                                                    setPassLowercase(true)
                                                } else {
                                                    setPassLowercase(false)
                                                }

                                                if (digitCount >= 2) {
                                                    setPassNumbers(true)
                                                } else {
                                                    setPassNumbers(false)
                                                }

                                                // test if password contains 2 special characters
                                                let specialCharCount = (v.match(special) || []).length
                                                setPassSpecialChars(v.match(special))

                                                if (specialCharCount >= 2) {
                                                    setPassSpecial(true)
                                                } else {
                                                    setPassSpecial(false)
                                                }

                                                if (
                                                    passLength &&
                                                    passUppercase &&
                                                    passLowercase &&
                                                    specialCharCount >= 2 &&
                                                    passNumbers
                                                ) {
                                                    setPassCorrect(true)
                                                } else {
                                                    setPassCorrect(false)
                                                }
                                            }}
                                        />
                                        <div
                                            className='ml-2 cursor-pointer'
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Hide size='30' /> : <FormView size='30' />}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name='password'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>Confirm password</label>
                                    <div className='flex items-center'>
                                        <Field
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name='confirmPassword'
                                            placeholder='Retype password here'
                                            className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:leading-6'
                                        />
                                        <div
                                            className='ml-2 cursor-pointer'
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? <Hide size='30' /> : <FormView size='30' />}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name='confirmPassword'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-4'>
                                    <ul>
                                        <li>
                                            {passLength ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Be a minimum of eight characters long
                                        </li>
                                        <li>
                                            {passUppercase ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contain at least two upper case letters
                                        </li>
                                        <li>
                                            {passLowercase ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contain at least two lower case letters
                                        </li>
                                        <li>
                                            {passNumbers ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contain at least two numbers
                                        </li>
                                        <li>
                                            {passSpecial ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contains at least two special characters, such as ! £ $ % & * @
                                        </li>
                                    </ul>
                                </div>
                                <hr className='mt-2 mb-5' />

                                <div className='mb-6 p-2 text-left'>
                                    <label className='block'>
                                        <Field
                                            className='mr-2 leading-tight w-5 h-5'
                                            name='check1'
                                            id='check1'
                                            type='checkbox'
                                        />
                                        <span>
                                            {' '}
                                            I understand that my posts, comments, and interactions on the platform will
                                            be visible to the Kings College London research team, bitJam (software
                                            developers), and other community members using the platform.
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        name='check1'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                    <br />
                                    <label className='block'>
                                        <Field
                                            className='mr-2 leading-tight w-5 h-5'
                                            name='check2'
                                            id='check2'
                                            type='checkbox'
                                        />
                                        <span>
                                            {' '}
                                            I understand that the CommonGround team, BitJam (software developers) and
                                            moderators of CommonGround can see the personal information I have provided
                                            and can contact me if necessary.
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        name='check2'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                    <br />
                                    <label className='block'>
                                        <Field
                                            className='mr-2 leading-tight w-5 h-5'
                                            name='check3'
                                            id='check3'
                                            type='checkbox'
                                        />
                                        <span>
                                            {' '}
                                            I understand that CommonGround is moderated in accordance with the{' '}
                                            <a
                                                href='/moderation-policy'
                                                target='_blank'
                                                title='Moderation Policy'
                                                rel='noopener noreferrer'
                                                className='font-cgbold text-blue1'
                                            >
                                                Moderation Policy
                                            </a>{' '}
                                            and King's College London safeguarding procedures regarding criminal
                                            offences and the safety of children and vulnerable adults.
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        name='check3'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                    <br />
                                    <label className='block'>
                                        <Field
                                            className='mr-2 leading-tight w-5 h-5'
                                            name='check4'
                                            id='check4'
                                            type='checkbox'
                                        />
                                        <span>
                                            {' '}
                                            I agree to the terms and conditions of the
                                            <a
                                                href='/moderation-policy'
                                                target='_blank'
                                                title='Moderation Policy'
                                                rel='noopener noreferrer'
                                                className='font-cgbold text-blue1'
                                            >
                                                {' '}
                                                Moderation Policy
                                            </a>{' '}
                                            and
                                            <a
                                                href='/moderation-policy'
                                                target='_blank'
                                                title='Moderation Policy'
                                                rel='noopener noreferrer'
                                                className='font-cgbold text-blue1'
                                            >
                                                {' '}
                                                Safeguarding Policy
                                            </a>{' '}
                                            .
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        name='check4'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                    <br />
                                    <label className='block'>
                                        <Field
                                            className='mr-2 leading-tight w-5 h-5'
                                            name='check5'
                                            id='check5'
                                            type='checkbox'
                                        />
                                        <span>
                                            {' '}
                                            I understand that CommonGround does not provide one-to-one crisis support 
                                            and that the platform is not monitored 24/7. Our "Further Support and Crisis 
                                            Page" contains contact information of organisations who are available to provide 
                                            this support.
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        name='check5'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                    <br />
                                    <label className='block'>
                                        <Field
                                            className='mr-2 leading-tight w-5 h-5'
                                            name='check6'
                                            id='check6'
                                            type='checkbox'
                                        />
                                        <span>
                                            {' '}
                                            I consent to the two-factor authentication log in process (i.e., I consent
                                            to my mobile number provided being used to receive a four-digit code to
                                            complete login). You can read more about two-factor authentication{' '}
                                            <a
                                                href='https://www.ncsc.gov.uk/guidance/setting-2-step-verification-2sv'
                                                target='_blank'
                                                title='Moderation Policy'
                                                rel='noopener noreferrer'
                                                className='font-cgbold text-blue1'
                                            >
                                                here
                                            </a>{' '}
                                            .
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        name='check6'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                    <br />
                                    <label className='block'>
                                        <Field
                                            className='mr-2 leading-tight w-5 h-5'
                                            name='check7'
                                            id='check7'
                                            type='checkbox'
                                        />
                                        <span>
                                            {' '}
                                            I am happy for my information and data to be stored in a secure database.
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        name='check7'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <p className='mt-6 text-left'>
                                    We will keep this information confidential and not pass on your details to anyone
                                    without your permission outside of the team.
                                </p>
                                <span>
                                    <button
                                        type='submit'
                                        className='my-5 rounded-2xl bg-green1 py-2 px-6 text-white font-cgbold'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting && <div className='loading' />}
                                        Create Account
                                    </button>
                                </span>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default Register
