import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import { Topic } from '../../types/Posts'
import AddResourceTopic from './AddResourceTopic'
import { getUser } from '../auth/LoginState'

const ResourceTopics = () => {
    const USER = getUser()
    const [topics, setTopics] = useState<Array<Topic>>([])
    const [modal, setModal] = useState(false)

    const getTopics = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/resource/topics`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setTopics(res.data.topics)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const removeTopic = async (id: string) => {
        const res = await axios({
            method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/resource/topic/delete`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {
                    id: id
                },
        })
        if (res.status === 200){
            getTopics()
            return Notification({
                message:
                    'You have removed a topic',
                type: 'success',
            })
        } else {
            return Notification({
                message:
                    'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                type: 'warning',
            })
        }
    }

    // const editTopic = (key: number) => {

    // }

    // const submitEditTopic = async (id: string) => {

    // }

    useEffect(() => {
        getTopics()
    }, [setTopics])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Resource Topics</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                {USER.role === 'admin' && (
                    <button
                        type='button'
                        className='rounded-full bg-blue1 text-white px-4 py-2 m-2 hover:bg-blue2 font-cgbold'
                        disabled={false}
                        onClick={() => {
                            setModal(true)
                        }}
                    >
                        Add Topic
                    </button>
                )}

                {topics && topics.length > 0 ? (
                    <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full'>
                        <thead>
                            <tr className='bg-blue2'>
                                <th className='py-2 px-4'>#</th>
                                <th className='py-2 px-4'>Topic</th>
                                <th className='py-2 px-4'>Description</th>
                                <th className='py-2 px-4'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topics &&
                                topics.map((topic: Topic, c: number) => {
                                    return (
                                        <tr
                                            className='border-b border-black/20 hover:bg-blue-100'
                                            key={c}
                                        >
                                            <td className='py-2 px-4 text-center'>{c + 1}</td>
                                            <td className='py-2 px-4' id="name">{topic.name}</td>
                                            <td className='py-2 px-4' id="description">{topic.description}</td>
                                            <td className='py-2 px-4 text-center'>
                                                <button
                                                    className='bg-blue1 text-white p-2 rounded-xl text-center' 
                                                    onClick={() => {removeTopic(topic._id)}}>
                                                    delete
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                ) : (
                    <div className='text-center font-cgbold'>No topics found</div>
                )}
            </div>

            {modal ? (
                <AddResourceTopic
                    modal={setModal}
                    loadTopics={getTopics}
                />
            ) : null}
        </>
    )
}

export default ResourceTopics
