import { BehaviorSubject } from 'rxjs'
import { fetchWrapper } from '../helpers'

const resourcesSubject = new BehaviorSubject([])
const baseUrl = `${process.env.REACT_APP_SERVER}/api/resource`

const create = (params, file) => {
	return fetchWrapper.upload(baseUrl, params, file)
}

const getAll = (pageSize, page, params) => {
	let url = `${baseUrl}?pageSize=${pageSize}&page=${page}`
	if (params) {
		for (let key in params) {
			url += `&${key}=${params[key]}`
		}
	}

	return fetchWrapper.get(url)
}

const getById = (id) => {
	return fetchWrapper.get(`${baseUrl}/${id}`)
}

const getTopics = () => {
	return fetchWrapper.get(`${baseUrl}/topics`)
}

const bookmark = (id) => {
	return fetchWrapper.post(`${baseUrl}/${id}/bookmark`)
}

const reaction = (id, type, commentId) => {
	if (!commentId || commentId === '' || commentId === '0') {
		return fetchWrapper.post(`${baseUrl}/${id}/reaction`, { type })
	} else {
		return fetchWrapper.post(
			`${baseUrl}/${id}/comment/${commentId}/reaction`,
			{ type }
		)
	}
}

const comments = (id, pageSize, page) => {
	return fetchWrapper.get(
		`${baseUrl}/${id}/comments?pageSize=${pageSize}&page=${page}`
	)
}

const comment = (id, commentId, comment) => {
	return fetchWrapper.post(`${baseUrl}/${id}/comment/${commentId}`, {
		comment,
	})
}

const commentReaction = (id, commentId, type) => {
	return fetchWrapper.post(
		`${baseUrl}/${id}/comment/${commentId}/reaction/${type}`
	)
}

const _delete = (id) => {
	return fetchWrapper.delete(`${baseUrl}/${id}`)
}

export const resourceService = {
	getAll,
	getById,
	getTopics,
	create,
	bookmark: bookmark,
	reaction: reaction,
	comments: comments,
	comment: comment,
	commentReaction: commentReaction,
	delete: _delete,
	resources: resourcesSubject.asObservable(),

	itemDetails(id) {
		const items = resourcesSubject.value
		for (const item of items) {
			if (item.id && item.id === id) {
				return item
			}
		}
		return null
	},

	addItem(data) {
		let items = resourcesSubject.value
		let found =
			items
				.map(function (item) {
					return item.id
				})
				.indexOf(data.id) > -1
		if (!found) {
			items.push(data)
			resourcesSubject.next(items)
		}
	},

	updateItem(id, data) {
		let items = resourcesSubject.value
		for (var i = 0; i < items.length; i++) {
			if (items[i].id === id) {
				for (var field in data) {
					if (Object.prototype.hasOwnProperty.call(data, field)) {
						items[i][field] = data[field]
					}
				}
			}
		}
		resourcesSubject.next(items)
		return items
	},

	removeItem(id) {
		let items = resourcesSubject.value
		let arr = items.filter(function (item) {
			return item.id !== id
		})
		resourcesSubject.next(arr)
		return items
	},

	clearItems(id) {
		return resourcesSubject.next([])
	},

	addComments(id, data) {
		let items = resourcesSubject.value
		for (var i = 0; i < items.length; i++) {
			if (items[i].id === id) {
				//if (items[i].comments.length === 0) {
				//  items[i].comments = [...data, ...items[i].comments];
				//}
				if (items[i].comments.length === 0) {
					items[i].comments = data
				} else {
					//TODO: CHECK THIS ONE OUT AS parentId is not declared initially, forcing false
					let parentId = false

					let comments = items[i].comments
					for (var j = 0; j < data.length; j++) {
						if (items[i].comments[j].parentId === parentId) {
							let found = false
							for (var k = 0; k < comments.length; k++) {
								if (comments[k].id === data[j].id) found = true
							}
							if (!found) comments.push(data[j])
						}
					}

					items[i].comments = comments
				}
			}
		}
		resourcesSubject.next(items)
		return items
	},

	addComment(id, parentId, comment, numComments) {
		//console.log('addComment')
		//console.log('id', id)
		//console.log('parentId', parentId)
		//console.log('comment', comment)
		//console.log('numComments', numComments)

		const insert = (arr, index, item) => [
			...arr.slice(0, index),
			item,
			...arr.slice(index),
		]

		let items = resourcesSubject.value
		for (var i = 0; i < items.length; i++) {
			if (items[i].id === id) {
				if (parentId === '0') {
					items[i].comments.push(comment)
				} else {
					// Comments are already flattened, so we only need to insert below the if in the array
					let comments = []
					let pos = -1
					for (var j = 0; j < items[i].comments.length; j++) {
						if (items[i].comments[j].parentId === parentId) pos = j
					}
					//console.log('pos', items[i].comments, items[i].comments.length, pos, comment);//return

					if (pos === -1) {
						comments = items[i].comments
						comments.push(comment)
					} else {
						comments = insert(items[i].comments, pos + 1, comment)
					}
					items[i].comments = comments
				}
				items[i].numComments = numComments
			}
		}
		resourcesSubject.next(items)
		return items
	},

	commentDetails(id, commentId) {
		const items = resourcesSubject.value
		for (const item of items) {
			if (item.id && item.id === id) {
				for (const comment of item.comments) {
					if (comment.id && comment.id === commentId) {
						return comment
					}
				}
			}
		}
		return null
	},
}
