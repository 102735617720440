// @ts-nocheck

import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import axios from 'axios'
import { Notification } from '../notifications'
import { StatusCritical, StatusGood, FormView, Hide } from 'grommet-icons'
import * as Yup from 'yup'
import Masthead from '../layout/MastHead'

interface MsgData {
    uid: string
}

const NewPassword = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
    const location = useLocation().state as MsgData
    const nav = useNavigate()
    const [passCorrect, setPassCorrect] = useState<boolean>(false)
    const [passLength, setPassLength] = useState<boolean>(false)
    const [passUppercase, setPassUppercase] = useState<boolean>(false)
    const [passLowercase, setPassLowercase] = useState<boolean>(false)
    const [passNumbers, setPassNumbers] = useState<boolean>(false)
    const [passSpecial, setPassSpecial] = useState<boolean>(false)
    const [passSpecialChars, setPassSpecialChars] = useState([])

    const initialValues = {
        password: '',
        confirmPassword: '',
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .test('pass-correct', 'Please apply password requirements below', (val) => {
                return passCorrect
            }),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    })

    const onSubmit = async ({ password }: { password: string }, { setSubmitting }: { setSubmitting: any }) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/account/new-password`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    uid: location.uid,
                    password: password,
                },
            })

            if (res.status === 200) {
                const user = {
                    id: res.data.uid,
                    username: res.data.username,
                    avatar: res.data.avatar,
                    role: res.data.role,
                    ts: new Date(),
                }

                window.localStorage.setItem('CGUSER', JSON.stringify(user))

                nav('/community')

                return Notification({ status: 'success', message: 'Your password has been updated' })
            }
        } catch (error) {
            setSubmitting(false)

            return Notification({ status: 'error', message: error })
        }
    }

    return (
        <>
            <Masthead />

            <div
                id='passwordreset'
                className='container h-screen mx-auto w-full max-w-xl mt-16 text-center'
            >
                <h2 className='text-5xl font-cgbold mt-20'>Password Reset</h2>

                <div className='bg-white/60 backdrop-blur-sm p-4 my-4'>
                    You previously requested a password reset, so you need to set a new password now.
                </div>

                <div className='bg-white/60 backdrop-blur-sm border border-black/6 shadow-2xl rounded-lg px-8 pt-6 pb-8 my-16 text-left'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form>
                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>
                                        Password
                                        <br />
                                        <span className='text-xs text-gray-700'>
                                            Please create your password. It must meet the criteria listed below. Please
                                            keep your password safe and never share it with anyone.
                                        </span>
                                    </label>
                                    <div className='flex items-center'>
                                        <Field
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            placeholder='Type password here'
                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            onChange={(e: any) => {
                                                let v = e.target.value,
                                                    upperCount = v.replace(/[^A-Z]/g, '').length,
                                                    lowerCount = v.replace(/[^a-z]/g, '').length,
                                                    digitCount = v.replace(/\D/g, '').length,
                                                    special = /[!@#$£%^&*(),.?":{}|<>]/g

                                                setFieldValue('password', v)

                                                if (v.length >= 9) {
                                                    setPassLength(true)
                                                } else {
                                                    setPassLength(false)
                                                }

                                                if (upperCount >= 2) {
                                                    setPassUppercase(true)
                                                } else {
                                                    setPassUppercase(false)
                                                }

                                                if (lowerCount >= 2) {
                                                    setPassLowercase(true)
                                                } else {
                                                    setPassLowercase(false)
                                                }

                                                if (digitCount >= 2) {
                                                    setPassNumbers(true)
                                                } else {
                                                    setPassNumbers(false)
                                                }

                                                // test if password contains 2 special characters
                                                let specialCharCount = (v.match(special) || []).length
                                                setPassSpecialChars(v.match(special))

                                                if (specialCharCount >= 2) {
                                                    setPassSpecial(true)
                                                } else {
                                                    setPassSpecial(false)
                                                }

                                                if (
                                                    passLength &&
                                                    passUppercase &&
                                                    passLowercase &&
                                                    specialCharCount >= 2 &&
                                                    passNumbers
                                                ) {
                                                    setPassCorrect(true)
                                                } else {
                                                    setPassCorrect(false)
                                                }
                                            }}
                                        />
                                        <div
                                            className='ml-2 cursor-pointer'
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Hide size='30' /> : <FormView size='30' />}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name='password'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-6'>
                                    <label className='block font-cgbold leading-6'>Confirm password</label>
                                    <div className='flex items-center'>
                                        <Field
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name='confirmPassword'
                                            placeholder='Retype password here'
                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                        />
                                        <div
                                            className='ml-2 cursor-pointer'
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? <Hide size='30' /> : <FormView size='30' />}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name='confirmPassword'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mt-4'>
                                    <ul>
                                        <li>
                                            {passLength ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Be a minimum of eight characters long
                                        </li>
                                        <li>
                                            {passUppercase ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contain at least two upper case letters
                                        </li>
                                        <li>
                                            {passLowercase ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contain at least two lower case letters
                                        </li>
                                        <li>
                                            {passNumbers ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contain at least two numbers
                                        </li>
                                        <li>
                                            {passSpecial ? (
                                                <StatusGood
                                                    color='#54e596'
                                                    className='mr-2'
                                                />
                                            ) : (
                                                <StatusCritical
                                                    color='#ffbaba'
                                                    className='mr-2'
                                                />
                                            )}
                                            Contains at least two special characters, such as ! £ $ % & * @
                                        </li>
                                    </ul>
                                </div>

                                <div className='mt-10 mb-4'>
                                    <button
                                        type='submit'
                                        className='rounded-2xl bg-green1 py-2 px-6 text-white font-cgbold'
                                        disabled={isSubmitting}
                                    >
                                        Set new password
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default NewPassword
