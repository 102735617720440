import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Notification } from '../notifications'
import * as Yup from 'yup'
import axios from 'axios'
import { getUser } from '../auth/LoginState'

type RT = {
    modal: (arg0: boolean) => void
    loadTopics: () => void
}

const AddResourceTopic: React.FC<RT> = ({ modal, loadTopics }) => {
    const usr = getUser()
    const [submitting, setSubmitting] = useState(false)

    const initialValues = {
        name: '',
        description: '',
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string(),
    })

    const onSubmit = async (values: any) => {
        setSubmitting(true)

        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/admin/resources/topics/add`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: values,
            })
            setSubmitting(false)

            if (res.status === 201) {
                loadTopics()
                modal(false)

                return Notification({
                    message: 'Post topic successfully added.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
            <div className='flex items-center justify-end h-11'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => modal(false)}
                >
                    X
                </button>
            </div>
            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                <div className='p-2 md:p-0'>
                    <div className='bg-white max-w-4xl mx-auto rounded-xl p-2'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {() => (
                                <Form>
                                    <h2 className='text-5xl text-center font-cgbold mt-2'>Add Resource Topic</h2>
                                    <div className='mt-4'>
                                        <label
                                            className='block font-cgbold leading-6'
                                            htmlFor='name'
                                        >
                                            Title
                                        </label>
                                        <Field
                                            type='text'
                                            name='name'
                                            placeholder='Name'
                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                        />
                                        <ErrorMessage
                                            name='name'
                                            component='div'
                                            className='block text-error font-cgbold mt-2'
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <label
                                            className='block font-cgbold leading-6'
                                            htmlFor='description'
                                        >
                                            Description <small>(optional)</small>
                                        </label>
                                        <Field
                                            component='textarea'
                                            name='description'
                                            placeholder='Description'
                                            className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                        />
                                        <ErrorMessage
                                            name='description'
                                            component='div'
                                            className='block text-error font-cgbold mt-2'
                                        />
                                    </div>

                                    <div className='my-4'>
                                        <div className='flex justify-between'>
                                            <button
                                                type='submit'
                                                className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                                disabled={submitting}
                                            >
                                                Add Resource Topic
                                            </button>
                                            <button
                                                type='button'
                                                className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                onClick={() => {
                                                    modal(false)
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddResourceTopic
