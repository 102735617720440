// @ts-nocheck
import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Notification } from '../notifications'
import * as Yup from 'yup'
import axios from 'axios'
import { getUser } from '../auth/LoginState'

const ApproveUser = ({ modal, user, loadUsers }) => {
    const usr = getUser()
    const [submitting, setSubmitting] = useState(false)

    const initialValues = {
        username: user ? user.username : null,
        studyId: '',
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        studyId: Yup.string().required('Study ID is required'),
    })

    const onSubmit = async (values: any) => {
        setSubmitting(true)
        if (values.username !== user.username) {
            values.oldUsername = user.username
        }
        values.uid = user._id

        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/users/approve`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: values,
            })
            setSubmitting(false)

            if (res.status === 201) {
                loadUsers()
                modal(false)

                return Notification({
                    message: 'Account successfully approved.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
            <div className='flex items-center justify-end h-11'>
                <button
                    className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                    onClick={() => modal(false)}
                >
                    X
                </button>
            </div>

            <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                <div className='p-2 md:p-0'>
                    <div className='bg-white max-w-4xl mx-auto rounded-xl p-2 md:p-6'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <div>
                                        <h2 className='text-5xl text-center font-cgbold mt-2'>Approve User Account</h2>

                                        <div className='mt-4'>
                                            <label
                                                className='block font-cgbold leading-6'
                                                htmlFor='username'
                                            >
                                                Username
                                            </label>
                                            <Field
                                                type='text'
                                                name='username'
                                                placeholder='Username'
                                                className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            />
                                            <ErrorMessage
                                                name='username'
                                                component='div'
                                                className='block text-error font-cgbold mt-2'
                                            />
                                        </div>

                                        <div className='mt-4'>
                                            <label
                                                className='block font-cgbold leading-6'
                                                htmlFor='studyId'
                                            >
                                                Study ID
                                            </label>
                                            <Field
                                                type='text'
                                                name='studyId'
                                                placeholder='Study ID'
                                                className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            />
                                            <ErrorMessage
                                                name='studyId'
                                                component='div'
                                                className='block text-error font-cgbold mt-2'
                                            />
                                        </div>

                                        <div className='mt-4'>
                                            <label className='block font-cgbold leading-6'>User Information</label>
                                            <br />
                                            <strong>Name: </strong>
                                            {user ? `${user.firstName} ${user.surname}` : null}
                                            <br />
                                            <strong>Email: </strong>
                                            {user ? user.email : null}
                                            <br />
                                            <strong>Year of birth: </strong>
                                            {user ? user.yBirth : null}
                                            <br />
                                            <strong>Mobile number: </strong>
                                            {user ? user.mobileNumber : null}
                                            <br />
                                            <strong>E-Mail: </strong>
                                            {user ? user.email : null}
                                            <br />
                                            <strong>Ethnicity: </strong>
                                            {user ? user.ethnicity : null}
                                            <br />
                                            <strong>Gender: </strong>
                                            {user ? user.gender : null}
                                            <br />
                                            <strong>Biography: </strong>
                                            {user ? user.biography : null}
                                            <br />
                                        </div>

                                        <div className='mt-4'>
                                            <div className='flex justify-between'>
                                                <button
                                                    type='submit'
                                                    className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                                >
                                                    {submitting && <div className='loading'></div>}
                                                    Approve Account
                                                </button>
                                                <button
                                                    type='button'
                                                    className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                    onClick={() => modal(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApproveUser
