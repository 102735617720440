import React from 'react'
import Masthead from '../layout/MastHead'

const NotVerified: React.FC = () => {
    return (
        <>
            <div className='bodyContent register overflow-visible'>
                <Masthead />

                <div className='fullscreenCenter fullscreen py-20'>
                    <div className='noPadding'>
                        <div>
                            <div className='flex flex-col width-full justify-center text-center text-2xl'>
                                <h1>Not verified!</h1>
                                <p>Your account has not been verified yet. We will notify you via email.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotVerified
