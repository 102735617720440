import React, { useEffect, useState } from 'react'

const ScrollToTop: React.FC = () => {
    const [showButton, setShowButton] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        })
    }, [])

    return (
        <>
            {showButton && (
                <div className='fixed cursor-pointer bottom-4 right-4 w-11 h-11 bg-blue1 text-white rounded-full shadow-lg z-50 text-4xl font-bold text-center'>
                    <button
                        className='pt-2'
                        onClick={scrollToTop}
                    >
                        &#94;
                    </button>
                </div>
            )}
        </>
    )
}

export default ScrollToTop
