import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { DocumentConfig, User } from 'grommet-icons'
import { getUser } from '../auth/LoginState'
import { formatDate } from '../../helpers/dates'
import { userAction } from '../../helpers/logs'
import { Notification } from '../notifications'
import NotifIcon from './NotifIcon'

type N = {
    notificationsWindow: boolean
    setNotificationsWindow: (param: boolean) => void
    setNotificationsCount: (param: number) => void
}

type Not = {
    _id: string
    sourceUser: any
    targetUser?: string
    category: string
    type: string
    content: string
    dateCreated: string
    read: boolean
    active: boolean
}

export const Notifications: React.FC<N> = ({ notificationsWindow, setNotificationsWindow, setNotificationsCount }) => {
    const user = getUser()
    const nav = useNavigate()
    const [notifications, setNotifications] = useState<Array<Not>>()
    const [nCount, setNCount] = useState<number>(0)

    const clearAllNotifications = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/user/notifications/clear`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {},
            })

            if (res.status === 201) {
                getNotifications()

                return Notification({
                    message: 'All notifications marked as read.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const markRead = async (id: string) => {
        try {
            const res = await axios({
                method: 'patch',
                url: `${process.env.REACT_APP_SERVER}/api/user/notification/${id}/read`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                getNotifications()
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const getNotifications = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/user/notifications/${user.role}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setNotifications(res.data.notifications)

                let nots = res.data.notifications,
                    notsCount = 0

                for (let i = 0; i < nots.length; i++) {
                    if (nots[i].read === false) {
                        notsCount++
                    }
                }

                // pass counter to header notification icon
                setNotificationsCount(notsCount)

                // use here for showing mark all as read button
                setNCount(notsCount)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [user.role, user.token, setNotificationsCount])

    useEffect(() => {
        const modal = document.getElementById('modal-container')

        if (user) {
            getNotifications()
        }

        window.addEventListener('click', (e) => {
            // @ts-ignore
            if (e.target.id === modal.id) {
                setNotificationsWindow(false)
            }
        })
    }, [setNotificationsWindow, getNotifications]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={notificationsWindow ? 'fixed w-[96vw] h-screen z-40 p-2' : 'hidden'}
            id='modal-container'
        >
            <div className='absolute bg-white h-screen md:max-h-96 p-4 overflow-y-auto rounded-lg shadow-2xl w-full top-28 translate-y-1 md:w-2/3 md:right-0 block border border-blue1 z-30'>
                <div className='flex flex-row items-center justify-between py-2 border-b-2 border-black/30 mb-4'>
                    <h4 className='text-3xl font-cgbold'>Notifications</h4>
                    {nCount > 0 ? (
                        <button
                            type='button'
                            onClick={() => clearAllNotifications()}
                            className='bg-purple1 rounded-full py-2 px-3 font-cgbold'
                        >
                            Mark all as Read
                        </button>
                    ) : null}
                </div>

                {notifications &&
                    notifications.map((notification: any, c: number) => {
                        return (
                            <div
                                className={
                                    notification.read
                                        ? 'cursor-pointer flex items-center my-1 px-4'
                                        : 'cursor-pointer flex items-center my-1 rounded-full px-4 bg-blue3'
                                }
                                key={c}
                                onClick={() => {
                                    let notificationCategory = ''

                                    if (
                                        notification.content.includes('on your post') &&
                                        !notification.content.includes('commented')
                                    ) {
                                        notificationCategory = 'Reaction to your post'
                                    } else if (notification.content.includes('on your comment')) {
                                        notificationCategory = 'Reaction to your comment'
                                    } else if (notification.content.includes('started following you')) {
                                        notificationCategory = 'Follow'
                                    } else if (notification.content.includes('commented on your post')) {
                                        notificationCategory = 'Comment'
                                    } else if (notification.content.includes('deleted your post')) {
                                        notificationCategory = 'Post delete'
                                    } else if (notification.content.includes('edited your post')) {
                                        notificationCategory = 'Post edit'
                                    }

                                    const notificationData = {
                                        time: notification.dateCreated,
                                        state: notification.read,
                                        category: notificationCategory? notificationCategory : notification.category,
                                    }

                                    userAction(
                                        user.id,
                                        'Notification',
                                        '',
                                        'notification',
                                        {},
                                        {},
                                        {},
                                        {},
                                        notificationData
                                    )

                                    switch (notification.type) {
                                        case 'user':
                                            nav('/admin/new-registrations', {
                                                state: {
                                                    uid: notification.sourceUser,
                                                },
                                            })
                                            break
                                        case 'password-reset':
                                            nav('/admin/users/password-reset-requests', {
                                                state: {
                                                    uid: notification.sourceUser,
                                                },
                                            })
                                            break
                                        case 'approve':
                                            nav('/admin/posts/approve/list', {
                                                state: {
                                                    pid: notification.postId,
                                                },
                                            })
                                            break
                                        case 'postmoderation':
                                            nav('/admin/posts/flagged', {
                                                state: {
                                                    pid: notification.postId,
                                                },
                                            })
                                            break
                                        case 'postdeleterequest':
                                            nav('/admin/posts/remove/user-requests', {
                                                state: {
                                                    pid: notification.postId,
                                                },
                                            })
                                            break
                                        case 'post':
                                            nav(`/community/article/${notification.postId}`, {
                                                state: {
                                                    links: []
                                                }
                                            })
                                            break;
                                        case 'resource':
                                            nav(`/resource/article/${notification.resourceId}`, {
                                                state: {
                                                    links: []
                                                }
                                            })
                                            break;
                                        case 'myprofile':
                                            nav('/account/profile')
                                            break
                                        case 'follow':
                                            nav(`/user/profile/${notification.sourceUser}`)
                                        default:
                                            break
                                    }
                                    markRead(notification._id)
                                    setNotificationsWindow(false)
                                }}
                            >
                                <div className='mr-4'>
                                    {notification.icon? <NotifIcon icon={notification.icon}/>
                                    : notification.type === 'user' ? <User /> : <DocumentConfig />}
                                </div>
                                <div className='w-full font-cgbold'>
                                    {notification.content}
                                    <br />
                                    <small className='text-xs text-slate-500'>
                                        {formatDate(notification.dateCreated)}
                                    </small>
                                </div>
                                {notification.read === false ? (
                                    <div className='bg-blue1 rounded-full text-white py-1 px-5 font-cgbold'>New</div>
                                ) : null}
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
