import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Notification } from '../notifications'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import User from '../community/User'
import { getUser } from '../auth/LoginState'
import { formatDate } from '../../helpers/dates'

const FlaggedPostsModerated: React.FC = () => {
    const user = getUser()
    const nav = useNavigate()
    const [posts, setPosts] = useState<any>([])

    const getFlaggedPostsModerated = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/flagged/moderated`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setPosts(res.data.posts)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [user.token, setPosts])

    useEffect(() => {
        getFlaggedPostsModerated()
    }, [getFlaggedPostsModerated])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Moderated Flagged posts</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                {posts && posts.length > 0 ? (
                    <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full'>
                        <thead>
                            <tr className='bg-blue2'>
                                <th className='py-2 px-4'>#</th>
                                <th className='py-2 px-4'>Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {posts.map((p: any, c: number) => {
                                return (
                                    <tr
                                        key={c}
                                        className='border-b border-black/20 hover:bg-blue-100'
                                    >
                                        <td className='py-2 px-4 text-center'>{c + 1}</td>
                                        <td className='py-2 px-4'>
                                            <strong>
                                                <div
                                                    className='text-blue1 cursor-pointer'
                                                    onClick={() =>
                                                        nav(`/community/article/${p.post._id}`, {
                                                            state: {
                                                                adminOrigin: true,
                                                            },
                                                        })
                                                    }
                                                >
                                                    {p.post.title}
                                                </div>
                                            </strong>
                                            <br />

                                            <details className='style4'>
                                                <summary className='cursor-pointer'>Reports</summary>
                                                <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full'>
                                                    <thead>
                                                        <tr className='border-b border-black/20'>
                                                            <th>#</th>
                                                            <th>Description</th>
                                                            <th>Resolution</th>
                                                            <th>Resolved By</th>
                                                            <th>Date Resolved</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {p.reports.map((r: any, i: number) => {
                                                            return (
                                                                <tr
                                                                    key={i}
                                                                    className='border-b border-black/20 hover:bg-blue-200'
                                                                >
                                                                    <td>{i + 1}</td>
                                                                    <td>{r.description}</td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {r.resolution}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        <User
                                                                            user={r.resolvedUser}
                                                                            getLoggedInUser={getFlaggedPostsModerated}
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {formatDate(r.dateResolved)}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </details>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>There are currently no flagged posts that have been approved!</div>
                )}
            </div>
        </>
    )
}

export default FlaggedPostsModerated
