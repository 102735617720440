// @ts-nocheck

import React, { useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { DateTime } from 'luxon'
import User from './User'
import EmojiReact from './EmojiReact'
import ResourceReact from './ResourceReact'
import CommentsHandler from './CommentsHandler'
import Bookmark from './Bookmark'
import Comments from './Comments'
import Moderation from './Moderation'
import { getUser } from '../auth/LoginState'
import ScrollToTop from '../layout/ScrollToTop'
import { logResourceAction } from '../../helpers/logs'
import { setUserAgent } from 'react-device-detect'

const ResourceArticle = () => {
    const usr = getUser()
    const loc = useLocation()
    const nav = useNavigate()
    const articleId = loc.pathname.replace('/resource/article/', '')

    const [data, setData] = useState<any>()
    const [user, setUser] = useState<any>()
    const [users, setUsers] = useState<any>()
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
    const [showComments, setShowComments] = useState<boolean>(false)
    const [hearYou, setHearYou] = useState<boolean>(false)
    const [hearYouCounter, setHearYouCounter] = useState<number>(0)
    const [supportYou, setSupportYou] = useState<boolean>(false)
    const [supportYouCounter, setSupportYouCounter] = useState<number>(0)
    const [happy, setHappy] = useState<boolean>(false)
    const [happyCounter, setHappyCounter] = useState<number>(0)
    const [sad, setSad] = useState<boolean>(false)
    const [sadCounter, setSadCounter] = useState<number>(0)
    const [proud, setProud] = useState<boolean>(false)
    const [proudCounter, setProudCounter] = useState<number>(0)
    const [laughter, setLaughter] = useState<boolean>(false)
    const [laughterCounter, setLaughterCounter] = useState<number>(0)
    const [backBtn, setBackBtn] = useState<any>(null)

    const checkUserBookmark = useCallback(
        (post: any) => {
            const bookmarks = post.stats.bookmarks

            setIsBookmarked(false)

            if (bookmarks && bookmarks.length > 0) {
                bookmarks.filter((post) => {
                    if (post.user === usr.id) {
                        setIsBookmarked(true)
                    }

                    return null
                })
            }
        },
        [usr.id]
    )

    const checkUserReactions = useCallback(
        (post: any) => {
            setHearYou(false)
            setSupportYou(false)
            setHappy(false)
            setSad(false)
            setProud(false)
            setLaughter(false)
            setHearYouCounter(post.stats.counters.hear)
            setSupportYouCounter(post.stats.counters.support)
            setHappyCounter(post.stats.counters.happy)
            setSadCounter(post.stats.counters.sad)
            setProudCounter(post.stats.counters.proud)
            setLaughterCounter(post.stats.counters.laughter)

            if (post.stats.reactions.length > 0) {
                post.stats.reactions.filter((reaction) => {
                    switch (reaction.reaction) {
                        case 'hear':
                            if (reaction.user === usr.id) {
                                setHearYou(true)
                            }
                            break
                        case 'support':
                            if (reaction.user === usr.id) {
                                setSupportYou(true)
                            }
                            break
                        case 'happy':
                            if (reaction.user === usr.id) {
                                setHappy(true)
                            }
                            break
                        case 'sad':
                            if (reaction.user === usr.id) {
                                setSad(true)
                            }
                            break
                        case 'proud':
                            if (reaction.user === usr.id) {
                                setProud(true)
                            }
                            break
                        case 'laughter':
                            if (reaction.user === usr.id) {
                                setLaughter(true)
                            }
                            break

                        default:
                            break
                    }

                    return null
                })
            }
        },
        [usr.id]
    )

    const getResource = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/resource/${articleId}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setData(res.data.resource)
                setUsers(res.data.users)
                setUser(res.data.user)
                checkUserBookmark(res.data.resource)
                checkUserReactions(res.data.resource)
            } else {
                console.log('error with response')
            }
        } catch (error) {
            console.log(error)
        }
    }, [articleId, usr.token, checkUserBookmark, checkUserReactions])

    const displayImage = () => {
        let displayImage = false,
            image = ''

        if (data.links && data.links.length > 0) {
            data.links.map((i, c) => {
                if (i.name.includes('.png') || i.name.includes('.jpg') || i.name.includes('.jpeg')) {
                    displayImage = true
                    image = i.link
                }
            })
        }

        if (displayImage) {
            return (
                <div>
                    <img
                        src={image}
                        alt='Article image'
                        className='w-100 mt-4'
                    />
                </div>
            )
        }
    }

    const includeNewLines = (article: String) => {
        let split = article.split('\r\n')
        let lines: any[] = []
        split.forEach((line) => {
            console.log(line == '')
            lines.push(line? line : <br></br>)
        })
        return lines
    }

    useEffect(() => {
        getResource()
        if (loc) {
            if (loc.state === null) {
                setBackBtn('Go back to Resources')
            } else if (loc.state.userProfileOrigin) {
                setBackBtn('Go back to User Profile')
            } else if (loc.state.myProfileOrigin) {
                setBackBtn('Go back to My Profile')
            } else if (loc.state.adminOrigin) {
                setBackBtn('Go back to Admin Dashboard')
            } else if (loc.state.searchOrigin) {
                setBackBtn('Go back to Search Results')
            } else {
                setBackBtn('Go back to Resources')
            }
        }
    }, [getResource])

    return (
        <>
            {!data && (
                <div className='flex flex-col md:flex-row mt-16 md:mt-24 justify-center'>
                <span>
                This post has either been deleted, may not exist, or you have requested for it to be deleted.
                To find out more about how to request for your posts to be deleted click&nbsp;
                <a
                    className='font-cgbold text-blue1'
                    href='https://drive.google.com/file/d/1SKrA0_XLlnC7477H-r-Wt9t-x2bDuk4I/view?usp=sharing'
                >
                    here.
                </a>
                </span>
            </div>
            )}
            {data && data._id && (
                <div className='flex flex-col md:flex-row mt-16 md:mt-24 justify-between mb-96'>
                    <div className='w-full md:w-2/12 p-4 justify-center items-center shadow-lg md:shadow-none z-30'>
                        <div
                            className='rounded-full py-2 px-6 bg-purple1 font-cgbold text-center cursor-pointer break-words'
                            onClick={() => nav(-1)}
                        >
                            {backBtn}
                        </div>
                    </div>

                    <div className='relative w-full mt-20 md:mt-4 md:w-10/12 shadow-lg rounded-lg border-2 border-black/10 flex flex-col md:flex-row p-4 mr-1'>
                        <div className='flex flex-row shrink-0 justify-between items-center md:flex-col md:justify-start mr-3 border-b-2 md:border-b-transparent md:border-r-2 border-black/20 w-full md:w-1/6'>
                            <User
                                user={users? users[data.user] : null}
                                getResources={getResource}
                            />
                            <div className='text-sm md:text-2xs md:mt-4'>
                                <span>Posted: </span>
                                <span>{DateTime.fromISO(data.dateCreated).toRelative()}</span>
                            </div>
                            <div className='text-sm md:text-2xs md:mt-4 text-center w-max'>
                                <span>Last reply: </span>
                                <span>
                                    {data &&
                                    data.comments &&
                                    data.comments.length &&
                                    data.comments[data.comments.length - 1] &&
                                    data.comments[data.comments.length - 1].dateCreated
                                        ? DateTime.fromISO(
                                              data.comments[data.comments.length - 1].dateCreated
                                          ).toRelative()
                                        : '---'}
                                </span>
                            </div>
                        </div>

                        <div className='w-full mt-3 md:mt-0 break-words grow-0 overflow-hidden'>
                            <div className='flex justify-start items-center'>
                                <div className='flex flex-wrap w-11/12'>
                                    <div className='rounded-full font-cgbold text-sm py-2 px-6 bg-green2'>
                                        Resource
                                    </div>
                                </div>

                                <div className='flex w-1/12 justify-end'>
                                    <Moderation
                                        item={data}
                                        getResources={getResource}
                                    />
                                </div>
                            </div>

                            <h4 className='text-2xl font-semibold mt-6 mb-4'>{data.title}</h4>

                            {data.content.includes('\n')? includeNewLines(data.content).map((line, index) => {
                                return <p key={index}>
                                    {line}
                                </p>
                            } ) : data.content}

                            {displayImage()}

                            {data.links && data.links.length > 0 ? (
                                <div className='mt-6 border-t-2 border-b-2 border-t-black/20 py-6'>
                                    {data.links.map((i, c) => {
                                        let readableName = i.name.split('.'),
                                            ext = readableName[1]

                                        readableName = readableName[0].slice(0, readableName[0].length - 12)
                                        
                                        return (
                                            <div
                                                key={c}
                                                className='flex items-center'
                                            >

                                                {i.type && i.type !== 'Image' && (
                                                    <div className='bg-blue1 text-white rounded-lg mr-2 font-cgbold py-1 px-2'>
                                                        {i.type}
                                                    </div>
                                                )}
                                                {['jpg', 'png', 'jpeg'].includes(ext)? 
                                                <div key={c}>
                                                    <a
                                                        href={i.link}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        className='text-blue1 font-cgbold'
                                                        onClick={() => logResourceAction(usr, data._id, 'Open')}
                                                    >
                                                        <img
                                                            src={i.link}
                                                            alt={readableName}
                                                            width={100}
                                                        />
                                                    </a>
                                                </div>
                                                :
                                                <a
                                                    href={i.link}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    className='text-blue1 font-cgbold'
                                                    onClick={() => logResourceAction(usr, data._id, 'Open')}
                                                >
                                                    {readableName}
                                                </a>
                                                }
                                                
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : null}

                            {data.url != '' ? (
                                <div
                                    className={
                                        data.links.length > 0
                                            ? 'flex items-center mt-6 border-b border-black/20 py-4'
                                            : 'flex items-center mt-6 border-t border-b border-black/20 py-4'
                                    }
                                >
                                    <div className='bg-blue1 text-white rounded-lg mr-2 font-cgbold py-1 px-2'>URL</div>
                                    <a
                                        href={data.url}
                                        target='_blank'
                                        title='External link'
                                        className='text-blue1 font-cgbold'
                                        onClick={() => logResourceAction(usr, item, 'Open')}
                                    >
                                        {data.url}
                                    </a>
                                </div>
                            ) : null}

                            <div className='mt-2 pt-6 flex flex-wrap'>
                                {data.hashtags
                                    ? data.hashtags.map((h, c) => {
                                          return (
                                              <div
                                                  className='rounded-full mx-1 my-1 border border-black/20 text-xs py-1 px-4 cursor-pointer'
                                                  key={c}
                                                  onClick={() => nav(`/search/tag/${h}`)}
                                              >
                                                  {h}
                                              </div>
                                          )
                                      })
                                    : null}
                            </div>

                            <div className='flex flex-row justify-between md:justify-start my-6 items-center mr-4'>
                                <ResourceReact
                                    componentType='hear'
                                    type='hear'
                                    resource={data._id}
                                    hearYou={hearYou}
                                    supportYou={supportYou}
                                    hearYouCounter={hearYouCounter}
                                    getResources={getResource}
                                />
                                <ResourceReact
                                    componentType='support'
                                    type='support'
                                    resource={data._id}
                                    hearYou={hearYou}
                                    supportYou={supportYou}
                                    supportYouCounter={supportYouCounter}
                                    getResources={getResource}
                                />
                                <EmojiReact
                                    resource={data._id}
                                    happy={happy}
                                    happyCounter={happyCounter}
                                    sad={sad}
                                    sadCounter={sadCounter}
                                    proud={proud}
                                    proudCounter={proudCounter}
                                    laughter={laughter}
                                    laughterCounter={laughterCounter}
                                    getResources={getResource}
                                />
                            </div>

                            <div className='flex flex-row justify-center md:justify-end mt-8'>
                                <Bookmark
                                    id={data._id}
                                    userToken={usr.token}
                                    userId={usr.id}
                                    isBookmarked={isBookmarked}
                                    getResources={getResource}
                                />
                            </div>
                        </div>
                    </div>

                    <ScrollToTop />
                </div>
            )}
        </>
    )
}

export default ResourceArticle
