import React from 'react'
import { useNavigate } from 'react-router-dom'
import Masthead from '../layout/MastHead'
import About from '../../assets/images/about.jpg'
import ManifestoTop from '../../assets/images/manifestotop.png'
import ManifestoBottom from '../../assets/images/manifestobottom.png'
import AboutBottom from '../../assets/images/aboutusbottom.png'
import Samaritans from '../../assets/images/samaritans.png'
import ScrollToTop from '../layout/ScrollToTop'
import KingsLogo from '../../assets/images/kings_logo.svg'
import BJLogo from '../../assets/images/bjlogo.png'
import a3 from '../../assets/images/avatar3.png'
import a4 from '../../assets/images/avatar4.png'
import a5 from '../../assets/images/avatar5.png'
import a6 from '../../assets/images/avatar6.png'
import a7 from '../../assets/images/avatar7.png'
import a8 from '../../assets/images/avatar8.png'
import a9 from '../../assets/images/avatar9.png'
import { elementAt } from 'rxjs'

const AboutUs = () => {
    const nav = useNavigate()

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-52 md:mt-16 text-center px-4'>
                <h1 className='text-5xl font-cgbold mt-20'>About Us</h1>
                <p className='mt-4'>
                    On this page you can find out more about CommonGround, the research behind it, and how to contact
                    the team.
                    <br></br>
                    We want CommonGround to be accessible for all. At the end of this page, you will find information on how to use a screen reader on CommonGround. There are also ‘how to’ tutorials on using CommonGround.
                </p>
            </div>

            <div className='container mx-auto w-full max-w-3xl mt-16 min-h-screen'>
                <div className='flex flex-wrap max-w-2xl mx-auto items-center justify-center'>
                    <a
                        href='#about'
                        className='flex items-center justify-center cursor-pointer bg-green3 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        About CommonGround
                    </a>
                    <a
                        href='#research'
                        className='flex items-center justify-center cursor-pointer bg-red1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        CommonGround:
                        <br /> the research
                    </a>
                    <a
                        href='#team'
                        className='flex items-center justify-center cursor-pointer bg-green1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Meet the team!
                    </a>
                    <a
                        href='#contact'
                        className='flex items-center justify-center cursor-pointer bg-purple1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Contact Us
                    </a>
                    <a
                        href='#principles'
                        className='flex items-center justify-center cursor-pointer bg-yellow1 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Community Principles
                    </a>
                    <a
                        href='#moderation'
                        className='flex items-center justify-center cursor-pointer bg-blue2 w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        Moderation Policy
                    </a>
                    <a
                        href='#usage'
                        className='flex items-center justify-center cursor-pointer bg-blue1 text-white w-48 h-16 mx-1 my-2 rounded-full py-2 px-4 font-cgbold text-center'
                    >
                        How to use CommonGround
                    </a>
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='about'
                        className='font-cgbold scroll-mt-40 text-2xl mt-4 bg-green3 text-center rounded-3xl'
                    >
                        About CommonGround
                    </h2>
                    <p className='mt-4'>
                        CommonGround is a community founded on the belief that physical and mental health are 
                        connected and influence one another. We are an online platform dedicated to people living 
                        with long-term conditions to support one another, learn, and share experiences. Anyone with 
                        a diagnosed physical health condition can join the community.
                    </p>
                    <p className='mt-4'>
                        CommonGround has been carefully created through collaboration between a team of researchers from 
                        King's College London (KCL), our software developers at <a target="_blank" rel="noopener noreferrer" className='font-cgbold text-blue1' href='https://bitjam.org.uk'>BitJam</a> (and previously TOAD), and people 
                        living with long-term physical health conditions. The voices of our lived experience panel have been 
                        central in developing CommonGround.
                    </p>
                    <div className='flex flex-col md:flex-row justify-center items-center mt-6'>
                        <img
                            alt='kings college london logo'
                            src={KingsLogo}
                            className='h-20 aspect-auto mt-6 cursor-pointer'
                        />
                        <img
                            alt='bitjam logo'
                            src={BJLogo}
                            className='h-20 aspect-auto mt-6 ml-6 cursor-pointer'
                        />
                    </div>
                    <p className='mt-4'>
                        The platform is currently available for three months as part of a clinical trial run by King’s College 
                        London. Each community 
                        member can choose to post, comment, and interact as much as they wish. We encourage you to give as much 
                        as you receive here on the CommonGround platform, to help our community grow.
                    </p>
                    <p className='mt-4'>
                        Our manifesto sums up what CommonGround is all about. It was created by someone with multiple long-term 
                        conditions and is inspired by the interactions the research team has had with our lived experience panel.
                    </p>

                    {/* <img
                        className='mx-auto'
                        src={About}
                        alt='CommonGround manifesto'
                    /> */}
                    <img
                        className='mx-auto w-screen'
                        src={ManifestoTop}
                        alt='CommonGround manifesto top'
                    />
                    <p className='text-center'>
                        Our diagnoses may be different,<br></br>
                        but we are united in dealing with the challenge<br></br>
                        when mind and body collide.<br></br>
                        This is a two way street;<br></br>
                        when we share we can learn from others<br></br>
                        and see our experiences in a new light.<br></br>
                        We are a community connected by health<br></br>
                        There is no need to apologise for<br></br>
                        what our bodies may do or not do.<br></br>
                        What works for you may not work for me.<br></br>
                        We are here to give and get support,<br></br>
                        To learn, share, and grow.<br></br>
                        This is a place to simply be present<br></br>
                        And stand on CommonGround.<br></br>
                    </p>
                    <img
                        className='mx-auto w-screen'
                        src={ManifestoBottom}
                        alt='CommonGround manifesto bottom'
                    />
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='research'
                        className='font-cgbold scroll-mt-40 text-2xl mt-8 bg-red1 text-center rounded-3xl'
                    >
                        Behind CommonGround: The research
                    </h2>
                    <p className='mt-4'>
                        Over 15 million people in the United Kingdom live with one or more long-term physical health condition. 
                        People living with a health condition may experience <a target="_blank" rel="noopener noreferrer" className='font-cgbold text-blue1' href='https://www.nhs.uk/every-mind-matters/mental-health-issues/low-mood/'>low mood</a>
                        , and symptoms of <a target="_blank" rel="noopener noreferrer" className='font-cgbold text-blue1' href='https://www.nhs.uk/every-mind-matters/mental-health-issues/anxiety/'>anxiety</a> or <a href="https://www.nhs.uk/mental-health/conditions/depression-in-adults/overview/&sa=D&source=docs&ust=1719498396761551&usg=AOvVaw0xtD_UNmYvRaCIOeZlRrYw" target="_blank">depression. </a>
                        <b>We wanted to create a way to support the emotional needs of people living with long-term conditions: 
                        this is how CommonGround was born.</b>
                    </p>
                    <p className='mt-4'>
                        <b>CommonGround</b> is part of an initiative to consider the whole patient, joining up physical and mental 
                        health. We are researching whether our online 
                        peer support community and self-help resources can help people living with long-term conditions to manage 
                        their emotional well-being or low mood and what people think about using the CommonGround platform.
                    </p>
                    <div className='mt-4'>
                        If you want to read more about how we co-produced CommonGround with people with long-term
                        conditions, you can access the blog posts below:
                        <ul className='list-disc'>
                            <li className='ml-6 mt-4'>
                                <a
                                    className='text-blue1 font-cgbold underlined'
                                    target="_blank" rel="noopener noreferrer"
                                    href='https://www.quahrc.co.uk/lessons-learned-online-co-production-during-the-covid-19-pandemic'
                                >
                                    Lessons learned: Online co-production during the COVID-19 pandemic
                                </a>
                            </li>
                            <li className='ml-6 mt-2'>
                                <a
                                    className='text-blue1 font-cgbold underlined'
                                    target="_blank" rel="noopener noreferrer"
                                    href='https://www.quahrc.co.uk/i-knew-at-that-moment-i-wasn%E2%80%99t-alone-%E2%80%93-new-support-for-people-with-ltcs'
                                >
                                    "I knew at that moment I wasn’t alone" – New support for people with long-term conditions
                                </a>
                            </li>
                            <li className='ml-6 mt-2'>
                                <a
                                    className='text-blue1 font-cgbold underlined'
                                    target="_blank" rel="noopener noreferrer"
                                    href='https://www.quahrc.co.uk/commonground-online-peer-support-for-preventing-comorbid-depression-in-people-with-long-term'
                                >
                                    Behind CommonGround: Online peer support for preventing comorbid depression in people with long-term conditions.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p className='mt-4'>
                        It is important to remember that not all people living with a long-term condition experience low
                        mood or need help to manage their mental well-being. However, some people may experience fluctuations in their mental well-being. If you would like
                        to find out about further support for managing your mental well-being, you can find information
                        on our{' '}
                        <a
                            className='text-blue1 font-cgbold'
                            href='/support'
                        >
                            Further Support and Crisis Page
                        </a>
                        .
                    </p>
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='team'
                        className='font-cgbold scroll-mt-40 text-2xl mt-8 bg-green1 text-center rounded-3xl'
                    >
                        Meet the team!
                    </h2>
                    <p className='mt-4'>
                        Behind CommonGround is a dedicated team overseeing the day-to-day running of the community. The 
                        research team managing this trial are from King's College 
                        London. Our team includes:
                    </p>
                    <div className='mt-4 border-8 border-green1 p-4 rounded-xl grid grid-cols-[25%_75%]'>
                        <img
                            className='mx-auto w-screen row-span-2'
                            src={a3}
                            alt='Avatar image'
                        />
                        <strong className='col-start-2'>Grace</strong>
                        <p className='col-start-2'>
                            Grace, a research fellow at KCL, said: “Working on this special co-production has been a real 
                            privilege. We hope to have created a safe space for people with long-term conditions to learn, 
                            share and grow. Most importantly, we hope that our platform will encourage members to offer 
                            support to one another. The CommonGround platform has been uniquely co-designed and steered by 
                            our wonderful team of people with lived experiences. This has been a really exciting journey to 
                            navigate. I look forward to embracing the future directions of the platform and experiencing how 
                            CommonGround will grow and evolve, based on the lessons we have yet to learn.”
                        </p>
                    </div>
                    <div className='mt-4 border-8 border-yellow1 p-4 rounded-xl grid grid-cols-[25%_75%]'>
                        <img
                            className='mx-auto w-screen row-span-2'
                            src={a4}
                            alt='Avatar image'
                        />
                        <strong className='col-start-2'>Han</strong>
                        <p className='col-start-2'>
                            Han, one of our research assistants, said: "It has been a privilege to contribute to the
                            development of CommonGround and create a truly unique space for people living with long-term
                            conditions. The platform has already grown so much from brainstorming ideas, and I cannot
                            wait to see how it grows further."
                        </p>
                    </div>
                    <div className='mt-4 border-8 border-green1 p-4 rounded-xl grid grid-cols-[25%_75%]'>
                        <img
                            className='mx-auto w-screen row-span-2'
                            src={a5}
                            alt='Avatar image'
                        />
                        <strong className='col-start-2'>Elly</strong>
                        <p className='col-start-2'>
                            Elly has several health conditions; some she has lived with since birth others she has
                            developed along the way. Her background is in writing, digital user research and healthcare
                            advocacy. She is passionate about improving working life for people with chronic illness and
                            has been a co-investigator with lived experience on the project since the beginning.
                        </p>
                    </div>
                    <div className='mt-4 border-8 border-blue1 p-4 rounded-xl grid grid-cols-[25%_75%]'>
                        <img
                            className='mx-auto w-screen row-span-2'
                            src={a8}
                            alt='Avatar image'
                        />
                        <strong className='col-start-2'>Vanessa</strong>
                        <p className='col-start-2'>
                            Vanessa, our qualitative methodologist, said: "I am excited by the collaborative way in
                            which the CommonGround platform was created. Involving people with long-term physical health
                            conditions in this process has helped to create a space that feels relevant, useful, and
                            supportive. I can't wait to see how people how people interact with it!"
                        </p>
                    </div>
                    <div className='mt-4 border-8 border-green1 p-4 rounded-xl grid grid-cols-[25%_75%]'>
                        <img
                            className='mx-auto w-screen row-span-2'
                            src={a7}
                            alt='Avatar image'
                        />
                        <strong className='col-start-2'>Alan</strong>
                        <p className='col-start-2'>
                            Alan is Professor of Mental Health Nursing at KCL. Alan has a research interest in improving 
                            care for people with mental and physical health problems.
                        </p>
                    </div>
                    <div className='mt-4 border-8 border-green3 p-4 rounded-xl grid grid-cols-[25%_75%]'>
                        <img
                            className='mx-auto w-screen row-span-2'
                            src={a6}
                            alt='Avatar image'
                        />
                        <strong className='col-start-2'>Matthew</strong>
                        <p className='col-start-2'>
                            Matthew is a psychiatrist, mental health researcher, Dean of the <a target="_blank" rel="noopener noreferrer" className='text-blue1 font-cgbold' href='https://www.kcl.ac.uk/ioppn'>Institute of Psychiatry, Psychology 
                            and Neuroscience</a> at KCL and Professor of General Hospital Psychiatry. His main interest is in the way 
                            in which mental and physical health interact. His research interests have been informed by many years 
                            of clinical practice seeing people with many different physical illnesses who experience emotional 
                            distress and mental disorders like depression.
                        </p>
                    </div>
                    <div className='mt-4 border-8 border-blue1 p-4 rounded-xl grid grid-cols-[25%_75%]'>
                        <img
                            className='mx-auto w-screen row-span-2'
                            src={a9}
                            alt='Avatar image'
                        />
                        <strong className='col-start-2'>Ewan</strong>
                        <p className='col-start-2'>
                            Ewan, our statistician said “I’m a researcher and statistician specialising in techniques to make sense 
                            of complex, longitudinal datasets. I’m excited about developing and evaluating digital interventions 
                            for mental health.”      
                        </p>
                    </div>
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <p className='mt-4'>
                        There are many others at KCL who have guided and supported this project. If you wish to contact
                        a member of the research team, you can find their contact details{' '}
                        <a
                            href='#contact'
                            className='font-cgbold text-blue1'
                        >
                            here
                        </a>
                        .
                    </p>
                    <p className='mt-4'>
                        Aside from KCL, several organisations have contributed to CommonGround. This includes TOAD who 
                        designed the early prototype of the platform and BitJam, our current software partners, who have 
                        developed the version of the platform that you are using now. We extend our thanks to both software 
                        development teams who have helped CommonGround grow.
                    </p>
                    <p className='mt-4'>
                        We would also like to thank the members of our Research Advisory Group - past and present - and 
                        others who have helped shape CommonGround through our Patient Public Involvement (PPI) activities. 
                        Your contributions have been invaluable in shaping CommonGround, and it has been a pleasure to 
                        collaborate with you.
                    </p>
                    {/*  // TODO: need logos */}
                    <p className='mt-4'>
                        We would also like to thank the Samaritans Online Harms team for generously sharing their time and 
                        knowledge to help the research team develop CommonGround and our Moderation Policy that helps to 
                        keep you safe.
                        
                    </p>
                    <div className='mx-auto my-4'>
                        <img
                            src={Samaritans}
                            className='mx-auto my-4'
                            alt='Samaritans logo'
                            width={'30%'}
                        />
                    </div>
                    
                    {/*  //TODO: check if this is correct */}
                    <p className='mt-4'>
                        This work has been generously funded by King’s Health Partners and <a target="_blank" rel="noopener noreferrer" className='text-blue1 font-cgbold' href='https://urbanhealth.org.uk/'>Impact on Urban 
                        Health</a>, through their Multiple Long-Term Conditions Challenge Fund.
                    </p>
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='contact'
                        className='font-cgbold scroll-mt-40 text-2xl mt-8 bg-purple1 text-center rounded-3xl'
                    >
                        Contact us
                    </h2>
                    {/*  // TODO: need hours */}
                    <p className='mt-4'>
                        <span className='font-cgbold'>You can contact the research team via email on <span className='text-blue1'>commonground@kcl.ac.uk</span>. Please be aware that 
                        the CommonGround email is not monitored 24/7. The research team will get back to you as soon 
                        as possible.</span> If you are experiencing any technical problems, please look at the troubleshooting 
                        and CommonGround tutorials in the ‘How to use CommonGround’ section below.
                    </p>

                    {/* <p className='mt-4'>
                        If you would like to give feedback on your experience on the platform at any time, please
                        complete this form:{' '}
                        <a
                            href='https://qualtrics.kcl.ac.uk/jfe/form/SV_6neFLC1TF4TY35Q'
                            className='font-cgbold text-blue1'
                        >
                            https://qualtrics.kcl.ac.uk/jfe/form/SV_6neFLC1TF4TY35Q
                        </a>
                        . Your feedback will be anonymous and guide the future direction of our research. Please be
                        aware that feedback will not be used to change the platform during the 'live' stage of this
                        current trial, and that our research team will not contact you in relation to your feedback. If
                        you wish to talk to the research team, please contact them via{' '}
                        <a
                            href='#contact'
                            className='font-cgbold text-blue1'
                        >
                            here
                        </a>
                        .
                    </p> */}
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='principles'
                        className='font-cgbold scroll-mt-40 text-2xl mt-8 bg-yellow1 text-center rounded-3xl'
                    >
                        Community Principles
                    </h2>
                    <p className='mt-4'>
                        The Community Principles of CommonGround are in place to ensure that CommonGround is a safe space 
                        where your interactions with other people on the site are positive and everyone is protected. 
                        You can read the Community Principles in full{' '}
                        <span
                            className='font-cgbold text-blue1 cursor-pointer'
                            onClick={() =>
                                nav('/principles', {
                                    state: {
                                        noCheckbox: true,
                                    },
                                })
                            }
                        >
                            here
                        </span>
                        .
                    </p>
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='moderation'
                        className='font-cgbold scroll-mt-40 text-2xl mt-8 bg-blue2 text-center rounded-3xl'
                    >
                        Moderation Policy
                    </h2>
                    {/*  //TODO: needs moderation policy link */}
                    <p className='mt-4'>
                        Our moderation policy helps us keep CommonGround a safe and supportive space where everyone
                        feels welcomed. We hope that the majority of interactions between community members will be
                        positive and empowering. However, we will have safeguards in place to help protect everyone
                        using the platform and make sure that all community members are using CommonGround
                        appropriately. To read our moderation policy, click{' '}
                        <span
                            className='font-cgbold text-blue1 cursor-pointer'
                            onClick={() => {
                                nav('/moderation-policy', {
                                    state: {
                                        noCheckbox: true,
                                    },
                                });
                                // If you don't do this it will take you to halfway down the page
                                window.scrollTo(0, 0)
                            }}
                        >
                            here
                        </span>. Our moderation policy is based on our
                        Community Principles, which you can read{' '}
                        <span
                            className='font-cgbold text-blue1 cursor-pointer'
                            onClick={() =>
                                nav('/principles', {
                                    state: {
                                        noCheckbox: true,
                                    },
                                })
                            }
                        >
                            here
                        </span>
                        . If you want to, you can also read our{' '}
                        <span
                            className='font-cgbold text-blue1 cursor-pointer'
                            onClick={() => {
                                nav('/moderation-policy', {
                                    state: {
                                        noCheckbox: true,
                                    },
                                })
                                window.scrollTo(0, 0)
                            }}
                        >
                            Safeguarding Policy
                        </span>
                    </p>
                </div>

                <div className='max-w-2xl mx-auto mt-4 px-4'>
                    <h2
                        id='usage'
                        className='font-cgbold scroll-mt-40 text-2xl mt-8 bg-blue1 text-center rounded-3xl text-white'
                    >
                        How to use CommonGround
                    </h2>

                    <div className='mt-4'>
                        <p>
                            If you find it hard to read or prefer to listen, you can use a screen reader to help you 
                            access CommonGround. Screen readers help people to access and interact with digital content, 
                            such as websites, via audio. The screen reader will read aloud what is on the screen. You can read 
                            more about screen readers and how they work on <a target="_blank" rel="noopener noreferrer" className='text-blue1 font-cgbold' href='https://abilitynet.org.uk/'>abilitynet.org.uk</a>. 
                            To find out how to use a screen reader on CommonGround, please follow the instructions below 
                            for your browser:
                        </p>
                        <ul className='list-disc'>
                            <li className='ml-6 mt-2'>
                                <a target="_blank" rel="noopener noreferrer" className='font-cgbold text-blue1' href='https://support.google.com/accessibility/answer/7031755?hl=en-GB'>Google Chrome screen reader</a>
                            </li>
                            <li className='ml-6 mt-2'>
                                <a target="_blank" rel="noopener noreferrer" className='font-cgbold text-blue1' href='https://support.microsoft.com/en-us/microsoft-edge/accessibility-features-in-microsoft-edge-4c696192-338e-9465-b2cd-bd9b698ad19a'>Microsoft Edge screen reader</a>
                            </li>
                            <li className='ml-6 mt-2'>
                                <a target="_blank" rel="noopener noreferrer" className='font-cgbold text-blue1' href='https://www.apple.com/voiceover/info/guide/_1121.html'>Safari (Apple) screen reader</a>
                            </li>
                        </ul>
                        <p className='mt-4'>
                            In this section, you can find out how to do different things here on CommonGround. If you are
                            experiencing any technical difficulties or require any further assistance, please contact us
                            directly.
                        </p>
                        {/*  // TODO: need pdfs */}
                        <ul className='list-disc'>
                            <li className='ml-6 mt-2'>
                                <a
                                    target="_blank" 
                                className='font-cgbold text-blue1' 
                                href='https://drive.google.com/file/d/1H8e5IeEyDpd7fi_C_FwByuiOOSuQP-S6/view?usp=sharing'
                                >
                                    Creating a new post: How to create a new post (including how to add an attachment),
                                </a>
                            </li>
                            <li className='ml-6 mt-2'> 
                                <a
                                    target="_blank"
                                    className='font-cgbold text-blue1'
                                    href='https://drive.google.com/file/d/18WJRQy7LICQ1FVc80YtCxZtp-cuJ4p9D/view?usp=sharing'
                                >
                                    My Garden: How to 'save' something to your garden,
                                </a>
                            </li>
                            <li className='ml-6 mt-2'> 
                                <a
                                    target="_blank"
                                    className='font-cgbold text-blue1'
                                    href='https://drive.google.com/file/d/1SKrA0_XLlnC7477H-r-Wt9t-x2bDuk4I/view?usp=sharing'
                                >
                                    Managing my posts: How to request your post to be deleted (and what this means) and
                                    hide/unhide from community feed,
                                </a>
                            </li>
                            <li className='ml-6 mt-2'> 
                                <a
                                    target="_blank"
                                    className='font-cgbold text-blue1'
                                    href='https://drive.google.com/file/d/1WMANPAHtaWLPv5X8zcbqhHl7UIPSerGg/view?usp=sharing'
                                >
                                    How to report something on the platform,
                                </a>
                            </li>
                            <li className='ml-6 mt-2'> 
                                <a
                                    target="_blank"
                                    className='font-cgbold text-blue1'
                                    href='https://drive.google.com/file/d/115cm7gRaIhGhdms0wUU0lySz4d4gSDRt/view?usp=sharing'
                                >
                                    Interacting with community members: How to follow/unfollow and mute other users (and
                                    access their profile),
                                </a>
                            </li>
                            <li className='ml-6 mt-2'> 
                                <a
                                    target="_blank"
                                    className='font-cgbold text-blue1'
                                    href='https://drive.google.com/file/d/1tLjXNrApE4GPIAuhs9lcv6XUT28K5W0b/view?usp=sharing'
                                >
                                    Managing my profile: Editing and updating your own profile (incl. Privacy toggles),
                                </a>
                            </li>
                            <li className='ml-6 mt-2'> 
                                <a
                                    target="_blank"
                                    className='font-cgbold text-blue1'
                                    href='https://drive.google.com/file/d/1E3pcuR6UJBfsVoyMcdjoNv7aloXji4eU/view?usp=sharing'
                                >
                                    Changing my password and updating my personal details.
                                </a>
                            </li>
                        </ul>
                        <p className='mt-4 bg-blue1 text-white text-center p-6 font-cgbold rounded-lg'>
                            Thank you for using CommonGround. By participating in the community, you are supporting our
                            research into improving the emotional well-being of people living with long-term conditions.
                        </p>
                        <img
                            className='mx-auto w-screen'
                            src={AboutBottom}
                            alt='Bottom of page design'
                        />
                    </div>
                </div>
            </div>

            <ScrollToTop />
        </>
    )
}

export default AboutUs
