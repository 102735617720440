import React, { useState, useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { resourceService as service } from '../../services'
import Dropzone from 'react-dropzone'
import DeleteIcon from '../../assets/images/delete.svg'
import { Topic } from '../../types/Posts'
import { hashtags } from '../../helpers/data'

type RUF = {
    setModalIsOpen: (param: boolean) => void
    topics: Array<Topic>
    setCategory: (param: string) => void
    getResources: () => void
}

const ResourceUploadForm: React.FC<RUF> = ({ setModalIsOpen, topics, setCategory, getResources }) => {
    const [dateCreated, setDateCreated] = useState('')
    const [file, setFile] = useState<any | undefined>(null)
    const [selectedTags, setSelectedTags] = useState<Array<string>>([])

    const initialValues = {
        title: '',
        type: '',
        topic: '',
        content: '',
        url: '',
        dateCreated: '',
        hashtags: '',
    }

    var minDt = new Date()

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        type: Yup.string().required('Type is required'),
        topic: Yup.string().required('Topic is required'),
        content: Yup.string().required('Content is required'),
        url: Yup.string(),
        dateCreated: Yup.date().required('Resource date is required').max(minDt, 'Resource date must be in the past'),
    })

    const handleFileDrop = (dropped: any) => {
        setFile(dropped[0])
    }

    const handleFileUpload = (e: any) => {
        setFile(e.target.files[0])
    }

    const handleFileDelete = () => {
        setFile(null)
    }

    const handleTags = (val: string) => {
        if (selectedTags) {
            const temp = selectedTags

            if (temp.length !== 0) {
                if (!temp.includes(val)) {
                    temp.push(val)
                } else {
                    const idx = temp.indexOf(val)

                    temp.splice(idx, 1)
                }

                setSelectedTags(temp)
            } else {
                setSelectedTags([val])
            }
        }
    }

    const onSubmit = (fields: any, { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }) => {
        setSubmitting(true)
        fields.tags = selectedTags

        service
            .create(fields, file)
            .then(() => {
                setCategory('')
                getResources()
                setModalIsOpen(false)
                setDateCreated('')
                setSubmitting(false)
            })
            .catch((error) => {
                console.log('ERR', error)
                setSubmitting(false)
            })
    }
    
    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (e.target.id == 'modal-container') {
                setModalIsOpen(false)
            }
        })
    }, [])

    return (
        <>
            <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'
                 id='modal-container'>
                <div className='flex items-center justify-end h-11'
                     id='modal-container'>
                    <button
                        className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                        onClick={() => setModalIsOpen(false)}
                    >
                        X
                    </button>
                </div>
                <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2 flex justify-center'
                     id='modal-container'>
                    <div className='modal bg-white max-w-4xl mx-0 rounded-xl p-4 md:p-6 w-[80vw] h-fit'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ isSubmitting, setFieldValue, setFieldTouched }) => (
                                <Form>
                                    <div>
                                        <h2 className='text-5xl text-center font-cgbold mt-2'>Share a resource</h2>
                                        <p className='text-center mt-2'>
                                            A safe and supportive community <em>connected over a shared experience</em>.
                                        </p>

                                        <div>
                                            <label
                                                className='block font-cgbold leading-6'
                                                htmlFor='title'
                                            >
                                                Title
                                            </label>
                                            <Field
                                                type='text'
                                                name='title'
                                                placeholder='Enter a title'
                                                className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            />
                                            <ErrorMessage
                                                name='title'
                                                component='div'
                                                className='block text-error font-cgbold mt-2'
                                            />
                                        </div>

                                        <div className='flex mt-4'>
                                            <div className='w-1/2 pr-1'>
                                                <label
                                                    className='block font-cgbold leading-6'
                                                    htmlFor='type'
                                                >
                                                    Type
                                                </label>
                                                <Field
                                                    name='type'
                                                    as='select'
                                                    className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                >
                                                    <option value=''></option>
                                                    <option value='Text'>Text</option>
                                                    <option value='Video'>Video</option>
                                                    <option value='Audio'>Audio</option>
                                                    <option value='Image'>Image</option>
                                                </Field>
                                                <ErrorMessage
                                                    name='type'
                                                    component='div'
                                                    className='block text-error font-cgbold mt-2'
                                                />
                                            </div>
                                            <div className='w-1/2 pl-1'>
                                                <label
                                                    className='block font-cgbold leading-6'
                                                    htmlFor='topic'
                                                >
                                                    Topic
                                                </label>
                                                <Field
                                                    name='topic'
                                                    as='select'
                                                    className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                >
                                                    <option value=''></option>
                                                    {topics &&
                                                        topics.map((topic: Topic, index: number) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={topic.name}
                                                                >
                                                                    {topic.name}
                                                                </option>
                                                            )
                                                        })}
                                                </Field>
                                                <ErrorMessage
                                                    name='topic'
                                                    component='div'
                                                    className='block text-error font-cgbold mt-2'
                                                />
                                            </div>
                                        </div>

                                        <div className='flex mt-4'>
                                            <div className='w-1/2 pr-1'>
                                                <label
                                                    className='block font-cgbold leading-6'
                                                    htmlFor='dateCreated'
                                                >
                                                    Date
                                                </label>
                                                <DatePicker
                                                    value={dateCreated}
                                                    maxDate={new Date()}
                                                    onChange={(val: any) => {
                                                        setFieldValue('dateCreated', val)
                                                        setTimeout(() => {
                                                            setFieldTouched('dateCreated', true)
                                                        }, 10)
                                                        setDateCreated(
                                                            `${val.getDate()}/${
                                                                val.getMonth() + 1
                                                            }/${val.getFullYear()}`
                                                        )
                                                    }}
                                                    dateFormat='dd/MM/yyyy'
                                                    className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                />
                                                {/* <Field
                                                        type='text'
                                                        name='dateCreated'
                                                        placeholder='Enter your date of birth'
                                                        className={
                                                            '' +
                                                            (errors.dateCreated && touched.dateCreated
                                                                ? ' invalid'
                                                                : '')
                                                        }
                                                        style={{ display: 'none' }}
                                                    /> */}
                                                <ErrorMessage
                                                    name='dateCreated'
                                                    component='div'
                                                    className='block text-error font-cgbold mt-2'
                                                />
                                            </div>
                                            <div className='w-1/2 pl-1'>
                                                <label
                                                    className='block font-cgbold leading-6'
                                                    htmlFor='resFile'
                                                >
                                                    Resource file
                                                </label>
                                                {!file && (
                                                    <>
                                                        <Dropzone
                                                            onDrop={handleFileDrop}
                                                            noClick
                                                            noKeyboard
                                                            //accept="image/png, image/gif, image/jpg, image/jpeg"
                                                            //style={{ display: 'block', width: '150px', height: '150px' }}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div {...getRootProps()}>
                                                                    {/* <div className='dropZone mobileHide'>
                                                                            Drop a file here
                                                                            <img
                                                                                src={UploadIcon}
                                                                                alt='Upload Icon'
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    marginLeft: '20px',
                                                                                }}
                                                                            />
                                                                        </div> */}
                                                                    <input {...getInputProps()} />
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                        <div className='uploadButton'>
                                                            <input
                                                                type='file'
                                                                name='resFile'
                                                                /*accept=".png, .gif, .jpg, .jpeg"*/ className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                                onChange={handleFileUpload}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {file && file.name && (
                                                    <div className='flex'>
                                                        {/* {[
                                                                'image/jpeg',
                                                                'image/jpg',
                                                                'image/gif',
                                                                'image/png',
                                                            ].indexOf(file.type) === -1 && (
                                                                <div className='uploadPreviewFile' />
                                                            )}
                                                            {[
                                                                'image/jpeg',
                                                                'image/jpg',
                                                                'image/gif',
                                                                'image/png',
                                                            ].indexOf(file.type) > -1 && (
                                                                <div
                                                                    className='uploadPreview'
                                                                    style={{
                                                                        backgroundImage: `url(${URL.createObjectURL(
                                                                            file
                                                                        )})`,
                                                                    }}
                                                                />
                                                            )} */}
                                                        {file.name}
                                                        <img
                                                            src={DeleteIcon}
                                                            alt='Delete Icon'
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginLeft: '20px',
                                                            }}
                                                            onClick={handleFileDelete}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='mt-4'>
                                            <label
                                                className='block font-cgbold leading-6'
                                                htmlFor='content'
                                            >
                                                Content
                                            </label>
                                            <Field
                                                component='textarea'
                                                rows='6'
                                                name='content'
                                                placeholder='Enter some content'
                                                className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            />
                                            <ErrorMessage
                                                name='content'
                                                component='div'
                                                className='block text-error font-cgbold mt-2'
                                            />
                                        </div>
                                        <div className='mt-4'>
                                            <label
                                                className='block font-cgbold leading-6'
                                                htmlFor='url'
                                            >
                                                URL <span className='text-xs font-cg'>(optional)</span>
                                            </label>
                                            <Field
                                                type='text'
                                                name='url'
                                                placeholder='Enter a url'
                                                className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                            />
                                            <ErrorMessage
                                                name='url'
                                                component='div'
                                                className='block text-error font-cgbold mt-2'
                                            />
                                        </div>
                                        <div className='mt-4'>
                                            <label className='block font-cgbold leading-6'>
                                                Hashtags <span className='text-sm font-cg'>(optional)</span>
                                            </label>

                                            <div className='mt-2 p-2 border border-black/20 rounded-lg max-h-60 overflow-y-scroll'>
                                                {hashtags.map((i: any, c: number) => (
                                                    <div key={c}>
                                                        <input
                                                            type='checkbox'
                                                            onChange={(val) => handleTags(val.target.value)}
                                                            value={i.value}
                                                        />{' '}
                                                        {i.text}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className='my-4'>
                                            <div className='flex justify-between'>
                                                <button
                                                    type='submit'
                                                    className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting && <div className='loading'></div>}
                                                    Add Resource
                                                </button>
                                                <button
                                                    type='button'
                                                    className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                                    onClick={() => {
                                                        setModalIsOpen(false)
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResourceUploadForm
