import React, { useCallback, useEffect, useState } from 'react'

type PF = {
    feeling: string
}

const PostFeeling: React.FC<PF> = ({ feeling }) => {
    const [img, setImg] = useState()

    const fetchFeelingImage = useCallback(async () => {
        try {
            if (feeling) {
                const img = await import(`../../assets/images/feeling/${feeling}.svg`)

                setImg(img.default)
            }
        } catch (err) {
            console.log(err)
        }
    }, [feeling])

    useEffect(() => {
        fetchFeelingImage()
    }, [fetchFeelingImage])

    return (
        <div className='flex justify-center max-w-fit items-center bg-yellow1 rounded-full font-cgbold py-0 px-6 mr-2 my-1'>
            <div className='w-9'>
                <img
                    src={img ? img : undefined}
                    alt='feeling'
                />
            </div>
            Feeling {feeling}
        </div>
    )
}

export default PostFeeling
