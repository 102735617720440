// @ts-nocheck

import React, { useCallback, useState, useEffect } from 'react'
import Masthead from '../layout/MastHead'
import axios from 'axios'
import Post from './Post'
import { getUser } from '../auth/LoginState'
import PostUploadForm from './PostUploadForm'
import IconPostThought from '../../assets/images/icon_postthought.svg'
import IconPastKnowledge from '../../assets/images/icon_postknowledge.svg'
import IconPostQuestion from '../../assets/images/icon_postquestion.svg'
import IconPostFeel from '../../assets/images/icon_postfeel.svg'
import ScrollToTop from '../layout/ScrollToTop'
import PostsNeedApproving from '../admin/PostsNeedApproving'
import { ObjectUnsubscribedError, debounce } from 'rxjs'


const CommunityIndex: React.FC = () => {
    const usr = getUser()
    const startDate = new Date()
    const [user, setUser] = useState<any>(null)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [topic, setTopic] = useState('')
    const [data, setData] = useState<{item: any}[]>([])
    const [users, setUsers] = useState<any | null>(null)
    const [filter, setFilter] = useState<String | null>(null)
    const [postIds, setPostIds] = useState<{item: string}[]>([])
    const [isGettingPosts, setIsGettingPosts] = useState<Boolean>(true)

    const postContent = (topic: string) => {
        setTopic(topic)
        setModalIsOpen(true)
    }

    const getPosts = async () => {
        try {
            setIsGettingPosts(true)
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/post/`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                params: {
                    skipIds: postIds,
                    startDate: startDate,
                },
            })

            if (res.status === 200) {
                setData((currentData) => [...currentData, ...res.data.posts])
                setPostIds((currentData) => [...currentData, ...res.data.postIds])
                setUsers({...users, ...res.data.users})
                if (res.data.u._id) setUser(res.data.u) 
                
            } else {
                console.log('error with response')
            }
            setIsGettingPosts(false)
        } catch (error) {
            console.log(error)
        }
    }

    const getLoggedInUser = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/account/${usr.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${usr.token}`,
                },
                data: {},
            })

            if (res.status == 200){
                setUser(res.data)
            } else {
                console.log(res.error)
            }
        } catch (error) {
            console.log(error)
        }
    })

    const updateFilter = (filter) => {
        setFilter(filter)
    }



    const handleScroll = (e) => {
        let windowHeight = Math.ceil(e.target.scrollingElement.scrollHeight - window.innerHeight)
        let scrollTop = Math.ceil(e.target.scrollingElement.scrollTop)
        
        if (windowHeight == scrollTop) {
            getPosts()
        }
    };

    useEffect(() => {
        getPosts()
    }, [])

    useEffect(() => {
        
        window.addEventListener("scroll", handleScroll);
    
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, [postIds])

    return (
        <>
            <Masthead />
            <div 
                className='container mx-auto w-full max-w-3xl mt-16 text-center flex flex-col items-center px-2'
            >
                <h2 className='text-4xl md:text-5xl font-cgbold mt-40 md:mt-20'>CommonGround Community</h2>
                <h4 className='text-md my-4'>
                    Welcome to the community feed - here you can create your own posts and read or respond to other
                    community members.
                </h4>
                <div className='bg-white grid grid-cols-2 md:grid-cols-4 w-full md:w-[80vw] h-[20vh] md:h-[20vh] items-center justify-between border-2 border-black/20 rounded-xl'>
                    <div
                        className='cursor-pointer w-full h-full bg-green3 hover:bg-green2 rounded-tl-xl md:rounded-bl-xl flex flex-col justify-center items-center'
                        onClick={() => {
                            postContent('thought')
                        }}
                    >
                        <div>
                            <img
                                className='h-12 md:h-20'
                                src={IconPostThought}
                                alt='Post Thought'
                                width={70}
                            />
                        </div>
                        <p className='font-cgbold text-xs md:text-lg mt-1'>Post a thought</p>
                    </div>
                    <div
                        className='cursor-pointer w-full h-full bg-red2 hover:bg-red1 rounded-tr-xl md:rounded-tr-none flex flex-col justify-center items-center'
                        onClick={() => {
                            postContent('knowledge')
                        }}
                    >
                        <div>
                            <img
                                className='h-12 md:h-20'
                                src={IconPastKnowledge}
                                alt='Past Knowledge'
                                width={70}
                            />
                        </div>
                        <p className='font-cgbold text-xs md:text-lg mt-1'>Share knowledge</p>
                    </div>
                    <div
                        className='cursor-pointer w-full h-full bg-purple2 hover:bg-purple1 rounded-bl-xl md:rounded-bl-none flex flex-col justify-center items-center'
                        onClick={() => {
                            postContent('question')
                        }}
                    >
                        <div>
                            <img
                                className='h-12 md:h-20'
                                src={IconPostQuestion}
                                alt='Post Question'
                                width={70}
                            />
                        </div>
                        <p className='font-cgbold text-xs md:text-lg mt-1'>Ask a question</p>
                    </div>
                    <div
                        className='cursor-pointer w-full h-full md:rounded-tr-xl rounded-br-xl bg-green5 hover:bg-green2 flex flex-col justify-center items-center'
                        onClick={() => {
                            postContent('feeling')
                        }}
                    >
                        <div>
                            <img
                                className='h-12 md:md:h-20'
                                src={IconPostFeel}
                                alt='How I am feeling'
                                width={70}
                            />
                        </div>
                        <p className='font-cgbold text-xs md:text-lg mt-1'>I am feeling</p>
                    </div>
                </div>
            </div>
            
            
            <div className='container mx-auto w-full max-w-4xl mt-16 mb-32 px-1'>
                {filter? <div 
                            onClick={() => {updateFilter(null)}}
                            className='rounded-full font-cgbold text-sm py-2 px-6 bg-green2 mr-2 w-fit cursor-pointer'
                        >
                            Show all community posts
                        </div> : ''}
                {data
                    ? data.map((p, c) => {

                            if(p && (!filter || p.post.topic.name == filter)){
                                return (
                                    <Post
                                        key={c}
                                        post={p.post}
                                        user={users? users[p.post.user]: null}
                                        users={users}
                                        loggedInUser={user}
                                        links={p.links}
                                        getPosts={getPosts}
                                        getLoggedInUser={getLoggedInUser}
                                        updateFilter={updateFilter}
                                    />
                                )
                            } else {
                                return ''
                            }
                      })
                    : null}
                {
                    isGettingPosts && (
                    <div className='mt-4 w-full text-center'>Loading posts...</div>
                    )
                }
            </div>
            
            {modalIsOpen ? (
                <PostUploadForm
                    setModalIsOpen={setModalIsOpen}
                    topics={[{ number: -1, text: topic }]}
                    getPosts={getPosts}
                />
            ) : null}

            <ScrollToTop />
        </>
    )
}

export default CommunityIndex
