import React, { useState } from "react"


type EC = {
    content: String
}

const ExpandableContent: React.FC<EC> = ({ content }) => {
    const [expanded, setExpanded] = useState(false)

    const includeNewLines = (article: String) => {
        let split = article.split('\r\n')
        let lines: any[] = []
        split.forEach((line) => {
            console.log(line == '')
            lines.push(line? line : <br></br>)
        })
        return lines
    }

    return (
        <div 
            onClick={() => setExpanded(!expanded)}
            className={expanded? 
                "w-full max-w-[600px] overflow-hidden break-words cursor-default" 
                : 
                "w-full max-w-[600px] whitespace-nowrap cursor-default text-ellipsis overflow-hidden "
            }   
        >
            {content.includes('\n')? includeNewLines(content).map((line, index) => {
                return <p key={index}>
                    {line}
                </p>
            } ) : content}
        </div>
    )
}

export default ExpandableContent