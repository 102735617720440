import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { useLocation } from 'react-router-dom'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import { getUser } from '../auth/LoginState'
import ApproveUser from './ApproveUser'

type U = {
    uid: string
}

const NewRegistrations: React.FC = () => {
    const user = getUser()
    const location = useLocation().state as U
    const [users, setUsers] = useState<any[]>([])
    const [modal, setModal] = useState<boolean>(false)
    const [formUser, setFormUser] = useState()

    const getUsers = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/admin/users/not-verified`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setUsers(res.data.users)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [user.token])

    const rejectAccount = async (id: string): Promise<string> => {

        try {
            const res = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_SERVER}/api/admin/users/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                }
            })

            if(res.status === 200){
                getUsers()

                return Notification({
                    message: 'Account was rejected.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message? error.message: error,
                type: 'danger'
            })
        };
    }

    useEffect(() => {
        getUsers()
    }, [setUsers, getUsers])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Manage Users</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                {users && users.length > 0 ? (
                    <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full'>
                        <thead>
                            <tr className='bg-blue2'>
                                <th className='py-2 px-4'>#</th>
                                <th className='py-2 px-4'>Username</th>
                                <th className='py-2 px-4'>Name</th>
                                <th className='py-2 px-4'>Bio</th>
                                <th className='py-2 px-4'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((i: any, c: any) => {
                                return (
                                    <tr
                                        key={c}
                                        className={
                                            location && i._id === location.uid
                                                ? 'border-b border-black/20 hover:bg-blue-100 bg-green3'
                                                : 'border-b border-black/20 hover:bg-blue-100'
                                        }
                                    >
                                        <td className='py-2 px-4 text-center'>{c + 1}</td>
                                        <td className='py-2 px-4'>{i.username}</td>
                                        <td className='py-2 px-4'>{`${i.firstName} ${i.surname}`}</td>
                                        <td className='py-2 px-4'>{i.biography}</td>
                                        <td className='py-2 px-4 text-center'>
                                            <button
                                                className='rounded-full py-2 px-4 bg-green1 font-cgbold text-white mr-2'
                                                onClick={() => {
                                                    setModal(true)
                                                    setFormUser(i)
                                                }}
                                            >
                                                Approve account
                                            </button>
                                            <button
                                                type='button'
                                                className='bg-blue1 text-white, font-cgbold rounded-full py-2 px-6'
                                                onClick={() => rejectAccount(i._id)}
                                            >
                                                Reject Account
                                            </button>
                                            {/* <button className='rounded-full py-2 px-4 bg-red1 font-cgbold'>
                                                Remove account
                                            </button> */}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className='text-center font-cgbold'>There are currently no new registrations!</div>
                )}
            </div>

            {modal ? (
                <ApproveUser
                    modal={setModal}
                    user={formUser}
                    loadUsers={getUsers}
                />
            ) : null}
        </>
    )
}

export default NewRegistrations
