import React from 'react'
import CommentsIcon from '../../assets/images/icon_comments.svg'

type CH = {
    showComments: boolean
    setShowComments: (param: boolean) => void
    count: number
}

const CommentsHandler: React.FC<CH> = ({ showComments, setShowComments, count }) => {
    return (
        <>
            <div
                className='flex flex-col text-xs items-center mr-2 md:mr-4 text-blue1 font-cgbold cursor-pointer'
                onClick={() => setShowComments(!showComments)}
            >
                <div className='mb-1'>Comments</div>
                <div className='justify-center items-center'>
                    <img
                        src={CommentsIcon}
                        alt='comments'
                        className={showComments ? 'w-10 rounded-full p-1 bg-blue2' : 'w-10 rounded-full p-1 bg-yellow1'}
                    />
                </div>
                <div className='mt-1'>{count > 0 ? count : '0'}</div>
            </div>
        </>
    )
}

export default CommentsHandler
