import { BehaviorSubject } from 'rxjs'
import { fetchWrapper } from '../helpers'

const userSubject = new BehaviorSubject(null)
const usersSubject = new BehaviorSubject([])
const baseUrl = `${process.env.REACT_APP_SERVER}/api/user`

const getCurrentUser = () => {
	// TODO create cache
	return fetchWrapper.get(`${baseUrl}/current`, {}).then((u) => {
		userSubject.next(u)
		return u
	})
}

const getUserById = (id) => {
	return ''
}

const follow = (id) => {
	return fetchWrapper.post(`${baseUrl}/${id}/follow`)
}

const notifications = () => {
	return fetchWrapper.post(`${baseUrl}/notifications`)
}

export const userService = {
	getCurrentUser,
	getUserById,
	follow,
	notifications,
	user: userSubject.asObservable(),
	users: usersSubject.asObservable(),
	get userValue() {
		return userSubject.value
	},

	itemDetails(id) {
		const items = usersSubject.value
		for (const item of items) {
			if (item.id && item.id === id) {
				return item
			}
		}
		return null
	},

	addItem(data) {
		let items = usersSubject.value
		let found =
			items
				.map(function (item) {
					return item.id
				})
				.indexOf(data.id) > -1
		if (!found) {
			items.push(data)
			usersSubject.next(items)
		}
	},

	updateItem(id, data) {
		let items = usersSubject.value
		for (var i = 0; i < items.length; i++) {
			if (items[i].id === id) {
				for (var field in data) {
					if (Object.prototype.hasOwnProperty.call(data, field)) {
						items[i][field] = data[field]
					}
				}
			}
		}
		usersSubject.next(items)
		return items
	},
}
