// @ts-nocheck

import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Notification } from '../notifications'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { useUser } from '../../helpers/useUser'
import * as Yup from 'yup'
import RequestPostDeleteModal from '../admin/RequestPostDeleteModal'
import Masthead from '../layout/MastHead'
import MyCommunity from './MyCommunity'
import { formatDate } from '../../helpers/dates'
import a1 from '../../assets/images/avatar1.png'
import a2 from '../../assets/images/avatar2.png'
import a3 from '../../assets/images/avatar3.png'
import a4 from '../../assets/images/avatar4.png'
import a5 from '../../assets/images/avatar5.png'
import a6 from '../../assets/images/avatar6.png'
import a7 from '../../assets/images/avatar7.png'
import a8 from '../../assets/images/avatar8.png'
import a9 from '../../assets/images/avatar9.png'
import Happy from '../../assets/images/reactions/Happy.svg'
import CommentsIcon from '../../assets/images/icon_comments.svg'

type FormTypes = {
    biography: string
    story: string
    pronouns: string
}

const Profile: React.FC = () => {
    const nav = useNavigate()
    const user = useUser()
    const [genderOther, setGenderOther] = useState<string>('')
    const [avatar, setAvatar] = useState<string>('')
    const [posts, setPosts] = useState<any>([])
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [deletePostId, setDeletePostId] = useState<string>('')

    const validationSchema = Yup.object().shape({
        biography: Yup.string(),
        story: Yup.string(),
        pronouns: Yup.string(),
        gender: Yup.string(),
    })

    const updatePrivacySetting = async (type: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/account/privacy-setting/${type}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {
                    user: user._id,
                },
            })

            if (res.status === 201) {
                return Notification({ message: 'Your privacy settings have been updated.', type: 'success' })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error) {
            return Notification({ message: error, type: 'error' })
        }
    }

    const updateGenderOther = (val: string) => {
        setGenderOther(val)
    }

    const getPosts = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/post/${user._id}/posts`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setPosts(res.data.p)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [user])

    const onSubmit = async (
        fields: FormTypes,
        { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
    ) => {
        setStatus()

        const reg = /^[a-zA-Z\s]*$/

        fields.avatar = avatar

        if (genderOther && genderOther !== '' && genderOther.match(reg)) {
            fields.genderOther = genderOther
        } else {
            fields.genderOther = ''
        }

        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/account/profile/update`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {
                    data: fields,
                    user: user._id,
                },
            })

            if (res.status === 201) {
                let session = window.sessionStorage.getItem('CGUSER')
                let local = window.localStorage.getItem('CGUSER')

                if (local) {
                    const temp = JSON.parse(local)

                    temp.avatar = fields.avatar

                    if (local) {
                        window.localStorage.setItem('CGUSER', JSON.stringify({ ...temp }))
                    }
                }

                setSubmitting(false)

                // nav('/community')

                return Notification({
                    message: 'You have successfully updated your profile.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const unhidePost = async (id: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/unhide`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {
                    postId: id,
                },
            })

            if (res.status === 201) {
                getPosts()

                return Notification({
                    message: 'Your post is now visible in the Community Feed.',
                    type: 'sucess',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        if (user) {
            if (user.avatar) {
                setAvatar(user.avatar)
            }

            if (user.genderOther !== '') {
                setGenderOther(user.genderOther)
            }

            getPosts()
        }
    }, [user, getPosts])

    return (
        <>
            <Masthead />
            {user ? (
                <>
                    <div className='container mt-6 max-w-5xl mx-auto p-3 md:px-2 '>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                biography: user ? user.biography : '',
                                story: user ? user.story : '',
                                pronouns: user ? user.pronouns : '',
                                gender: user ? user.gender : '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ values, setFieldValue, isSubmitting }) => (
                                <Form>
                                    <div className='container mx-auto w-full max-w-5xl mt-44 md:mt-16 text-center'>
                                        <h2 className='text-5xl font-cgbold mt-20'>Your Profile</h2>
                                        <h4 className='text-lg font-cgbold my-2'>
                                            Here you can view and edit your profile. You can edit your bio, pronouns, or
                                            gender, and change your avatar. Once you are happy with your changes, click
                                            “update profile”. You can also update your privacy settings too.
                                        </h4>

                                        <div className='w-full flex flex-col md:flex-row justify-center items-start pt-4 text-left'>
                                            <div className='flex flex-col md:mr-2 w-full md:w-1/3'>
                                                <div className='mt-4 w-full'>
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='biography'
                                                    >
                                                        Write a little about yourself, if you want to. (optional)
                                                    </label>
                                                    <span className='text-xs text-gray-700'>
                                                        It is up to you what you choose to share. You can say why you are using CommonGround or about your health journey so far. You can say what your hobbies or interests are. You can edit this and hide/display on your profile at any time. Please remember to remain anonymous.
                                                    </span>
                                                    <Field
                                                        component='textarea'
                                                        rows='2'
                                                        name='biography'
                                                        placeholder='What do you want to write? Keep it short: a sentence or two.'
                                                        maxLength='200'
                                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                    />
                                                    <ErrorMessage
                                                        name='biography'
                                                        component='div'
                                                        className='block text-error font-cgbold mt-2'
                                                    />
                                                </div>
                                                <div className='mt-4 w-full'>
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='story'
                                                    >
                                                        Your Story (optional)
                                                    </label>
                                                    <span className='text-xs text-gray-700'>
                                                        Here you can write more about your story if you wish to. Please remember that this will be visible on your profile to other CommonGround community members. Please remember to remain anonymous.
                                                    </span>
                                                    <Field
                                                        component='textarea'
                                                        rows='6'
                                                        name='story'
                                                        placeholder="What's your story?"
                                                        maxLength='2000'
                                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                    />
                                                    <ErrorMessage
                                                        name='story'
                                                        component='div'
                                                        className='block text-error font-cgbold mt-2'
                                                    />
                                                </div>
                                                <div className='mt-4 w-full'>
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='pronouns'
                                                    >
                                                        Pronouns
                                                    </label>
                                                    <span className='text-xs text-gray-700'>
                                                        Add your pronouns here if you want to.
                                                    </span>
                                                    <Field
                                                        type='text'
                                                        name='pronouns'
                                                        placeholder="What's pronouns?"
                                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                    />
                                                    <ErrorMessage
                                                        name='pronouns'
                                                        component='div'
                                                        className='block text-error font-cgbold mt-2'
                                                    />
                                                </div>
                                                <div className='mt-4 w-full'>
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='gender'
                                                    >
                                                        Gender
                                                    </label>
                                                    <span className='text-xs text-gray-700'>
                                                        Select your gender if you want to
                                                    </span>
                                                    <Field
                                                        component='select'
                                                        name='gender'
                                                        placeholder='Plese select your gender'
                                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                        onChange={(e: any) => {
                                                            setFieldValue('gender', e.target.value)

                                                            if (e.target.value === 'Prefer to self-describe') {
                                                                document
                                                                    .getElementById('gender_other')
                                                                    .classList.remove('hidden')
                                                            } else {
                                                                document
                                                                    .getElementById('gender_other')
                                                                    .classList.add('hidden')
                                                                setGenderOther('')
                                                            }
                                                        }}
                                                    >
                                                        <option value=''>Please select an option</option>
                                                        <option value='Prefer not to say'>Prefer not to say</option>
                                                        <option value='Female, including transgender females'>
                                                            Female, including transgender females
                                                        </option>
                                                        <option value='Male, including transgender males'>
                                                            Male, including transgender males
                                                        </option>
                                                        <option value='non-binary'>non-binary</option>
                                                        <option value='Prefer to self-describe'>
                                                            Prefer to self-describe
                                                        </option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name='gender'
                                                        component='div'
                                                        className='block text-error font-cgbold mt-2'
                                                    />
                                                </div>
                                                <div
                                                    id='gender_other'
                                                    className={
                                                        user.gender === 'Prefer to self-describe'
                                                            ? ' mt-4 w-full'
                                                            : 'hidden mt-4 w-full'
                                                    }
                                                >
                                                    <label
                                                        className='block font-cgbold leading-6'
                                                        htmlFor='gender'
                                                    >
                                                        Self describe gender
                                                    </label>
                                                    <input
                                                        type='text'
                                                        name='gender_other'
                                                        onChange={(e) => updateGenderOther(e.target.value)}
                                                        value={genderOther}
                                                        className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                                    />
                                                </div>
                                            </div>

                                            <div className='mt-4 md:mt-0 md:ml-2 w-full md:w-2/3'>
                                                {user.role !== 'admin' ? (
                                                    <div className='flex flex-col items-center justify-center'>
                                                        <h4 className='text-xl font-cgbold'>Select your avatar</h4>
                                                        <div className='flex flex-row flex-wrap items-center justify-center text-center'>
                                                            <label>
                                                                <img
                                                                    src={a1}
                                                                    alt='avatar 1'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar1'
                                                                    checked={avatar === 'avatar1'}
                                                                    onClick={() => setAvatar('avatar1')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a2}
                                                                    alt='avatar 2'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar2'
                                                                    checked={avatar === 'avatar2'}
                                                                    onClick={() => setAvatar('avatar2')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a3}
                                                                    alt='avatar 3'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar3'
                                                                    checked={avatar === 'avatar3'}
                                                                    onClick={() => setAvatar('avatar3')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a4}
                                                                    alt='avatar 4'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar4'
                                                                    checked={avatar === 'avatar4'}
                                                                    onClick={() => setAvatar('avatar4')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a5}
                                                                    alt='avatar 5'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar5'
                                                                    checked={avatar === 'avatar5'}
                                                                    onClick={() => setAvatar('avatar5')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a6}
                                                                    alt='avatar 6'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar6'
                                                                    checked={avatar === 'avatar6'}
                                                                    onClick={() => setAvatar('avatar6')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a7}
                                                                    alt='avatar 7'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar7'
                                                                    checked={avatar === 'avatar7'}
                                                                    onClick={() => setAvatar('avatar7')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a8}
                                                                    alt='avatar 8'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar8'
                                                                    checked={avatar === 'avatar8'}
                                                                    onClick={() => setAvatar('avatar8')}
                                                                />
                                                            </label>
                                                            <label>
                                                                <img
                                                                    src={a9}
                                                                    alt='avatar 9'
                                                                    width={90}
                                                                />
                                                                <Field
                                                                    type='radio'
                                                                    name='avatar'
                                                                    value='avatar9'
                                                                    checked={avatar === 'avatar9'}
                                                                    onClick={() => setAvatar('avatar9')}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className='column'
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <h4 className='text-2xl font-cgbold mb-4'>
                                                            Select your avatar
                                                        </h4>
                                                        You cannot change your avatar as your account is the Admin
                                                        Account.
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='w-full my-4 text-center'>
                                            <button
                                                type='submit'
                                                className='rounded-full py-2 px-6 bg-green1 text-white font-cgbold'
                                            >
                                                {isSubmitting && <div className='loading'></div>}
                                                Update Profile
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        <hr />

                        <div className='mt-4'>
                            <h2 className='text-2xl font-cgbold'>Privacy Settings</h2>
                            <p className='text-sm my-4'>
                                These privacy toggles allow you to choose whether this information is only visible to
                                you or is visible to other community members on CommonGround. You can change this at any
                                time by switching the toggle.
                            </p>
                            {user.role !== 'admin' ? (
                                <table className='table-auto'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '30%' }}>Display A little about me</td>
                                            <td style={{ width: '20%', textAlign: 'center' }}>
                                                <Toggle
                                                    id='bio-toggle'
                                                    defaultChecked={user.privacy.bio}
                                                    disabled={user.role === 'admin' ? true : false}
                                                    onChange={() => updatePrivacySetting('bio')}
                                                />
                                            </td>
                                            <td rowSpan={3}>
                                                If the toggle is green, other community members can view the information
                                                on your profile. If you want to hide it, click the toggle so that it
                                                turns grey.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Display My Story</td>
                                            <td style={{ width: '20%', textAlign: 'center' }}>
                                                <Toggle
                                                    id='gender-toggle'
                                                    defaultChecked={user.privacy.story}
                                                    disabled={user.role === 'admin' ? true : false}
                                                    onChange={() => updatePrivacySetting('story')}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Display my gender</td>
                                            <td style={{ width: '20%', textAlign: 'center' }}>
                                                <Toggle
                                                    id='gender-toggle'
                                                    defaultChecked={user.privacy.gender}
                                                    disabled={user.role === 'admin' ? true : false}
                                                    onChange={() => updatePrivacySetting('gender')}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Display my pronouns</td>
                                            <td style={{ width: '20%', textAlign: 'center' }}>
                                                <Toggle
                                                    id='pronouns-toggle'
                                                    defaultChecked={user.privacy.pronouns}
                                                    disabled={user.role === 'admin' ? true : false}
                                                    onChange={() => updatePrivacySetting('pronouns')}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <div>Admin accounts cannot adjust privacy settings</div>
                            )}
                        </div>

                        <hr className='my-6' />

                        <div className='mt-4'>
                            <MyCommunity loggedInUser={user} />
                        </div>

                        <hr className='my-6' />

                        <div className='mt-4'>
                            <h2 className='text-2xl font-cgbold'>My recent posts</h2>
                            <p className='text-sm mt-2 mb-4'>Your recent posts are shown below.</p>

                            {posts &&
                                posts.map((post: any, count: number) => {
                                    if (post.status === 'approved') {
                                        const reactionsSum =
                                            post.stats.counters.happy +
                                            post.stats.counters.hear +
                                            post.stats.counters.laughter +
                                            post.stats.counters.proud +
                                            post.stats.counters.sad +
                                            post.stats.counters.support
                                        return (
                                            <div
                                                key={count}
                                                className='w-full p-4 md:p-6 rounded-lg border border-black/20 my-2 md:my-4 shadow-lg'
                                            >
                                                <div className='flex flex-col justify-between items-center'>
                                                    <div
                                                        className='w-full text-blue1 cursor-pointer border-b border-b-black/20 pb-2'
                                                        onClick={() =>
                                                            nav(`/community/article/${post._id}`, {
                                                                state: {
                                                                    myProfileOrigin: true,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        {post.title}
                                                    </div>

                                                    <div className='flex flex-row items-center w-72 mt-4 justify-between'>
                                                        <div className='flex flex-col justify-center items-center'>
                                                            <div className='font-cgbold'>Posted: </div>
                                                            <div>{formatDate(post.dateCreated)}</div>
                                                        </div>
                                                        <div className='flex flex-col justify-center items-center'>
                                                            <div>
                                                                <img
                                                                    src={Happy}
                                                                    alt='Happy'
                                                                    className='w-10 rounded-full p-1 bg-yellow1'
                                                                />
                                                            </div>
                                                            <div>{reactionsSum}</div>
                                                        </div>
                                                        <div className='flex flex-col justify-center items-center'>
                                                            <div>
                                                                <img
                                                                    src={CommentsIcon}
                                                                    alt='Comments'
                                                                    className='w-10 rounded-full p-1 bg-yellow1'
                                                                />
                                                            </div>
                                                            <div>{post.numComments}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    return ''
                                })}
                        </div>

                        <hr className='my-6' />

                        <div className='mt-4 mb-40'>
                            <h2 className='text-2xl font-cgbold'>Posts that I have hidden from the community feed</h2>
                            <p className='text-sm mb-4 mt-2'>
                                The posts below you have chosen to hide from the community feed. If you wish community
                                members to see your post again, click "Unhide Post". If you would like your post to be
                                deleted from CommonGround by our moderation team, click "Request my post to be deleted"
                            </p>
                            {posts.map((post: any, count: number) => {
                                if (post.status == 'USER HIDDEN') {
                                    return (
                                        <div
                                            key={count}
                                            className='w-full p-4 md:p-6 rounded-lg border border-black/20 my-2 md:my-4 shadow-lg'
                                        >
                                            <div
                                                className='w-full text-blue1 cursor-pointer border-b border-b-black/20 pb-2'
                                                onClick={() =>
                                                    nav(`/community/article/${post._id}`, {
                                                        state: {
                                                            userProfileOrigin: true,
                                                        },
                                                    })
                                                }
                                            >
                                                {post.title}
                                            </div>

                                            <div className='flex flex-col md:flex-row items-center mt-4 justify-between'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='font-cgbold'>Posted: </div>
                                                    <div>{formatDate(post.dateCreated)}</div>
                                                </div>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='font-cgbold'>Hidden: </div>
                                                    <div>{formatDate(post.dateHidden)}</div>
                                                </div>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <button
                                                        className='rounded-full py-2 px-6 bg-green1 font-cgbold text-white'
                                                        onClick={() => unhidePost(post._id)}
                                                    >
                                                        Unhide Post
                                                    </button>
                                                    <button
                                                        className='rounded-full py-2 px-6 bg-red1 font-cgbold mt-2'
                                                        onClick={() => {
                                                            setDeleteModal(true)
                                                            setDeletePostId(post._id)
                                                        }}
                                                    >
                                                        Request my post to be deleted
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else if (post.status == 'USER DELETE REQUEST'){
                                    return (
                                        <div
                                            key={count}
                                            className='w-full p-4 md:p-6 rounded-lg border border-black/20 my-2 md:my-4 shadow-lg'
                                        >
                                            <div
                                                className='w-full text-blue1 cursor-pointer border-b border-b-black/20 pb-2'
                                                onClick={() =>
                                                    nav(`/community/article/${post._id}`, {
                                                        state: {
                                                            userProfileOrigin: true,
                                                        },
                                                    })
                                                }
                                            >
                                                {post.title}
                                            </div>

                                            <div className='flex flex-col md:flex-row items-center mt-4 justify-between'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='font-cgbold'>Posted: </div>
                                                    <div>{formatDate(post.dateCreated)}</div>
                                                </div>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='font-cgbold'>Hidden: </div>
                                                    <div>{formatDate(post.dateHidden)}</div>
                                                </div>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <button
                                                        className='rounded-full py-2 px-6 bg-green1 font-cgbold text-white'
                                                        onClick={() => unhidePost(post._id)}
                                                    >
                                                        Unhide Post
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                return null
                            })}
                        </div>
                    </div>
                </>
            ) : null}

            {deleteModal && deletePostId ? (
                <RequestPostDeleteModal
                    setDeleteModal={setDeleteModal}
                    postId={deletePostId}
                />
            ) : null}
        </>
    )
}

export default Profile
