import React, { createContext, useEffect, useState } from 'react'

interface AuthContextType {
    isAuthenticated: boolean
    login: () => void
    logout: () => void
}

type AuthComponent = {
    children: React.ReactNode
}

export const AuthContext = createContext<AuthContextType>({
    isAuthenticated: false,
    login: () => {},
    logout: () => {},
})

export const AuthProvider: React.FC<AuthComponent> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const login = () => setIsAuthenticated(true)
    const logout = () => setIsAuthenticated(false)

    useEffect(() => {
        const token = window.sessionStorage.getItem('CGUSER')
        if (token) {
            login()
        }
    })

    return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>
}
