import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { userInteractionLog } from '../../helpers/logs'
import { useNavigate } from 'react-router-dom'
import { urlParser } from '../../helpers/logs'




const Tag: React.FC | any = (data: any) => {
    const user = data.user
    const username = data.user && user.match(/[a-zA-Z0-9_]+/gm)[0]
    const ext = user.match(/[^a-zA-Z0-9_]+/gm)? user.match(/[^a-zA-Z0-9_]+/gm)[0] : ' '
    const nav = useNavigate()
    const [userid, setUserid] = useState(null)

    const userExists = async function () {
        const res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/api/user/username/${username}`,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })

        if (res.status === 200 && res.data.id){
            setUserid(res.data.id)
        }
    }

    useEffect(() => {

        if (username) userExists();

    },[])

    return <>
        {user && userid !== null?   <span> <a 
                                className='text-blue1 font-bold'
                                onClick={() => {
                                    userInteractionLog(urlParser(window.location.href), userid, 'profileView', true)
                                    nav(`/user/profile/${userid}`)
                                }}
                            >
                                {`@${username}`}
                            </a>{ext + ' '}</span>
                            : 
                            `@${user} `}
    </> 
}

export default Tag