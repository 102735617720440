import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { userAction } from '../../helpers/logs'
import MenuIcon from '../../assets/images/dot_menu.svg'
import ModerationPostModal from './ModerationPostModal'
import PostEdit from './PostEdit'
import PostDelete from './PostDelete'
import PostEscalate from './PostEscalate'
import PostRequestDelete from './PostRequestDelete'
import { getUser } from '../auth/LoginState'

type M = {
    loggedInUser: any
    item: any
    getPosts: () => void
}

const Moderation: React.FC<M> = ({loggedInUser, item, getPosts }) => {
    const USER = loggedInUser
    const [visible, setVisible] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)
    const [modalEdit, setModalEdit] = useState<boolean>(false)
    const [modalDelete, setModalDelete] = useState<boolean>(false)
    const [modalEscalate, setModalEscalate] = useState<boolean>(false)
    const [modalRequestDelete, setModalRequestDelete] = useState<boolean>(false)
    // const [modalPin, setModalPin] = useState<boolean>(false)

    const togglePin = async (id: string) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/toggle-pin`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {
                    postId: id,
                },
            })

            if (res.status === 201) {
                getPosts()

                return Notification({
                    message: 'Pin has been toggled successfully.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    const hidePost = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/hide`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    postId: item._id,
                },
            })

            const hidden = {
                postId: item._id,
            }

            userAction(USER._id, 'Post', item._id, 'hide', {}, {}, {}, hidden)

            if (res.status === 201) {
                window.location.reload()

                return Notification({
                    message: 'Your post has been hidden from the Community Feed.',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (e.target.id !== 'modal') {
                setVisible(false)
            }
        })
    }, [])

    return (
        <>
            {(USER && item.userAdmin === false) || (USER && USER.role == 'admin') ? (
                <div
                    className='cursor-pointer'
                    id='modal'
                    onClick={() => {
                        setVisible(!visible)
                    }}
                >
                    <img
                        src={MenuIcon}
                        id='modal'
                        alt='menu icon'
                    />
                </div>
            ) : (
                <div>&nbsp;</div>
            )}
            {visible ? (
                <div
                    className='absolute bg-white border-2 border-blue1 -ml-72 translate-x-3 p-4 rounded-lg shadow-lg'
                    id='modal'
                >
                    {USER && USER._id !== item.user ? (
                        <div
                            onClick={() => {
                                setModal(true)
                                setVisible(false)
                            }}
                            className='cursor-pointer mt-4 md:mt-2 text-blue1 font-cgbold'
                        >
                            Flag this post to the moderators
                        </div>
                    ) : null}

                    {USER && USER._id === item.user ? (
                        <>
                            <div
                                onClick={() => hidePost()}
                                className='cursor-pointer mt-4 md:mt-2 text-blue1 font-cgbold'
                            >
                                Hide this post from Community Feed
                            </div>
                            {USER.role !== 'admin' ? (
                                <div
                                    onClick={() => {
                                        setModalRequestDelete(true)
                                        setVisible(false)
                                    }}
                                    className='cursor-pointer mt-4 md:mt-2 text-blue1 font-cgbold'
                                >
                                    Request to delete this post
                                </div>
                            ) : null}
                        </>
                    ) : null}

                    {USER && USER.role === 'admin' ? (
                        <>
                            <div
                                onClick={() => {
                                    setModalEdit(true)
                                    setVisible(false)
                                }}
                                className='cursor-pointer mt-4 md:mt-2 text-blue1 font-cgbold'
                            >
                                Edit this post
                            </div>
                            <div
                                onClick={() => {
                                    setModalDelete(true)
                                    setVisible(false)
                                }}
                                className='cursor-pointer mt-4 md:mt-2 text-blue1 font-cgbold'
                            >
                                Delete this post
                            </div>
                            <div
                                onClick={() => {
                                    setModalEscalate(true)
                                    setVisible(false)
                                }}
                                className='cursor-pointer mt-4 md:mt-2 text-blue1 font-cgbold'
                            >
                                Escalate this post
                            </div>
                            <div
                                onClick={() => {
                                    togglePin(item._id)
                                    setVisible(false)
                                }}
                                className='cursor-pointer mt-4 md:mt-2 text-blue1 font-cgbold'
                            >
                                {item.pinned ? <>Unpin this post</> : <>Pin this post</>}
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}

            {modal ? (
                <ModerationPostModal
                    item={item}
                    setModal={setModal}
                />
            ) : null}

            {modalEdit ? (
                <PostEdit
                    post={item}
                    modal={setModalEdit}
                    getPosts={getPosts}
                />
            ) : null}

            {modalDelete ? (
                <PostDelete
                    post={item}
                    modal={setModalDelete}
                    getPosts={getPosts}
                />
            ) : null}

            {modalEscalate ? (
                <PostEscalate
                    post={item}
                    modal={setModalEscalate}
                    getPosts={getPosts}
                />
            ) : null}

            {modalRequestDelete ? (
                <PostRequestDelete
                    post={item}
                    modal={setModalRequestDelete}
                    getPosts={getPosts}
                />
            ) : null}

            {/* {modalPin ? (
                <PostPin
                    post={item}
                    modal={setModalPin}
                    getPosts={getPosts}
                />
            ) : null} */}
        </>
    )
}

export default Moderation
