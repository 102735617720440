import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { useLocation, useNavigate } from 'react-router-dom'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import { getUser } from '../auth/LoginState'

type P = {
    pid: string
}

const FlaggedPosts: React.FC = () => {
    const user = getUser()
    const location = useLocation().state as P
    const nav = useNavigate()
    const [posts, setPosts] = useState<any>([])

    const getFlaggedPosts = useCallback(async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/admin/posts/flagged/list`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${user.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setPosts(res.data.posts)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }, [user.token, setPosts])

    useEffect(() => {
        getFlaggedPosts()
    }, [getFlaggedPosts])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>Flagged posts</h2>
                <Menu />
            </div>
            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                {posts && posts.length > 0 ? (
                    <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full'>
                        <thead>
                            <tr className='bg-blue2'>
                                <th className='pt-2 px-4'>#</th>
                                <th
                                    className='pt-2 px-4'
                                    style={{ width: '70%' }}
                                >
                                    Title
                                </th>
                                <th className='pt-2 px-4'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {posts.map((p: any, c: any) => {
                                return (
                                    <tr
                                        key={c}
                                        className={
                                            location && p._id === location.pid
                                                ? 'border-b border-black/20 hover:bg-blue-100 bg-green3'
                                                : 'border-b border-black/20 hover:bg-blue-100'
                                        }
                                    >
                                        <td className='py-2 px-4 text-center'>{c + 1}</td>
                                        <td className='py-2 px-4'>
                                            <div
                                                className='font-cgbold cursor-pointer text-blue1'
                                                onClick={() =>
                                                    nav(`/community/article/${p.post._id}`, {
                                                        state: {
                                                            adminOrigin: true,
                                                        },
                                                    })
                                                }
                                            >
                                                {p.post.title}
                                            </div>
                                        </td>
                                        <td className='py-2 px-4 text-center'>
                                            <button
                                                className='rounded-full py-2 px-6 bg-green1 text-white font-cgbold'
                                                onClick={() =>
                                                    nav(`/admin/posts/flagged/${p.post._id}`, {
                                                        state: {
                                                            post: p.post,
                                                            links: p.links,
                                                        },
                                                    })
                                                }
                                            >
                                                Review post
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>There are currently no flagged posts!</div>
                )}
            </div>
        </>
    )
}

export default FlaggedPosts
