export const hashtags = [
    {value: 'Anxiety', text: 'Anxiety'},
    {value: 'Awareness', text: 'Awareness'},
    {value: 'Appointments & Consultations', text: 'Appointments & Consultations'},
    {value: 'Childhood', text: 'Childhood'},
    {value: 'Chronic', text: 'Chronic'},
    {value: 'Depression', text: 'Depression'},
    {value: 'Diagnosis', text: 'Diagnosis'},
    {value: 'Diet', text: 'Diet'},
    {value: 'Disability', text: 'Disability'},
    {value: 'Eating & Food', text: 'Eating & Food'},
    {value: 'Education & Studying', text: 'Education & Studying'},
    {value: 'Exercise', text: 'Exercise'},
    {value: 'Family', text: 'Family'},
    {value: 'Fear', text: 'Fear'},
    {value: 'Flare-ups', text: 'Flare-ups'},
    {value: 'Friends', text: 'Friends'},
    {value: 'Finding the balance', text: 'Finding the balance'},
    {value: 'Future', text: 'Future'},
    {value: 'Health', text: 'Health'},
    {value: 'Hobbies', text: 'Hobbies'},
    {value: 'Hospital', text: 'Hospital'},
    {value: 'Isolation', text: 'Isolation'},
    {value: 'Keeping active', text: 'Keeping active'},
    {value: 'Lifestyle', text: 'Lifestyle'},
    {value: 'Loss', text: 'Loss'},
    {value: 'Loneliness', text: 'Loneliness'},
    {value: 'Mental Health & Well-being', text: 'Mental Health & Well-being'},
    {value: 'Mindfulness', text: 'Mindfulness'},
    {value: 'Motivation', text: 'Motivation'},
    {value: 'Money and finances', text: 'Money and finances'},
    {value: 'Media & Arts', text: 'Media & Arts'},
    {value: 'Medication', text: 'Medication'},
    {value: 'Nature', text: 'Nature'},
    {value: 'New symptoms', text: 'New symptoms'},
    {value: 'Pain', text: 'Pain'},
    {value: 'Positivity', text: 'Positivity'},
    {value: 'Recovery', text: 'Recovery'},
    {value: 'Relationships', text: 'Relationships'},
    {value: 'Relaxation', text: 'Relaxation'},
    {value: 'Reminiscing the past', text: 'Reminiscing the past'},
    {value: 'Routine & Daily Habits', text: 'Routine & Daily Habits'},
    {value: 'Self-care', text: 'Self-care'},
    {value: 'Self-growth', text: 'Self-growth'},
    {value: 'Sleep', text: 'Sleep'},
    {value: 'Stress', text: 'Stress'},
    {value: 'Support', text: 'Support'},
    {value: 'Something random', text: 'Something random'},
    {value: 'Talking with my healthcare team', text: 'Talking with my healthcare team'},
    {value: 'Tools and technology', text: 'Tools and technology'},
    {value: 'Travel', text: 'Travel'},
    {value: 'Treatment', text: 'Treatment'},
    {value: 'Walking', text: 'Walking'},
    {value: 'Work', text: 'Work'},
    {value: 'Worry', text: 'Worry'},
]

