import React, { useEffect, useState } from 'react'

import i1 from '../../assets/images/notifications/person.png'
import i2 from '../../assets/images/notifications/reply.png'
import i3 from '../../assets/images/notifications/comment.png'
import i4 from '../../assets/images/notifications/delete.png'
import i5 from '../../assets/images/notifications/modDelete.png'
import i6 from '../../assets/images/notifications/edit.png'
import i7 from '../../assets/images/notifications/flag.png'
import i8 from '../../assets/images/notifications/Sad.png'
import i9 from '../../assets/images/notifications/Laughter.png'
import i10 from '../../assets/images/notifications/Happy.png'
import i11 from '../../assets/images/notifications/Proud.png'
import i12 from '../../assets/images/notifications/I_support_you.png'
import i13 from '../../assets/images/notifications/I_hear_you.png'

type I = {
    icon: string,
}

const Icon: React.FC<I> = ({icon}) => {
    const [src, setSrc] = useState<string>('')

    useEffect(() => {
        switch (icon) {
            case 'person':
                setSrc(i1)
                break
            case 'reply':
                setSrc(i2)
                break
            case 'comment':
                setSrc(i3)
                break
            case 'delete':
                setSrc(i4)
                break
            case 'modDelete':
                setSrc(i5)
                break
            case 'edit':
                setSrc(i6)
                break
            case 'flag':
                setSrc(i7)
                break
            case 'Sad':
                setSrc(i8)
                break
            case 'Laughter':
                setSrc(i9)
                break
            case 'Happy':
                setSrc(i10)
                break
            case 'Proud':
                setSrc(i11)
                break
            case 'I support you':
                setSrc(i12)
                break
            case 'I hear you':
                setSrc(i13)
                break
            default:
                return
        }
    }, [])

    return <>
        <img
            src={src}
            width={40}
            height={40}
        >
        </img>
    </>
}

export default Icon
