import React from 'react'
import { Navigate } from 'react-router-dom'
import { isLoggedIn } from './LoginState'

interface RA {
    children: React.ReactNode
    redirectTo: string
}

const RequireAuth: React.FC<RA> = ({ children, redirectTo }) => {
    let auth = isLoggedIn()

    return <>{auth ? children : <Navigate to={redirectTo} />}</>
}

export default RequireAuth
