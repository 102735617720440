import React, { useContext, useEffect, useState } from 'react'
import { FormView, Hide } from 'grommet-icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { isMobile, isBrowser } from 'react-device-detect'
import { AuthContext } from '../../context/auth-context'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Notification } from '../notifications'
import axios from 'axios'
import * as Yup from 'yup'
import Masthead from '../layout/MastHead'

interface MsgData {
    msg: string
    from: any
}

const Login = () => {
    const location = useLocation().state as MsgData
    const { login } = useContext(AuthContext)
    const nav = useNavigate()
    const [device, setDevice] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (isMobile) {
            setDevice('Mobile')
        } else if (isBrowser) {
            setDevice('Browser')
        } else {
            setDevice('Unknown')
        }
    }, [location])

    const initialValues = {
        email: '',
        password: '',
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Email is invalid').required('Email is required'),
        password: Yup.string().required('Password is required'),
    })

    const onSubmit = async (values: any) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/account/login`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    email: values.email.toLowerCase(),
                    password: values.password,
                    device: device,
                },
                withCredentials: true,
            })

            if (res.status === 200) {
                const local = window.localStorage.getItem('CGUSER')
                let sameUser = true

                if (res.data.user.accountVerified === false) {
                    return nav('/account/not-verified')
                } else if (res.data.user.accountVerified === true) {
                    window.sessionStorage.setItem(
                        'CGUSER',
                        JSON.stringify({
                            id: res.data.user.id,
                            username: res.data.user.username,
                            studyId: res.data.user.studyId,
                            role: res.data.user.role,
                            roleAdmin: res.data.user.roleAdmin,
                            token: res.data.user.token,
                            avatar: res.data.user.avatar,
                            sessionId: res.data.user.sessionId,
                            accountSetup: res.data.user.accountSetup,
                            accountVerified: res.data.user.accountVerified,
                        })
                    )

                    // check if user logging in is the same user as cookie
                    if (local !== null) {
                        const localUser = JSON.parse(local)

                        if (localUser.email !== res.data.user.email) {
                            sameUser = false
                        }

                        if (sameUser) {
                            const lastLoginTS = new Date(JSON.parse(local).ts)
                            const currentTS = new Date()

                            // check if user logged in today otherwise send to 2FA
                            if (lastLoginTS.getDate() === currentTS.getDate()) {
                                nav('/community')

                                login()

                                return Notification({
                                    message: 'You have been successfully logged in. Welcome back to CommonGround!',
                                    type: 'success',
                                })
                            } else {
                                nav('/account/validation-code', {
                                    state: {
                                        uid: res.data.user.id,
                                        token: res.data.user.token,
                                    },
                                })
                            }
                        } else {
                            window.localStorage.removeItem('CGUSER')

                            nav('/account/validation-code', {
                                state: {
                                    uid: res.data.user.id,
                                    token: res.data.user.token,
                                },
                            })
                        }
                    } else {
                        nav('/account/validation-code', {
                            state: {
                                uid: res.data.user.id,
                                token: res.data.user.token,
                            },
                        })
                    }
                }
            }
        } catch (error: any) {
            if (error?.response?.data) {
                return Notification({
                    message: error.response.data.errors,
                    type: 'danger'
                })
            }
            
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
        // alertService.clear()

        // if (device !== '') {
        //     accountService
        //         .login(email, password, device)
        //         .then((res) => {
        //             const local = window.localStorage.getItem('CGUSER')
        //             let sameUser = true

        //             if (res.user.accountVerified === false) {
        //                 return nav('/account/not-verified')
        //             } else if (res.user.accountVerified === true) {
        //                 window.sessionStorage.setItem(
        //                     'CGUSER',
        //                     JSON.stringify({
        //                         id: res.user.id,
        //                         username: res.user.username,
        //                         role: res.user.role,
        //                         roleAdmin: res.user.roleAdmin,
        //                         token: res.user.token,
        //                         avatar: res.user.avatar,
        //                     })
        //                 )

        //                 // check if user logging in is the same user ar cookie
        //                 if (local !== null) {
        //                     const localUser = JSON.parse(local)

        //                     if (localUser.email !== res.user.email) {
        //                         sameUser = false
        //                     }

        //                     if (sameUser) {
        //                         const lastLoginTS = new Date(JSON.parse(local).ts)
        //                         const currentTS = new Date()

        //                         // check if user logged in today otherwise send to 2FA
        //                         if (lastLoginTS.getDate() === currentTS.getDate()) {
        //                             nav('/community')

        //                             login()

        //                             return Notification({
        //                                 message: 'You have been successfully logged in. Welcome back!',
        //                                 type: 'success',
        //                             })
        //                         } else {
        //                             nav('/account/validation-code', {
        //                                 state: {
        //                                     uid: res.user.id,
        //                                     token: res.user.token,
        //                                 },
        //                             })
        //                         }
        //                     } else {
        //                         window.localStorage.removeItem('CGUSER')

        //                         nav('/account/validation-code', {
        //                             state: {
        //                                 uid: res.user.id,
        //                                 token: res.user.token,
        //                             },
        //                         })
        //                     }
        //                 } else {
        //                     nav('/account/validation-code', {
        //                         state: {
        //                             uid: res.user.id,
        //                             token: res.user.token,
        //                         },
        //                     })
        //                 }
        //             }
        //         })
        //         .catch((error) => {
        //             setSubmitting(false)

        //             return Notification({
        //                 message: error,
        //                 type: 'danger',
        //             })
        //         })
        // } else {
        //     return Notification({
        //         message: 'Please refresh the page and try again.',
        //         type: 'danger',
        //     })
        // }
    }

    return (
        <>
            <Masthead />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className='container h-screen mx-auto w-full max-w-xl mt-16 text-center'>
                            <h2 className='text-5xl font-cgbold mt-20'>Login</h2>

                            <div className='bg-white/60 backdrop-blur-sm p-4 my-4'>
                                Welcome back to CommonGround! Please log in below. Are you new here?{' '}
                                <div
                                    className='text-blue1 font-cgbold'
                                    onClick={() => {
                                        nav('/principles', {
                                            state: {
                                                noCheckbox: false,
                                            },
                                        });
                                    }}
                                >
                                    Click here to create an account.
                                </div>
                            </div>

                            <div className='bg-white/60 backdrop-blur-sm border border-black/6 shadow-2xl rounded-lg px-8 pt-6 pb-8 my-16 text-left'>
                                <div className='mb-4'>
                                    <label
                                        className='block font-cgbold leading-6'
                                        htmlFor='username'
                                    >
                                        Email
                                    </label>
                                    <Field
                                        type='text'
                                        name='email'
                                        placeholder='Please enter your email address'
                                        className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='email'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label
                                        className='block font-cgbold leading-6'
                                        htmlFor='password'
                                    >
                                        Password
                                    </label>
                                    <div className='flex items-center'>
                                        <Field
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            placeholder='Enter your password'
                                            className='block w-full shadow rounded-md border-2 focus:border-green1 md:border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                        />
                                        <div
                                            className='ml-2 cursor-pointer'
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Hide size='30' /> : <FormView size='30' />}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name='password'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mb-4'>
                                    <button
                                        type='submit'
                                        className='rounded-2xl bg-blue1 py-2 px-6 text-white font-cgbold'
                                        disabled={isSubmitting}
                                    >
                                        Log in
                                    </button>
                                </div>

                                <div className='mb-4'>
                                    <hr className='border-black/20 my-6' />
                                    <Link
                                        to='/account/forgotten-password'
                                        className='text-blue1 float-right text-sm'
                                    >
                                        Forgotten Password?
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default Login
