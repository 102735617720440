import React from "react";
import Masthead from "../layout/MastHead";
import ScrollToTop from "../layout/ScrollToTop";

const Privacy: React.FC = function () {
 
    return <>
        <Masthead/>
        <div className="w-full flex justify-center">
            <div className="mt-52 md:mt-20 flex flex-col items-start w-full md:w-[60vw] px-2">
                <div className="text-5xl font-cgbold self-center">
                    Privacy Policy
                </div>
                <div className="mt-8 border-8 rounded-lg px-2 py-4 border-blue2 bg-white">This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you register and use CommonGround. Please note that this Privacy Policy concerns your data in relation to using the peer support platform only. For information on how we use your data from the wider research trial (e.g., your responses to our questionnaires) please refer to the participant information sheet. If you have any questions regarding how we collect, use, and store your data, please do not hesitate to contact the research team via email:<a className=" font-cgbold text-blue1">commonground@kcl.ac.uk</a> </div>
                <div className="mt-8">Confidentiality and anonymity are essential to the operation of our peer support platform CommonGround. King’s College London will always maintain confidentiality and you will remain anonymous to those outside the CommonGround team unless under expectational circumstances as outlined in our <a href="/moderation-policy#moderation" className=" font-cgbold text-blue1">Moderation Policy</a> and <a href="/moderation-policy#safeguarding" className=" font-cgbold text-blue1">Safeguarding Policy.</a> </div>
                <div className="mt-8 font-cgbold">As a member of the CommonGround community, you have agreed to follow the Community Principles, which include the following statements: </div>
                <div className="px-4">
                <ul className="inline-block list-disc list-outside overflow-visible">
                    <li className="mt-4">“Please treat other community members as you wish to be treated. Do not discuss sensitive information that has been shared on CommonGround anywhere else and please do not share information about another community member outside of the platform.”</li>
                    <li className="mt-4">“Do not post any personal details that might identify you or other members, share your social media accounts, or contact information. Please do not try to discover the real identity of other community members.”</li>
                </ul>
                </div>
                <div className="mt-8">These statements intend to protect the confidentiality and anonymity of all members of the community and require the community members to have an active role in this. Whilst we hope that such circumstances do not arise, King’s College London is unable to predict or have full control over the behaviour of our community members, and <strong>protecting the anonymity and confidentiality of the community members is the responsibility of everyone with an account on CommonGround.</strong> If you have any concerns that a community member might be breaking anonymity or confidentiality, contact us via email (<a className=" font-cgbold text-blue1">commonground@kcl.ac.uk</a>) or flag their post to our Moderation Team.</div>
                <div className="mt-8 border-8 rounded-lg px-2 py-4 border-green2 bg-white"><b>The following Privacy Policy concerns the actions of King’s College London and our software developers (BitJam) only.</b> By registering on, and using, CommonGround, you have consented to the collection of your data in accordance with this Privacy Policy. <b>Throughout this Privacy Policy, ‘you’</b> means the individual accessing or using CommonGround.</div>
                <div className="mt-8 grid grid-cols-3 auto-rows-[100px] gap-4">
                    <a 
                        className="p-2 flex text-center text-xs md:text-lg font-cgbold justify-center items-center bg-blue1 rounded-lg text-white"
                        href="#collecting"
                    >
                        Collecting and Using your data
                    </a>
                    <a 
                        className="p-2 flex text-center text-xs md:text-lg font-cgbold justify-center items-center bg-green2 rounded-lg"
                        href="#browser"
                    >
                        Browser Storage and Cookies Use
                    </a>
                    <a 
                        className="p-2 flex text-center text-xs md:text-lg font-cgbold justify-center items-center bg-green1 rounded-lg text-white"
                        href="#protection"
                    >
                        Your data protection rights
                    </a>
                    <a 
                        className="p-2 flex text-center text-xs md:text-lg font-cgbold justify-center items-center bg-purple1 rounded-lg"
                        href="#others"
                    >
                        Links and resources from other websites
                    </a>
                    <a 
                        className="p-2 flex text-center text-xs md:text-lg font-cgbold justify-center items-center bg-blue2 rounded-lg"
                        href="#changes"
                    >
                        Changes to CommonGround Privacy Policy
                    </a>
                    <a 
                        className="p-2 flex text-center text-xs md:text-lg font-cgbold justify-center items-center bg-blue3 rounded-lg"
                        href="#contact"
                    >
                        Contact Us
                    </a>
                </div>
                <div 
                    className="mt-8 scroll-mt-40 self-center bg-blue1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2 b"
                    id="collecting"
                >
                    Collecting and Using your data
                </div>
                <div 
                    className="mt-8 font-cgbold"
                >
                    Personal Data
                </div>
                <div className="mt-8">When registering for CommonGround, you are required to provide some personal information: First name, last name, email address, gender, ethnicity, mobile number, and year of birth. Entering your long-term condition is also an optional entry. This data is used to create your account and facilitate our research team to approve your account and grant you access to CommonGround. This data is also used as required to implement our <a href="/moderation-policy#moderation" className=" font-cgbold text-blue1">Moderation Policy</a> and <a href="/moderation-policy#safeguarding" className=" font-cgbold text-blue1">Safeguarding Policy</a>. Your email address is used to log in to CommonGround, complete the password reset process, and receive updates from the CommonGround team. Your mobile number is required for the <a href="https://www.ncsc.gov.uk/guidance/setting-2-step-verification-2sv" target="_blank" rel="noopener noreferrer" className=" font-cgbold text-blue1">two-factor authentication</a> process when logging in (first login only within a 24-hour period). The research team (KCL) and BitJam (software developers) can see your personal information if necessary (as outlined in our Moderation Policy and Safeguarding Policy). Your personal data will never be routinely shared beyond the research team and BitJam without your permission or used for any other purpose than those outlined in this Privacy Policy. As our <a href="/moderation-policy#safeguarding" className=" font-cgbold text-blue1">Safeguarding Policy</a> states, under exceptional circumstances, we may be required to pass your personal details onto the relevant authorities.</div> 
                <div className="mt-8">We also do not collect any personal data indirectly. The only personal data we collect is provided directly by you. By registering on and using CommonGround you have consented to your personal and usage data being stored on a secure database, in a secure, password-protected electronic drive. Once the trial has ended all data will be deleted from BitJam servers and data will be stored on secure KCL servers for 7 years in line with King’s College London’s Data Retention Policy. If you would like more information about how your data will be processed under the terms of UK data protection laws, please visit the following link: <a href="https://www.kcl.ac.uk/research/support/research-ethics/kings-college-london-statement-on-use-of-personal-data-in-research" target="_blank" rel="noopener noreferrer" className=" font-cgbold text-blue1">KCL statement on the use of personal data in research.</a> </div>
                <div className="mt-8 font-cgbold">Usage Data: Activity on CommmonGround</div>
                <div className="mt-8">Usage Data is the data related to your activity on CommonGround. This usage data includes but is not limited to, the date and time of logins, the number of posts you create, the number of comments you make, and the number of community members you follow. This usage data is collected automatically by BitJam (software developers) following guidance from King’s College London to facilitate the evaluation of the peer support platform. For example, this data helps us to understand how much people use the peer support platform, and the most popular types of activity. This data is therefore used to evaluate how people are using the platform and what they do on the platform, to ultimately inform further development of CommonGround.</div> 
                <div className="mt-8">Usage data will also include the type of device you are using to access CommonGround. Device means any device that can access CommonGround, such as a computer, a smartphone, or a digital tablet. BitJam will collect and store this data on <a href="https://www.mongodb.com/" target="_blank" rel="noopener noreferrer" className=" font-cgbold text-blue1">MongoDB</a> in an encrypted form (encrypted at REST). This encrypted form makes the data unreadable if the server is ever compromised. BitJam will share the usage data of all community members at King’s College London’s request, transferring the de-identified, anonymous, encrypted data via password-protected files. These files will not include your personal information. These files only show your activity linked to a unique, anonymous study ID that you are assigned when you consented to the research study. King’s College London is the data controller, which means that we are responsible for looking after your information and using it properly. The researchers at KCL can also download this data through their admin accounts on CommonGround. The ability to download this data from the admin panel is restricted to approved members of the research team. </div>
                <div className="mt-8 font-cgbold">Usage data: Posts and comments on CommonGround</div>
                <div className="mt-8">Usage data also includes the content that you choose to share on CommonGround in the form of a post (titles, post content, any attachments added, links [URLs] added), the form of comments (on your own or community member’s post, on a resource or post shared by the CommonGround team). BitJam will collect and store this data on <a href="https://www.mongodb.com/" target="_blank" rel="noopener noreferrer" className=" font-cgbold text-blue1">MongoDB</a> in an encrypted form (encrypted at REST). This encrypted form makes the data unreadable if the server is ever compromised. BitJam will share the usage data of all community members at King’s College London’s request, transferring the de-identified, anonymous, encrypted data via password-protected files. These files will not include your personal information. These files only show your activity linked to a unique, anonymous study ID that you are assigned when you consented to the research study.</div>
                <div className="mt-8">The posts that you request the CommonGround team to delete. When you request for one of your posts to be deleted by the CommonGround team, your post will be deleted as soon as feasibly possible by the team. In circumstances when you have requested us to delete your post, the title and content of your post will be overwritten with ‘title deleted at user request’ and ‘content deleted at user request’ respectively. All comments are also deleted, whether created by yourself or another community member. There will however be a record of the date and time at which you created the original post.  If you wish to remove the post from the community feed in the meantime, you can choose to ‘hide this post’. Please be aware that requests to delete posts cannot be reversed. If the post you request to be deleted contains content outlined in our Safeguarding Policy, your post will be removed from CommonGround, but the original content (including but not limited to title, post content, comments, time, and date) will be recorded and shared with the appropriate authorities as necessary (please refer to our <a href="/moderation-policy" className="font-cgbold text-blue1">Moderation Policy</a> and <a href="/moderation-policy" className="font-cgbold text-blue1">Safeguarding Policy</a> for more information).  If you have any questions, please do not hesitate to contact us via email: <a className="font-cgbold text-blue1">commonground@kcl.ac.uk</a>. </div>
                <div className="mt-8">The posts that are deleted by the CommonGround team are in accordance with our Moderation Policy. Under some circumstances (please see the Moderation Policy and Community Principles) the CommonGround team may delete a post or comment you have made. In these cases, where the content is deleted for moderation purposes, the post and related comments are removed from the public platform. However, the original title and content of the post are retained. </div>
                <div 
                    className="mt-8 scroll-mt-40 self-center bg-green2 w-full text-center rounded-2xl text-xl font-cgbold p-2"
                    id="browser"    
                >
                    Browser Storage and Cookies Use
                </div>
                <div className="mt-8"><b>Browser Local Storage</b> refers to data that is stored on your browser (for example, Google Chrome, Safari, Microsoft Edge) that facilitates your experiences of using different websites. The CommonGround website uses browser storage to determine whether you need to complete the two-factor authentication process during login. This makes sure that you only have to complete two-factor authentication once during a 24-hour period, as your browser remembers if you have logged in recently. Please note that two-factor authentication will be required at every log in if you are using a private browser. </div>
                <div className="mt-8"><b>Browser Session Storage</b> refers to data that is stored for a single session and is removed when you close the webpage or log out. This data helps CommonGround to display the correct pages to you, for instance only showing you the Community Feed and ‘My Garden’ pages if the browser detects that you have logged in. </div>
                <div className="mt-8"><b>Cookies</b> are small files that websites commonly us to collect information about how you browse a website. You can read more about cookies and how to control them <a href="https://ico.org.uk/for-the-public/online/cookies/#:~:text=A%20cookie%20is%20a%20small,people%20looking%20at%20a%20website" target="_blank" rel="noopener noreferrer" className=" font-cgbold text-blue1">here</a>.  The cookies that the CommonGround website uses do not identify or use your personal information. If you wish, you can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, refusing Cookies may affect your use of CommonGround. CommonGround uses Cookies to detect whether a user is logged in or not, to make sure that the correct pages and content are shown to you. For example, only displaying the Community Feed and ‘My Garden’ pages if you are logged in, but not if you are logged out. These cookies cannot be used to identify who you are unless someone has malicious access to your personal computer or mobile, or the server is compromised. </div>
                <div 
                    className="mt-8 scroll-mt-40 self-center bg-green1 text-white w-full text-center rounded-2xl text-xl font-cgbold p-2"
                    id="protection"    
                >
                    Your data protection rights
                </div>
                <div className="mt-8">Under <a href="https://www.gov.uk/data-protection" target="_blank" rel="noopener noreferrer" className=" font-cgbold text-blue1">data protection law</a>, you have some rights relating to the data we collect from you on CommonGround. These rights are outlined below. These rights may be limited in a research setting.</div>
                
                <ul className="list-outside list-disc px-4">
                    <li className="mt-4"><span className="font-cgbold">Your right of access.</span> You have the right to ask King’s College London for copies of the personal information you have provided.</li>
                    <li className="mt-4"><span className="font-cgbold">Your right to rectification.</span> You have the right to ask King’s College London to rectify personal information you think is inaccurate or complete information that you believe is incomplete.</li>
                    <li className="mt-4"><span className="font-cgbold">Your right to erasure.</span> You have the right to ask King’s College London to erase your personal information in certain circumstances. Please note, however, that we may need to retain certain information when we have a legal obligation or lawful basis to do so.</li>
                    <li className="mt-4"><span className="font-cgbold">Your right to restriction of processing.</span>  You have the right to ask King’s College London to restrict the processing of your personal information in certain circumstances.</li>
                    <li className="mt-4"><span className="font-cgbold">Your right to object to processing.</span>  You have the right to object to the processing of your personal information in certain circumstances.</li>
                    <li className="mt-4"><span className="font-cgbold">Your right to data portability.</span>  You have the right to ask that King’s College London transfer the personal information you gave us to another organisation, or you, in certain circumstances. As outlined above, we will not share your personal information with anyone beyond the research team (King’s College London) or software developers (BitJam) without your permission, unless required as our Safeguarding Policy outlines.</li>
                </ul>
                <div className="mt-8">Exercising any of your rights above does not incur a charge or penalty. If you wish to make a request, please contact us via email at <a className=" font-cgbold text-blue1">commonground@kcl.ac.uk</a> with the subject line Data Protection Rights Request. If you make a request, King’s College London has one month to respond to you. </div>
                <div 
                    className="mt-8 scroll-mt-40 self-center bg-purple1 w-full text-center rounded-2xl text-xl font-cgbold p-2"
                    id="others"    
                >
                    Links and resources from other websites
                </div>
                <div className="mt-8">CommonGround may contain links to external websites or resources that are not owned by King’s College London. Anything that has been shared by the CommonGround team has been checked to the best of our ability to be accurate and secure. Anything that has been shared by a community member has not been checked or approved by the CommonGround team, King’s College London, or BitJam. <strong>For any website that is not operated by us (CommonGround or King’s College London) you are accessing at your own risk, and we strongly advise you to review the Privacy Policy of other websites you may visit.</strong> </div>
                <div 
                    className="mt-8 scroll-mt-40 self-center bg-blue2 w-full text-center rounded-2xl text-xl font-cgbold p-2"
                    id="changes"    
                >
                    Changes to CommonGround Privacy Policy
                </div>
                <div className="mt-8">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You may wish to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>
                <div 
                    className="mt-8 scroll-mt-40 self-center bg-blue3 w-full text-center rounded-2xl text-xl font-cgbold p-2"
                    id="contact"    
                >
                    Contact Us
                </div>
                <div className="mt-8">If you have any questions about this Privacy Policy, you can contact us via email at <a className=" font-cgbold text-blue1">commonground@kcl.ac.uk</a>. Please be aware that this email is not monitored 24/7, and our research team will respond to you as soon as possible. </div>
                <div className="mt-8 bg-blue1 font-cgbold text-white rounded-lg p-4">Thank you for taking the time to read the CommonGround Privacy Policy. If you have any questions please contact the CommonGround team via email on <a className="font-cgbold">commonground@kcl.ac.uk</a>. </div>
                <div></div>
            </div>
        </div>
        <ScrollToTop />
    </>
}

export default Privacy;