import axios from 'axios'
import { useEffect, useState } from 'react'

export const useUser = (id?: string) => {
    
    const session = window.sessionStorage.getItem('CGUSER')
    let loggedInUser: any

    if (session !== null) {
        loggedInUser = JSON.parse(session)
    }

    let requiredUser: any = id

    if (loggedInUser && !requiredUser) {
        if (loggedInUser.id !== requiredUser){
            requiredUser = loggedInUser.id
        }
    }

    const [user, setUser] = useState<any>()

    useEffect(() => {
        const fetchUser = async () => {
            
            if (requiredUser) {
                try {
                    const res = await axios({
                        method: 'get',
                        url: `${process.env.REACT_APP_SERVER}/api/account/${requiredUser}`,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                    })
    
                    if (res.status === 200 && res.data) {
                        setUser(res.data)
                    } else {
                        console.log('ERROR', res)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }
        fetchUser()
    }, [id, requiredUser])

    return user
}
