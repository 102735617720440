import React from 'react'
import { Link } from 'react-router-dom'
import KingsLogo from '../../assets/images/kings_logo.svg'
import BJLogo from '../../assets/images/bjlogo.png'
import BGHometl from '../../assets/images/bg_home1_tl.svg'
import BGHometr from '../../assets/images/bg_home1_tr.svg'
import BGHomebl from '../../assets/images/bg_home1_bl.svg'
import BGHomebr from '../../assets/images/bg_home1_br.svg'
import BGHome2L from '../../assets/images/bg_home2_l.svg'
import BGHome2R from '../../assets/images/bg_home2_r.svg'
import BGHome3L from '../../assets/images/bg_home3_l.svg'
import BGHome3R from '../../assets/images/bg_home3_r.svg'
import BGHome4L from '../../assets/images/bg_home4_l.svg'
import BGHome4C from '../../assets/images/bg_home4_c.svg'
import BGHome4R from '../../assets/images/bg_home4_r.svg'
import BGHome5L from '../../assets/images/bg_home5_l.svg'
import BGHome5C from '../../assets/images/bg_home5_c.svg'
import BGHome5R from '../../assets/images/bg_home5_r.svg'

const Homepage: React.FC = () => {
    return (
        <div className='text-center md:text-left'>
            <section className='h-1/2 md:h-screen w-screen relative'>
                <img
                    alt=''
                    src={BGHometl}
                    className='absolute object-cover top-0 left-0 md:w-1/4 -z-10'
                />
                <img
                    alt=''
                    src={BGHometr}
                    className='absolute object-cover top-0 hidden md:block right-0 h-1/6 md:h-1/3 -z-10'
                />
                <img
                    alt=''
                    src={BGHomebl}
                    className='absolute object-cover bottom-0 hidden md:block left-0 w-1/3 md:w-1/6 -z-10'
                />
                <img
                    alt=''
                    src={BGHomebr}
                    className='absolute object-cover bottom-0 right-0 h-1/5 md:h-1/2 -z-10'
                />
                <div className='container mx-auto p-4 flex flex-col justify-center items-center h-1/2 md:h-full z-10'>
                    <h1 className='text-4xl font-cgextrabold mt-16 md:text-7xl'>
                        CommonGround
                    </h1>
                    <h1 className='text-4xl font-cgextrabold text-blue1 my-16 md:text-5xl'>
                        Dedicated to connecting people living with long-term conditions
                    </h1>
                    <p className='bg-white/60 backdrop-blur-sm p-3 rounded-sm'>
                        CommonGround is a community founded on the belief that physical and mental well-being are
                        connected and influence one another. We are an online platform dedicated to people living with
                        long-term conditions to support one another, learn, and share experiences.
                    </p>
                    <p className='mt-6 bg-white/60 backdrop-blur-sm p-3 rounded-sm'>
                        CommonGround has been carefully created through collaboration between a team of researchers from
                        King's College London and people living with long-term physical health conditions. You can read
                        about CommonGround and how it was created{' '}
                        <a
                            href='/aboutus'
                            title='About Us'
                            className='font-cgbold text-blue1'
                        >
                            here
                        </a>
                        .
                    </p>
                    <div className='flex flex-col md:flex-row justify-center items-center mt-6'>
                        <img
                            alt='kings college london logo'
                            src={KingsLogo}
                            className='w-1/4 md:w-1/12 mt-6 cursor-pointer'
                        />
                        <img
                            alt='bitjam logo'
                            src={BJLogo}
                            className='w-1/4 md:w-1/12 mt-6 ml-6 cursor-pointer'
                        />
                    </div>
                </div>
            </section>

            <section className='h-1/2 mt-12 md:mt-0 md:h-[70vh] w-screen relative'>
                <img
                    alt=''
                    src={BGHome2L}
                    className='absolute top-1/2 -translate-y-1/2 -z-10'
                />
                <img
                    alt=''
                    src={BGHome2R}
                    className='absolute top-2/3 right-0 md:bottom-0 w-1/4 -z-10'
                />
                <div className='flex flex-col justify-center items-center h-full md:max-w-lg md:items-start md:float-right md:mr-96'>
                    <h1 className='text-4xl text-blue1 font-cgbold md:text-7xl'>Learn.</h1>
                    <p className='font-cgbold bg-white/60 backdrop-blur-sm p-3 rounded-sm mt-16 mb-6 md:rounded-xl md:text-xl'>
                        Learning new skills and expanding your knowledge can improve your well-being and boost your
                        self-confidence.
                    </p>
                    <p className='bg-white/60 backdrop-blur-sm p-3 rounded-sm md:rounded-xl'>
                        For example, you might learn how to improve your sleep or improve your problem-solving skills.
                        <br />
                        Our self-help resources have been selected by researchers and experts in mental health, 
                        alongside careful consultations with people with long-term conditions. Some of these resources
                        are tailored for people with long-term conditions, which may help you to improve your emotional
                        and mental health.
                    </p>
                </div>
            </section>

            <section className='h-1/2 mt-12 md:mt-0 md:h-[70vh] w-screen relative'>
                <img
                    alt=''
                    src={BGHome3L}
                    className='absolute w-1/4 md:w-1/4 top-1/2 -translate-y-1/2 -z-10'
                />
                <img
                    alt=''
                    src={BGHome3R}
                    className='absolute w-1/2 bottom-0 right-0 -z-10'
                />
                <div className='flex flex-col justify-center items-center h-full md:max-w-lg md:items-start md:float-left md:ml-96'>
                    <h1 className='text-4xl text-blue1 font-cgbold md:text-7xl'>Share.</h1>
                    <p className='font-cgbold bg-white/60 backdrop-blur-sm p-3 rounded-sm mt-16 mb-6 md:rounded-xl md:text-xl'>
                        A peer support platform is the perfect place to share your experiences of living with a
                        long-term condition.
                    </p>
                    <p className='bg-white/60 backdrop-blur-sm p-3 rounded-sm md:rounded-xl'>
                        Talking about how you feel can help you to understand your emotions. Sharing your experiences
                        and feelings can be rewarding for you and in turn, help you connect with others.
                    </p>
                </div>
            </section>

            <section className='h-1/2 mt-12 md:mt-0 md:h-[70vh] w-screen relative'>
                <img
                    alt=''
                    src={BGHome4L}
                    className='absolute top-1/2 -translate-y-1/2 w-1/4 md:w-1/2 -z-10'
                />
                <img
                    alt=''
                    src={BGHome4C}
                    className='absolute top-1/2 -translate-y-1/2 w-1/2 left-1/2 md:w-1/4 -z-10'
                />
                <img
                    alt=''
                    src={BGHome4R}
                    className='absolute bottom-0 right-0 w-2/3 md:top-0 md:w-1/4 -z-10'
                />
                <div className='flex flex-col justify-center items-center h-full md:max-w-lg md:items-start md:float-right md:mr-96'>
                    <h1 className='text-4xl text-blue1 font-cgextrabold md:text-7xl'>Support.</h1>
                    <p className='font-cgbold bg-white/60 backdrop-blur-sm p-3 rounded-sm mt-16 mb-6 md:rounded-xl md:text-xl'>
                        If you are experiencing a difficult time, you may be looking for support, or you might just want
                        to talk about the day-to-day challenges.
                    </p>
                    <p className='bg-white/60 backdrop-blur-sm p-3 rounded-sm md:rounded-xl'>
                        Because CommonGround has been created specifically for people living with long-term conditions,
                        other community members may know what you are going through. The CommonGround community works
                        best when members give support as much as they receive it.
                    </p>
                </div>
            </section>

            <section className='h-1/2 mt-12 md:mt-0 md:h-[70vh] w-screen relative'>
                <img
                    alt=''
                    src={BGHome5L}
                    className='absolute w-1/2 top-1/2 transform -translate-y-1/2 md:top-48 md:w-1/3 -z-10'
                />
                <img
                    alt=''
                    src={BGHome5C}
                    className='absolute top-1/2 transform -translate-y-1/2 w-1/2 left-1/2 md:w-1/3 -z-10'
                />
                <img
                    alt=''
                    src={BGHome5R}
                    className='absolute top-1/2 right-0 w-1/12 md:top-0 -z-10'
                />
                <div className='flex flex-col justify-center items-center h-full md:max-w-lg md:items-start md:float-left md:ml-96'>
                    <h1 className='text-4xl text-blue1 font-cgextrabold md:text-7xl'>Grow.</h1>
                    <p className='font-cgbold bg-white/60 backdrop-blur-sm p-3 rounded-sm mt-16 mb-6 md:rounded-xl md:text-xl'>
                        With knowledge, comes growth.
                    </p>
                    <p className='bg-white/60 backdrop-blur-sm p-3 rounded-sm md:rounded-xl'>
                        Your CommonGround account comes with your own private space (we call this your garden) where you
                        can save the resources and posts from the community that inspires you or helps you the most.
                        It's safe, secure, and easy to access.
                    </p>
                </div>
            </section>
        </div>
    )
}

export default Homepage
