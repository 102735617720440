import React from 'react'
import { useNavigate } from 'react-router-dom'
import ProfilePicture from '../../assets/images/profile-picture.svg'
import admin from '../../assets/images/admin_avatar.png'
import admin2 from '../../assets/images/admin2_avatar.png'
import avatar1 from '../../assets/images/avatar1.png'
import avatar2 from '../../assets/images/avatar2.png'
import avatar3 from '../../assets/images/avatar3.png'
import avatar4 from '../../assets/images/avatar4.png'
import avatar5 from '../../assets/images/avatar5.png'
import avatar6 from '../../assets/images/avatar6.png'
import avatar7 from '../../assets/images/avatar7.png'
import avatar8 from '../../assets/images/avatar8.png'
import avatar9 from '../../assets/images/avatar9.png'
import { urlParser, userInteractionLog } from '../../helpers/logs'

interface U {
    user: any
}

const SimplifiedUser: React.FC<U> = ({ user }) => {
    const nav = useNavigate()

    const fetchAvatar = () => {
        if (user.role === 'admin') {
            if (user.roleAdmin === 'moderator') {
                return admin
            } else {
                return admin2
            }
        } else if (user.role === 'user' && user.privacy.avatar === true) {
            switch (user.avatar) {
                case 'avatar1':
                    return avatar1
                case 'avatar2':
                    return avatar2
                case 'avatar3':
                    return avatar3
                case 'avatar4':
                    return avatar4
                case 'avatar5':
                    return avatar5
                case 'avatar6':
                    return avatar6
                case 'avatar7':
                    return avatar7
                case 'avatar8':
                    return avatar8
                case 'avatar9':
                    return avatar9
                default:
                    break
            }
        } else {
            return ProfilePicture
        }
    }

    const goToProfile = () => {
        userInteractionLog(urlParser(window.location.href), user, 'profileView', true)
        nav(`/user/profile/${user._id}`)
        return
    } 

    return (
        <>
            {user ? (
                <div className='flex flex-row items-center justify-center mr-1 cursor-pointer'>
                    <img
                        src={fetchAvatar()}
                        alt='Display users avatar'
                        onClick={goToProfile}
                        width='40'
                    />
                    <div className='ml-2 break-all'>{user.username}</div>
                </div>
            ) : null}
        </>
    )
}

export default SimplifiedUser
