import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Notification } from '../notifications'
import Masthead from '../layout/MastHead'
import Menu from './Menu'
import { getUser } from '../auth/LoginState'
import RevokeAdminModal from './RevokeAdminModal'
import PromoteAdminModal from './PromoteAdminModal'

const AllUsers: React.FC = () => {
    const USER = getUser()
    const nav = useNavigate()
    const [users, setUsers] = useState<any>()
    const [manageUser, setManageUser] = useState<string>('')
    const [revokeModal, setRevokeModal] = useState<boolean>(false)
    const [promoteModal, setPromoteModal] = useState<boolean>(false)

    const getUsers = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/admin/users/list`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {},
            })

            if (res.status === 200) {
                setUsers(res.data.users)
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    useEffect(() => {
        if (USER) {
            getUsers()
        }
    }, [setUsers])

    return (
        <>
            <Masthead />
            <div className='container mx-auto w-full max-w-3xl mt-16 text-center'>
                <h2 className='text-5xl font-cgbold mt-20'>All Users</h2>
                <Menu />
            </div>

            <div className='container mx-auto w-full max-w-4xl mt-16 min-h-screen'>
                {users && users.length > 0 ? (
                    <table className='table-auto border-collapse border border-black/20 shadow-lg min-w-full'>
                        <thead>
                            <tr className='bg-blue2'>
                                <th className='py-2 px-4'>#</th>
                                <th className='py-2 px-4'>Username</th>
                                <th className='py-2 px-4'>Admin Role</th>
                                <th className='py-2 px-4'>Email</th>
                                <th className='py-2 px-4'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((i: any, c: any) => {
                                return (
                                    <tr
                                        key={c}
                                        className='border-b border-black/20 hover:bg-blue-100'
                                    >
                                        <td className='py-2 px-4 text-center'>{c + 1}</td>
                                        <td className='py-2 px-4'>
                                            <a
                                                className='font-semibold text-blue1 cursor-pointer'
                                                onClick={() => nav(`/user/profile/${i._id}`)}
                                            >
                                                {i.username}
                                            </a>
                                        </td>
                                        <td className='py-2 px-4 capitalize'>{i.roleAdmin || ''}</td>
                                        <td className='py-2 px-4'>{i.email}</td>
                                        <td className='py-2 px-4 text-center'>
                                            {i.role === 'admin' ? (
                                                <button
                                                    className='rounded-full py-2 px-4 bg-red1 font-cgbold'
                                                    onClick={() => {
                                                        setRevokeModal(true)
                                                        setManageUser(i._id)
                                                    }}
                                                >
                                                    Revoke Admin rights
                                                </button>
                                            ) : (
                                                <button
                                                    className='rounded-full py-2 px-4 bg-green1 text-white font-cgbold'
                                                    onClick={() => {
                                                        setPromoteModal(true)
                                                        setManageUser(i._id)
                                                    }}
                                                >
                                                    Promote to Admin
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className='text-center font-cgbold'>There are currently no users!</div>
                )}

                {revokeModal && (
                    <RevokeAdminModal
                        modal={setRevokeModal}
                        userId={manageUser}
                        getUsers={getUsers}
                    />
                )}

                {promoteModal && (
                    <PromoteAdminModal
                        modal={setPromoteModal}
                        userId={manageUser}
                        getUsers={getUsers}
                    />
                )}
            </div>
        </>
    )
}

export default AllUsers
