import React, { useState } from 'react'
import { Notification } from '../notifications'
import axios from 'axios'
import { useUser } from '../../helpers/useUser'

type ModalProps = {
    modal: (arg: boolean) => void
    userId: string
    getUsers: () => void
}

const PromoteAdminModal: React.FC<ModalProps> = ({ userId, modal, getUsers }) => {
    const user = useUser(userId)
    const loggedInUser = useUser()
    const [adminRole, setAdminRole] = useState<string>('')
    const [error, setError] = useState<boolean>(false)

    const promoteAdmin = async () => {
        if (error === false && adminRole !== '') {
            try {
                const res = await axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_SERVER}/api/admin/users/promote`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${loggedInUser.token}`,
                    },
                    data: {
                        uid: userId,
                        adminRole: adminRole,
                    },
                })

                if (res.status === 201) {
                    getUsers()
                    modal(false)

                    return Notification({
                        message: `User promoted to ${adminRole}!`,
                        type: 'success',
                    })
                } else {
                    return Notification({
                        message:
                            'There was a problem with your request. Please try again later. If the problem continues, please contact developers.',
                        type: 'warning',
                    })
                }
            } catch (error) {
                return Notification({
                    message: error,
                    type: 'danger',
                })
            }
        } else {
            setError(true)
        }
    }

    if (user && loggedInUser) {
        return (
            <>
                <div className='fixed top-0 left-0 z-50 w-full h-full bg-black/80 backdrop-blur-md'>
                    <div className='flex items-center justify-end h-11'>
                        <button
                            className='flex w-10 h-10 rounded-full bg-purple1 items-center justify-center text-xl font-cgbold mr-4'
                            onClick={() => modal(false)}
                        >
                            X
                        </button>
                    </div>

                    <div className='w-full max-h-min mx-auto md:px-6 absolute h-[calc(100%_-_2.75rem)] overflow-y-auto overscroll-contain py-2'>
                        <div className='p-2 md:p-0'>
                            <div className='bg-white max-w-4xl mx-auto rounded-xl p-2 md:p-6'>
                                <h2 className='text-5xl text-center font-cgbold my-2'>Approve User Account</h2>

                                <p className='font-cgbold text-lg text-center my-6'>
                                    Be careful! This user will have access to all admin features.
                                </p>

                                <p className='my-4'>Please select Admin Role:</p>

                                <div className='flex justify-center items-center'>
                                    <input
                                        id='moderator'
                                        type='radio'
                                        name='adminRole'
                                        className='mr-2'
                                        value='moderator'
                                        onChange={(e) => {
                                            setError(false)
                                            setAdminRole(e.target.value)
                                        }}
                                    />
                                    <label
                                        className='block font-cgbold leading-6'
                                        htmlFor='moderator'
                                    >
                                        Moderator
                                    </label>

                                    <input
                                        id='engagement'
                                        type='radio'
                                        name='adminRole'
                                        className='ml-6 mr-2'
                                        value='engagement'
                                        onChange={(e) => {
                                            setError(false)
                                            setAdminRole(e.target.value)
                                        }}
                                    />
                                    <label
                                        className='block font-cgbold leading-6'
                                        htmlFor='engagement'
                                    >
                                        Engagement
                                    </label>
                                </div>

                                {error ? (
                                    <div className='mt-4 text-error text-center font-cgbold'>
                                        Please select Admin Role
                                    </div>
                                ) : null}

                                <div className='mt-4'>
                                    <div className='flex justify-between'>
                                        <button
                                            type='submit'
                                            className='bg-green1 text-white font-cgbold rounded-full py-2 px-6'
                                            onClick={() => {
                                                promoteAdmin()
                                            }}
                                        >
                                            Promote User
                                        </button>
                                        <button
                                            type='button'
                                            className='bg-purple1 font-cgbold rounded-full py-2 px-6'
                                            onClick={() => modal(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}

export default PromoteAdminModal
