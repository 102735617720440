import { accountService } from '../services'

const handleResponse = (response) => {
    return response.text().then((text) => {
        const data = text && JSON.parse(text)

        if (!response.ok) {
            let err
            if (data) {
                if (data.message) {
                    err = data.message
                } else if (data.error) {
                    err = data.error
                } else if (data.errors) {
                    err = data.errors
                    if (data.errors.message) err = data.errors.message
                }
            } else if (response.statusText) {
                err = response.statusText
            }

            // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            if ([401, 403].includes(response.status) && accountService.userValue) {
                accountService.logout()
            } else if (err === 'jwt expired') {
                accountService.logout()
            }

            return Promise.reject(err)
        }

        return data
    })
}

const upload = (url, body, uploadfile) => {
    let formData = new FormData()

    for (var field in body) {
        formData.append(field, body[field])
    }

    if (uploadfile) {
        if (typeof uploadfile.name === 'string') {
            formData.append('uploadfile', uploadfile)
        } else {
            uploadfile.file && formData.append('file', uploadfile.file)
            uploadfile.audio && formData.append('audio', uploadfile.audio)
            uploadfile.picture && formData.append('picture', uploadfile.picture)
        }
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            //'Content-Type': 'multipart/form-data',
            ...authHeader(url),
        },
        credentials: 'include',
        body: formData,
    }
    return fetch(url, requestOptions).then(handleResponse)
}

const authHeader = (url) => {
    //const cookie = Cookies.get('CGUSER')
    //if (cookie) {
    //    const user = JSON.parse(cookie)
    //    const isApiUrl = url.startsWith(process.env.REACT_APP_SERVER)
    //    if (user.token !== '' && isApiUrl) {
    //        return {
    //            Authorization: `Bearer ${user.token}`,
    //        }
    //    } else {
    //        if (user.token && user.token !== '') {
    //            return {
    //                Authorization: `Bearer ${user.token}`,
    //            }
    //        }
    //        return {}
    //    }
    //}
}

const get = (url) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(url),
        credentials: 'include',
    }
    return fetch(url, requestOptions).then(handleResponse)
}

const post = (url, body) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(url),
        },
        credentials: 'include',
        body: JSON.stringify(body),
    }
    return fetch(url, requestOptions).then(handleResponse)
}

const put = (url, body) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(url),
        },
        body: JSON.stringify(body),
    }
    return fetch(url, requestOptions).then(handleResponse)
}

// Prefixed with underscored because delete is a reserved word in javascript
const _delete = (url) => {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(url),
    }
    return fetch(url, requestOptions).then(handleResponse)
}

export const fetchWrapper = {
    get,
    post,
    upload,
    put,
    delete: _delete,
}
