import { fetchWrapper } from '../helpers'

const baseUrl = `${process.env.REACT_APP_SERVER}/api/hashtag`

const getAll = () => {
	return fetchWrapper.get(`${baseUrl}/all`)
}

export const hashtagService = {
	getAll,
}
