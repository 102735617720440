import React from 'react'
import axios from 'axios'
import { Notification } from '../notifications'
import { userAction } from '../../helpers/logs'

interface B {
    id: string
    userToken: string
    userId: string
    isBookmarked: boolean
    getPosts: () => void
}

const Bookmark: React.FC<B> = ({ id, userToken, userId, isBookmarked, getPosts }) => {
    const updateBookmarked = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/post/${id}/bookmark`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${userToken}`,
                },
                data: {
                    postId: id,
                    type: 'post',
                },
            })

            const saved = {
                user: userId,
                postId: id,
                action: isBookmarked ? 'Unsave' : 'Save',
            }

            userAction(userId, 'Post', id, saved.action, {}, {}, {}, {}, {}, saved)

            if (res.status === 201) {
                getPosts()

                return Notification({
                    message: isBookmarked ? 'The post has been removed from your Garden' : 'The post has been successfully saved to your Garden',
                    type: 'success',
                })
            } else {
                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk.',
                    type: 'warning',
                })
            }
        } catch (error: any) {
            return Notification({
                message: error.message ? error.message : error,
                type: 'danger',
            })
        }
    }

    return (
        <>
            {id && (
                <div
                    className={
                        isBookmarked
                            ? 'cursor-pointer py-2 px-6 rounded-full border-2 border-blue1 bg-blue1 text-white font-cgbold text-center flex align-center'
                            : 'cursor-pointer py-2 px-6 rounded-full border-2 border-blue1 text-blue1 font-cgbold text-center flex align-center'
                    }
                    onClick={() => {
                        updateBookmarked()
                    }}
                >
                    {isBookmarked ? <div className='flex my-auto'>Remove from my Garden</div> : <div className='flex my-auto'>Save to my Garden</div>}
                </div>
            )}
        </>
    )
}

export default Bookmark
