// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { Notification } from '../notifications'
import MenuIcon from '../../assets/images/dot_menu.svg'
import { getUser } from '../auth/LoginState'
import { postService, resourceService, alertService } from '../../services'
import axios from 'axios'

type M = {
    item: any
    type: string
    getResources: () => any
    callback: () => void
}

const Moderation: React.FC<M> = ({ item, type, getResources, callback }) => {
    const USER = getUser()
    // const user = accountService.userValue

    const [visible, setVisible] = useState(false)

    const reportItem = () => {}

    const deleteItem = async () => {
        if (USER && USER.role !== 'admin') return
        
        alertService.clear()
        try {

            const res = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_SERVER}/api/resource/${item._id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${USER.token}`,
                },
                data: {},
            })

            if (res.status == 200){
                setVisible(false);
                getResources()
            } else {
                return Notification({
                    message: 'Resource has been deleted successfully.',
                    type: 'success',
                })
            }

        } catch {
            return Notification({
                message: 'Something went wrong.',
                type: 'error',
            })
        }
    }

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (e.target.id !== 'modal') {
                setVisible(false)
            }
        })
    }, [])

    return (
        <>
            {USER && USER.role === 'admin' && (
                <div className=''>
                    <div
                        className='cursor-pointer'
                        onClick={() => {
                            setVisible(!visible)
                        }}
                    >
                        <img
                            src={MenuIcon}
                            id='modal'
                            alt='menu icon'
                        />
                    </div>

                    <div
                        className='absolute bg-white border-2 border-black/20 p-4 rounded-lg shadow-lg cursor-pointer'
                        id='modal'
                        style={{ display: visible ? 'block' : 'none' }}
                    >
                        {USER.role === 'admin' && <div onClick={deleteItem}>Delete</div>}
                    </div>
                </div>
            )}
        </>
    )
}

export default Moderation
