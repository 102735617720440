import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../helpers/useUser'
import ProfilePicture from '../../assets/images/profile-picture.svg'
import admin from '../../assets/images/admin_avatar.png'
import admin2 from '../../assets/images/admin2_avatar.png'
import avatar1 from '../../assets/images/avatar1.png'
import avatar2 from '../../assets/images/avatar2.png'
import avatar3 from '../../assets/images/avatar3.png'
import avatar4 from '../../assets/images/avatar4.png'
import avatar5 from '../../assets/images/avatar5.png'
import avatar6 from '../../assets/images/avatar6.png'
import avatar7 from '../../assets/images/avatar7.png'
import avatar8 from '../../assets/images/avatar8.png'
import avatar9 from '../../assets/images/avatar9.png'
import { getUser } from '../auth/LoginState'
import { Notification } from '../notifications'
import axios from 'axios'
import { urlParser, userInteractionLog } from '../../helpers/logs'

interface U {
    user: any
}

const User: React.FC<U> = ({ user }) => {
    const nav = useNavigate()
    const USER = user
    const loggedInUser = getUser()

    let fetchAvatar = () => {
        if(!USER) return ProfilePicture
        if ( USER.role === 'admin') {
            if (USER.roleAdmin === 'moderator') {
                return admin
            } else {
                return admin2
            }
        } else if (USER.role === 'user') {
            switch (USER.avatar) {
                case 'avatar1':
                    return avatar1
                case 'avatar2':
                    return avatar2
                case 'avatar3':
                    return avatar3
                case 'avatar4':
                    return avatar4
                case 'avatar5':
                    return avatar5
                case 'avatar6':
                    return avatar6
                case 'avatar7':
                    return avatar7
                case 'avatar8':
                    return avatar8
                case 'avatar9':
                    return avatar9
                default:
                    break
            }
        } else {
            return ProfilePicture
        }
    }

    const logNavigation = async (path: string) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/stats/navlog`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: {
                    studyId: USER.role === 'admin' ? 'admin' : USER.studyId,
                    userId: USER._id,
                    path: path,
                },
            })
            if (res.status !== 200) {
                return Notification({
                    message: 'Error with response from server',
                    type: 'danger',
                })
            }
        } catch (error) {
            return Notification({
                message: error,
                type: 'danger',
            })
        }
    }

    const navToUser = () => {
        userInteractionLog(urlParser(window.location.href), user, 'profileView', true)
        nav(`/user/profile/${USER._id}`)
    }

    const navToProfile = () => {
        logNavigation('Own Profile')
        nav('/account/profile')
    }

    useEffect(() => {
        fetchAvatar()
    }, [USER])

    return (
        <>
            {USER? (
                <div className='flex flex-col flextext-center items-center justify-center'>
                    <img
                        src={fetchAvatar()}
                        alt='Display users profile'
                        onClick={() => 
                            USER._id == loggedInUser.id ? navToProfile() : navToUser()
                        }
                        width={50}
                    />
                    <p className='font-cgbold text-sm break-all'>{USER.username}</p>
                </div>
            ) : null}
        </>
    )
}

export default User
