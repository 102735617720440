// @ts-nocheck
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import axios from 'axios'
import { Notification } from '../notifications'
import * as Yup from 'yup'
import Masthead from '../layout/MastHead'

const ForgottenPassword = () => {
    const nav = useNavigate()
    const [msgSuccess, setMsgSuccess] = useState<string>('')
    const [msgError, setMsgError] = useState<string>('')

    const initialValues = {
        email: '',
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Your email address is required to request password reset.'),
    })

    const onSubmit = async ({ email }: { email: string }, { setSubmitting }: { setSubmitting: any }) => {
        try {
            const res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER}/api/account/password-reset`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    email: email.toLowerCase(),
                },
            })

            if (res.status === 200) {
                window.sessionStorage.removeItem('CGUSER')
                window.localStorage.removeItem('CGUSER')

                setMsgSuccess('Your password reset request has been received. Please check your email.')
                setMsgError('')

                return Notification({
                    message: 'Your password reset request has been received. Please check your email.',
                    type: 'success',
                })
            } else {
                setMsgError(
                    'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk'
                )
                setMsgSuccess('')

                return Notification({
                    message:
                        'There was a problem with your request. Please try again later. If the problem continues, please contact the team at commonground@kcl.ac.uk',
                    type: 'warning',
                })
            }
        } catch (error) {
            setMsgError(
                'Account not found. Please make sure that you have correctly entered the email address that you use for CommonGround.'
            )
            setMsgSuccess('')

            return Notification({
                message:
                    'Account not found. Please make sure that you have correctly entered the email address that you use for CommonGround.',
                type: 'danger',
            })
        }
    }

    return (
        <>
            <Masthead />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div className='container h-screen mx-auto w-full max-w-xl mt-16 text-center'>
                            <h2 className='text-5xl font-cgbold mt-20'>Reset password</h2>
                            <div className='bg-white/60 backdrop-blur-sm p-4 my-4'>
                                If you have forgotten your password, please enter your email address below and request a
                                password reset. A link will be sent to your email address that will allow you to reset
                                your password. If you have any questions or experience any issues when resetting your
                                password, please contact the team via email commonground@kcl.ac.uk
                            </div>

                            <div className='bg-white/60 backdrop-blur-sm border border-black/6 shadow-2xl rounded-lg px-8 pt-6 pb-8 my-16 text-left'>
                                <div className='mb-4'>
                                    <label
                                        className='block font-cgbold leading-6'
                                        htmlFor='email'
                                    >
                                        Email
                                    </label>
                                    <Field
                                        type='text'
                                        name='email'
                                        placeholder='Please enter your email address'
                                        className='block w-full shadow rounded-md border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-green1 sm:text-sm sm:leading-6'
                                    />
                                    <ErrorMessage
                                        name='email'
                                        component='div'
                                        className='block text-error font-cgbold mt-2'
                                    />
                                </div>

                                <div className='mb-4'>
                                    <button
                                        type='submit'
                                        className='rounded-2xl bg-green1 py-2 px-6 text-white font-cgbold'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting && <div className='loading'></div>}
                                        Request password reset
                                    </button>
                                </div>

                                {msgSuccess !== '' && (
                                    <div className='my-4 text-blue2 font-semibold text-center'>{msgSuccess}</div>
                                )}

                                {msgError !== '' && (
                                    <div className='my-4 text-red1 font-semibold text-center'>{msgError}</div>
                                )}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ForgottenPassword
