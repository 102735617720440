import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Masthead from '../layout/MastHead'

type LocationState = {
    noCheckbox: boolean
}

const Principles: React.FC = () => {
    const nav = useNavigate()
    const loc = useLocation().state as LocationState
    const [terms, setTerms] = useState(false)
    
    const register = () => {
        if (terms) {
            nav('/account/register')
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Masthead />

            <div className='container max-w-3xl mx-auto bg-white/60 backdrop-blur-sm p-4 mt-16 mb-4 text-center'>
                <h2 className='text-3xl md:text-5xl font-cgbold'>
                    {loc.noCheckbox === false ? (
                        <span>To create an account, you need to agree to the Community Principles.</span>
                    ) : (
                        <span>CommonGround Community Principles</span>
                    )}
                </h2>
                <p className='my-4'>
                    The CommonGround Community Principles have been co-produced by people with long-term health
                    conditions and the research team at King's College London. They help to keep everyone safe and create
                    a supportive, judgement-free community. Everyone is personally responsible for following the
                    Community Principles and for the content they share. You are required to read and agree to follow
                    the principles to create an account.
                </p>
            </div>

            <div className='container max-w-3xl mx-auto'>
                <div className='flex flex-col rounded-md shadow-lg border-4 border-green2 my-8'>
                    <div className='w-full bg-green2 p-4 text-center font-cgbold text-xl'>Please remain anonymous.</div>
                    <div className='w-full p-4'>
                        CommonGround is an anonymous community, meaning that other community members do not know who you
                        are - this can make it easier to share your experiences and become confident in supporting your
                        peers. Do not post any personal details that might identify you or other members, share your
                        social media accounts, or contact information. Please do not try to discover the real identity
                        of other community members. Please note that the CommonGround team, including our moderators,
                        can see your personal details if required.
                    </div>
                </div>

                <div className='flex flex-col rounded-md shadow-lg border-4 border-red1 my-8'>
                    <div className='w-full bg-red1 p-4 text-center font-cgbold text-xl'>
                        Always be respectful to others in the community.
                    </div>
                    <div className='w-full p-4'>
                        Please treat other community members as you wish to be treated. Do not discuss sensitive
                        information that has been shared on CommonGround anywhere else and please do not share
                        information about another community member outside of the platform. Always remember to think
                        before you speak and try to understand how someone else might be feeling. Be appreciative of the
                        support you receive and let community members know what you find more or less useful. Listen to
                        other community members and ask open-ended questions.
                    </div>
                </div>

                <div className='flex flex-col rounded-md shadow-lg border-4 border-purple1 my-8'>
                    <div className='w-full bg-purple1 p-4 text-center font-cgbold text-xl'>The community affinity.</div>
                    <div className='w-full p-4'>
                        CommonGround is for people living with long-term health conditions. We know that they need a
                        space where community members are connected through the shared experience of living with a
                        long-term health condition(s). If you do not have a long-term condition, this platform is not
                        for you, so please do not apply to join - you may find alternative support{' '}
                        <a
                            href='/support'
                            className='font-cgbold text-blue1'
                        >
                            here
                        </a>
                        .{' '}
                        <span className='font-cgbold'>
                            Please note that CommonGround is currently live as part of a research trial run by King's
                            College London, and each account requires approval from the research team
                        </span>
                        . If you have any queries, please do not hesitate to contact us on commonground@kcl.ac.uk.
                    </div>
                </div>

                <div className='flex flex-col rounded-md shadow-lg border-4 border-yellow1 my-8'>
                    <div className='w-full bg-yellow1 p-4 text-center font-cgbold text-xl'>
                        Be supportive to others in the community when you can.
                    </div>
                    <div className='w-full p-4'>
                        The community works best when you try to offer support to other community members when you can.
                        In turn, others will offer you support back. We consider this the power of peer support. We
                        understand that you might not always feel as though you can offer others support, so a simple “I
                        hear you” can go a long way. Sharing your experiences and advice is helpful, but please remember
                        that each member decides what is best for themselves.
                    </div>
                </div>

                <div className='flex flex-col rounded-md shadow-lg border-4 border-green3 my-8'>
                    <div className='w-full bg-green3 p-4 text-center font-cgbold text-xl'>
                        Do not make assumptions about others in the community.
                    </div>
                    <div className='w-full p-4'>
                        We understand community members may have different experiences and you may want to share and
                        discuss reasons for this. Please remember that the community is a safe space for all. Please do
                        not judge community members (or yourself!) at any time. Please try to disengage if a
                        disagreement should occur, and respectfully 'agree to disagree'. Do not talk about other
                        community members in your posts or comments.
                    </div>
                </div>

                <div className='flex flex-col rounded-md shadow-lg border-4 border-green2 my-8'>
                    <div className='w-full bg-green2 p-4 text-center font-cgbold text-xl'>
                        What CommonGround is not.
                    </div>
                    <div className='w-full p-4'>
                        CommonGround is not an emergency medical or crisis service. <span className='font-cgbold'>If you do not feel safe and are in
                        immediate danger, please call 999 for the emergency services.</span> If you need one-to-one support,
                        you can call 116 123 to talk to the <a href='https://www.samaritans.org/' className='text-blue1 font-cgbold'>Samaritans</a> (a free, 24/7 telephone helpline) or text <a href='https://giveusashout.org/' className='font-cgbold text-blue1'>SHOUT </a>
                        to 85258 (a free, 24/7 texting helpline). Remember, the community offers a safe and private
                        space for peer support to develop. The CommonGround community is your peers, they are not
                        medically trained. <span className='font-cgbold'>Please consult your GP or other healthcare professionals for medical advice
                        or to resolve any medical queries you might have.</span>
                    </div>
                </div>

                <div className='flex flex-col rounded-md shadow-lg border-4 border-red1 my-8'>
                    <div className='w-full bg-red1 p-4 text-center font-cgbold text-xl'>Please do not...</div>
                    <div className='w-full p-4'>
                        <ul className='list-disc list-inside'>
                            <li>Post about illegal activity </li>
                            <li>
                                Encourage any type of risky behaviour that is potentially harmful (for example,
                                excessive drinking, illegal drug use, hate speech)
                            </li>
                            <li>
                                Post graphic descriptions of harming yourself, glamorising or stigmatising self-harm,
                                suicide, abuse, or assault.
                            </li>
                            <li>Sell or advertise anything through the platform</li>
                            <li>Share false or misleading information</li>
                            <li>
                                Talk about another community member or moderator in a threatening, inappropriate or
                                disrespectful manner
                            </li>
                            <li>Use excessive swearing or swear at community members</li>
                        </ul>

                        <p className='mt-4'>
                            Please remember that all discussions must be respectful and please be mindful of other
                            community members' views, whether political, religious, or of any other nature.
                        </p>

                    </div>
                </div>

                <p className='mt-4'>
                    What happens if the Community Principles are not followed? Our moderators are regularly
                    checking the discussions on CommonGround. Our moderators are here to keep everyone safe and
                    will take any appropriate action(s) required, including resolving disagreements and
                    privately contacting community members. You can read our Moderation Policy {' '} 
                    <a
                        href='moderation-policy'
                        className='font-cgbold text-blue1'
                        title='Moderation Policy'
                    >
                        here.
                    </a>
                </p>

                {loc.noCheckbox === false ? (
                    <form className='p-4'>
                        <input
                            type='checkbox'
                            name='acceptTerms'
                            id='acceptTerms'
                            className='mr-2'
                            onClick={() => {
                                setTerms(!terms)
                            }}
                        />
                        <label
                            htmlFor='acceptTerms'
                            className='checkbox'
                        ></label>
                        I have read and agree to the CommonGround Community Principles.
                        <input
                            type='button'
                            value='Create Account'
                            className={
                                terms === false
                                    ? 'block btn bg-blue3 text-gray-500 mt-4 mb-10 mx-auto py-2 px-6 rounded-xl font-cgbold'
                                    : 'block btn bg-blue1 text-white mt-4 mx-auto py-2 px-6 rounded-xl font-cgbold cursor-pointer mb-20'
                            }
                            disabled={terms === false ? true : false}
                            onClick={register}
                        />
                    </form>
                ) : null}
            </div>
        </>
    )
}

export default Principles
